/* eslint-disable @typescript-eslint/no-explicit-any */
import { generic } from "@/types";
// function to save form values for later
export const forLater = async (id: string, data: generic): Promise<boolean> => {
  const dataString = JSON.stringify(data);
  localStorage.setItem(id, dataString);
  return true;
};

// function to retrieve form values for later
export const getForLater = (id: string): any => {
  const data = localStorage.getItem(id);
  if (data) {
    return JSON.parse(data);
  }
  return false;
};

// function to remove form values for later
export const removeForLater = (id: string): void => {
  localStorage.removeItem(id);
};
