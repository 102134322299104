
import { defineComponent } from "vue";
import Table from "@/components/Dashboard/Table.vue";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "ManageApplication",
  components: {
    Table,
    Pagination,
  },
  data() {
    return {
      tableData: [
        {
          id: 1,
          name: "Integration of mHealth in Central District to teach mothers how to stop Postpartum...",
          application: "XDDGJEDLTIKR_12JDIK",
          date: "12/05/2021",
          status: "submitted",
          progress: 2,
        },
        {
          id: 2,
          name: "Development of thermostable oxytocin drugs",
          application: "XDDGJEDLTIKR_12JDIK",
          date: "12/05/2021",
          status: "approved",
          progress: 4,
        },
        {
          id: 3,
          name: "Use of Virtual Reality to simulate birthing process, to aid doctors and nurses stop ble...",
          application: "XDDGJEDLTIKR_12JDIK",
          date: "12/05/2021",
          status: "rejected",
          progress: 4,
        },
      ],
      header: ["Program-Areas", "Application-Id", "Date", "Status", ""],
    };
  },
});
