
import { defineComponent } from "vue";

export default defineComponent({
  name: "SubBar",
  components: {},
  data() {
    return {
      url: "",
      access: "",
    };
  },

  mounted() {
    this.url = window.location.pathname;
    this.access =
      (this.$route.query.id as string) ||
      (localStorage.getItem("partner_innovationId") as string);
  },
});
