
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { filterResult } from "@/modules/health-directorate/views/need-draft/Types";
import Invite from "./Invite.vue";

interface technicalDirectorsData {
  id: string;
  username: string;
  email: string;
  department: string;
}

export default defineComponent({
  name: "viewHDTechnicalDirectors",
  components: {
    DataTable,
    Column,
    Invite,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      dataDialog: false,
      technicalDirectorDialog: false,
      inviteDialog: false,
      title: "Technical Director",
      programAreaProp,
      datas: [] as Array<technicalDirectorsData>,
      data: {} as technicalDirectorsData,
      user: {} as technicalDirectorsData,
    };
  },
  methods: {
    ...mapMutations("HEALTH_DIRECTORATE_STORE", ["SET_TD"]),
    async getData() {
      this.loading = true;
      await this.$store.dispatch(
        "HEALTH_DIRECTORATE_STORE/GET_TECHNICAL_DIRECTORS"
      );
      this.datas = await this.$store.getters["HEALTH_DIRECTORATE_STORE/getTDs"];
      this.loading = false;
    },
    // confirmArchiveTechnicalDirector(data: technicalDirectorsData) {
    //   this.data = data;
    //   this.dataDialog = true;
    // },
    // archiveTechnicalDirector() {
    //   this.datas = this.datas.filter((val) => val.id !== this.data.id);
    //   this.dataDialog = false;
    //   this.data = {} as technicalDirectorsData;
    // },
    async viewData(id: string) {
      this.loading = true;
      this.SET_TD(id);
      this.data = await this.$store.getters["HEALTH_DIRECTORATE_STORE/getTD"];
      this.technicalDirectorDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    addTechnicalDirector() {
      this.inviteDialog = true;
    },
  },
  async mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
