
import { defineComponent } from "vue";
import InformaticsInvite from "@/modules/auth/components/invites/Informatics.vue";

export default defineComponent({
  name: "InformaticsInvites",
  components: {
    Invite: InformaticsInvite,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
});
