
import { defineComponent } from "vue";
import CardProgress from "@/components/Card/ProgressCard.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import SideFilter from "@/components/SideFilter.vue";
import { programAreaProp } from "@/props-config";
import { cardStyleTrack, trackDatas, sideFilterBtn } from "./Data";

export default defineComponent({
  name: "HomeProgress",
  components: {
    ScrollArea,
    CardProgress,
    SideFilter,
  },
  data() {
    return {
      cardStyleTrack,
      trackDatas,
      sideFilterBtn,
      programAreaProp,
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
    };
  },
  methods: {
    filterList(value: string) {
      console.log("partner-home", value);
    },
    async programArea(value: { [key: string]: string }) {
      console.log(value);
    },
  },
});
