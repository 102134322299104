
import { defineComponent, PropType } from "vue";
import { cardListData, bodyListData } from "./propConfig";

export default defineComponent({
  name: "Card-List",
  props: {
    body: {
      type: Object as PropType<bodyListData>,
      required: true,
    },
    card: {
      type: Object as PropType<cardListData>,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
  },
});
