
import { defineComponent } from "vue";

export default defineComponent({
  name: "RegistrationModal",
  data() {
    return {
      registerModal: true,
    };
  },
  watch: {
    registerModal: {
      immediate: true,
      handler() {
        if (this.registerModal === false) {
          this.$emit("closeModal");
        }
      },
    },
  },
});
