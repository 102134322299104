import Index from "./Index.vue";
import Application from "./ManageApplications.vue";
import SavedNeeds from "./SavedNeeds.vue";

export default [
  {
    path: "application",
    name: "ApplicationIndex",
    component: Index,
    children: [
      {
        path: "",
        name: "ManageApplication",
        component: Application,
      },
      {
        path: "saved-needs",
        name: "SavedNeeds",
        component: SavedNeeds,
      },
    ],
  },
];
