
import { defineComponent } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { body, Header } from "./Data";
import { programAreaPropAlt } from "@/props-config/application";

export default defineComponent({
  name: "Approved",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      title: "Approved Health Needs",
      loading: false,
      body,
      Header,
      programAreaPropAlt,
    };
  },
});
