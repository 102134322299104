
import { defineComponent } from "vue";
import Card from "@/components/Card/Card.vue";
import WelcomeScreen from "../../components/Welcome.vue";
import Partners from "../../components/Partner.vue";
import WelcomeImageLoader from "../../components/Loader/WelcomeImageLoader.vue";
import {
  Welcome,
  cardStyle,
  cardData,
  partnerData,
  Process,
  whatWeDo,
  howWeDoIt,
  whoDoesIt,
} from "./Data";

export default defineComponent({
  name: "InnovationHome",
  components: {
    WelcomeScreen,
    Card,
    Partners,
    WelcomeImageLoader,
  },
  data() {
    return {
      Welcome,
      cardStyle,
      cardData,
      partnerData,
      Process,
      whatWeDo,
      howWeDoIt,
      whoDoesIt,
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
});
