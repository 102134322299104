
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { switchDashboard } from "@/utils/Switcher";
import RegisterModal from "./RegistrationModal.vue";

export default defineComponent({
  name: "Header",
  components: {
    RegisterModal,
  },
  data() {
    return {
      toggledNav: false,
      authStatus: false,
      registerModal: false,
      Navigation: [
        {
          name: "Home",
          path: "/home",
        },
        {
          name: "Health Priority Needs",
          path: "/priority-needs",
        },
        {
          name: "Innovation Proposal",
          path: "/submit-proposal",
        },
        {
          name: "Partner With Us",
          path: "/partners",
        },
        {
          name: "Innovations In The Field",
          path: "/innovation-field",
        },
      ],
      DashboardLink: "",
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
    }),
  },
  methods: {
    ...mapActions("AUTH_STORE", ["LOGOUT"]),
    goBackToDashboard() {
      const category = localStorage.getItem("category");
      if (category !== null) {
        switchDashboard(category);
      }
    },
    logout() {
      this.LOGOUT();
    },
  },
  mounted() {
    this.authStatus = this.authStatusValue;
  },
  watch: {
    $route() {
      this.toggledNav = false;
    },
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
      },
    },
  },
});
