
import { defineComponent, PropType } from "vue";
import { NavData } from "./propConfig";

export default defineComponent({
  name: "Nav-Tab",
  props: {
    navLink: {
      type: Array as PropType<NavData[]>,
      required: true,
      default: () => [
        {
          name: "Application",
          path: "/",
          icon: "icon bi-file-earmark-text-fill",
          notification: "3",
        },
        {
          name: "Saved",
          path: "/",
          icon: "icon bi-star-fill",
          notification: "",
        },
      ],
    },
  },
  data() {
    return {};
  },
  methods: {},
});
