
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Card from "@/components/Card/NeedCard.vue";
import LoginModal from "./LoginModal.vue";
import InterestModal from "./Interest.vue";
import { needType } from "@/types";
import {
  btnStyleAlt,
  btnStyle,
  cardDescriptionStyle,
  content,
  innovationRequirement,
  innovationRequirement2,
} from "../priority-needs/Data";

export default defineComponent({
  name: "ProposalDescription",
  components: {
    Card,
    LoginModal,
    InterestModal,
  },
  data() {
    return {
      id: "",
      btnStyle,
      btnStyleAlt,
      cardDescriptionStyle,
      content,
      innovationRequirement,
      innovationRequirement2,
      isLoaded: false,
      authStatus: null,
      healthNeed: {} as needType,
      interestStatus: false,
      relatedDatas: [] as needType[],
      showLogin: false,
      accountType: "",
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", {
      healthNeedValue: "getHealthNeed",
      datasValue: "getFourHealthNeeds",
    }),
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
      userDetailsValue: "getDetails",
    }),
  },
  watch: {
    healthNeedValue: {
      handler(val) {
        this.healthNeed = val;
      },
      immediate: true,
    },
    datasValue: {
      handler(newValue) {
        this.relatedDatas = newValue;
      },
      deep: true,
    },
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", [
      "GET_HEALTH_NEED",
      "GET_HEALTH_NEEDS",
      "GET_RELATED_HEALTH_NEED",
    ]),

    getFileExt(data: string) {
      const name = data.split(".");
      const fileExt = name[name.length - 1];
      return fileExt;
    },
    getFileName(data: string) {
      const name = data?.split("/");
      return name[name?.length - 1];
    },

    submitInnovation() {
      if (this.authStatusValue === false) {
        this.authStatus = this.authStatusValue;
        this.showLogin = true;
        return;
      }
      sessionStorage.removeItem("BHMIS_HealthNeed");
      const { title, program_areas, specific_program_areas } = this.healthNeed;
      const data = {
        title: title,
        programArea: program_areas[0]?.name,
        subProgramArea: specific_program_areas,
      };
      sessionStorage.setItem("BHMIS_HealthNeed", JSON.stringify(data));
      this.$router.push(
        `/innovator/innovation-submission?id=${this.healthNeed.id}`
      );
    },
    submitPartner() {
      this.interestStatus = true;
    },
  },
  async mounted() {
    this.isLoaded = false;
    this.healthNeed = await this.healthNeedValue;
    if (!this.healthNeed.id) {
      await this.GET_HEALTH_NEED(this.id);
    }

    this.relatedDatas = await this.GET_RELATED_HEALTH_NEED(
      this.healthNeed.id || this.id
    );
    this.authStatus = await this.authStatusValue;
    this.accountType = await this.userDetailsValue?.account_type;
    this.isLoaded = true;
  },
  async created() {
    this.id = this.$route.query.id as string;
  },
});
