
import { defineComponent, PropType } from "vue";
import { mapGetters } from "vuex";
import { NavData } from "./propConfig";

export default defineComponent({
  name: "SideNav",
  props: {
    showSideBar: Boolean,
    showMiniSideBar: Boolean,
    Navigation: {
      type: Array as PropType<NavData[]>,
      required: true,
    },
  },
  data() {
    return {
      activeNav: "",
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getAccountType"]),
    accountType() {
      return this.convertAccountType;
    },
  },
  methods: {
    convertAccountType() {
      switch (this.getAccountType) {
        case "innovators":
          return "Innovator";
        case "partners":
          return "Partner";
        case "admin":
          return "ADMIN";
        case "program_coordinators":
          return "Program  Coordinator";
        case "informatics_officials":
          return "Admin Dashboard";
        default:
          return "";
      }
    },
    shorterSides() {
      this.$emit("miniSideToggle");
    },
  },
});
