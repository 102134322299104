import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-lg container-fluid py-3" }
const _hoisted_2 = { class: "border-bottom mb-lg-3 mb-md-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Nav = _resolveComponent("Nav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Breadcrumb, {
        home: _ctx.home,
        model: _ctx.items
      }, null, 8, ["home", "model"])
    ]),
    _createVNode(_component_Nav, { navLink: _ctx.navLink }, null, 8, ["navLink"]),
    _createVNode(_component_router_view)
  ]))
}