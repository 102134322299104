
import { defineComponent } from "vue";
import DeclarationForm from "../../components/DeclarationForm.vue";
import List from "@/components/Dashboard/List.vue";

export default defineComponent({
  name: "PartnershipDeclarationForm",
  components: { DeclarationForm, List },
  data() {
    return {
      data: [
        {
          id: 1,
          title: "HIV prevalence in Mothers",
          time: "32/02/9999",
        },
        {
          id: 2,
          title: "HIV prevalence in Mothers",
          time: "32/02/9999",
        },
      ],
    };
  },
});
