import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-224dfdac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 py-4 container-lg container-fluid" }
const _hoisted_2 = { class: "pb-3" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "d-flex align-items-center justify-content-center no_need" }
const _hoisted_8 = {
  key: 1,
  class: "data_container"
}
const _hoisted_9 = {
  key: 0,
  class: "row"
}
const _hoisted_10 = {
  key: 1,
  class: "message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollArea = _resolveComponent("ScrollArea")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_CardList = _resolveComponent("CardList")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_ScrollArea, { onProgramId: _ctx.programArea }, null, 8, ["onProgramId"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      (_ctx.loading === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
              return _createVNode(_component_Skeleton, {
                width: "100%",
                height: "7rem",
                class: "mb-3",
                key: i
              })
            }), 64))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.getHealthNeeds.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("h4", null, "No Priority Health Need Found for " + _toDisplayString(_ctx.tagTitle), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.getHealthNeeds.length > 0)
              ? (_openBlock(), _createElementBlock("main", _hoisted_8, [
                  (_ctx.Message === '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getHealthNeeds, (item, i) => {
                          return (_openBlock(), _createBlock(_component_CardList, {
                            key: i,
                            body: item,
                            card: _ctx.cardListStyle,
                            route: `/submit-proposal/call-for-proposal/${item.id}?id=${item.id}`
                          }, null, 8, ["body", "card", "route"]))
                        }), 128))
                      ]))
                    : (_ctx.Message !== '')
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_10, _toDisplayString(_ctx.Message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])),
      (_ctx.getHealthNeeds.length > 0)
        ? (_openBlock(), _createBlock(_component_Paginator, {
            key: 2,
            rows: _ctx.getPagination.size,
            totalRecords: _ctx.getPagination.total_records,
            onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event)))
          }, null, 8, ["rows", "totalRecords"]))
        : _createCommentVNode("", true)
    ])
  ]))
}