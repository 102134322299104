
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Chart from "primevue/chart";
import Carousel from "primevue/carousel";
import moment from "moment";
import Toast from "@/utils/Toast";
import Card from "@/components/Card/ImgCard.vue";
import CardList from "@/components/Card/CardList.vue";
import BoxOverview from "@/components/Dashboard/Overview.vue";
import {
  Welcome,
  cardStyle,
  cardListStyle,
  listDatas,
  Datas,
  options,
  responsiveOption,
} from "./Data";
import WelcomeScreen from "../../components/Welcome.vue";
import WelcomeImageLoader from "../../components/Loader/WelcomeImageLoader.vue";

export default defineComponent({
  name: "In The Field",
  components: {
    WelcomeScreen,
    WelcomeImageLoader,
    Card,
    Carousel,
    CardList,
    Chart,
    BoxOverview,
  },
  data() {
    return {
      innovationList: [],
      basicData: {},
      option: options,
      responsiveOptions: responsiveOption,
      Welcome,
      cardStyle,
      cardListStyle,
      listDatas,
      Datas,
      tileData: {
        healthNeeds: 0,
        healthInnovations: 0,
        innovators: 0,
        partners: 0,
      },
      filterAnalytics: "",
      analyticsData: {},
      monthYear: "",
      isLoaded: false,
      loading: true,
      loadMore: false,
      PaginationData: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
      form: {
        title: "",
        description: "",
        author: "",
      },
      partneredInnovationList: [],
      loading1: false,
      showForm: false,
      loading3: false,
      TestimonialsData: [],
    };
  },
  computed: {
    ...mapGetters(["getAnalytics"]),
    ...mapGetters("INNOVATION_STORE", ["getInnovationInTheField"]),
  },
  watch: {
    filterAnalytics: {
      handler() {
        if (this.filterAnalytics !== "") {
          this.getAnalyticsData(this.filterAnalytics);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "GET_PLATFORMS_ANALYTICS",
      "GET_INNOVATION_FIELD",
      "GET_PLATFORMS_ANALYTICS_BY_PERIOD",
      "GET_ALL_PLATFORMS_ANALYTICS",
    ]),
    ...mapActions("INNOVATION_STORE", [
      "GET_INNOVATION_FIELD",
      "GET_TESTIMONIAL",
      "POST_TESTIMONIAL",
    ]),
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATIONS_BY_STATUS"]),
    onImgLoad() {
      this.isLoaded = true;
    },
    // Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.loadMore = true;
        const response = await this.GET_INNOVATION_FIELD({
          status: "PARTNERED&size=2",
          page: data.page + 1,
        });
        this.innovationList = response.data?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => ({
            id: item.id,
            title: item?.proposal_details?.solution_title,
            body: item?.proposal_details?.solution_description,
            innovator: item?.innovator.username,
            firstname: item?.innovator.first_name,
            lastname: item?.innovator.last_name,
            link: true,
          })
        );
        this.PaginationData.size = response?.size;
        this.PaginationData.total_records = response?.total_records;
        const element = document.getElementById("scroll-to");
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadMore = false;
      }
    },
    // =========
    /**
     * @function getData - Get platform analytics data
     * @description function to get platform analytics data
     */
    async getAnalyticsData(data?: string) {
      // get the month and year from the date Wed Jun 01 2022 00:00:00 GMT+0100 (West Africa Standard Time)
      let query = {} as { [key: string]: string };
      if (data !== undefined && data !== "") {
        const month = data && data.toString()?.split(" ")[1];
        const monthNumber = month && moment().month(month).format("M");
        const year = data && data.toString()?.split(" ")[3];
        this.monthYear = month + " " + year;
        query = {
          month: monthNumber,
          year: year,
        };
      }
      // ---------
      try {
        if (query?.month && query?.year) {
          this.analyticsData = await this.GET_PLATFORMS_ANALYTICS_BY_PERIOD(
            query
          );
        } else {
          await this.GET_PLATFORMS_ANALYTICS();
        }
        if (Object.keys(this.getAnalytics).length !== 0) {
          this.loading = true;
          this.analyticsData = {
            labels: [
              "Published Needs",
              "Published Innovations",
              "Innovators",
              "Partners",
            ],
            datasets: [
              {
                label: "Innovation Scaling Platform",
                maxBarThickness: 50,
                backgroundColor: "#7DB9CF",
                data: [
                  this.getAnalytics.needs_published,
                  this.getAnalytics.innovations_published,
                  this.getAnalytics.innovators,
                  this.getAnalytics.partners,
                ],
              },
            ],
          };
        }
      } catch (error) {
        console.log(error);
        return;
      } finally {
        this.loading = false;
      }
    },
    /**
     * @function getData - Get platform analytics data
     * @description function to get platform analytics data
     */
    async getData() {
      try {
        await this.GET_PLATFORMS_ANALYTICS();
        if (Object.keys(this.getAnalytics).length !== 0) {
          this.loading = true;
          this.tileData = {
            healthNeeds: this.getAnalytics.total_needs,
            healthInnovations: this.getAnalytics.total_innovations,
            innovators: this.getAnalytics.innovators,
            partners: this.getAnalytics.partners,
          };
          this.basicData = {
            labels: [
              "Health Needs",
              "Health Innovations",
              "Innovators",
              "Partners",
            ],
            datasets: [
              {
                label: "Innovation Scaling Platform",
                maxBarThickness: 72,
                backgroundColor: "#5DD3FF",
                data: [
                  this.getAnalytics.needs_published,
                  this.getAnalytics.innovations_published,
                  this.getAnalytics.innovators,
                  this.getAnalytics.partners,
                ],
              },
            ],
          };
        }
      } catch (error) {
        console.log(error);
        return;
      } finally {
        this.loading = false;
      }
    },
    async getInnovationData() {
      try {
        const response = await this.GET_INNOVATION_FIELD({
          status: "PARTNERED",
          size: 2,
        });
        this.innovationList = response.data?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => ({
            id: item.id,
            title: item?.proposal_details?.solution_title,
            body: item?.proposal_details?.solution_description,
            innovator: item?.innovator.username,
            firstname: item?.innovator.first_name,
            lastname: item?.innovator.last_name,
            link: true,
          })
        );
        this.PaginationData.size = response?.size;
        this.PaginationData.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
        return;
      } finally {
        this.loading = false;
      }
    },
    async handleTestimonial() {
      this.loading3 = false;
      const { title, description, author } = this.form;
      if (title === "" || description === "" || author === "") {
        Toast.warning("All fields are compulsory");
        return;
      }
      try {
        await this.POST_TESTIMONIAL({
          title,
          description,
          author,
        });
        Toast.success("Testimonial created successfully");
        this.showForm = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading3 = false;
      }
    },
    async getTestimonials() {
      try {
        this.loading1 = true;
        const data = await this.GET_TESTIMONIAL();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const testimonyData = data.map((el: any) => {
          return {
            title: `${el.title}: ${el.author}`,
            body: el.description,
            image: "",
            titleLink: "",
          };
        });
        this.TestimonialsData = testimonyData;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading1 = false;
      }
    },
    async getInnovations() {
      try {
        this.loading1 = true;
        const { data } = await this.GET_INNOVATIONS_BY_STATUS({
          status: "PARTNERED",
          size: 1000,
        });
        this.partneredInnovationList = data.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (el: { [key: string]: any }) => {
            return {
              id: el.id,
              name: el.solution_title.toUpperCase(),
            };
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading1 = false;
      }
    },
  },
  async mounted() {
    this.getData();
    this.getAnalyticsData();
    this.getInnovationData();
    this.getInnovations();
    this.getTestimonials();
  },
});
