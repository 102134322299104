import Role from "@/utils/Role";
import Index from "../views/Index.vue";
import Home from "../views/home/router";
import Proposal from "../views/proposal/router";
import ManageProposal from "../views/manage-proposal/router";
import ManageNeeds from "../views/manage-needs/router";
import CreateNeeds from "../views/manage-needs/CreateNeeds.vue";
import NeedDrafts from "../views/need-draft/router";

import TrackingInnovation from "../views/track-innovation/TrackInnovation.vue";
import Description from "@/modules/innovator/views/home/Description.vue";
import Invite from "../views/technical-directorate/Invite.vue";
import ViewHealthDirectorates from "../views/health-directorate/index.vue";
import ViewTechnicalDirectors from "../views/technical-directorate/index.vue";
import HDSetup from "../views/health-directorate/HDSetup.vue";

// Needs Approval by a technical director
import Approve from "../views/manage-needs/ApproveNeeds.vue";

export default [
  {
    path: "/mda",
    name: "Health Directorate",
    component: Index,
    meta: {
      requiresAuth: true,
      guest: false,
      authorize: [Role.healthDirector],
    },
    children: [
      ...Home,
      {
        path: "create_needs",
        name: "CreateNeeds",
        component: CreateNeeds,
      },
      ...NeedDrafts,
      ...Proposal,
      ...ManageProposal,
      ...ManageNeeds,
      {
        path: "track-innovation",
        name: "TrackingInnovation",
        component: TrackingInnovation,
      },
      {
        path: "track-innovation/:slug",
        name: "Description",
        component: Description,
      },
      {
        path: "health-directorates",
        name: "ViewHealthDirectorates",
        component: ViewHealthDirectorates,
      },
      {
        path: "heath_directorate_setup",
        name: "HDSetup",
        component: HDSetup,
      },
      {
        path: "technical-directors",
        name: "viewHDTechnicalDirectors",
        component: ViewTechnicalDirectors,
      },
      {
        path: "invite-team",
        name: "HDInvites",
        component: Invite,
      },
    ],
  },
  {
    path: "/mda/approve-needs",
    name: "ApprovePriorityNeeds",
    component: Approve,
    // meta: { requiresAuth: false, guest: true },
  },
];
