import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-513972c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-lg-3 col-md-4 col-12 mb-3" }
const _hoisted_2 = { class: "card-body w-100" }
const _hoisted_3 = { class: "card-text" }
const _hoisted_4 = { class: "see_more text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: `${_ctx.route}`,
      onClick: _withModifiers(_ctx.handleDisplay, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["card rounded-1 pb-2 custom_card", [_ctx.card.customCard]])
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.body.proposal_details?.solution_title)
              ? (_openBlock(), _createElementBlock("h5", {
                  key: 0,
                  class: _normalizeClass(["fw-bold", [_ctx.card.titleStyle]]),
                  "data-testid": "solution-title"
                }, _toDisplayString(_ctx.body.proposal_details?.solution_title), 3))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.trimText(_ctx.body.proposal_details?.solution_description, 70)) + "  ", 1),
              _createElementVNode("u", _hoisted_4, [
                _createVNode(_component_router_link, {
                  to: `${_ctx.route}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("See More")
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["to", "onClick"])
  ]))
}