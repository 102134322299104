
import { defineComponent } from "vue";
// import { mapGetters } from "vuex";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import Form from "../components/DeclarationForm.vue";

export default defineComponent({
  name: "PartnerInterest",
  components: {
    Form,
    Header,
    Footer,
  },
  data() {
    return {};
  },
  computed: {},
});
