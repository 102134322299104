
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult, generic } from "@/types/";

export default defineComponent({
  name: "MyInnovationInterest",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      loading: false,
      filters: {} as filterResult,
      title: "Innovations(s)",
      data: [] as generic[],
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getUserId"]),
  },
  methods: {
    ...mapActions("PARTNER_STORE", ["GET_MY_INNOVATIONS_INTEREST"]),
    async getData() {
      try {
        this.loading = true;
        this.data = await this.GET_MY_INNOVATIONS_INTEREST(this.getUserId);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toInnovation(e: any) {
      this.$router.push(
        `/partners/partner-on-proposal/${e.data.id}?id=${e.data.id}`
      );
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        innovation: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
