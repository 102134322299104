
import { defineComponent } from "vue";
import PartnerInvite from "@/modules/auth/components/invites/Partner.vue";

export default defineComponent({
  name: "TDInvites",
  components: {
    PartnerInvite,
  },
});
