
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  props: {},
  data() {
    return {
      newLetter: {
        type: "email",
        name: "newsletter",
        id: "newsletter",
      },
      buttonStyle: "btn-primary rounded-0 border-light border-1 px-5",
    };
  },
  methods: {
    newsLetter() {
      console.log("hello");
    },
  },
});
