
import { defineComponent } from "vue";
import Nav from "@/components/Dashboard/Navs.vue";

export default defineComponent({
  name: "ResourceLibrary",
  components: {
    Nav,
  },
  data() {
    return {
      navLink: [
        {
          name: "Relevant Documents",
          path: "/resource-library",
          notification: "",
        },
        {
          name: "Stages",
          path: "/resource-library/stages",
          notification: "",
        },
      ],
    };
  },
});
