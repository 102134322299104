
import { defineComponent } from "vue";
import ScrollArea from "@/components/ScrollAreas.vue";

export default defineComponent({
  name: "Priority Description",
  components: {
    ScrollArea,
  },
  data() {
    return {
      buttonStyle:
        "btn-primary btn-lg rounded-0 border-primary border-3 text-white py-2 px-5",
      cardDetails: {
        customCard: "border-hover rounded-0 bg-white text-left border-0",
        titleStyle: "",
        customRow: "col-lg-3 col-md-6 col-12",
        image: false,
        imageStyle: "",
      },
      datas: [
        {
          id: 1,
          title: "Postpartum Hermorrhage",
          titleLink: "",
          body: "A heavy bleeding after the birth of your baby. Losing lots of blood quickly can cause a severe drop in your blood pressure. It may lead to shock and death if not treated.",
        },
        {
          id: 2,
          title: "HIV prevalence in Mothers",
          titleLink: "",
          body: "HIV continues to be a major global public health issue, having claimed 34.7 million [26.0–45.8 million] lives so far",
        },
        {
          id: 3,
          title: "Increase in Diabetes in New borns",
          titleLink: "",
          body: "Babies of mothers who have gestational diabetes have a higher risk of developing obesity and type 2 diabetes later in life. Stillbirth. Untreated gestational diabetes can result in a baby's death either before or shortly after birth",
        },
        {
          id: 4,
          title: "Insufficient postnatal drugs in South East",
          titleLink: "",
          body: "Babies of mothers who have gestational diabetes have a higher risk of developing obesity and type 2 diabetes later in life. Stillbirth. Untreated gestational diabetes can result in a baby's death either before or shortly after birth",
        },
      ],
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
    };
  },
  methods: {
    startProcess() {
      if (this.$route.path.split("/")[1] === "innovator") {
        this.$router.push({
          // name: "ApplicationProcess",
          // path: "/innovator/home/application-process",
          name: "Application2",
          path: "/innovator/home/application2",
        });
      }
    },
    async programArea(value: { [key: string]: string }) {
      console.log(value);
    },
  },
});
