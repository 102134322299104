
import { defineComponent } from "vue";
import Card from "@/components/Card/Card.vue";
import LoginModal from "./LoginModal.vue";
import {
  Datas,
  btnStyleAlt,
  btnStyle,
  cardDescriptionStyle,
  content,
  innovationRequirement,
  innovationRequirement2,
} from "./Data";

export default defineComponent({
  name: "Priority Needs Description",
  components: {
    Card,
    LoginModal,
  },
  data() {
    return {
      btnStyle,
      btnStyleAlt,
      cardDescriptionStyle,
      Datas,
      content,
      innovationRequirement,
      innovationRequirement2,
      isLoaded: false,
      showModal: false,
    };
  },
  methods: {
    submit() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push({ path: "/innovator/home" });
      } else {
        this.showModal = !this.showModal;
      }
    },
    close() {
      this.showModal = !this.showModal;
    },
  },
});
