
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import PublishDetails from "../components/PublishDetails.vue";
import MultiLoginModal from "../components/form/MultiLoginModal.vue";

export default defineComponent({
  name: "Publish",
  components: {
    Header,
    Footer,
    Display: PublishDetails,
    FormModal: MultiLoginModal,
  },
  props: {
    layout: {
      type: Boolean,
      required: false,
      default: true,
    },
    ApproveId: {
      type: String,
      required: false,
      default: "",
    },
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: "",
      authStatus: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
    }),
    ...mapGetters("INNOVATION_STORE", {
      datasValue: "getHealthNeed",
    }),
  },
  watch: {
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
      },
    },
    ApproveId: {
      handler() {
        this.getData((this.$route.query.id as string) || this.ApproveId);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    ...mapMutations("INNOVATION_STORE", ["healthNeed"]),
    async getData(id: string) {
      try {
        this.loading = true;
        this.healthNeed();
        await this.GET_HEALTH_NEED(id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleLogin() {
      this.authStatus = this.authStatusValue;
    },
    onEvaluate() {
      this.$emit("close");
    },
  },
  mounted() {
    this.getData((this.$route.query.id as string) || this.ApproveId);
    this.handleLogin();
  },
});
