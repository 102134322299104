const cardStyle = {
  customCard: "border-hover my-2 border-0 card-bg2",
  customTitle: "h6",
  customImage: "",
  customeProgramArea: "",
  customBody: "small py-1",
  customFooter: "",
  customInnovator: "",
  customLocation: "",
};

const Datas = [
  {
    title: "Postpartum Hermorrhage",
    image: "",
    programArea: "Maternal & child Health",
    body: "A heavy bleeding after the birth of your baby. Losing lots of blood quickly can cause a severe drop in your blood pressure. It may lead to shock and death if not treated.",
    location: "Orapa town",
    link: true,
  },
  {
    title: "HIV prevalence in Mothers",
    image: "",
    programArea: "Maternal & child Health",
    body: "HIV continues to be a major global public health issue, having claimed 34.7 million [26.0–45.8 million] lives so far",
    location: "Orapa town",
    link: true,
  },
  {
    title: "Increase in Diabetes in New borns",
    image: "",
    programArea: "Maternal & child Health",
    body: "Babies of mothers who have gestational diabetes have a higher risk of developing obesity and type 2 diabetes later in life. Stillbirth. Untreated gestational diabetes can result in a baby's death either before or shortly after birth",
    location: "Orapa town",
    link: true,
  },
  {
    title: "HIV prevalence in Mothers",
    image: "",
    programArea: "Maternal & child Health",
    body: "HIV continues to be a major global public health issue, having claimed 34.7 million [26.0–45.8 million] lives so far",
    location: "Orapa town",
    link: false,
  },
  {
    title: "Postpartum Hermorrhage",
    image: "",
    programArea: "Maternal & child Health",
    body: "A heavy bleeding after the birth of your baby. Losing lots of blood quickly can cause a severe drop in your blood pressure. It may lead to shock and death if not treated.",
    location: "Orapa town",
    link: false,
  },
  {
    title: "HIV prevalence in Mothers",
    image: "",
    programArea: "Maternal & child Health",
    body: "HIV continues to be a major global public health issue, having claimed 34.7 million [26.0–45.8 million] lives so far",
    location: "Orapa town",
    link: false,
  },
];
export { cardStyle, Datas };
