
import { defineComponent } from "vue";
import Nav from "@/components/Dashboard/Navs.vue";
import chartOptions from "../../components/highChart";
import { programAreaProp } from "@/props-config";

export default defineComponent({
  name: "Proposal",
  components: {
    Nav,
  },
  data() {
    return {
      programAreaProp,
      chartOptions,
      navLink: [
        {
          name: "Submit Proposal",
          path: "/mda/proposal",
        },
        {
          name: "Approved Proposal",
          path: "/mda/proposal/approved",
        },
      ],
    };
  },
});
