import { Path } from "./Paths";

const Role: Role = {
  innovator: Path.innovatorType,
  partner: Path.partnerType,
  informatics: Path.informaticsType,
  healthDirector: Path.healthDirectorType,
  programCord: Path.programCoordinatorType,
  // pending========
  admin: "admin",
  mE: "monitoring_and_evaluation",
};

type Role = {
  [key: string]: string;
};

export default Role;
