import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00202cd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "approve_form container-lg container-fluid" }
const _hoisted_2 = { class: "d-flex align-items-center h-100 justify-content-end prog_container my-3" }
const _hoisted_3 = { class: "progress_bar" }
const _hoisted_4 = { class: "mx-2" }
const _hoisted_5 = { class: "header" }
const _hoisted_6 = { class: "text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Form2 = _resolveComponent("Form2")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ProgressBar, {
            value: _ctx.pageProgress,
            showValue: false,
            class: "w-100"
          }, null, 8, ["value"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("strong", null, "page " + _toDisplayString(_ctx.page) + " of 2", 1)
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("h5", _hoisted_6, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.needDetails?.title), 1),
          _createTextVNode(" Innovation Submission Form ")
        ])
      ]),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.page === 1)
          ? (_openBlock(), _createBlock(_component_Form, { key: 0 }))
          : _createCommentVNode("", true)
      ], 1024)),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_ctx.page !== 1)
          ? (_openBlock(), _createBlock(_component_Form2, {
              key: 0,
              onSubmitProposal: _ctx.handleSubmit,
              loader: _ctx.loading
            }, null, 8, ["onSubmitProposal", "loader"]))
          : _createCommentVNode("", true)
      ], 1024))
    ]),
    _createVNode(_component_Footer)
  ]))
}