
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueSignature from "vue3-signature-pad";
import Steps from "primevue/steps";
import Toast from "@/utils/Toast";
import LoginModal from "./LoginModalPartner.vue";

export default defineComponent({
  name: "InnovatorMouDetail",
  components: {
    VueSignature,
    Steps,
    LoginModal,
  },
  data() {
    return {
      showLogin: true,
      user: "",
      token: "",
      form: {
        fullname: "",
        signature: "",
      },
      datas: {},
      affirm: false,
      btnStyle: "btn-primary rounded-0 px-lg-5 px-md-3 py-2 mx-2",
      loading: false,
      loading1: false,
      showSignature: false,
      options: {
        penColor: "#265785",
      },
      width: 100,
      height: 200,
      activeTitle: 2,
      items: [
        {
          title: 1,
          label: "Innovator Partner MOU terms",
          to: "/innovator/my-innovation/mou-details",
        },
        {
          title: 2,
          label: "Scaling Milestones Road Map",
          to: "/innovator/my-innovation/innovator-partner-mou",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", {
      datasValue: "getInnovatorPartnerMou",
    }),
    ...mapGetters("AUTH_STORE", [
      "isAuthenticated",
      "getAccountType",
      "getUserId",
    ]),
  },
  watch: {
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "PARTNER_SIGNS_MOU",
      "GET_INNOVATOR_PARTNER_MOU",
    ]),
    ...mapActions("PARTNER_STORE", ["GET_PARTNER"]),
    ...mapActions("AUTH_STORE", ["MODAL_LOGOUT"]),
    // Get the data from the server {submitted mou by the innovator}
    async getInnovatorMou() {
      try {
        this.loading = true;
        this.GET_INNOVATOR_PARTNER_MOU(this.token);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // Handle the active link
    toggleActive(title: number) {
      console.log(title);
      this.activeTitle = title;
    },
    nextPage() {
      this.$router.push("/innovator/my-innovation/step2");
    },
    undo() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.signaturePad as any).undoSignature();
    },
    // Handle signature pad
    handleSignature() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = (this.$refs.signaturePad as any).saveSignature();
      this.form.signature = data;
      this.showSignature = false;
    },

    DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
    // Handle form validation
    formValidation() {
      const form = this.$refs.PartnerMou as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        const input = form.querySelector(`[name=${k}]`);
        if (v === "") {
          Error.push(k);
          input?.classList.add("is-invalid");
        } else {
          input?.classList.remove("is-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          `Please fill out all the required fields, ${Error.join("\r\n,\r\n")}`
        );
        return false;
      }
      return true;
    },
    // Handle form submission after the validation function returns true.
    async onSubmit() {
      if (this.formValidation() === false) {
        return;
      }
      if (this.affirm === false) {
        Toast.warning("Please accept the agreement");
        return;
      }
      const data = new FormData();
      data.append("partner_full_name", this.form.fullname);
      const file = this.DataURIToBlob(this.form.signature);
      data.append("partner_signature", file, "partner-signature");
      try {
        this.loading = true;
        await this.PARTNER_SIGNS_MOU({
          token: this.token,
          data,
        });
        Toast.success("Operation Successful");
        setTimeout(() => {
          this.$router.push("/partners");
        }, 2000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.token = this.$route.query.token as string;
    if (this.getAccountType !== "partners") {
      await this.MODAL_LOGOUT();
    } else {
      await this.getInnovatorMou();
    }

    try {
      this.loading = true;
      const response = await this.GET_PARTNER(this.getUserId);
      this.user = response;
      this.form.fullname = `${response.first_name} ${response.last_name}`;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
});
