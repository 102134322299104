
import { defineComponent, PropType } from "vue";
import { checkBoxData } from "./propConfig";

export default defineComponent({
  emits: ["update:checkbox"],
  props: {
    inputType: {
      type: Object as PropType<checkBoxData>,
      required: true,
    },
  },

  setup() {
    /**
     * Typescript Definition for checkbox
     * @param {e} event
     * @returns {boolean}
     */
    const handleCheck = (e: Event) => (e.target as HTMLInputElement).checked;
    return { handleCheck };
  },
});
