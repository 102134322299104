const Welcome = {
  image: "banner-1.jpg",
  title: `Innovations in the Field`,
  subtitle: "",
  body: `The innovations being scaled up are showing great impact.`,
  imageStyling: "",
  titleStyling: "",
  subtitleStyling: "",
  bodyStyling: "",
  buttonStyle:
    "btn-transparent btn-lg rounded-0 border-light text-white py-3 px-5 btn-elevate",
  buttonLink: "",
  buttonText: "",
};

const cardStyle = {
  customCard:
    "border-hover rounded-0 text-dark border-0 pt-2 no-zoom fst-italic w-100",
  titleStyle: "text-center fst-normal",
  customRow: "col-12 bg-transparent",
  image: false,
  imageStyle: "img-circle",
};

const cardListStyle = {
  customCard: "border-hover rounded-0 text-left my-5 text-dark border-0",
  customTitle: "",
  customImage: "",
  customProgramArea: "text-primary",
  customBody: "",
  customFooter: "",
  customInnovator: "",
  customLocation: "",
};

const listDatas = [
  {
    title:
      "Information resources platform for patients on TB and smoking cessation",
    image: "banner-11.jpg",
    programArea: "",
    body: "The platform will incorporate a forum for sharing patient experiences and educational anecdotes, and use “gamification” elements to retain the users’ attention and interest.Gamification refers to the use of game techniques and mechanisms for purposes other than games, such as to improve knowledge retention and user engagement.",
    innovator: "Gidigbo Health",
    location: "",
  },
  {
    title:
      "Clinical decision support systems for TB treatment and smoking cessation",
    image: "banner-3.jpg",
    programArea: "",
    body: "These tools could in future exploit “big data” gleaned from clinical information which is underused (e.g. through multi-country networks providing secure access to TB patient medical records). The application will facilitate the daily work of the practitioner and optimise the treatment of TB patients who may have other comorbidities or health risks (e.g. HIV, NCDs, smoking). See More",
    innovator: "TechnoHealth",
    location: "",
  },
];

const Datas = [
  {
    image: "banner-8.jpg",
    title: "Mariam John",
    titleLink: "",
    body: "I am a mother of five, I lost two of my children due to insufficient drugs, this projects has helped me, I no longer loose children. My community now gets the appropriate medical attention we need. Thank you so much",
  },
  {
    image: "banner-7.jpg",
    title: "John Doe",
    titleLink: "",
    body: "I never believed technology can come to my village, but now, I can send text message to my doctor and tell him how my pregnancy is developing. This is a new thing in our community. Our children will live long.",
  },
];

const options = {
  events: [],
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "",
        usePointStyle: true,
      },
    },
    tooltips: {
      mode: "index",
      intersect: true,
      enabled: false,
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        drawOnChartArea: false,
        color: "#ebedef",
      },
    },
    y: {
      type: "linear",
      display: true,
      position: "right",
      min: 0,
      grid: {
        drawOnChartArea: true,
        color: "#ebedef",
      },
      ticks: {
        beginAtZero: true,
        stepSize: 5,
        color: "#495057",
      },
    },
  },
  tooltips: { enabled: false },
  hover: { mode: null },
  showTooltips: false,
};

const responsiveOption = [
  {
    breakpoint: "1024px",
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: "600px",
    numVisible: 2,
    numScroll: 2,
  },
  {
    breakpoint: "480px",
    numVisible: 1,
    numScroll: 1,
  },
];
export {
  Welcome,
  cardStyle,
  cardListStyle,
  listDatas,
  Datas,
  options,
  responsiveOption,
};
