import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  getInnovators: (state: state) => state.innovators,
  getInnovations: (state: state) => state.innovations,
  getInnovationsByProgramArea: (state: state) => state.innovationsByProgramArea,
  getFourInnovations: (state: state) => state.fourInnovations,
  getMyInnovations: (state: state) => state.myInnovations,
  getInnovator: (state: state) => state.innovator,
  getInnovation: (state: state) => state.innovation,
  getMyInnovation: (state: state) => state.myInnovation,
  getInnovatorPartnerMou: (state: state) => state.innovatorPartnerMou,
  getPage: (state: state) => state.page,
  getPageProgress: (state: state) => state.pageProgress,
  getProposalForm1: (state: state) => state.innovationProposal1,
  getProposalForm2: (state: state) => state.innovationProposal2,
  getProposalForm: (state: state) => state.innovationProposal,
  getInnovationMilestones: (state: state) => state.innovationMilestones,
  getInnovationTimeline: (state: state) => state.innovationTimeline,
  getInnovationPartnerList: (state: state) => state.innovationPartnerList,
  getSignedDeclarationForm: (state: state) => state.signedDeclarationForm,
  getPartnerDeclarationForm: (state: state) => state.partnerDeclarationForm,
  getRelevantDocument: (state: state) => state.relevantDocument,
  getPartnerByInnovator: (state: state) => state.partnersByInnovator,
  getPartnerByInnovationId: (state: state) => state.partnersByInnovationId,
  getMilestones: (state: state) => state.milestones,
};

export default getters;
