
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult, generic } from "@/types/";
import SubBar from "../../components/SubBar.vue";

export default defineComponent({
  name: "MyPartnershipList",
  components: {
    DataTable,
    Column,
    SubBar,
  },
  data() {
    return {
      loading: false,
      filters: {} as filterResult,
      title: "Innovations(s)",
      data: [] as generic[],
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getUserId"]),
  },
  methods: {
    ...mapActions("PARTNER_STORE", ["GET_MY_INNOVATIONS", "GET_PARTNER"]),
    async getData() {
      try {
        this.loading = true;
        this.data = await this.GET_MY_INNOVATIONS(this.getUserId);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toInnovationDetails(e: any) {
      // Pass the innovation id through the 2 tabs
      localStorage.removeItem("partner_innovationId");
      localStorage.removeItem("partner_innovationName");
      localStorage.removeItem("innovator_details");
      localStorage.setItem("partner_innovationId", e.data.id);
      localStorage.setItem("partner_innovationName", e.data.solution_title);
      localStorage.setItem(
        "innovator_details",
        JSON.stringify(e.data.innovator)
      );
      this.$router.push(`/partner/my-partnerships/timeline?id=${e.data.id}`);
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        innovation: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    localStorage.removeItem("partner_innovationId");
    localStorage.removeItem("partner_innovationName");
    localStorage.removeItem("innovator_details");
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
