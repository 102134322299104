<template>
  <div class="card card-body border-0">
    <div class="table-responsive">
      <table class="table table-hover align-top text-primary table-borderless">
        <thead class="border-0">
          <tr class="text-uppercase text-start fs-6 border-0">
            <th scope="col" v-for="item in header" :key="item">
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id" :id="item.id">
            <td>
              {{ item.title }}
            </td>
            <td>
              <strong>{{ item.id }}</strong>
            </td>
            <td>{{ item.date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableAlt",
  props:{
      data: {
        type: Array,
        required: true,
      },
  },
  data() {
    return {
      header: ["Proposal", "Application ID", "Date of submission"],
    };
  },
  methods: {
  },
});
</script>

<style lang="scss" scoped>
table {
  font: normal normal 600 13px/17px Segoe UI;
  tbody {
    tr {
      padding: 3rem 0;
      box-shadow: 0 0.065rem 0.25rem rgb(0 0 0 / 8%);
      th {
        padding-left: 0;
        padding-right: 0;
      }
      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: max-content;
        word-break: keep-all;
        word-wrap: inherit;
        border: 0;
      }
    }
  }
}
</style>
