
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import CardList from "@/components/Card/List.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import Skeleton from "primevue/skeleton";
import { cardListStyle } from "./Data";

export default defineComponent({
  name: "PriorityNeedList",
  components: {
    CardList,
    ScrollArea,
    Skeleton,
  },
  data() {
    return {
      cardListStyle,
      Message: "",
      isLoaded: false,
      loading: false,
      tagTitle: "",
      searchParams: "",
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
      byTag: false,
    };
  },
  watch: {
    searchParams: {
      async handler() {
        if (this.searchParams.length >= 3) {
          await this.search();
        } else if (this.searchParams.length === 0) {
          await this.GET_HEALTH_NEEDS();
        }
      },
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", [
      "GET_HEALTH_NEEDS",
      "GET_HEALTH_NEEDS_BY_STATUS_PAGINATION",
      "SEARCH_HEALTH_NEEDS",
      "GET_HEALTH_NEED_BY_TAG",
    ]),
    gotoForm() {
      this.$router.push("/priority-needs/submit");
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    /**
     * Function to filter the data by the tab selected
     * @param {string} programArea
     * Filtered if programArea is not empty
     * Message if Filtered is empty
     */
    async programArea(value: { [key: string]: string }) {
      this.tagTitle = value.name;
      this.byTag = true;
      if (value.name === "All") {
        this.byTag = false;
        await this.getData();
        return;
      }
      try {
        this.loading = true;
        const response = await this.GET_HEALTH_NEED_BY_TAG({
          id: value.id,
          status: "PUBLISHED",
        });
        this.publishedData = response.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // ==============Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.loading = true;
        const response = await this.GET_HEALTH_NEEDS_BY_STATUS_PAGINATION({
          status: "PUBLISHED",
          page: data.page + 1,
        });
        this.publishedData = response.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      // scroll to the id of the element
      const element = document.getElementById("scroll-area");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      this.loading = false;
    },
    // ==============Handle Search
    async search() {
      try {
        this.loading = true;
        const response = await this.SEARCH_HEALTH_NEEDS({
          status: "PUBLISHED",
          search: this.searchParams,
        });
        this.publishedData = response.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.tagTitle = this.searchParams;
      }
    },
    async getData() {
      try {
        this.loading = true;
        const response = await this.GET_HEALTH_NEEDS();
        this.publishedData = response.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.tagTitle = this.searchParams;
      }
    },
  },
  async mounted() {
    this.getData();
  },
});
