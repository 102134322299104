
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Chart from "primevue/chart";
import Calendar from "primevue/calendar";
import moment from "moment";

type headerData = {
  [key: string]: string | number | boolean;
};

export default defineComponent({
  name: "InformaticsOverview",
  components: {
    Calendar,
    Chart,
  },
  data() {
    return {
      loading: false,
      title: "Overview",
      id: "",
      headerData: [
        {
          title: "Health Needs",
          value: 0,
          bgColor: false,
        },
        {
          title: "Health Innovations",
          value: 0,
          bgColor: false,
        },
        {
          title: "Innovators",
          value: 0,
          bgColor: false,
        },
        {
          title: "Partners",
          value: 0,
          bgColor: false,
        },
      ] as headerData[],
      minDate: new Date(2022, 5),
      maxDate: new Date(),
      filterNeeds: "",
      filterInnovations: "",
      needsAnalytics: {},
      innovationAnalytics: {},
      option: {
        events: [],
        plugins: {
          legend: {
            display: false,
            labels: {
              color: "",
              usePointStyle: true,
            },
          },
          tooltips: {
            mode: "index",
            intersect: true,
            enabled: false,
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              drawOnChartArea: false,
              color: "#ebedef",
            },
          },
          y: {
            type: "linear",
            display: true,
            position: "right",
            min: 0,
            grid: {
              drawOnChartArea: true,
              color: "#ebedef",
            },
            ticks: {
              beginAtZero: true,
              stepSize: 5,
              color: "#495057",
            },
          },
        },
        tooltips: { enabled: false },
        hover: { mode: null },
        showTooltips: false,
      },
      monthYear: moment().format("MMMM YYYY"),
    };
  },
  computed: {
    ...mapGetters(["getAnalytics"]),
  },
  methods: {
    ...mapActions([
      "GET_PLATFORMS_ANALYTICS",
      "GET_PLATFORMS_ANALYTICS_BY_PERIOD",
    ]),
    async getAnalyticsData() {
      try {
        this.loading = true;
        await this.GET_PLATFORMS_ANALYTICS();
        if (Object.keys(this.getAnalytics).length !== 0) {
          this.headerData = [
            {
              title: "Health Needs",
              value: this.getAnalytics.total_needs,
              bgColor: false,
            },
            {
              title: "Health Innovations",
              value: this.getAnalytics.total_innovations,
              bgColor: false,
            },
            {
              title: "Innovators",
              value: this.getAnalytics.innovators,
              bgColor: false,
            },
            {
              title: "Partners",
              value: this.getAnalytics.partners,
              bgColor: false,
            },
          ];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // ------
    /**
     * @function getData - Get platform analytics data
     * @description function to get platform analytics data
     */
    async getHeathNeedsAnalytics(data?: string) {
      // get the month and year from the date Wed Jun 01 2022 00:00:00 GMT+0100 (West Africa Standard Time)
      let query = {} as { [key: string]: string };
      if (data !== undefined && data !== "") {
        const month = data && data.toString()?.split(" ")[1];
        const monthNumber = month && moment().month(month).format("M");
        const year = data && data.toString()?.split(" ")[3];
        this.monthYear = month + " " + year;
        query = {
          month: monthNumber,
          year: year,
        };
      }
      // ---------
      try {
        if (query?.month && query?.year) {
          this.needsAnalytics = await this.GET_PLATFORMS_ANALYTICS_BY_PERIOD(
            query
          );
        } else {
          await this.GET_PLATFORMS_ANALYTICS();
        }
        if (
          Object.keys(this.needsAnalytics).length !== 0 ||
          Object.keys(this.getAnalytics).length !== 0
        ) {
          this.loading = true;
          this.needsAnalytics = {
            labels: [
              "Awaiting Approval",
              "In Evaluation",
              "Published",
              "Rejected",
            ],
            datasets: [
              {
                label: "Innovation Scaling Platform",
                maxBarThickness: 50,
                backgroundColor: "#7DB9CF",
                data: [
                  this.getAnalytics.needs_awaiting_approval,
                  this.getAnalytics.needs_in_evaluation,
                  this.getAnalytics.needs_published,
                  this.getAnalytics.needs_rejected,
                ],
              },
            ],
          };
        }
      } catch (error) {
        console.log(error);
        return;
      } finally {
        this.loading = false;
      }
    },
    // Function to get innovation analytics data
    async getInnovationAnalytics(data?: string) {
      let query = {} as { [key: string]: string };
      if (data !== undefined && data !== "") {
        const month = data && data.toString()?.split(" ")[1];
        const monthNumber = month && moment().month(month).format("M");
        const year = data && data.toString()?.split(" ")[3];
        this.monthYear = month + " " + year;
        query = {
          month: monthNumber,
          year: year,
        };
      }
      try {
        if (query?.month && query?.year) {
          this.innovationAnalytics =
            await this.GET_PLATFORMS_ANALYTICS_BY_PERIOD(query);
        } else {
          await this.GET_PLATFORMS_ANALYTICS();
        }
        if (
          Object.keys(this.innovationAnalytics).length !== 0 ||
          Object.keys(this.getAnalytics).length !== 0
        ) {
          this.loading = true;
          this.innovationAnalytics = {
            labels: [
              "Awaiting Approval",
              "In Evaluation",
              "Published",
              "Rejected",
            ],
            datasets: [
              {
                label: "Innovation Scaling Platform",
                maxBarThickness: 50,
                backgroundColor: "#A3B997",
                data: [
                  this.getAnalytics.innovations_awaiting_approval,
                  this.getAnalytics.innovations_in_evaluation,
                  this.getAnalytics.innovations_published,
                  this.getAnalytics.innovations_rejected,
                ],
              },
            ],
          };
        }
      } catch (error) {
        console.log(error);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    filterNeeds: {
      handler() {
        if (this.filterNeeds !== "") {
          this.getHeathNeedsAnalytics(this.filterNeeds);
        }
      },
      deep: true,
    },
    filterInnovations: {
      handler() {
        if (this.filterInnovations !== "") {
          this.getInnovationAnalytics(this.filterInnovations);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getHeathNeedsAnalytics();
    this.getInnovationAnalytics();
    this.getAnalyticsData();
  },
});
