
import { defineComponent } from "vue";
import CardListDate from "@/components/Card/CardListDate.vue";
import { cardStyle, Datas } from "./Data";

export default defineComponent({
  name: "Submitted",
  components: {
    CardListDate,
  },
  data() {
    return {
      cardStyle,
      Datas,
    };
  },
});
