
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Header from "@/components/Dashboard/Header.vue";
import SideNav from "@/components/Dashboard/SideNav.vue";
import sideRoute from "../routes/SideRoute";
import { sideRouteType } from "@/types";

export default defineComponent({
  name: "DashboardIndex",
  components: {
    Header,
    SideNav,
  },
  data() {
    return {
      sideBar: false,
      miniSideBar: false,
      SideNavigation: [] as sideRouteType[],
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      lastViewedValue: "getLastViewedNeed",
    }),
    ...mapGetters("INNOVATION_STORE", {
      needValue: "getHealthNeed",
    }),
  },
  watch: {
    lastViewedValue: {
      handler() {
        this.SideNavigation = sideRoute;
      },
      immediate: true,
    },
    needValue: {
      handler() {
        this.SideNavigation = sideRoute;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    async getLastViewedNeed() {
      if (this.lastViewedValue !== null) {
        // Ask if the user wants to switch to the last viewed need
        if (confirm(`Do you want to continue from the HEALTH NEED?`)) {
          await this.GET_HEALTH_NEED(this.lastViewedValue);
          setTimeout(() => {
            this.$router.push(
              `/submit-proposal/call-for-proposal/${this.needValue?.title}?id=${this.needValue?.id}`
            );
          }, 1000);
        }
      }
    },
  },
  mounted() {
    console.log(
      `%c 👋🏽, Welcome to BHMIS! \n\n \r\rBHIMS is a platform for the sharing of ideas and innovations in the field of education and research.!`,
      "color: #ccc; font-family:sans-serif; font-size: 1rem; padding-left: 1rem"
    );
  },
  created() {
    this.SideNavigation = sideRoute;
  },
});
