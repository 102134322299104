
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import EvaluateForm from "../components/form/EvaluateInnovationForm.vue";
import MultiLoginModal from "../components/form/MultiLoginModal.vue";

export default defineComponent({
  name: "EvaluateInnovation",
  props: {
    EvaluateId: {
      type: String,
      required: false,
      default: "",
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Header,
    Footer,
    Form: EvaluateForm,
    FormModal: MultiLoginModal,
  },
  data() {
    return {
      id: "",
      userId: "",
      comments: "",
      authStatus: null,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: {} as { [key: string]: any },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      need: {} as { [key: string]: any },
      needId: "",
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
      userIdValue: "getUserId",
    }),
    ...mapGetters("INNOVATOR_STORE", {
      datasValue: "getInnovation",
    }),
    ...mapGetters("INNOVATION_STORE", {
      needValue: "getHealthNeed",
    }),
  },
  watch: {
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
        this.userId = this.userIdValue;
      },
    },
    datasValue: {
      handler() {
        this.data = this.datasValue;
        this.need = this.needValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATION"]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    getFileExt(data: string) {
      const name = data.split(".");
      const fileExt = name[name.length - 1];
      return fileExt;
    },
    getFileName(data: string) {
      const name = data?.split("/");
      return name[name?.length - 1];
    },
    async getData() {
      await this.GET_INNOVATION(this.id);
      this.authStatus = this.authStatusValue;
      this.data = this.datasValue;
      this.userId = this.userIdValue;
      this.needId = await this.datasValue?.need;
      await this.GET_HEALTH_NEED(this.needId);
      this.need = this.needValue;
    },
    async handleLogin() {
      this.authStatus = this.authStatusValue;
    },
    async gotoNeed() {
      this.needId = await this.datasValue?.need;
      await this.GET_HEALTH_NEED(this.needId);
      if (this.needValue?.title) {
        window.open(
          `/submit-proposal/call-for-proposal/${this.needValue?.title}?id=${this.needValue?.id}`,
          "_blank"
        );
      }
    },
    handleFile(file: string) {
      // const url = "https://innovation-scaling-api-service.herokuapp.com" + file;
      window.open(file, "_blank");
    },
    onEvaluate() {
      this.$emit("Close");
    },
  },
  mounted() {
    this.id = (this.$route.query.id as string) || this.EvaluateId;
    this.getData();
    this.handleLogin();
  },
});
