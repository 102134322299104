
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Request from "@/utils/Request";
import Card from "@/components/Card/NeedCard.vue";
import WelcomeScreen from "../../components/Welcome.vue";
import WelcomeImageLoader from "../../components/Loader/WelcomeImageLoader.vue";
import List from "./PriorityNeedsList.vue";
import { Welcome, cardStyle, headerData } from "./Data";

export default defineComponent({
  name: "Priority Needs",
  components: {
    Card,
    WelcomeScreen,
    WelcomeImageLoader,
    List,
  },
  data() {
    return {
      Welcome,
      cardStyle,
      Message: "",
      isLoaded: false,
      loading: false,
      headerData,
      buttonStyle: "btn-primary btn-lg py-2 w-100 rounded-0 btn_sm px-5",
      btn: "btn-transparent rounded-0 border-light text-white py-3 px-5 btn-elevate",
      FourDatas: [],
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", ["getFourHealthNeeds"]),
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEEDS"]),
    gotoForm() {
      this.$router.push("/priority-needs/submit");
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    // GET 4 RECENT NEEDS
    async getFourRecentNeeds() {
      this.loading = true;
      await Request("GET", `health_needs/?status=PUBLISHED&size=4`)
        .then((res) => {
          this.FourDatas = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  async mounted() {
    await this.getFourRecentNeeds();
  },
});
