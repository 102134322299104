import index from "./index.vue";
import Partner from "./Partner.vue";
// import PartnerLogin from "./Partner-login.vue";
// import PartnerRegistration from "./Partner-registration.vue";
import PartnerProposal from "./Partner-Proposal.vue";
import Description from "./Description.vue";

export default [
  {
    path: "/partners",
    name: "PartnerIndex",
    component: index,
    meta: {
      title: "Partner With Us",
      description:
        "BHIMS is a platform for the sharing of ideas and innovations in the field of health.",
      keywords:
        "innovation, innovation in healthcare, partner with an innovation",
      ogTitle: "Innovation | Partner",
      transition: "slide-left",
    },
    children: [
      {
        path: "",
        alias: "home",
        name: "Partner",
        component: Partner,
      },
      // {
      //   path: "login",
      //   name: "PartnerLogin",
      //   component: PartnerLogin,
      // },
      // {
      //   path: "register",
      //   name: "PartnerRegistration",
      //   component: PartnerRegistration,
      // },
      {
        path: "partner-on-proposal",
        name: "PartnerProposal",
        component: PartnerProposal,
      },
      {
        path: "partner-on-proposal/:slug",
        name: "ProposalDescription",
        component: Description,
      },
    ],
  },
];
