export enum Path {
  healthDirectorateDashboard = "/mda/home",
  healthDirectorType = "health_directors",
  // ---
  informaticsDashboard = "/informatics",
  informaticsUrl = "informatics_officials/",
  informaticsType = "informatics_officials",
  // ---
  innovatorDashboard = "/innovator/home",
  innovatorProfile = "/innovator/profile",
  innovatorUrl = "innovators/",
  innovatorType = "innovators",
  // ---
  partnerDashboard = "/partner/home",
  partnerProfile = "/partner/profile",
  partnerUrl = "partners/",
  partnerType = "partners",
  // ---
  programCoordinatorDashboard = "/program-coordinators/home",
  programCoordinatorUrl = "program_coordinators/",
  programCoordinatorType = "program_coordinators",
}
