
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import { useRouter } from "vue-router";
import * as yup from "yup";
import Toast from "@/utils/Toast";

import {
  emailProp,
  usernameProp,
  passwordProp,
  confirm_passwordProp,
} from "@/props-config";
import InviteService from "@/services/invites.service";

export default defineComponent({
  name: "TDInvite",
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const schema = yup.object({
      username: yup.string().required("Username is required"),
      email: yup.string().required().email(),
      password: yup.string().required().min(8),
      confirm_password: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Passwords do not match"),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: confirm_password, errorMessage: confirm_passwordError } =
      useField("confirm_password");

    const onSubmit = handleSubmit(async (values, { resetForm }) => {
      let data = values;
      loading.value = true;

      const { username, email, password } = data;
      const directorate = localStorage.getItem("user_id") || "";

      let formData = {
        username,
        email,
        directorate,
        password,
      };
      try {
        if (directorate === "") {
          Toast.warning("Directorate is required");
          setTimeout(() => {
            router.push("/auth");
          }, 2500);
          return;
        }
        const resp = await InviteService.healthDirectorate(formData);
        if (resp) {
          Toast.info(resp.description || resp.message);
          resetForm();
          setTimeout(() => {
            router.push("/auth/validate_token");
          }, 2500);
        }
      } catch (error) {
        // Toast.warning(error);
        console.log({ error });
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,

      email,
      username,
      password,
      confirm_password,

      emailProp,
      usernameProp,
      passwordProp,
      confirm_passwordProp,

      emailError,
      usernameError,
      passwordError,
      confirm_passwordError,

      buttonStyle:
        "btn-primary btn-lg px-5 py-2 rounded-0 border-primary border-3 mt-4",
    };
  },
  methods: {},
});
