
import { defineComponent, PropType } from "vue";
import { textAreaData } from "./propConfig";

export default defineComponent({
  name: "Text-Area",
  emits: ["^update:"],
  props: {
    inputType: {
      type: Object as PropType<textAreaData>,
      required: true,
    },
    label: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    toolTip: {
      type: Object as PropType<{
        icon: string;
        message: string;
      }>,
      default: () => ({
        icon: "",
        message: "",
      }),
    },
  },
  setup() {
    /**
     * Typescript Definition for checkbox
     * @param {e} event
     * @returns {string}
     */
    const handleTextArea = (e: Event) => (e.target as HTMLInputElement).value;
    return { handleTextArea };
  },
});
