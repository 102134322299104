/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import request from "@/utils/Request";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import { state } from "./types";
// import { state, supervisor, evaluate, interest, publish } from "./types";

const actions: ActionTree<state, unknown> = {
  // ====================== HEALTH NEEDS ======================
  /**
   * @function CREATE_HEALTH_NEEDS - create health needs
   * @param context
   * @param payload
   */
  async CREATE_HEALTH_NEEDS(context, payload): Promise<void> {
    try {
      const { data } = await Request("POST", "health_needs/", payload);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_HEALTH_NEEDS
   * @param commit
   * @param payload {string}
   */
  async GET_HEALTH_NEEDS({ commit }, payload: string): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `health_needs/?status=${payload || "PUBLISHED"}&size=5`
      );
      commit("healthNeeds", data.data);
      commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function SEARCH_HEALTH_NEEDS - function to search health needs
   * @param commit
   * @param payload {string}
   */
  async SEARCH_HEALTH_NEEDS(
    { commit },
    payload: { [key: string]: string }
  ): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `health_needs/?status=${payload.type || "PUBLISHED"}&search=${
          payload.search
        }&size=5`
      );
      commit("healthNeeds", data.data);
      commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_HEALTH_NEEDS_BY_ID
   * @param context
   * @param payload {string}
   * @description get health needs by id then get related health needs
   */
  async GET_HEALTH_NEED(context, payload: string): Promise<void> {
    try {
      const { data } = await Request("GET", `health_needs/${payload}/`);
      context.commit("healthNeed", data);
      if (data?.relatedDatas?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const promises = data.relatedDatas.map(async (item: any) => {
          await context.dispatch("GET_RELATED_HEALTH_NEED", item.id);
        });
        const resp = await Promise.all(promises);
        context.commit("relatedHealthNeed", resp);
      }
      return data;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_RELATED_HEALTH_NEED
   * @param context
   * @param payload {string}
   */
  async GET_RELATED_HEALTH_NEED(context, payload: string): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `health_needs/${payload}/related_needs?size=4`
      );
      return data.splice(0, 4);
    } catch (error: any) {
      // const { detail } = error.response?.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_HEALTH_NEED_BY_TAG
   * @param context
   * @param payload {string}
   */
  async GET_HEALTH_NEED_BY_TAG(
    { commit },
    payload: { [key: string]: string }
  ): Promise<void> {
    try {
      const { id, status } = payload;
      const { data } = await Request(
        "GET",
        `health_needs/?status=${status || "PUBLISHED"}&program_areas=${id}`
      );
      commit("healthNeeds", data.data);
      commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // const { detail } = error.response?.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_HEALTH_NEED_BY_STATUS_PAGINATION
   * @param context
   * @param payload
   */
  async GET_HEALTH_NEEDS_BY_STATUS_PAGINATION(context, payload): Promise<void> {
    const { status, size, page } = payload;
    const { data } = await request(
      "GET",
      `health_needs/?status=${status || "PUBLISHED"}&page=${page}&size=${
        size || 5
      }`
    );
    context.commit("healthNeeds", data.data);
    context.commit(
      "setPagination",
      {
        page: data.page,
        size: data.size,
        total_pages: data.total_pages,
        total_records: data.total_records,
      },
      { root: true }
    );
    return data;
  },
  /**
   * @function APPROVE_HEALTH_NEED
   * @param context
   * @param payload
   */
  async APPROVE_HEALTH_NEED(context, payload): Promise<void> {
    const { id, data } = payload;
    try {
      const response = await Request(
        "POST",
        `health_needs/${id}/approve/`,
        data
      );
      Toast.info("Need has been successfully approved");
      return response.data;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // REJECT HEALTH NEED
  async REJECT_HEALTH_NEED(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("POST", `health_needs/${id}/reject/`, data);
      Toast.info("Need has been successfully rejected");
      return;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * Function to submit & Approve edited health by supervisor
   * @param context
   * @param payload
   * @returns {Promise<void>}
   */
  async EDIT_HEALTH_NEED(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("PATCH", `health_needs/${id}/edit/`, data);
      return;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  // ARCHIVE HEALTH NEED
  async ARCHIVE_HEALTH_NEED(context, id: string): Promise<void> {
    try {
      const { data } = await Request("GET", `health_needs/${id}/archive/`);
      await context.dispatch("GET_HEALTH_NEEDS");
      return data;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // EVALUATE HEALTH NEED
  async EVALUATE_HEALTH_NEED(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("POST", `health_needs/${id}/evaluate/`, data);
      Toast.success("Evaluation submitted successfully");
      return;
    } catch (error: any) {
      console.log(error);
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // EVALUATE HEALTH NEED
  async GET_EVALUATION(context, id: string): Promise<void> {
    try {
      const { data } = await Request("GET", `health_needs/${id}/evaluations/`);
      return data;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // PUBLISH HEALTH NEED
  async PUBLISH_HEALTH_NEED(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("POST", `health_needs/${id}/publish/`, data);
      Toast.info("Need published successfully");
      return;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // PARTNER HEALTH NEED
  async INTEREST_HEALTH_NEED(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request(
        "POST",
        `health_needs/${id}/indicate_partnership_interest/`,
        data
      );
      Toast.info("interest has been successfully registered");
      return;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // ====================== NEED DRAFTS ======================
  // GET ALL NEED DRAFTS
  async GET_NEED_DRAFTS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", "health_needs/need_drafts/all/");
      commit("needDrafts", data);
      return data;
    } catch (error: any) {
      // const { detail } = error.response.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // GET SPECIFIC NEED DRAFT
  async GET_NEED_DRAFT({ commit }, id: string): Promise<void> {
    try {
      const { data } = await Request("GET", `health_needs/need_drafts/${id}/`);
      commit("needDrafts", data);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  // APPROVE NEED DRAFT
  async APPROVE_NEED_DRAFT(context, id: string): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `health_needs/need_drafts/${id}/approve/`
      );
      await context.dispatch("GET_NEED_DRAFTS");
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  // SEND OUT NEED DRAFT
  async SEND_OUT_NEED_DRAFT(context, id: string): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `health_needs/need_drafts/${id}/send_out/`
      );
      await context.dispatch("GET_NEED_DRAFTS");
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details || error);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_INNOVATION_FIELD
   */
  async GET_INNOVATION_FIELD({ commit }, payload): Promise<void> {
    const { status, page, size } = payload;
    try {
      const { data } = await Request(
        "GET",
        page
          ? `innovations/?status=${status || "PARTNERED"}&page=${page}`
          : `innovations/?status=${status || "PARTNERED"}&size=${size}`
      );
      commit("setInnovationField", data.data);
      // commit(
      //   "setPagination",
      //   {
      //     page: Number(data.page),
      //     size: data.size,
      //     total_pages: data.total_pages,
      //     total_records: data.total_records,
      //   },
      //   { root: true }
      // );
      return data;
    } catch (error: any) {
      Toast.error("Error fetching innovations");
      throw error;
    }
  },
  /**
   * @function GET_TESTIMONIAL
   * @description - Get a list of TESTIMONIALS
   */
  async GET_TESTIMONIAL(): Promise<void> {
    try {
      const { data } = await Request("GET", `testimonials/`);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function POST_TESTIMONIAL
   * @description - Create a TESTIMONIAL
   */
  async POST_TESTIMONIAL(context, payload): Promise<void> {
    try {
      const { data } = await Request("POST", `testimonials/`, payload);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
};

export default actions;
