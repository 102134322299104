
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { partnerType } from "@/props-config/partner";
import Toast from "@/utils/Toast";
import { generic } from "@/types";
import { forLater, getForLater, removeForLater } from "@/utils/SaveForLater";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default defineComponent({
  name: "PartnershipDeclarationForm",
  components: {
    TermsAndConditions,
  },
  data() {
    return {
      partnerType,
      termsModal: false,
      loading: false,
      termsProp: {
        text: "I have read and accepted the",
        id: "terms",
      },
      id: "",
      form: {
        type: "",
        why: "",
        how: "",
        steps: "",
        intent: "",
        conflict: "",
        conflictCert: "",
        confidentiality: "",
        resources: "",
        terms: false,
      },
    };
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["PARTNER_WITH_INNOVATION"]),
    formValidation() {
      // check if every item in this.form is not empty else toast the appropriate error message
      const form = this.$refs.innovationPartner as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        if (
          v === "" ||
          v === false ||
          JSON.stringify(v) === "[]" ||
          JSON.stringify(v) === "{}" ||
          v === undefined
        ) {
          // do not validate if k === file && k === middlename
          if (k !== "resources") {
            const input = form.querySelector(`[name=${k}]`);
            Error.push(k + " is required");
            input?.classList.add("is-invalid");
            // push error message to Error array on new line
          }
        } else {
          // find the input that has been filled in form and remove the error class
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          `Please fill out all the required fields, ${Error.join("\n")}`
        );
        return false;
      }
      return true;
    },
    async onSubmit() {
      if (this.formValidation() === false) {
        return;
      }
      const data = {
        type: this.form.type,
        reason: this.form.why,
        process: this.form.how,
        next_steps: this.form.steps,
        bid_intent: this.form.intent,
        conflict_of_interest: this.form.conflict,
        conflict_of_interest_certs: this.form.conflictCert,
        confidentiality: this.form.confidentiality,
        resources_available: this.form.resources,
      };

      try {
        this.loading = true;
        await this.PARTNER_WITH_INNOVATION({ id: this.id, data });
        removeForLater("innovationForm");
        this.$router.push("/partners");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    saveForLater() {
      const formId = "innovationForm";
      const data = {} as HTMLFormElement;
      for (let entry of Object.entries(this.form)) {
        data[entry[0]] = entry[1];
      }
      forLater(formId, data);
      Toast.success("Saved for later");
    },
    async retrieveForm() {
      const data = getForLater("innovationForm");
      if (data) {
        // pop up a confirm dialog to confirm the retrieval
        if (
          confirm("You have a saved form. Do you want to retrieve it?") === true
        ) {
          await this.setFormData(data);
          Toast.success("Retrieved form data");
        } else {
          Toast.info("Form data not retrieved");
          return;
        }
      }
    },
    async setFormData(data: generic) {
      // populate the form with data from localStorage
      this.form.type = data.type;
      this.form.why = data.why;
      this.form.how = data.how;
      this.form.steps = data.steps;
      this.form.intent = data.intent;
      this.form.conflict = data.conflict;
      this.form.conflictCert = data.conflictCert;
      this.form.confidentiality = data.confidentiality;
      this.form.resources = data.resources;
    },
  },
  created() {
    if (this.retrieveForm) {
      this.retrieveForm();
    }
  },
  mounted() {
    this.id = this.$route.query.id as string;
  },
});
