
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import store from "@/store";
import Toast from "@/utils/Toast";
import * as yup from "yup";
import {
  emailProp,
  usernameProp,
  passwordProp,
  confirm_passwordProp,
  partnerTypeProp,
} from "@/props-config";

export default defineComponent({
  name: "PartnerInvite",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      username: yup.string().required("Username is required"),
      email: yup.string().required().email(),
      partner_type: yup.string().required("Partner Type is required"),
      password: yup.string().required().min(8),
      confirm_password: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Passwords do not match"),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: partner_type, errorMessage: partner_typeError } =
      useField("partner_type");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: confirm_password, errorMessage: confirm_passwordError } =
      useField("confirm_password");

    const onSubmit = handleSubmit(async (values, { resetForm }) => {
      let data = values;
      loading.value = true;

      const { username, email, password, partner_type } = data;

      let formData = {
        username,
        email,
        partner_type,
        password,
      };
      await store.dispatch("AUTH_STORE/INVITE_PARTNER", formData);
      Toast.info(
        `Invite Sent!,
        please check ${email} for activation link`
      );
      resetForm();
    });

    return {
      onSubmit,
      loading,

      email,
      username,
      password,
      confirm_password,
      partner_type,

      emailProp,
      usernameProp,
      passwordProp,
      confirm_passwordProp,
      partnerTypeProp,

      emailError,
      usernameError,
      passwordError,
      confirm_passwordError,
      partner_typeError,

      buttonStyle:
        "btn-primary btn-lg px-5 py-2 rounded-0 border-primary border-3 mt-4",
    };
  },
  methods: {},
});
