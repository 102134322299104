
import { defineComponent } from "vue";
import Header from "@/components/Dashboard/Header.vue";
import SideNav from "@/components/Dashboard/SideNav.vue";
import Routes from "../Routes";
import { generic } from "@/types";

export default defineComponent({
  name: "Informatics",
  components: {
    Header,
    SideNav,
  },
  data() {
    return {
      sideBar: false,
      miniSideBar: false,
      SideNavigation: [] as Array<generic>,
    };
  },
  mounted() {
    console.log(
      `%c 👋🏽, Welcome to BHMIS! \n\n \r\rBHIMS is a platform for the sharing of ideas and innovations in the field of education and research.!`,
      "color: #ccc; font-family:sans-serif; font-size: 1rem; padding-left: 1rem"
    );
  },
  created() {
    this.SideNavigation = Routes;
  },
});
