/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStore } from "vuex";
import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import { store as INNOVATION_STORE } from "@/modules/innovation";
import { store as AUTH_STORE } from "@/modules/auth";
import { store as INFORMATICS_STORE } from "@/modules/informatics";
import { store as PARTNER_STORE } from "@/modules/partner";
import { store as INNOVATOR_STORE } from "@/modules/innovator";
import { store as PROGRAM_CORD_STORE } from "@/modules/program-cord";
import { store as HEALTH_DIRECTORATE_STORE } from "@/modules/health-directorate";
import { store as ME_STORE } from "@/modules/m&e";

export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    AUTH_STORE,
    INFORMATICS_STORE,
    HEALTH_DIRECTORATE_STORE,
    PARTNER_STORE,
    INNOVATOR_STORE,
    INNOVATION_STORE,
    PROGRAM_CORD_STORE,
    ME_STORE,
  },
  strict: process.env.NODE_ENV !== "production",
});
