
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import {
  solutionImpactProp,
  contextTypeProp,
  contextProp,
  testedProp,
  testProcessProp,
  criticalProcessProp,
  potentialProp,
  challengesProp,
  selfDescriptionProp,
  fileProp1,
  fileProp2,
  fileProp3,
} from "./InnovationSubmissionProp";
import Toast from "@/utils/Toast";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import Intellectual from "@/components/Intellectual.vue";

export default defineComponent({
  name: "InnovationSubmissionForm",
  components: {
    TermsAndConditions,
    Intellectual,
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      termsModal: false,
      intellectualModal: false,
      loading: false,
      solutionImpactProp,
      contextTypeProp,
      contextProp,
      testedProp,
      testProcessProp,
      criticalProcessProp,
      potentialProp,
      challengesProp,
      selfDescriptionProp,
      fileProp1,
      fileProp2,
      fileProp3,
      buttonStyle: "btn-primary rounded-0 px-5 py-2 mx-2",
      btnStyle: "btn-secondary fw-bold rounded-0 px-5 py-2 mx-2",
      btnStyle1: "btn-transparent fw-bold rounded-0 px-5 py-2 mx-2 d-none",
      form: {
        impact: "",
        contextType: "",
        context: "",
        tested: "",
        testProcess: "",
        criticalProcess: "",
        potential: "",
        challenges: "",
        selfDescription: "",
        privacyPolicy: false,
        intellectualProperty: false,
      },
      file1: "",
      file2: "",
      file3: "",
    };
  },
  watch: {
    loader: {
      handler() {
        this.loading = this.loader;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("INNOVATOR_STORE", [
      "UPDATE_PAGE",
      "UPDATE_PROGRESS",
      "SAVE_INNOVATION2",
    ]),
    updatePage() {
      this.UPDATE_PAGE(1);
      this.UPDATE_PROGRESS(50);
    },
    saveForLater() {
      console.log("save for later");
    },
    formValidation() {
      // check if every item in this.form is not empty else toast the appropriate error message
      const form = this.$refs.innovation2 as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        if (
          v === "" ||
          v === false ||
          JSON.stringify(v) === "[]" ||
          JSON.stringify(v) === "{}" ||
          v === undefined
        ) {
          const input = form.querySelector(`[name=${k}]`);
          if (k !== "testProcess" && k !== "context") {
            Error.push(`${k} is required`);
            input?.classList.add("is-invalid");
          }
        } else {
          // find the input that has been filled in form and remove the error class
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(Error.join(",\r\n "));
        return false;
      }
      return true;
    },
    submitPage() {
      if (this.formValidation() === false) {
        return;
      }
      if (this.form.tested === "Yes" && this.form.testProcess === "") {
        Toast.warning(
          "If yes, which country(s) has it been tested in? Explain the process of testing the solution and the results thereof is required!"
        );

        return false;
      }
      if (this.file1.length === 0) {
        Toast.warning("Please upload a reference letter");
        return;
      }

      const formData = new FormData();
      formData.append("solution_impact", this.form.impact);
      formData.append("solution_impact_context", this.form.contextType);
      if (this.form.context !== "") {
        formData.append("context_description", this.form.context);
      }
      formData.append("is_africa_tested", this.form.tested);
      formData.append("test_process", this.form.testProcess);
      formData.append("critical_next_steps", this.form.criticalProcess);
      formData.append("scale_potential", this.form.potential);
      formData.append("scale_challenges", this.form.challenges);
      formData.append("personal_description", this.form.selfDescription);
      formData.append("reference_letter_doc", this.file1);
      if (this.file2 !== "") {
        formData.append("tech_specs_doc", this.file2); //optional
      }
      if (this.file3 !== undefined && this.file3 !== null) {
        formData.append("video_upload", this.file3);
      }
      // loop through the form data and add to the formData
      // VALIDATION
      this.SAVE_INNOVATION2(formData);
      this.$emit("submitProposal");
    },
  },
  // updated() {
  //   // on update scroll to the top of the page
  //   window.scrollTo(0, 0);
  // },
});
