
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import Form from "../../components/form/ApprovePriorityNeedsForm.vue";

export default defineComponent({
  name: "ApprovePriorityNeeds",
  props: {
    layout: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  components: {
    ApproveHealthNeed: Form,
    Header,
    Footer,
    TermsAndConditions,
  },
  data() {
    return {
      id: "",
      termsModal: false,
    };
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    async getData() {
      await this.GET_HEALTH_NEED(this.id);
    },
  },
  created() {
    this.id = this.$route.query.id as string;
    this.getData();
  },
});
