const Header = [
  { field: "needs", header: "Health Needs", sortable: true, frozen: true },
  { field: "tags", header: "TAGS", sortable: true, frozen: false },
  { field: "location", header: "Location", sortable: true, frozen: false },
  {
    field: "proposals",
    header: "No. Innovation Proposals",
    sortable: true,
    frozen: false,
  },
  {
    field: "partner",
    header: "No. Partners",
    sortable: true,
    frozen: false,
  },
];
const body = [
  {
    needs: "COVID 19",
    tags: "Drugs and Commodity",
    location: "Gaborone",
    proposals: "12",
    partner: 65,
  },
  {
    needs: "Postpartum hemorrhage",
    tags: "Health Services",
    location: "Orapa Town",
    proposals: "12",
    partner: 72,
  },
  {
    needs: "Postpartum hemorrhage",
    tags: "Drugs and Commodity",
    location: "SE District",
    proposals: "122",
    partner: 79,
  },
  {
    needs: "Covid 12",
    tags: "Drugs and Commodity",
    location: "Orapa Town",
    proposals: "126",
    partner: 29,
  },
];

export { body, Header };
