/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import { state } from "./types";
const localUrl = "informatics_officials/";

const actions: ActionTree<state, unknown> = {
  /**
   * @function GET_TAGS -  get all programs areas
   */
  async GET_TAGS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", `tags/`);
      commit("setTags", data);
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function SUB_GET_TAGS -  get all programs areas
   */
  async SUB_GET_TAGS(): Promise<void> {
    try {
      const { data } = await Request("GET", `sub_tags/`);
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function CREATE_PROGRAM_AREA -  get all programs areas
   */
  async CREATE_PROGRAM_AREA(context, payload): Promise<void> {
    try {
      const { data } = await Request("POST", `tags/`, payload);
      Toast.success(`Program area ${data.name} created successfully`);
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function CREATE_SUB_PROGRAM_AREA -  get all programs areas
   */
  async CREATE_SUB_PROGRAM_AREA(context, payload): Promise<void> {
    try {
      const { data } = await Request("POST", `sub_tags/`, payload);
      Toast.success(`Program area ${data.name} created successfully`);
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function EDIT_PROGRAM_AREA -  get all programs areas
   */
  async EDIT_PROGRAM_AREA(context, payload): Promise<void> {
    const { id, form } = payload;
    try {
      const { data } = await Request("PATCH", `tags/${id}/`, form);
      Toast.info(`Program area ${form.name} updated successfully`);
      return data;
    } catch (err: any) {
      const { details } = err.response.data;
      Toast.error(err || details);
      throw details;
    }
  },
  /**
   * @function DELETE_PROGRAM_AREA -  delete tag by id
   */
  async DELETE_PROGRAM_AREA(context, payload): Promise<void> {
    try {
      const { data } = await Request("DELETE", `tags/${payload}/`);
      Toast.success("Operation successful");
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function DELETE_SUB_PROGRAM_AREA -  delete tag by id
   */
  async DELETE_SUB_PROGRAM_AREA(context, payload): Promise<void> {
    try {
      const { data } = await Request("DELETE", `sub_tags/${payload}/`);
      Toast.success("Operation successful");
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_USER_PROFILE -  get user details by id
   * @description - get the user details by user id
   */
  async GET_USER_PROFILE({ commit }, payload): Promise<void> {
    const { route, id } = payload;
    try {
      const { data } = await Request("GET", `${route}${id}/`);
      commit("setUserProfileData", data);
      commit("setUserProfileParams", payload);
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_PARTNER_DECLARATION
   * @description - get the user partnership declarations by user id
   */
  async GET_PARTNER_DECLARATION({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `partners/${payload}/partnership_declarations/`
      );
      commit("setPartnershipDeclarations", data);
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  // GET ALL INFORMATICS OFFICIALS
  async GET_OFFICIALS(context): Promise<void> {
    try {
      const response = await Request("GET", localUrl);
      context.commit("officials", response.data);
      return response.data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },

  /**
   * GET SPECIFIC INFORMATICS OFFICIAL
   * @param context
   * @param payload {id: string}
   * @returns {Promise<void>}
   * todo - to be used when the data is much { compare which is faster fetch request or filter }
   */
  async GET_OFFICIAL(context, payload: string): Promise<unknown> {
    try {
      const response = context.state.Officials.filter(
        (el) => el.id === payload
      );
      context.commit("official", { ...response[0] });

      return response;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_USERS -  get all users
   * @param context
   * @returns {Promise<void>}
   */
  async GET_USERS({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        payload?.length > 0 ? `users/?type=${payload}` : `users/`
      );
      commit("setUsers", data);
      return data;
    } catch (error: any) {
      Toast.error(error.response.data.details);
      throw error;
    }
  },
  /**
   * @function DELETE_USER -  delete all users
   * @param context
   * @returns {Promise<void>}
   */
  async DELETE_USER(context, payload): Promise<void> {
    try {
      const { data } = await Request("DELETE", `users/${payload}/`);
      return data;
    } catch (error: any) {
      Toast.error(error.response.data.details);
      throw error;
    }
  },
};

export default actions;
