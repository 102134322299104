
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "PartnerInterestForm",
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      loading: false,
      form: {
        firstname: "",
        lastname: "",
        organization: "",
        email: "",
      },
      buttonStyle: "btn-primary py-2 px-lg-4 px-2 rounded-0 fs-6",
    };
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["INTEREST_HEALTH_NEED"]),
    formValidation() {
      // check if every item in this.form is not empty else toast the appropriate error message
      // const { firstname, lastname, organization, email } = this.form;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        if (v === "") {
          Error.push(`${k} is required`);
        }
      }
      if (Error.length > 0) {
        Toast.warning(Error.join("\r\n,\r\n"));
        return false;
      }
      return true;
    },
    async onSubmit() {
      if (this.formValidation() === false) {
        return;
      }
      const data = {
        name: `${this.form.firstname} ${this.form.lastname}`,
        email: this.form.email,
        organization: this.form.organization,
      };
      try {
        this.loading = true;
        await this.INTEREST_HEALTH_NEED({ id: this.id, data });
        this.$emit("submitted");
        Toast.success("Your interest has been submitted");
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
