import Index from "./index.vue";
import Shortlisted from "./Shortlisted.vue";
import Approved from "./Approved.vue";

export default [
  {
    path: "manage-proposal",
    name: "Proposal",
    component: Index,
    children: [
      {
        path: "",
        alias: "shortlisted",
        name: "Shortlisted",
        component: Shortlisted,
      },
      {
        path: "approved",
        name: "Approved",
        component: Approved,
      },
    ],
  },
];
