import { MutationTree } from "vuex";
import {
  state,
  paginationType,
  analyticsType,
  viewPartnerShipDistType,
} from "./types";

const mutations: MutationTree<state> = {
  setTags: (state, data): void => {
    state.tags = [];
    state.tags = data;
  },
  setAnalytics: (state, data): void => {
    state.analytics = {} as analyticsType;
    state.analytics = data;
  },
  // Mutation for pagination
  setPagination: (state, data): void => {
    state.pagination = {} as paginationType;
    state.pagination = data;
  },
  setWithPartners: (state, data): void => {
    state.withPartners = [];
    state.withPartners = data;
  },
  setViewPartnerShipDist: (state, data): void => {
    state.viewPartnerShipDist = {} as viewPartnerShipDistType;
    state.viewPartnerShipDist = data;
  },
};

export default mutations;
