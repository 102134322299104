import Index from "./Index.vue";
import Submitted from "./Submitted.vue";
import Approved from "./Approved.vue";

export default [
  {
    path: "proposal",
    name: "Proposal",
    component: Index,
    children: [
      {
        path: "",
        alias: "submitted",
        name: "Submitted",
        component: Submitted,
      },
      {
        path: "approved",
        name: "Approved",
        component: Approved,
      },
    ],
  },
];
