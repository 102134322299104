import { MutationTree } from "vuex";
import { state, generic } from "./types";

const mutations: MutationTree<state> = {
  // Partner
  partners: (state, data): void => {
    state.partners = [];
    state.partners = data;
  },
  partner: (state, payload): void => {
    state.partner = {} as generic;
    state.partner = payload;
    //   state.partner = state.partners.filter(
    //     (item: generic) => item.id === payload
    //   )[0];
  },
  partnerFileList: (state, data): void => {
    state.partnerFileList = [];
    state.partnerFileList = data;
  },
};

export default mutations;
