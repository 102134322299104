
import { defineComponent } from "vue";
import Header from "../components/layout/header.vue";
import Footer from "../components/layout/footer.vue";

export default defineComponent({
  name: "Innovation",
  components: {
    Header,
    Footer,
  },
  mounted() {
    // this.$toast.add({
    //   severity: "warn",
    //   summary: `Something went wrong`,
    //   detail: "lorem ipsum dolor sit amet, consectetur",
    //   life: 3000,
    // });
    console.log(
      `%c 👋🏽, Welcome to BHMIS! \n\n \r\rBHIMS is a platform for the sharing of ideas and innovations in the field of education and research.!`,
      "color: #ccc; font-family:sans-serif; font-size: 1rem; padding-left: 1rem"
    );
  },
});
