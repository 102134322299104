
import { defineComponent } from "vue";
import ApplicationProcessForm from "../../components/Form/ApplicationProcess/ApplicationProcessForm.vue";

export default defineComponent({
  name: "Application2",
  components: { ApplicationProcessForm },
  data() {
    return {};
  },
  methods: {},
});
