import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "errorMsg" }
const _hoisted_2 = { class: "errorMsg" }
const _hoisted_3 = { class: "errorMsg" }
const _hoisted_4 = { class: "errorMsg" }
const _hoisted_5 = { class: "errorMsg" }
const _hoisted_6 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseSelect2 = _resolveComponent("BaseSelect2")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    action: "",
    onSubmit: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createVNode(_component_BaseInput, {
      inputType: _ctx.usernameProp,
      username: _ctx.username,
      "onUpdate:username": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
    }, null, 8, ["inputType", "username"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.usernameError), 1),
    _createVNode(_component_BaseInput, {
      inputType: _ctx.emailProp,
      email: _ctx.email,
      "onUpdate:email": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event))
    }, null, 8, ["inputType", "email"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.emailError), 1),
    _createVNode(_component_BaseSelect2, {
      selectType: _ctx.departmentProp,
      department: _ctx.department,
      "onUpdate:department": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.department) = $event))
    }, null, 8, ["selectType", "department"]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.departmentError), 1),
    _createVNode(_component_BaseInput, {
      inputType: _ctx.passwordProp,
      password: _ctx.password,
      "onUpdate:password": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event))
    }, null, 8, ["inputType", "password"]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.passwordError), 1),
    _createVNode(_component_BaseInput, {
      inputType: _ctx.confirm_passwordProp,
      confirm_password: _ctx.confirm_password,
      "onUpdate:confirm_password": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.confirm_password) = $event))
    }, null, 8, ["inputType", "confirm_password"]),
    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.confirm_passwordError), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BaseButton, {
        disabled: _ctx.loading,
        class: _normalizeClass({ submitting: _ctx.loading }),
        customStyle: _ctx.buttonStyle
      }, {
        default: _withCtx(() => [
          _createTextVNode("Invite")
        ]),
        _: 1
      }, 8, ["disabled", "class", "customStyle"])
    ])
  ], 32))
}