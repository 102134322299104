
import { defineComponent } from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { filterResult, generic } from "@/types/";

export default defineComponent({
  name: "viewHealthDirectors",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      dataDialog: false,
      title: "Health Directorates",
      programAreaProp,
      datas: [] as Array<generic>,
      data: {} as generic,
      user: {} as generic,
      technicalDirectors: [] as Array<generic>,
    };
  },
  computed: {
    ...mapGetters("HEALTH_DIRECTORATE_STORE", {
      datasValue: "getHDs",
      dataValue: "getHD",
      tdValue: "getTDs",
    }),
  },
  watch: {
    dataValue: {
      handler(newValue) {
        this.data = newValue;
      },
      immediate: true,
    },
    tdValue: {
      handler(newValue) {
        this.technicalDirectors = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("HEALTH_DIRECTORATE_STORE", ["SET_HD"]),
    ...mapActions("HEALTH_DIRECTORATE_STORE", [
      "GET_HEALTH_DIRECTORATES",
      "GET_TECHNICAL_DIRECTOR_BY_HEALTH_DIRECTORATE",
    ]),
    async getData() {
      this.loading = true;
      await this.GET_HEALTH_DIRECTORATES();
      this.datas = this.datasValue;
      this.loading = false;
    },
    async viewData(id: string) {
      this.loading = true;
      this.SET_HD(id);
      this.data = await this.dataValue;
      this.dataDialog = true;
      this.loading = false;
    },
    async viewTechnicalDirectors(id: string) {
      this.loading = true;
      await this.GET_TECHNICAL_DIRECTOR_BY_HEALTH_DIRECTORATE(id);
      this.technicalDirectors = this.tdValue;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    invite() {
      this.$router.push("heath_directorate_setup");
    },
  },
  async mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
