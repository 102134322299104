const Welcome = {
  image: "banner-10.jpg",
  title: `Submit a Health Priority Need`,
  subTitle: "",
  body: "Are you an employee of the Ministry of Health? Have you identified a health need that requires an innovative solution?",
  imageStyling: "",
  titleStyling: "",
  subtitleStyling: "",
  bodyStyling: "",
  buttonStyle:
    "btn-transparent btn-lg rounded-0 border-light text-white py-3 px-5 btn-elevate",
  buttonLink: "/priority-needs/submit",
  buttonText: "Submit a Health Priority Need",
};

const cardStyle = {
  customCard: "border-hover rounded-0 bg-white text-left border-0",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12 animate",
  image: false,
  imageStyle: "",
};

const customButton = `btn-transparent round border-primary mx-3 px-4`;

// ============================== Card List
const Datas = [
  {
    title: "Dashboard analytics for TB indicators and epidemiological trends",
    titleLink: "",
    image: "",
    programArea: "Maternal & child Health",
    body: "The product will consist of a dashboard visual display of programme and epidemiological data for use in public health decision-making on control of TB and other associated condition.",
    innovator: "",
    location: "Orapa town",
    link: true,
  },
  {
    title:
      "Clinical decision support systems for TB treatment and smoking cessation",
    titleLink: "",
    image: "",
    programArea: "Nutrition",
    body: "These tools could in future exploit “big data” gleaned from clinical information which is underused (e.g. through multi-country networks providing secure access to TB patient medical records).",
    innovator: "",
    location: "Orapa town",
    link: true,
  },
  {
    title: "Improve treatment support for TB patients",
    titleLink: "",
    image: "",
    programArea: "Nutrition",
    body: "The VOT interaction will not be limited to observing the act of ingesting TB drugs but also create opportunities to interact on associated problems, especially adverse drug reactions, comorbidities - particularly diabetes care for",
    innovator: "",
    location: "Orapa town",
    link: true,
  },
  {
    title: "Diagnostic device connectivity for TB",
    titleLink: "",
    image: "",
    programArea: "Maternal & child Health",
    body: "The goal is to have diagnostic test devices which support “connectivity” tailored to the needs of centralized and decentralized testing facilities in low resource settings.",
    innovator: "",
    location: "Orapa town",
    link: false,
  },
];

const cardListStyle = {
  customCard: "border-hover rounded-0 my-2 border-0 card-bg2 animate",
  customTitle: "h5",
  customImage: "",
  customProgramArea: "",
  customBody: "small py-1",
  customFooter: "",
  customInnovator: "",
  customLocation: "",
};

// =========================== Card Description style
const btnStyle = `btn-transparent btn-lg rounded-0 border-primary border-1 text-dark py-3 px-lg-5 px-3 mx-2 mb-1 fs-6`;
const btnStyleAlt = `btn-primary btn-lg rounded-0 border-primary border-1 text-white py-3 px-lg-5 px-3 mb-1 fs-6`;
const cardDescriptionStyle = {
  customCard: "border-hover rounded-0 bg-white text-left border-0",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12",
  image: false,
  imageStyle: "",
};

const content = [
  ` In 2012 0.5 million (26%) of Botswana's estimated 2 million population
  were women of reproductive age; thus the total fertility rate was 2.6 in
  2012. The maternal mortality ratio (MMR) (maternal deaths per 100,000 live
  births) in Botswana was 129 in 2015; this is compared to almost double in
  1990, when the MMR was 243. Between 1990 and 2015 there was a 47% decrease
  in maternal mortality, this is below the 75% reduction target of MDG 5.`,
  `   In 2015, Botswana ranked 55 out of 193 in the world in child mortality
  rate; there was an average of 44 deaths per 1,000 live births (this
  equates to 2000 children dying under the age of 5). Although high, this
  was an improvement from 1990 – when the child mortality rate was 54.
  UNICEF's most recent data states that Botswana's under-five mortality rate
  is now down to 36.5. Although Botswana's child mortality is relatively
  high, it is lower than most countries in Africa,[citation needed] which
  suggests that child well-being is higher in Botswana than in the majority
  of the continent. Botswana is an upper middle income country with a small
  population.`,
  `
  The infant mortality rate (IMR) in Botswana has also reduced over the last
  15 years; in 1990 the infant mortality rate was 42, and this has since
  decreased to 35 in 2015. A 2020 estimate from the CIA World Factbook found
  that Botswana's total IMR was 26.8 deaths per 1,000 live births. The
  gender break down has the male IMR at 29.2 deaths per 1,000 live births an
  the female IMR at 24.2 deaths per 1,000 live births. Botswana has the 66th
  highest IMR in the world according to the CIA World Factbook.`,
  `      In 2017, Every day in the world, about 808 women died due to complications
  of pregnancy and child birth. Almost all of these deaths occurred in
  low-resource settings, and most could have been prevented. The primary
  causes of death are haemorrhage, hypertension, infections, and indirect
  causes, mostly due to interaction between pre-existing medical conditions
  and pregnancy. The risk of a woman in a low income country dying from a
  maternal-related cause during her lifetime is about 130 times higher
  compared to a woman living in a high income country. Maternal mortality is
  a health indicator that shows very wide gaps between rich and poor and
  between countries.`,
];

const innovationRequirement = {
  title: `Innovation Requirements`,
  content: [
    `Innovation proposal must have passed the regulatory and ethical
    requirements" For more info, visit the resource library`,
    `Platform for effective tracking of postpartum haemorrhage cases`,
  ],
};
const innovationRequirement2 = {
  title: `Target End User`,
  content: [
    `Thermostable drugs- Drug application should be Effective in minimal
    doses`,
    `Platform for effective tracking of cases- Development of
    dashboard/platform to track postpartum haemorrhage cases on a weekly
    and monthly bases across all districts. Tracking systems should be
    designed as easy to use and implement.`,
  ],
};

const headerData = [
  {
    image: "asset1",
    title: "Digital notification of TB cases",
    body: "An application for front-line health care workers outside the NTP which provides for the comprehensive and timely notification of TB cases to the national TB surveillance database.",
  },
  {
    image: "asset2",
    title: "Improve treatment support for TB patients",
    body: "The mobile device used for VOT may also be used to communicate and provide enablers to the patient in order to increase adherence, in addition to documenting self-administration.",
  },
];
export {
  Welcome,
  cardStyle,
  customButton,
  Datas,
  cardListStyle,
  btnStyle,
  btnStyleAlt,
  cardDescriptionStyle,
  content,
  innovationRequirement,
  innovationRequirement2,
  headerData,
};
