import { state, generic } from "./types";

const state: state = {
  healthDirectorates: [],
  technicalDirectors: [],
  healthDirectorate: {} as generic,
  technicalDirector: {} as generic,
};

export default state;
