/* eslint-disable @typescript-eslint/no-explicit-any */
import request from "@/utils/Request";
import Toast from "@/utils/Toast";

import store from "@/store";

/**
 * LOGIN PARAMS DECELERATION
 */
interface loginData {
  username?: string;
  password?: string;
}
/**
 * REGISTRATION PARAMS DECELERATION
 */
interface registerData {
  username?: string;
  email?: string;
  password?: string;
  firstname?: string;
  lastname?: string;
  organization_name?: string;
  address?: string;
  interest?: string;
  description?: string;
}
class AuthService {
  /**
   * LOGIN FUNCTION
   * @param {*} data i.e login formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async login(data: loginData) {
    localStorage.clear();
    const response = await request("POST", "login", data);
    const { token, user } = response.data;
    if (response?.data?.token) {
      // store.commit("AUTH_STORE/user_detail", user);
      // store.commit("AUTH_STORE/user_token", token);
      // store.commit("AUTH_STORE/user_permission", user.user_permission);
      store.commit("user_detail", user, { root: true });
      store.commit("user_token", token, { root: true });
      store.commit("user_permission", user.user_permission, { root: true });
      localStorage.setItem("token", token);
      Toast.success("Login Successfully");
      return true;
    }
  }
  /**
   * CREATE INDIVIDUAL INNOVATOR FUNCTION
   * @param {*} data i.e Create individual Innovator formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async create_individual_innovator(data: registerData) {
    const response = await request("POST", "innovators/individual", data);
    console.log(response);
    if (response?.data?.id) {
      Toast.success("Created Successfully");
      return response.data;
    }
    if (response.data.detail) {
      Toast.warning("Please try again");
      return false;
    }
  }
  /**
   * CREATE ORGANIZATION INNOVATOR FUNCTION
   * @param {*} data i.e Create organization Innovator formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async create_organization_innovator(data: registerData) {
    const response = await request("POST", "innovators/organization", data);
    if (response?.data?.id) {
      Toast.success("Created Successfully");
      return response.data;
    }
  }
  /**
   * CREATE A NEED CREATOR FUNCTION
   * @param {*} data i.e Create a user that can create needs formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async need_creator(data: registerData) {
    const response = await request("POST", "mda-admins", data);
    if (response?.data?.id) {
      Toast.success("Created Successfully");
      return response.data;
    }
  }
  /**
   * GET USERS FUNCTION
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async getUser(url: string) {
    const response = await request("GET", url);
    console.log("getUser => ", response);
  }
  /**
   * !! LOGOUT FUNCTION
   * @returns clears all user data and redirects to the homepage
   */
  async logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    store.commit("AUTH_STORE/logout");
    Toast.info("Logout Successfully");
  }
}

export default new AuthService();
