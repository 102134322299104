
import { defineComponent } from "vue";
import AutoComplete from "primevue/autocomplete";
import countriesData from "@/data/Countries.json";
import { genders } from "./InnovationRegistrationData";
import { mapActions } from "vuex";
import Toast from "@/utils/Toast";
import {
  orgTypes,
  businessType,
  termsProp,
  fileProp,
} from "./InnovationRegistrationData";
import { countryProp } from "@/props-config/application";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import { findOutProp } from "./InnovationSubmissionProp";

interface type {
  id: string;
  name: string;
}

export default defineComponent({
  name: "InnovatorRegFormOrg",
  components: {
    AutoComplete,
    TermsAndConditions,
  },
  props: {
    id: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      termsModal: false,
      loading: false,
      popUpLoading: false,
      countries: [] as Array<type>,
      selectedCountries: [] as Array<type>,
      genders: [] as type[],
      countryProp,
      orgTypes,
      businessType,
      termsProp,
      fileProp,
      findOutProp,
      maxDate: new Date(),

      /* eslint-disable @typescript-eslint/no-explicit-any */
      group: "ORGANIZATION",
      docFile: "",
      form: {
        findOut: "",
        orgType: "",
        name: "",
        other: "",
        countryOfReg: "" as any,
        descriptionOrg: "",
        businessSector: "",
        orgEmail: "",
        mobile: "",
        web: "",
        altFirstname: "",
        altMiddlename: "",
        altLastname: "",
        altUsername: "",
        passportNo: "",
        nationality: "" as any,
        countryOfResidence: "" as any,
        phoneNumber: "",
        gender: "" as any,
        email: "",
        password: "",
        confirm_password: "",
        description: "",
        terms: false,
      },
      buttonStyle: "btn-primary rounded-0 px-5 py-3 mx-2",
      btnStyle: "btn-transparent fw-bold rounded-0 px-5 py-3 mx-2",
    };
  },
  methods: {
    ...mapActions("AUTH_STORE", ["INVITE_INNOVATOR_ORG"]),
    // function to filter country on keyup
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchCountry(event: any) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.selectedCountries = [...this.countries];
        } else {
          this.selectedCountries = this.countries.filter((country: type) => {
            return country.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    formValidation() {
      // check if every item in this.form is not empty else Toast the appropriate error message
      const form = this.$refs.InviteInnovatorOrg as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        if (
          v === "" ||
          v === false ||
          JSON.stringify(v) === "[]" ||
          JSON.stringify(v) === "{}" ||
          v === undefined
        ) {
          const input = form.querySelector(`[name=${k}]`);
          if (
            k !== "altMiddlename" &&
            k !== "web" &&
            k !== "other" &&
            k !== "findOut"
          ) {
            Error.push(`${k} is required`);
            input?.classList.add("is-invalid");
          }
        } else {
          // find the input that has been filled in form and remove the error class
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          `Please fill out all the required fields, ${Error.join(",\r\n ")}`
        );
        return false;
      }
      return true;
    },

    async onSubmit() {
      const formDetail = this.$refs.InviteInnovatorOrg as HTMLFormElement;
      if (this.formValidation() === false) {
        return;
      }
      if (this.form.orgType === "Others" && this.form.other === "") {
        Toast.warning("Type of Business/Organization is required");
        return false;
      }
      if (this.form.terms === false) {
        Toast.warning("Please accept the terms and conditions");
        return;
      }
      if (this.form.password !== this.form.confirm_password) {
        Toast.warning("Password and Confirm Password do not match");
        return;
      }
      if (this.form.password.length < 8) {
        Toast.warning("Password must be at least 8 characters long");
        return;
      }

      if (this.docFile.length === 0) {
        Toast.warning("Please upload a document");
        return;
      }

      const data = new FormData();
      if (this.docFile && this.docFile.length !== 0) {
        data.append("org_reg_doccument", this.docFile);
      } else if (this.docFile.length === 0) {
        // formData.append("files", "");
      }
      data.append("username", this.form.altUsername.toLowerCase());
      data.append("first_name", this.form.altFirstname);
      if (this.form.altMiddlename !== "") {
        data.append("middle_name", this.form.altMiddlename);
      }
      data.append("last_name", this.form.altLastname);
      data.append("email", this.form.email);
      data.append("mobile", this.form.mobile);
      data.append("password", this.form.password);
      data.append("passport_number", this.form.passportNo);
      data.append(
        "nationality",
        this.form.nationality?.name || this.form.nationality
      );
      data.append(
        "country_of_residence",
        this.form.countryOfResidence?.name || this.form.countryOfResidence
      );
      data.append("org_mobile", this.form.phoneNumber);
      data.append("gender", this.form.gender?.id);
      data.append("short_description", this.form.description);
      if (this.id.id !== "") {
        data.append("last_viewed_need", this.id.id);
      }
      // data.append("saved_needs", "saved-needs");
      data.append("business_type", this.form.orgType);
      if (this.form.other !== "") {
        data.append("others_description", this.form.other);
      }
      data.append("registered_name", this.form.name);
      data.append(
        "country_of_registration",
        this.form.countryOfReg?.name || this.form.countryOfReg
      );
      data.append("org_description", this.form.descriptionOrg);
      data.append("business_sector", this.form.businessSector);
      data.append("org_email", this.form.orgEmail);
      data.append("website", this.form.web);
      data.append("type", this.group);
      if (this.form.findOut !== "") {
        data.append("discovery_means", this.form.findOut);
      }
      try {
        this.loading = true;
        await this.INVITE_INNOVATOR_ORG(data);
        formDetail.reset();
        setTimeout(() => {
          this.$router.push("/submit-proposal/call-for-proposal");
        }, 3000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.countries = countriesData.data;
    this.genders = genders;
  },
  created() {
    let today = new Date();
    let year = today.getFullYear();
    const yearData = year - 16;
    this.maxDate = new Date();
    this.maxDate.setFullYear(yearData);
  },
});
