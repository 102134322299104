
import { defineComponent } from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult, generic } from "@/types/";

export default defineComponent({
  name: "viewPartners",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      // archivePartnerDialog: false,
      dataDialog: false,
      title: "Partners",
      programAreaProp,
      datas: [] as Array<generic>,
      data: {} as generic,
      user: {} as generic,
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", {
      datasValue: "getInnovators",
      dataValue: "getInnovator",
    }),
  },
  watch: {
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
      },
      immediate: true,
    },
    dataValue: {
      handler(newValue) {
        this.data = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("PARTNER_STORE", ["partner"]),
    ...mapActions("PARTNER_STORE", ["GET_PARTNERS"]),
    async getPartners() {
      this.loading = true;
      await this.GET_PARTNERS();
      this.datas = await this.datasValue;
      this.loading = false;
    },
    async getPartner(id: string) {
      this.loading = true;
      this.partner(id);
      this.data = await this.dataValue;
      this.dataDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    invite() {
      this.$router.push("partners/invites");
    },
  },
  async mounted() {
    this.getPartners();
  },
  created() {
    this.initFilters();
  },
});
