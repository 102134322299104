const fullNameProp = {
  type: "text",
  name: "Name",
  id: "name",
  autoComplete: "name",
};
const firstnameProp = {
  type: "text",
  name: "First name",
  id: "firstName",
  autoComplete: "given-name",
};
const lastnameProp = {
  type: "text",
  name: "Last name",
  id: "lastName",
  autoComplete: "family-name",
};
const usernameProp = {
  label: "Username/Email",
  type: "text",
  name: "Username",
  id: "username",
  autoComplete: "username",
};
const employeeNumberProp = {
  type: "text",
  name: "Employee Number",
  id: "employee_number",
};
const typeProp = {
  type: "text",
  name: "Type",
  id: "type",
};
const emailProp = {
  type: "email",
  name: "Email",
  placeholder: "youremail@mail.com",
  id: "email",
  autoComplete: "email",
};
const webUrlProp = {
  type: "text",
  name: "Web URL",
  placeholder: "www.https://... .com",
  id: "web_url",
  autoComplete: "url",
};
const passwordProp = {
  type: "password",
  name: "Password",
  id: "password",
  autoComplete: "new-password",
};
const confirm_passwordProp = {
  type: "password",
  name: "Confirm password",
  id: "confirm_password",
  autoComplete: "new-password",
};
const rememberProp = {
  text: "Stay Signed in",
  id: "remember",
};
const organization_nameProp = {
  type: "text",
  name: "Organization name",
  id: "organizationName",
  autoComplete: "organization",
};
const phone_numberProp = {
  type: "tel",
  name: "Phone Number",
  id: "phone_number",
  autoComplete: "tel-national",
};
const addressProp = {
  type: "text",
  name: "Address",
  id: "address",
  autoComplete: "street-address",
};

const directorateProp = {
  type: "text",
  name: "Directorate",
  id: "directorate",
  autoComplete: "organization",
};

const targetProp = {
  type: "text",
  name: "Innovation Target Profile",
  id: "target_profile",
  autoComplete: "",
  placeholder: "Select how urgent this need is",
};

const descriptionProp = {
  name: "Description",
  id: "description",
};
const interestProp = {
  type: "text",
  name: "Interest",
  id: "interest",
};
const titleProp = {
  name: "Needs Title",
  id: "title",
  placeholder: "Needs Title",
};
const messageProp = {
  name: "Description of Needs",
  id: "message",
  placeholder: "Describe your needs",
};
const requirementsProp = {
  name: "Innovation Requirements",
  id: "requirements",
  placeholder: "Requirements",
};

const researchProp = {
  type: "text",
  name: "Title",
  id: "Title",
  placeholder: "Research",
};

const pointProp = {
  type: "text",
  name: "Point 1",
  id: "point1",
  placeholder: "What action was executed",
};

const uploadProp = {
  type: "file",
  name: "upload",
  id: "upload",
};

const commentsProp = {
  placeholder: "What action was executed",
  name: "Comments",
  id: "Comments",
};

// SELECT

const categoryProp = {
  label: true,
  name: "User Type",
  id: "category",
  options: [
    "Health Informatics Team",
    // "Health Directorate",
    "Health Innovator",
    "Health Partner",
    "Program Coordinator",
    // "Monitoring and Evaluation",
  ],
};

const departmentProp = {
  label: true,
  name: "Department",
  id: "department",
  options: ["RANDOM", "NOT_RANDOM"],
};

const locationProp = {
  label: true,
  name: "Innovation Location",
  id: "location",
  options: ["location 1", "location 2", "location 3"],
};
const priorityLevelProp = {
  label: true,
  name: "Priority Level",
  id: "priority_level",
  options: ["level 1", "level 2", "level 3"],
};

const accountTypeProp = {
  label: true,
  name: "Account Type",
  id: "accountType",
  options: ["Individual", "Organization"],
};

const programAreaProp = {
  label: false,
  name: "Program Area",
  id: "programArea",
  options: ["Postpartum Hermorrhage"],
};

const partnerTypeProp = {
  label: true,
  name: "Partner Type",
  id: "partner_type",
  options: ["FINANCIAL", "PRODUCT"],
};

const innovatorTypeProp = {
  label: true,
  name: "Innovator Type",
  id: "innovator_type",
  options: ["INDIVIDUAL", "ORGANIZATION"],
};

//dynamic

const directorateSelectProp = {
  label: true,
  name: "Directorate",
  id: "directorate",
  options: [] as Array<{ [key: string]: string }>,
};

export {
  fullNameProp,
  firstnameProp,
  lastnameProp,
  usernameProp,
  employeeNumberProp,
  typeProp,
  emailProp,
  webUrlProp,
  passwordProp,
  confirm_passwordProp,
  rememberProp,
  accountTypeProp,
  organization_nameProp,
  phone_numberProp,
  addressProp,
  descriptionProp,
  interestProp,
  programAreaProp,
  titleProp,
  messageProp,
  requirementsProp,
  locationProp,
  priorityLevelProp,
  researchProp,
  pointProp,
  uploadProp,
  commentsProp,
  categoryProp,
  departmentProp,
  directorateProp,
  targetProp,
  directorateSelectProp,
  partnerTypeProp,
  innovatorTypeProp,
};
