import { MutationTree } from "vuex";
import { state } from "./types";
import { generic, needType, program_areas } from "@/types";

const mutations: MutationTree<state> = {
  innovations(state, payload) {
    state.Innovations = [];
    state.Innovations = payload;
  },
  SET_INNOVATION(state, id: string) {
    state.Innovation = {} as generic;
    state.Innovation = state.Innovations.filter(
      (item: generic) => item.id === id
    )[0];
  },
  // Health Needs
  healthNeeds: (state, data): void => {
    state.healthNeeds = [];
    state.healthNeeds = data;
    // get the first four needs and store it in fourHealthNeeds state
    const cloneData = data.slice(0);
    state.fourHealthNeeds = [];
    state.fourHealthNeeds = cloneData.splice(0, 4);
  },
  // filter by health need  by program_areas
  healthNeedsByProgramArea: (state, name: string) => {
    state.healthNeedsByProgramArea = [];
    const data = [] as needType[];
    if (name === "All") {
      state.healthNeedsByProgramArea = state.healthNeeds;
      return;
    }
    state.healthNeeds.forEach((item: needType) =>
      item.program_areas.forEach((itemArea: program_areas) => {
        if (itemArea && itemArea.name.toLowerCase() === name.toLowerCase()) {
          data.push(item);
        }
      })
    );
    state.healthNeedsByProgramArea = data.length === 0 ? [] : data;
  },
  cloneHealthNeedsByProgramArea: (state, name: string) => {
    state.cloneHealthNeedsByProgramArea = [];
    const data = [] as needType[];
    if (name === "All") {
      state.cloneHealthNeedsByProgramArea = state.fourHealthNeeds;
      return;
    }
    state.fourHealthNeeds.forEach((item: needType) =>
      item.program_areas.forEach((itemArea: program_areas) => {
        if (itemArea && itemArea.name.toLowerCase() === name.toLowerCase()) {
          data.push(item);
        }
      })
    );
    state.cloneHealthNeedsByProgramArea = data.length === 0 ? [] : data;
  },
  healthNeed: (state, data): void => {
    state.healthNeed = {} as needType;
    state.healthNeed = data;
  },
  relatedHealthNeeds: (state, data): void => {
    state.relatedHealthNeeds = [];
    state.relatedHealthNeeds = data;
  },
  SET_HEALTH_NEED: (state, id: string): void => {
    state.healthNeed = {} as needType;
    state.healthNeed = state.healthNeeds.filter(
      (item: needType) => item.id === id
    )[0];
  },
  // NeedDraft
  needDraft: (state, data): void => {
    state.needDrafts = [];
    state.needDrafts = [...data];
  },
  SET_NEED_DRAFT: (state, id: string): void => {
    state.needDraft = {} as generic;
    state.needDraft = state.needDrafts.filter(
      (item: generic) => item.id === id
    )[0];
  },
  // set innovation in the field
  setInnovationField: (state, data): void => {
    state.innovationInTheField = [];
    state.innovationInTheField = data;
  },
};

export default mutations;
