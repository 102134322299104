import Role from "@/utils/Role";
import Index from "../views/index.vue";
import Description from "@/components/Dashboard/Description.vue";
// Informatics
import Home from "../views/informatics/Home.vue";
import ViewOfficials from "../views/informatics/View.vue";
import InformaticsInvites from "../views/informatics/Invites.vue";
import ToEvaluate from "../views/informatics/ToEvaluate.vue";
// health-directorates
import ViewHD from "../views/health-directorates/View.vue";
import HDSetup from "../views/health-directorates/HDSetup.vue";
// technical-directors
// import ViewTD from "../views/technical-directors/View.vue";
// Partner
import ViewPartner from "../views/partners/View.vue";
// Innovators
import ViewInnovator from "../views/innovators/index.vue";
// needs
// import ViewHealthNeeds from "../views/health-needs/View.vue";
import ApproveInnovation from "../views/ApproveInnovation.vue";
import Publish from "../views/PublishHealthNeed.vue";

import Overview from "../views/overview/index.vue";
import programAreas from "../views/program-areas/index.vue";
import ProgramCoordinator from "../views/program-coordinators/index.vue";
import InformaticsHealthNeeds from "../views/health-needs/index.vue";
import InformaticsInnovations from "../views/innovations/index.vue";
// USER PROFILE
import UserManagement from "../views/user-management/index.vue";
import UserProfile from "../views/user-management/Profile.vue";

const routes = [
  {
    path: "/informatics",
    name: "HealthInformatics",
    component: Index,
    meta: {
      requiresAuth: true,
      guest: false,
      authorize: [Role.informatics],
    },
    children: [
      {
        path: "home",
        name: "HealthInformatics",
        component: Home,
      },
      {
        path: "home/:slug",
        name: "Priority Description",
        component: Description,
      },
      // Invite Informatics Members
      {
        path: "invites",
        name: "InformaticsInvites",
        component: InformaticsInvites,
      },
      {
        path: "to-evaluate",
        name: "ToEvaluate",
        component: ToEvaluate,
      },
      {
        path: "view_officials",
        name: "HealthInformaticsOfficials",
        component: ViewOfficials,
      },
      // =============================
      {
        path: "",
        alias: "overview",
        name: "InformaticsOverview",
        component: Overview,
      },
      // =============USER PROFILE=========
      {
        path: "user-management",
        name: "UserManagement",
        component: UserManagement,
      },
      {
        path: "user-profile",
        name: "UserProfile",
        component: UserProfile,
      },
      {
        path: "program_areas",
        name: "programAreas",
        component: programAreas,
      },
      {
        path: "program_cord",
        name: "ProgramCoordinator",
        component: ProgramCoordinator,
      },
      {
        path: "health_needs",
        name: "InformaticsHealthNeeds",
        component: InformaticsHealthNeeds,
      },
      {
        path: "innovations",
        name: "InformaticsInnovations",
        component: InformaticsInnovations,
      },
      // =============================
      // Health Directorates
      {
        path: "directorates",
        name: "viewHealthDirectors",
        component: ViewHD,
      },
      {
        path: "heath_directorate_setup",
        name: "HDSetup",
        component: HDSetup,
      },
      // Partners
      {
        path: "partners",
        name: "viewPartners",
        component: ViewPartner,
      },
      // Innovators
      {
        path: "innovators",
        name: "viewInnovators",
        component: ViewInnovator,
      },
    ],
  },
  {
    path: "/informatics/approve-innovation",
    name: "ApproveInnovation",
    component: ApproveInnovation,
    meta: { requiresAuth: false, guest: true },
  },
  // {
  //   path: "/informatics/approve-innovation-1",
  //   name: "ApproveInnovation1",
  //   component: TreatmentSupport,
  //   meta: { requiresAuth: false, guest: true },
  // },
  {
    path: "/informatics/publish",
    name: "Publish",
    component: Publish,
    meta: { requiresAuth: false, guest: true },
  },
];

export default routes;
