
import { defineComponent } from "vue";
import CardList from "@/components/Card/CardList.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import Pagination from "@/components/Pagination.vue";
import { programAreaPropAlt } from "@/props-config/application";
import { cardStyle, Datas } from "./Data";

export default defineComponent({
  name: "Track-Innovation",
  components: {
    CardList,
    ScrollArea,
    Pagination,
  },
  data() {
    return { cardStyle, Datas, programAreaPropAlt };
  },
  methods: {
    async programArea(value: { [key: string]: string }) {
      console.log(value);
    },
  },
});
