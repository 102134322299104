
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import {
  fullNameProp,
  emailProp,
  addressProp,
  typeProp,
  webUrlProp,
  descriptionProp,
  usernameProp,
  passwordProp,
  confirm_passwordProp,
} from "@/props-config";
import { healthDirectorate } from "@/types/Informatics";
import data from "@/assets/json/countries.json";
import store from "@/store";

export default defineComponent({
  name: "HealthDirectorateInviteForm",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      name: yup.string().required("Name is required"),
      country: yup.string().required("Country is required"),
      email: yup.string().required().email(),
      address: yup.string().required("Address is required"),
      type: yup.string().required("Type is required"),
      web_url: yup.string().required("Web url is required"),
      description: yup.string().required("Description is required"),
      username: yup.string().required("Username is required"),
      email_td: yup
        .string()
        .required("Technical Director's Email is required")
        .email(),
      password: yup.string().required().min(8),
      confirm_password: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Passwords do not match"),
    });
    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: name, errorMessage: nameError } = useField("name");
    const { value: country, errorMessage: countryError } = useField("country");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: address, errorMessage: addressError } = useField("address");
    const { value: type, errorMessage: typeError } = useField("type");
    const { value: web_url, errorMessage: web_urlError } = useField("web_url");
    const { value: description, errorMessage: descriptionError } =
      useField("description");
    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: email_td, errorMessage: email_tdError } =
      useField("email_td");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: confirm_password, errorMessage: confirm_passwordError } =
      useField("confirm_password");

    const onSubmit = handleSubmit(async (values, { resetForm }) => {
      let data = values;
      loading.value = true;
      const {
        name,
        country,
        email,
        address,
        type,
        web_url,
        description,
        username,
        email_td,
        password,
      } = data;

      let formData = {
        name,
        country,
        email,
        address,
        type,
        web_url,
        description,
        head_td: {
          username,
          email: email_td,
          password,
        },
      };
      try {
        // dispatch function from store
        await store.dispatch(
          "AUTH_STORE/SETUP_HEALTH_DIRECTORATE",
          formData as healthDirectorate
        );
        resetForm();
      } catch (error) {
        // Toast.error(error);
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,
      data,

      name,
      country,
      email,
      address,
      type,
      web_url,
      description,
      username,
      email_td,
      password,
      confirm_password,

      fullNameProp,
      emailProp,
      addressProp,
      typeProp,
      webUrlProp,
      descriptionProp,
      usernameProp,
      passwordProp,
      confirm_passwordProp,

      nameError,
      countryError,
      emailError,
      addressError,
      typeError,
      web_urlError,
      descriptionError,
      usernameError,
      email_tdError,
      passwordError,
      confirm_passwordError,

      buttonStyle:
        "btn-primary btn-lg px-5 py-2 rounded-0 border-primary border-3 mt-4",
    };
  },
});
