
import { defineComponent } from "vue";
import Nav from "@/components/Dashboard/Navs.vue";

export default defineComponent({
  name: "ApplicationIndex",
  components: {
    Nav,
  },
  data() {
    return {
      sideBar: false,
      navLink: [
        {
          name: "Manage Application",
          path: "/innovator/application",
          icon: "icon bi-file-earmark-text-fill",
          notification: "5",
        },
        {
          name: "Saved Needs",
          path: "/innovator/application/saved-needs",
          icon: "icon bi-star-fill",
          notification: "",
        },
      ],
    };
  },
});
