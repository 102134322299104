
import { defineComponent, PropType } from "vue";
import { fileData } from "./propConfig";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "Base-File",
  emits: ["^update:"],
  props: {
    value: {
      type: Object as PropType<fileData>,
      default: () => ({
        name: "file if available",
        id: "filename",
        label: "Select File .pdf .doc .xls",
        accept: "*",
        multiple: false,
        required: false,
      }),
    },
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newLabel: "" as string | any,
    };
  },
  methods: {
    handleInput: function (e: Event) {
      const file = e.target as HTMLInputElement;
      if (file.files && file?.files?.length > 1) {
        // throw an error if size is more than 1mb
        if (file.files[0].size > 10000000) {
          Toast.warning("File size is more than 10mb");
          // throw new Error("File size is too large");
          this.newLabel = "";
          return;
        }
        this.newLabel = file.files.length + " files selected";
        return file.files;
      } else {
        // console.log error if file.size is more than 1mb
        const size = file.files?.item(0)?.size;
        if (size && size > 10000000) {
          Toast.warning("File size is more than 10mb");
          this.newLabel = "";
          return;
        }
        this.newLabel = file.files?.item(0)?.name;
        return file.files?.item(0);
      }
    },
  },
});
