
import { defineComponent } from "vue";

export default defineComponent({
  name: "BoxOverview",
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          healthNeeds: 0,
          healthInnovations: 0,
          innovators: 0,
          partners: 0,
        };
      },
      required: true,
    },
    background: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
});
