
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import Toast from "@/utils/Toast";
import {
  categoryProp,
  emailProp,
  usernameProp,
  passwordProp,
  rememberProp,
  directorateSelectProp,
} from "@/props-config";
import store from "@/store";
import { switchDashboard } from "@/utils/Switcher";

export default defineComponent({
  name: "MultiLoginForm",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      category: yup.string().required("Category is required"),
      directorate: yup.string().when("category", {
        is: (category: string) => category === "Health Directorate",
        then: yup.string().required("Directorate is required"),
      }),
      email: yup.string().when("category", {
        is: "Program Coordinator",
        then: yup.string().required("Email is required"),
      }),
      username: yup.string().when("category", {
        is: (category: string) => category !== "Program Coordinator",
        then: yup.string().required("Username is required"),
      }),
      password: yup.string().required().min(8),
      remember: yup.boolean(),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: category, errorMessage: categoryError } =
      useField("category");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: directorate, errorMessage: directorateError } =
      useField("directorate");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: remember } = useField("remember");

    /**
     * @Function handleSubmit
     * @description function to handle user login based on userType
     * @param {string} userType
     * @param {String} username | email
     * @param {String} password
     * @return {Object} user object which redirects to the dashboard
     */
    const onSubmit = handleSubmit(async (values) => {
      let data = values;
      loading.value = true;
      const { username, password, remember, category, directorate, email } =
        data;

      // Function to getUser by token in localStorage
      remember === true
        ? localStorage.setItem("remember", "YES")
        : localStorage.removeItem("remember");

      const formData = {
        username,
        password,
      };

      const formData2 = {
        username,
        password,
        directorate,
      };

      const formData3 = {
        username: email,
        password,
      };

      try {
        let resp;
        switch (category) {
          case "Health Informatics Team":
            resp = await store.dispatch(
              "AUTH_STORE/LOGIN_INFORMATICS",
              formData
            );
            break;
          case "Health Directorate":
            resp = await store.dispatch(
              "AUTH_STORE/LOGIN_TECHNICAL_DIRECTOR",
              formData2
            );
            break;
          case "Health Innovator":
            resp = await store.dispatch("AUTH_STORE/LOGIN_INNOVATOR", formData);
            break;
          case "Health Partner":
            resp = await store.dispatch("AUTH_STORE/LOGIN_PARTNER", formData);
            break;
          case "Program Coordinator":
            resp = await store.dispatch(
              "AUTH_STORE/LOGIN_PROG_CORD",
              formData3
            );
            break;
          default:
            break;
        }
        if (resp) {
          setTimeout(() => {
            localStorage.removeItem("category");
            Toast.info("Redirecting...");
          }, 1500);
          setTimeout(() => {
            localStorage.setItem("category", category || "");
            switchDashboard(category);
          }, 3000);
        }
        loading.value = false;
      } catch (error) {
        console.log({ error });
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,

      category,
      email,
      username,
      password,
      remember,

      categoryError,
      emailError,
      usernameError,
      passwordError,

      categoryProp,
      emailProp,
      usernameProp,
      passwordProp,
      rememberProp,
      directorate,
      directorateError,
      directorateSelectProp,

      buttonStyle:
        "btn-primary btn-lg py-2 w-100 rounded-0 border-primary border-3",
    };
  },
});
