
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Form from "@/modules/auth/components/invites/ProgramCord.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { filterResult } from "@/types/";
import { switchApiByUserType } from "@/utils/Switcher";

export default defineComponent({
  name: "UserManagement",
  components: {
    DataTable,
    Column,
    Form,
  },
  data() {
    return {
      inviteDialog: false,
      displayConfirmation: false,
      deleteUserData: {} as { [key: string]: string | boolean },
      title: "User Management",
      loading: false,
      selectedUsers: [] as { [key: string]: string | boolean }[],
      filters: {} as filterResult,
      userTypeFilter: "",
      userType: [
        { name: "ALL" },
        { name: "INNOVATORS" },
        { name: "PARTNERS" },
        { name: "PROGRAM_COORDINATOR" },
        { name: "INFORMATICS" },
      ],
      partnershipsModal: false,
    };
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", [
      "getUsers",
      "getPartnershipDeclarations",
    ]),
  },
  methods: {
    ...mapActions("INFORMATICS_STORE", [
      "GET_USERS",
      "DELETE_USER",
      "GET_USER_PROFILE",
      "GET_PARTNER_DECLARATION",
    ]),
    trimText(text: string, limit: number) {
      if (text.length > limit) {
        return `${text.substring(0, limit)}...`;
      }
      return text;
    },
    async getData(filter?: string) {
      try {
        this.loading = true;
        await this.GET_USERS(filter && filter?.length > 0 ? filter : null);
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
    /**
     * @Function GetUserProfile
     * @Description onclick redirect user to a page with user info based on uuid
     */
    async getUserProfile(user: { [key: string]: string }) {
      this.loading = true;
      const { type, uuid } = user;
      const apiRoute = switchApiByUserType(type);
      try {
        await this.GET_USER_PROFILE({ route: apiRoute, id: uuid });
        this.$router.push(`/informatics/user-profile`);
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
    /**
     * @Function getDeclaration
     * @Description onclick redirect user to a page with user info based on uuid
     */
    async getDeclaration(user: { [key: string]: string }) {
      this.loading = true;
      try {
        this.loading = true;
        await this.GET_PARTNER_DECLARATION(user.uuid);
        this.partnershipsModal = true;
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
    /**
     * @function downloadForm
     * @parameter {Object} data
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async downloadForm(data: any) {
      const a = window.open("", "", "height=500, width=500");
      if (a !== null) {
        a.document.write("<html>");
        a.document.write('<body style="text-align: left;">');
        a.document.write("<br />");
        a.document.write(
          `<h4 style='text-align: center, text-decoration: underline'>${data?.partner?.firstname} ${data?.partner?.lastname}</h4>`
        );
        a.document.write("<br />");
        a.document.write("<table>");
        a.document.write("<tbody>");
        a.document.write(
          `<tr><td>Innovation Name</td> <td>${
            data.innovation_name || "--"
          }</td></tr>`
        );
        a.document.write(
          `<tr><td>Type</td> <td>${data.type || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Reason</td> <td>${data.reason || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Source of Funding</td> <td>${
            data.source_of_funding || "--"
          }</td></tr>`
        );
        a.document.write(
          `<tr><td>Process</td> <td>${data.process || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Status</td> <td>${data.status || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Next Steps</td> <td>${data.next_steps || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Bid Intent</td> <td>${data.bid_intent || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Confidential</td> <td>${data.confidential || "--"}</td></tr>`
        );
        a.document.write(
          `<tr><td>Conflict of Interest</td> <td>${
            data.conflict_of_interest || "--"
          }</td></tr>`
        );
        a.document.write(
          `<tr><td>Conflict of Interest</td> <td>${
            data.conflict_of_interest_certs || "--"
          }</td></tr>`
        );
        a.document.write(
          `<tr><td>Resources of Available</td> <td>${
            data.resources_available || "--"
          }</td></tr>`
        );
        a.document.write("</tbody>");
        a.document.write("</table>");
        a.document.write("<br />");
        a.document.write("</body></html>");
        a.document.close();
        a.print();
      }
    },
    // ========================
    tryToDeleteUser(data: { [key: string]: string | boolean }) {
      this.deleteUserData = data;
      this.displayConfirmation = true;
    },
    // Delete User
    async deleteUser() {
      try {
        this.loading = true;
        await this.DELETE_USER(this.deleteUserData?.id);
        this.displayConfirmation = false;
        this.deleteUserData = {};
        this.userTypeFilter = "ALL";
        await this.getData();
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
    // Delete users
    async deleteUsers() {
      try {
        this.loading = true;
        Promise.all(
          this.selectedUsers.map((user) => this.DELETE_USER(user.id))
        );
        this.selectedUsers = [];
        await this.getData();
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
        this.userTypeFilter = "ALL";
      }
    },
    // ------
    closeInviteDialog() {
      this.inviteDialog = false;
      this.getData();
    },
    addProgramCoordinator() {
      this.inviteDialog = true;
    },
    // ------
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  watch: {
    async userTypeFilter(val) {
      if (val === "ALL") {
        await this.getData();
      } else {
        await this.getData(val);
      }
    },
  },
  mounted() {
    this.userTypeFilter = "ALL";
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
