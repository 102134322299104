
import { defineComponent, PropType } from "vue";
import { listData } from "./propConfig";

export default defineComponent({
  name: "ListData",
  props: {
    data: {
      type: Array as PropType<listData[]>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    display(id: string) {
      if (id) {
        let title = document.getElementById(`title-${id}`);
        let status = document.getElementById(`status-${id}`);
        let feature = document.getElementById(`feature-${id}`);
        let icon = document.getElementById(`icon-${id}`);
        let more = document.getElementById(`more-${id}`);
        title?.classList.toggle("show-title");
        feature?.classList.toggle("show-features");
        icon?.classList.toggle("reveal");
        more?.classList.toggle("show-features");
        status?.classList.toggle("hide-status");
      }
    },
  },
});
