/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import Request from "@/utils/Request";
import { state } from "./types";
const localUrl = "/program_coordinators/";
import Toast from "@/utils/Toast";

const actions: ActionTree<state, unknown> = {
  // GET ALL PROGRAM COORDINATORS
  async GET_PROG_CORDS(context): Promise<void> {
    try {
      const response = await Request("GET", localUrl);
      context.commit("set_prog_cords", response.data);
      return response.data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },

  /**
   * GET SPECIFIC INFORMATICS OFFICIAL
   * @param context
   * @param payload {id: string}
   * @returns {Promise<void>}
   * todo - to be used when the data is much { compare which is faster fetch request or filter }
   */
  async GET_PROG_CORD(context, payload: string): Promise<unknown> {
    try {
      // const response = await request("GET", `${localUrl}${payload}`);
      const response = context.state.prog_cords.filter(
        (el) => el.id === payload
      );
      context.commit("set_prog_cord", { ...response[0] });

      return response;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
};

export default actions;
