
import { defineComponent } from "vue";
import TableAlt from "@/components/Dashboard/Table2.vue";
import { Datas } from "./Data";

export default defineComponent({
  name: "Approved",
  components: {
    TableAlt,
  },
  data() {
    return {
      Datas,
    };
  },
});
