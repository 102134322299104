import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ba3e63e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 partner_dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_SideNav, {
        showSideBar: _ctx.sideBar,
        Navigation: _ctx.SideNavigation
      }, null, 8, ["showSideBar", "Navigation"]),
      _createVNode(_component_Header, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sideBar = !_ctx.sideBar))
      }),
      _createElementVNode("main", {
        class: _normalizeClass(["content container", { overlay: _ctx.sideBar }]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sideBar = false))
      }, [
        _createVNode(_component_router_view)
      ], 2)
    ])
  ]))
}