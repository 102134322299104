
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import List from "@/components/Card/InnovationList.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import { cardListStyle } from "../priority-needs/Data";

export default defineComponent({
  name: "CallForProposal",
  components: {
    List,
    ScrollArea,
  },
  data() {
    return {
      cardListStyle,
      Message: "",
      data: {},
      loading: false,
      tagTitle: "",
      searchParams: "",
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
      byTag: false,
    };
  },
  watch: {
    searchParams: {
      async handler() {
        if (this.searchParams.length >= 3) {
          await this.search();
        } else if (this.searchParams.length === 0) {
          await this.getData();
        }
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "GET_INNOVATIONS_BY_STATUS",
      "GET_INNOVATIONS_BY_STATUS_PAGINATION",
      "SEARCH_INNOVATIONS",
      "GET_INNOVATION_BY_TAG",
    ]),
    /**
     * Function to filter the data by the tab selected
     * @param {string} programArea
     * Filtered if programArea is not empty
     * Message if Filtered is empty
     */
    async programArea(value: { [key: string]: string }) {
      this.tagTitle = value.name;
      this.byTag = true;
      if (value.name === "All") {
        this.byTag = false;
        await this.getData();
        return;
      }
      try {
        this.loading = true;
        const data = await this.GET_INNOVATION_BY_TAG({
          id: value.id,
          status: "APPROVED",
        });
        this.publishedData = data.data;
        this.publishedDataPagination.size = data?.size;
        this.publishedDataPagination.total_records = data?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getData() {
      try {
        this.loading = true;
        const response = await this.GET_INNOVATIONS_BY_STATUS({
          status: "APPROVED",
        });
        this.publishedData = response?.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.loading = true;
        const response = await this.GET_INNOVATIONS_BY_STATUS_PAGINATION({
          status: "APPROVED",
          page: data.page + 1,
        });
        this.publishedData = response?.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      // scroll to the id of the element
      const element = document.getElementById("scrollArea");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      this.loading = false;
    },
    // ==============Handle Search
    async search() {
      try {
        this.loading = true;
        const response = await this.SEARCH_INNOVATIONS({
          status: "APPROVED",
          search: this.searchParams,
        });
        this.publishedData = response?.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.getData();
  },
});
