import Role from "@/utils/Role";
import Index from "../views/index.vue";
import Home from "../views/home/index.vue";
import Description from "@/components/Dashboard/Description.vue";
import ApplicationProcess from "../views/home/ApplicationProcess.vue";
import myInnovation from "../views/my-innovation/routes";
// ===================== Submit innovation ===================
import Submission from "../views/Submission.vue";
// ================= Register an innovator ===================
import InnovatorRegistration from "../views/Registration.vue";
import MyInnovationPartner from "../views/partner/index.vue";
import Mou from "../views/my-innovation/Mou.vue";
import InnovatorMouDetail from "../components/Form/MouDetail.vue";
// ==========================PROFILE==============================
import InnovatorProfile from "../views/profile/index.vue";
// Test
import Application2 from "../views/home/Application.vue";
import Complete from "../views/home/Complete.vue";
import OtherInnovation from "../views/other-innovation/index.vue";
import Application from "../views/application/router";

const routes = [
  {
    path: "/innovator",
    name: "DashboardIndex",
    component: Index,
    meta: {
      requiresAuth: true,
      guest: false,
      authorize: [Role.innovator],
      title: "Innovators Dashboard",
      description:
        "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
      keywords: "innovation, innovation in healthcare, Dashboard",
      transition: "slide-left",
    },
    children: [
      {
        path: "",
        alias: "home",
        name: "DashboardHome",
        component: Home,
      },
      {
        path: "home/:slug",
        name: "Priority Description",
        component: Description,
      },
      {
        path: "home/application-process",
        name: "ApplicationProcess",
        component: ApplicationProcess,
      },
      // Test
      {
        path: "home/application2",
        name: "Application2",
        component: Application2,
      },
      // Complete
      {
        path: "home/complete",
        name: "complete",
        component: Complete,
      },
      ...myInnovation,
      {
        path: "my-partner",
        name: "MyInnovationPartner",
        component: MyInnovationPartner,
      },

      {
        path: "other-innovation",
        name: "InnovatorOtherInnovation",
        component: OtherInnovation,
        meta: { requiresAuth: false, guest: true },
      },
      ...Application,
      {
        path: "profile",
        name: "InnovatorProfile",
        component: InnovatorProfile,
      },
      {
        path: "my-innovation/mou-details",
        name: "Mou",
        component: Mou,
        meta: { requiresAuth: false, guest: true },
      },
      {
        path: "my-innovation/innovator-partner-mou",
        name: "InnovatorMouDetail",
        component: InnovatorMouDetail,
        meta: { requiresAuth: false, guest: true },
      },
    ],
  },
  {
    path: "/innovator/innovation-submission",
    name: "Submission",
    component: Submission,
    meta: { requiresAuth: true, guest: false },
  },
  {
    path: "/innovator/innovator-registration",
    name: "InnovatorRegistration",
    component: InnovatorRegistration,
    meta: { requiresAuth: false, guest: true },
  },
];

export default routes;
