
import { defineComponent } from "vue";
export default defineComponent({
  name: "SubBar",
  components: {},
  data() {
    return {
      url: "",
    };
  },

  mounted() {
    this.url = window.location.pathname;
  },
});
