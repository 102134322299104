import Index from "./index.vue";
import ViewNeedDraft from "./ViewNeedDraft.vue";
import ViewHDNeedDraft from "./ViewHDNeedDraft.vue";

export default [
  {
    path: "need-drafts",
    name: "NeedDraft",
    component: Index,
    children: [
      {
        path: "",
        alias: "view-all-needs",
        name: "ViewNeedDraft",
        component: ViewNeedDraft,
      },
      {
        path: "view-HD-needs",
        name: "ViewHDNeedDraft",
        component: ViewHDNeedDraft,
      },
    ],
  },
];
