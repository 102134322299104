
import { defineComponent } from "vue";

export default defineComponent({
  name: "M&E-Home",
  data() {
    return {};
  },
  methods: {},
});
