const cardStyle = {
  customCard: "border-hover my-2 border-0 card-bg2",
  customTitle: "h6 fw-bold mb-2",
  customBody: "small py-1",
  customFooter: "",
};

const Datas = [
  {
    title:
      "Integration of mHealth in Central District to tech mothers how to stop Postpartum haemorrhage",
    image: "",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    link: true,
  },
  {
    title: "Development of thermostable oxytocin drugs",
    image: "",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    link: true,
  },
];

export { cardStyle, Datas };
