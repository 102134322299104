
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import Toast from "@/utils/Toast";
import * as yup from "yup";
import {
  firstnameProp,
  lastnameProp,
  usernameProp,
  emailProp,
  employeeNumberProp,
  // passwordProp,
  // confirm_passwordProp,
} from "@/props-config";
import store from "@/store";

export default defineComponent({
  name: "ProgramCord-Invite",
  setup(props, { emit }) {
    const loading = ref(false);
    const schema = yup.object({
      firstname: yup.string().required("Firstname is required"),
      lastname: yup.string().required("Lastname is required"),
      username: yup.string().required("Username is required"),
      email: yup.string().required().email(),
      employee_number: yup.string().required("Employee number is required"),
      // password: yup.string().required().min(8),
      // confirm_password: yup
      //   .string()
      //   .required()
      //   .oneOf([yup.ref("password")], "Passwords do not match"),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: firstname, errorMessage: firstnameError } =
      useField("firstname");
    const { value: lastname, errorMessage: lastnameError } =
      useField("lastname");
    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: employee_number, errorMessage: employeeNumberError } =
      useField("employee_number");
    // const { value: password, errorMessage: passwordError } =
    //   useField("password");
    // const { value: confirm_password, errorMessage: confirm_passwordError } =
    //   useField("confirm_password");

    const onSubmit = handleSubmit(async (values, { resetForm }) => {
      let data = values;
      loading.value = true;

      const { firstname, lastname, username, email, employee_number } = data;

      let formData = {
        first_name: firstname,
        last_name: lastname,
        username,
        email,
        employee_number,
      };
      try {
        await store.dispatch("AUTH_STORE/INVITE_PROGRAM_CORD", formData);
        Toast.info(
          `Invite Sent!,
        please check ${email} for activation link`
        );
        resetForm();
        emit("close");
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,

      firstname,
      lastname,
      email,
      username,
      employee_number,
      // password,
      // confirm_password,

      firstnameProp,
      lastnameProp,
      emailProp,
      usernameProp,
      // passwordProp,
      // confirm_passwordProp,
      employeeNumberProp,

      firstnameError,
      lastnameError,
      emailError,
      usernameError,
      employeeNumberError,
      // passwordError,
      // confirm_passwordError,
    };
  },
  methods: {},
});
