
import { defineComponent } from "vue";
import CardList from "@/components/Card/CardList.vue";
import Card from "@/components/Card/Card.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "InnovatorOtherInnovation",
  components: {
    CardList,
    Card,
    ScrollArea,
    Pagination,
  },
  data() {
    return {
      card: {
        customCard: "border-hover rounded-0 my-2 border-0",
        customTitle: "h6",
        customImage: "",
        customeProgramArea: "",
        customBody: "small py-1",
        customFoot: "",
        customInnovator: "",
        customLocation: "",
      },
      /**
       * Style the CardList component section of the In-The-Fields page
       */
      cardListDetails: {
        customCard: "border-hover rounded-0 text-left my-3 text-dark border-0",
        customTitle: "",
        customImage: "",
        customProgramArea: "",
        customBody: "",
        customFooter: "",
        customInnovator: "",
        customLocation: "",
      },
      /**
       * data for the list card component
       */
      listDatas: [
        {
          title: "Postpartum Hermorrhage",
          image: "banner-11.jpg",
          programArea: "",
          body: "A heavy bleeding after the birth of your baby. Losing lots of blood quickly can cause a severe drop in your blood pressure. It may lead to shock and death if not treated.",
          innovator: "Company One",
          location: "",
        },
        {
          title: "Increase in Diabetes in New Borns",
          image: "banner-3.jpg",
          programArea: "",
          body: "Babies of mothers who have gestational diabetes have a higher risk of developing obesity and type 2 diabetes later in life. Stillbirth. Untreated gestational diabetes can result in a baby's death either before or shortly after birth",
          innovator: "Company Two",
          location: "",
        },
      ],
      /**
       * data for the bottom card component
       */ sideDatas: [
        {
          image: "banner-8.jpg",
          title: "Mariam John",
          titleLink: "",
          body: "I am a mother of five, I lost two of my children due to insufficient drugs, this projects has helped me, I no longer loose children. My community now gets the appropriate medical attention we need. Thank you so much",
        },
        {
          image: "banner-5.jpg",
          title: "Ann Jane",
          titleLink: "",
          body: "My community has never enjoyed good water for the past 10 years, people die every time from typhoid. We now have a two hospitals, we also have bore hole water. Our community is glad for this project. Thank you.",
        },
      ],
      /**
       * Style te the Card section of the In-The-Fields page
       */
      cardDetails: {
        customCard:
          "bg-primary text-white border-hover rounded-0 text-left border-0 pt-2 shadow-none no-zoom",
        titleStyle: "text-center",
        customRow: "col-lg-12 col-md-6 bg-primary",
        image: true,
        imageStyle: "img-circle",
      },
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
    };
  },
  methods: {
    async programArea(value: { [key: string]: string }) {
      console.log(value);
    },
  },
});
