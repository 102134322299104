
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import EvaluateHealthNeed from "../EvaluateHealthNeed.vue";
import EvaluateInnovation from "../EvaluateInnovation.vue";

type headerData = {
  [key: string]: string | number | boolean;
};

export default defineComponent({
  name: "ProgramCoord",
  components: {
    EvaluateHealthNeed,
    EvaluateInnovation,
  },
  data() {
    return {
      loading: false,
      needLoading: false,
      innovationLoading: false,
      title: "Overview",
      healthNeedDialog: false,
      innovationDialog: false,
      id: "",
      headerData: [
        {
          title: "Health Needs",
          value: 0,
          bgColor: false,
        },
        {
          title: "Health Innovations",
          value: 0,
          bgColor: false,
        },
        {
          title: "Innovators",
          value: 0,
          bgColor: false,
        },
        {
          title: "Partners",
          value: 0,
          bgColor: false,
        },
      ] as headerData[],
    };
  },
  computed: {
    ...mapGetters(["getAnalytics"]),
    ...mapGetters("INNOVATION_STORE", ["getHealthNeeds"]),
    ...mapGetters("INNOVATOR_STORE", ["getInnovations"]),
  },
  methods: {
    ...mapActions(["GET_PLATFORMS_ANALYTICS"]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEEDS"]),
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATIONS"]),
    async getPendingHealthNeeds() {
      try {
        this.needLoading = true;
        await this.GET_HEALTH_NEEDS("IN_EVALUATION");
      } catch (error) {
        console.log(error);
      } finally {
        this.needLoading = false;
      }
    },
    async getPendingInnovations() {
      try {
        this.innovationLoading = true;
        await this.GET_INNOVATIONS("IN_EVALUATION");
      } catch (error) {
        console.log(error);
      } finally {
        this.innovationLoading = false;
      }
    },
    async closeHealthNeedDialog() {
      this.healthNeedDialog = false;
      // await this.getPendingHealthNeeds();
    },
    async closeInnovationDialog() {
      this.innovationDialog = false;
      // await this.getPendingInnovations();
    },
    gotoEvaluateNeed(id: string) {
      this.id = "";
      this.id = id;
      this.healthNeedDialog = true;
    },
    gotoEvaluateInnovation(id: string) {
      this.id = "";
      this.id = id;
      this.innovationDialog = true;
    },
    async getAnalyticsData() {
      try {
        this.loading = true;
        await this.GET_PLATFORMS_ANALYTICS();
        if (Object.keys(this.getAnalytics).length !== 0) {
          this.headerData = [
            {
              title: "Health Needs",
              value: this.getAnalytics.total_needs,
              bgColor: false,
            },
            {
              title: "Health Innovations",
              value: this.getAnalytics.total_innovations,
              bgColor: false,
            },
            {
              title: "Innovators",
              value: this.getAnalytics.innovators,
              bgColor: false,
            },
            {
              title: "Partners",
              value: this.getAnalytics.partners,
              bgColor: false,
            },
          ];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAnalyticsData();
    this.getPendingHealthNeeds();
    this.getPendingInnovations();
  },
});
