
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult } from "@/types/";

export default defineComponent({
  name: "MyInnovationPartner",
  components: {
    DataTable,
    Column,
  },
  props: {},
  data() {
    return {
      loading: false,
      title: "Partner(s)",
      filters: {} as filterResult,
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getPartnerByInnovator"]),
    ...mapGetters("AUTH_STORE", ["getUserId"]),
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_PARTNERS_BY_INNOVATOR"]),
    async getInnovationPartner() {
      try {
        this.loading = true;
        await this.GET_PARTNERS_BY_INNOVATOR(this.getUserId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        innovation: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    await this.getInnovationPartner();
  },
  created() {
    this.initFilters();
  },
});
