import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fd7b5ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-center mb-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-5 col-md-6 col-12 mx-auto" }
const _hoisted_6 = { class: "card shadow bg-transparent border-0" }
const _hoisted_7 = { class: "card-body px-4 bg-white rounded-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiLoginForm = _resolveComponent("MultiLoginForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, " Login to the " + _toDisplayString(_ctx.category === ""
            ? "Innovation Scaling Platform"
            : `${_ctx.category} Dashboard`), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_MultiLoginForm, { onCategoryChange: _ctx.updateCategory }, null, 8, ["onCategoryChange"])
            ])
          ])
        ])
      ])
    ])
  ]))
}