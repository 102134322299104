
import { defineComponent } from "vue";
import LoginForm from "./LoginModalForm.vue";

export default defineComponent({
  name: "InformaticsLoginModal",
  components: {
    Form: LoginForm,
  },
  data() {
    return {
      show: true,
    };
  },
});
