
import { defineComponent, PropType } from "vue";
import { cardData, bodyData } from "./propConfig";

export default defineComponent({
  name: "Card-Component",
  props: {
    body: {
      type: Object as PropType<bodyData>,
      required: true,
    },
    card: {
      type: Object as PropType<cardData>,
      required: true,
    },
  },
});
