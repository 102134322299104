/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import httpClient from "../plugins/Axios";
import { AxiosResponse } from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.VUE_APP_API_BASE_URL;

/**
 * AXIOS FUNCTION
 * @param {*} method i.e post, get, patch, delete
 * @param {*} url i.e BaseURL + Api-url
 * @param {*} body: formData-Object
 * @returns response only if status is 'OK'
 */
type MethodType = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

const Request = (
  method: MethodType = "GET",
  url: string,
  data?: any
): Promise<AxiosResponse> =>
  new Promise((resolve, reject) => {
    const token = Cookies.get("bhims_token");
    httpClient({
      method,
      url: `${baseURL}${url}`,
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: token !== undefined ? `Bearer ${token}` : "",
      },
    })
      .then(
        (response: AxiosResponse<any> | PromiseLike<AxiosResponse<any>>) => {
          resolve(response);
        }
      )
      // .catch((err: AxiosError) => {
      //   if (err.response) {
      //     // The request was made and the server responded with a status code
      //     // that falls out of the range of 2xx
      //     reject(err.response.data?.errors[0]?.detail || err.response.status);
      //     throw err.response.data?.errors[0]?.detail || err.response.status;
      //   } else if (err.request) {
      //     console.log("err.request");
      //     // The request was made but no response was received
      //     // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
      //     // http.ClientRequest in node.js
      //     console.log(err.request);
      //   } else {
      //     // Something happened in setting up the request that triggered an Err
      //     console.log("Err", err.message);
      //     reject(err.message);
      //     throw err.message;
      //   }
      //   console.log("checks", err.config);
      // });
      .catch((err: any) => {
        reject(err);
        throw err;
      });
  });

export default Request;
