
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "Evaluate",
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        relevance: 0,
        innovative: 0,
        potential: 0,
        possibility: 0,
        scalable: 0,
        comment: "",
      },
      userId: "",
      buttonStyle: "btn-primary rounded-0 px-lg-5 px-md-3 px-1 py-3 mx-2",
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      userIdValue: "getUserId",
    }),
  },
  watch: {
    userIdValue: {
      handler() {
        this.userId = this.userIdValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["EVALUATE_INNOVATION"]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    async getData() {
      await this.GET_HEALTH_NEED(this.id);
    },
    async onSubmit() {
      if (this.userId === "") {
        Toast.error("You are not logged in");
        this.$emit("NotLoggedIn");
        return;
      }
      if (this.form.relevance === 0) {
        Toast.error("Please enter a relevance score");
        return;
      }
      if (this.form.innovative === 0) {
        Toast.error("Please enter a innovative score");
        return;
      }
      if (this.form.potential === 0) {
        Toast.error("Please enter a potential impact score");
        return;
      }
      if (this.form.possibility === 0) {
        Toast.error("Please enter innovation feasible score");
        return;
      }
      if (this.form.scalable === 0) {
        Toast.error("Please enter a scalable score");
        return;
      }
      if (this.form.comment === "") {
        Toast.error("Please enter your comment");
        return;
      }
      const data = {
        comment: this.form.comment,
        program_coordinator: this.userId,
        score: this.getScore(),
      };
      try {
        this.loading = true;
        await this.EVALUATE_INNOVATION({ id: this.id, data });
        this.form = {
          relevance: 0,
          innovative: 0,
          potential: 0,
          possibility: 0,
          scalable: 0,
          comment: "",
        };
        // if it is a dialog, close it
        if (this.modal === true) {
          this.$emit("Evaluated");
          return;
        }
        // if it is a page, redirect to the health need page
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/home");
        }, 3000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // average of all scores
    getScore() {
      const scores = [
        this.form.relevance,
        this.form.innovative,
        this.form.potential,
        this.form.possibility,
        this.form.scalable,
      ];
      const sum = scores.reduce((a, b) => a + b, 0);
      const avg = sum / scores.length;
      return Number(avg.toFixed(2));
    },
  },
  mounted() {
    this.userId = this.userIdValue;
  },
});
