
import { defineComponent } from "vue";
import LoginForm from "./LoginModalForm.vue";

export default defineComponent({
  name: "SubmitPartnerLoginModal",
  components: {
    Form: LoginForm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show === false) {
          this.$emit("closeModal");
        }
      },
    },
  },
});
