const datas = [
  {
    sideTitle: "About the Platform",
    title: "What is the aim of the BHIMS platform?",
    body: "The BHIMS platform aims to harness and foster the development of health innovations that can be scaled and integrated within broad health systems in Botswana by managing the process of demand-driven innovations in the country.",
  },
  // ----------Health Priority Needs"
  {
    sideTitle: "Health Priority Needs",
    title: "What are health priority needs?",
    body: "Health priority needs refer to gaps/issues/obstacles identified in the health sector that require innovative solutions for them to be met. These needs may cut across different program areas and/or health system areas.",
  },
  {
    sideTitle: "Health Priority Needs",
    title: "How can I upload needs to the platform?",
    body: "The BHIMS platform has a section for uploading needs. This section provides a form that captures details of that need to provide more context definition for innovators to tailor their solutions and/or inventions accordingly.",
  },
  {
    sideTitle: "Health Priority Needs",
    title:
      "Who can submit an innovation need; are there specific requirements?",
    body: "To submit a need, it is required of you to have a valid employee number issued by the Botswana Ministry of Health",
  },
  {
    sideTitle: "Health Priority Needs",
    title: "Are there incentives/recognitions for submitting a health need?",
    body: "No, there are not.",
  },
  // ----------Innovation Proposal Submission
  {
    sideTitle: "Innovation Proposal Submission",
    title: "Who is an innovator and what are the requirements for an innovator",
    body: "An innovator can either be an individual or organization submitting an innovation proposal highlighting areas where their innovation can help address the specific published health need on the BHIMS platform ",
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title: "How does one submit an innovation proposal?",
    body: 'An innovator can visit the "Health priority needs" tab, review the various published health needs and click on "Submit an innovation proposal" which is below the summary of each of the published health needs',
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title: "What are the criteria for an innovation to be accepted",
    body: "Apart from other criterias, a fully completed innovation proposal application with all the requirements aligned to a health need is on of the key areas for an innovation to first be evaluated and there after published on the BHIMS platform",
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title:
      "Can I submit an innovation that does not address any published need on the platform?",
    body: "No. The BHIMS platform has been designed to effectively manage the end-to-end process of demand-driven innovations, and as such all innovations must align with already specified needs on the platform.",
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title: "Can I submit an innovation as an individual or organization?",
    body: "Yes. You can submit an innovation proposal as either an individual or organization. The innovator registration form has been deisign to capture necessary details depending on who you are applying as.",
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title: "How long would it take for an innovation proposal to be approved?",
    body: "The innovation proposal goes through three stages until it is published on the platform. Firstly, the proposal needs to be approved once it has been submitted. Secondly, the approved proposal has to be evaluated by a minimum of three program coordinators with an average score of not less than 80%. Finally the evaluated proposal is then published on the BHIMS platform",
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title: "As an innovator can I withdraw my application on a need?",
    body: "You can contact the BMOH through the email: bhims@moh.com for direction and more details",
  },
  {
    sideTitle: "Innovation Proposal Submission",
    title:
      "Are there incentives/recognitions for submitting an innovation proposal?",
    body: "A published innovation proposal is open to partnership opportunities with both private and public sector stakeholder",
  },
  // ---------Partnerships Who can partner on an innovation?
  {
    sideTitle: "Partnerships",
    title: "Who can partner on an innovation?",
    body: "All organizations, individuals, stakeholders etc in both the public and private sector",
  },
  {
    sideTitle: "Partnerships",
    title: "How can I partner on an innovation?",
    body: "Through the BHIMS platform you can partner on the any of the published innovation proposals which are on both the 'Partner with us' and 'Submit Innovation proposal' domains",
  },
  {
    sideTitle: "Partnerships",
    title: "Can i partner on multiple innovations?",
    body: "Yes",
  },
  {
    sideTitle: "Partnerships",
    title: "Is there any guidance on privacy and intellectual property rights?",
    body: "Yes. Please read the privacy and intellectual property terms and conditions here",
  },
  // ---------Others
  {
    sideTitle: "Others",
    title: "Who do I contact if I have questions?",
    body: "You can contact the BMOH through the email: bhims@moh.com for direction and more details",
  },
  {
    sideTitle: "Others",
    title:
      "My innovation is still under development, can i still submit an innovation proposal on a health need for it?",
    body: "The eligible innovations are those that are in current use and are making various contributions towards development in the various sectors",
  },
  {
    sideTitle: "Others",
    title:
      "What format of a document can be uploaded in support of the innovation proposal?",
    body: "The required format of the documents to upload are highlighted on each field requiring uploading of a document",
  },
  {
    sideTitle: "Others",
    title:
      "When uploading the document i seem to face an error what could be the problem",
    body: "File size maybe be too large, Max size of a document is 5MB",
  },
  {
    sideTitle: "Others",
    title:
      "Can i save the my progress before submitting the innovation proposal?",
    body: "The submit innovation proposal form has a time out of more than 5 minutes which gives the innovator ample time to complete their innovation proposal. It is also recommended to have all necessary information and supporting documents ready prior to commencing the innovation proposal submission process",
  },
  {
    sideTitle: "Others",
    title: "How can i track the progress of my submitted innovation proposal?",
    body: "Email notifications are sent to the innovator at the various stages including when the innovation proposal is published on the platform ",
  },
  {
    sideTitle: "Others",
    title:
      "I submitted my proposal without attaching a document, can i resubmit or is there a way i can edit my submitted proposal?",
    body: "All mandatory fields including supporting documents are marked with an asterisk ",
  },
  {
    sideTitle: "Others",
    title:
      "Can i submit a proposal on behalf of an organization? What is the application procedure?",
    body: "When creating a profile to submit an innovation proposal as an organization, this information can be filled under business or organization contact person details",
  },
  {
    sideTitle: "Others",
    title: "Can i attach an audio message recording or video?",
    body: "Currently there are no fields on the platform for such uploads",
  },
  {
    sideTitle: "Others",
    title: "Is there a deadline to submitting an innovation proposal?",
    body: "It is encouraged to complete the innovation proposal submission process as soon as possible to give ample time for quick completion of administration processes and stages prior to the innovation proposal being published on the platform",
  },
  {
    sideTitle: "Others",
    title: "What nationalities or countries are eligible to apply?",
    body: "Individuals from all nationalities with their innovation implemented or addressing the various health needs in Botswana are encouraged to apply ",
  },
];
const sideLink = [
  "About the Platform",
  "Health Priority Needs",
  "Innovation Proposal Submission",
  "Partnerships",
  "Others",
];

export { datas, sideLink };
