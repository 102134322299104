import InnovationGroup from "./index.vue";
import MyInnovationDetails from "./MyInnovationDetails.vue";
import MyInnovations from "./MyInnovations.vue";
import PartnerList from "./MyPartnerLists.vue";
import Timeline from "./MyTimelines.vue";
import Messages from "./MyMessages.vue";
import Files from "./MyFiles.vue";

const routes = [
  {
    path: "my-innovation",
    name: "InnovationGroup",
    component: InnovationGroup,
    children: [
      {
        path: "",
        alias: "home",
        name: "MyInnovation",
        component: MyInnovations,
      },
      {
        path: "innovation-details",
        name: "MyInnovationDetails",
        component: MyInnovationDetails,
      },
      {
        path: "list",
        name: "MyInnovationPartnerList",
        component: PartnerList,
      },
      {
        path: "timeline",
        name: "MyInnovationTimeline",
        component: Timeline,
      },
      {
        path: "messages",
        name: "MyInnovationMessages",
        component: Messages,
      },
      {
        path: "files",
        name: "MyInnovationFiles",
        component: Files,
      },
    ],
  },
];

export default routes;
