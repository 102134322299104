
import { defineComponent, PropType } from "vue";
import { mapActions } from "vuex";
import moment from "moment";
import { milestoneDetail } from "@/types";

export default defineComponent({
  name: "TimeLine",
  props: {
    data: {
      required: true,
      type: Array as PropType<Array<milestoneDetail>>,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    size: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingPartner: false,
    };
  },
  methods: {
    ...mapActions("PARTNER_STORE", ["GET_PARTNER"]),
    getDateFormat(data: string) {
      return moment(data).format("DD, MMMM YYYY");
    },
  },
});
