import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1436d7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/partner/my-partnerships",
      class: _normalizeClass(["sub-bar-item", _ctx.url === '/partner/my-partnerships' ? 'sub-bar-active' : ''])
    }, {
      default: _withCtx(() => [
        _createTextVNode("My Innovations")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/partner/my-partnerships/timeline",
      class: _normalizeClass(["sub-bar-item", [
        _ctx.url === '/partner/my-partnerships/timeline' ? 'sub-bar-active' : '',
        _ctx.access === '' || _ctx.access === null ? 'disabled' : '',
      ]])
    }, {
      default: _withCtx(() => [
        _createTextVNode("Timeline")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/partner/my-partnerships/messages",
      class: _normalizeClass(["sub-bar-item", [
        _ctx.url === '/partner/my-partnerships/messages' ? 'sub-bar-active' : '',
        _ctx.access === '' || _ctx.access === null ? 'disabled' : '',
      ]])
    }, {
      default: _withCtx(() => [
        _createTextVNode("Messages")
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}