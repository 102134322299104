
import { defineComponent } from "vue";
import CompleteMessage from "../../components/CompleteMessage.vue";

export default defineComponent({
  name: "Complete",
  components: { CompleteMessage },
  data() {
    return {};
  },
  methods: {},
});
