import { state } from "./types";
import { generic, needType } from "@/types";

const state: state = {
  Innovations: [],
  healthNeeds: [],
  relatedHealthNeeds: [],
  fourHealthNeeds: [],
  healthNeedsInEvaluation: [],
  publishedHealthNeeds: [],
  healthNeedsByProgramArea: [],
  cloneHealthNeedsByProgramArea: [],
  publishedHealthNeedsByProgramArea: [],
  needDrafts: [],
  innovationInTheField: [],
  Innovation: {} as generic,
  healthNeed: {} as needType,
  needDraft: {} as generic,
};

export default state;
