import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9a8d2e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformaticsProfile = _resolveComponent("InformaticsProfile")!
  const _component_PartnerProfile = _resolveComponent("PartnerProfile")!
  const _component_InnovatorProfile = _resolveComponent("InnovatorProfile")!
  const _component_ProgramCoordProfile = _resolveComponent("ProgramCoordProfile")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.getUserType(_ctx.getUserProfileParams) === 'informatics_officials')
      ? (_openBlock(), _createBlock(_component_InformaticsProfile, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.getUserType(_ctx.getUserProfileParams) === 'partners')
      ? (_openBlock(), _createBlock(_component_PartnerProfile, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.getUserType(_ctx.getUserProfileParams) === 'innovators')
      ? (_openBlock(), _createBlock(_component_InnovatorProfile, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.getUserType(_ctx.getUserProfileParams) === 'program_coordinators')
      ? (_openBlock(), _createBlock(_component_ProgramCoordProfile, { key: 3 }))
      : _createCommentVNode("", true)
  ]))
}