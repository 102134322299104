
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { filterResult, generic } from "@/types/";

export default defineComponent({
  name: "ToEvaluate",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      dataDialog: false,
      title: "Health Informatics",
      programAreaProp,
      datas: [] as Array<generic>,
      data: {} as generic,
    };
  },
  methods: {
    ...mapMutations("INFORMATICS_STORE", ["official"]),
    async getOfficials() {
      this.loading = true;
      await this.$store.dispatch("INFORMATICS_STORE/GET_OFFICIALS");
      this.datas = this.$store.getters["INFORMATICS_STORE/getOfficials"];
      this.loading = false;
    },
    async getOfficial(id: string) {
      this.loading = true;
      this.official(id);
      this.data = await this.$store.getters["INFORMATICS_STORE/getOfficial"];
      this.dataDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    addOfficial() {
      this.$router.push("invites");
    },
  },
  async mounted() {
    this.getOfficials();
  },
  created() {
    this.initFilters();
  },
});
