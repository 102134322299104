
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import ProgCoordInnovations from "@/modules/program-cord/views/innovations/index.vue";
import Request from "@/utils/Request";
import ApproveInnovation from "../ApproveInnovation.vue";

export default defineComponent({
  name: "InformaticsInnovations",
  components: {
    Innovations: ProgCoordInnovations,
    ApproveInnovation,
  },
  data() {
    return {
      loading: false,
      innovationLoading: false,
      innovationDialog: false,
      id: "",
      pendingData: [],
    };
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATIONS"]),
    async getPendingInnovations() {
      try {
        this.innovationLoading = true;
        const { data } = await Request("GET", "innovations/?status=IN_REVIEW");
        this.pendingData = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.innovationLoading = false;
      }
    },
    async closeInnovationDialog() {
      this.innovationDialog = false;
      await this.getPendingInnovations();
    },
    gotoApproveInnovation(id: string) {
      this.id = "";
      this.id = id;
      this.innovationDialog = true;
    },
  },
  mounted() {
    this.getPendingInnovations();
  },
});
