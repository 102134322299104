const sideRoute = [
  {
    name: "Home",
    path: "/mda/home",
    icon: "icon bi-house-fill",
  },
  {
    name: "Create Needs",
    path: "/mda/create_needs",
    icon: "icon bi-file-earmark-arrow-up-fill",
  },
  {
    name: "Needs Draft",
    path: "/mda/need-drafts",
    icon: "icon bi-file-earmark-arrow-up-fill",
  },
  {
    name: "Manage Proposal",
    path: "/mda/manage-proposal",
    icon: "icon bi-pen-fill",
  },
  {
    name: "Manage Needs",
    path: "/mda/manage-needs",
    icon: "icon bi-gear-fill",
  },
  {
    name: "Track Innovation",
    path: "/mda/track-innovation",
    icon: "icon bi-search",
  },
  {
    name: "Health Directorate",
    path: "/mda/health-directorates",
    icon: "icon bi-person-rolodex",
  },
  {
    name: "Technical Directors",
    path: "/mda/technical-directors",
    icon: "icon bi-person-badge-fill",
  },
];

export default sideRoute;
