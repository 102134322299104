/* eslint-disable @typescript-eslint/no-explicit-any */
import request from "@/utils/Request";
const localUrl = "informatics_departments/";

/**
 * INVITE PARAMS DECELERATION
 */
interface inviteData {
  username?: string;
  email?: string;
  department?: string;
  password?: string;
}

interface tokenData {
  token?: string;
}

class InviteService {
  /**
   * INFORMATICS INVITE FUNCTION
   * @param {*} data i.e invite formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async informatics(data: inviteData) {
    const response = await request(
      "POST",
      `informatics_departments/invite`,
      data
    );
    if (response) {
      return response.data;
    }
  }
  /**
   * HEALTH DIRECTORATE INVITE FUNCTION
   * @param {*} data i.e invite formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async healthDirectorate(data: inviteData) {
    const response = await request("POST", `technical_directors/invite`, data);
    if (response) {
      return response.data;
    }
  }
  /**
   * INFORMATICS TOKEN VALIDATION FUNCTION
   * @param {*} data i.e activate formData
   * @param {*} url i.e Api-Url
   * @returns response.data only if status is 'OK'
   */
  async activateToken(data: tokenData) {
    const response = await request(
      "POST",
      `${localUrl}activate?token=${data.token}`
    );
    if (response) {
      return response.data;
    }
  }
}

export default new InviteService();
