import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59aae19b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "welcome_screen" }
const _hoisted_2 = { class: "welcome-img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title w-100 mx-md-3 mb-3" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = {
  key: 1,
  class: "welcome_button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`@/assets/img/banner/${_ctx.welcomeDetail.image}`),
        class: "img-fluid",
        width: "100%",
        height: "auto",
        alt: "welcome-img",
        onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loaded')))
      }, null, 40, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["welcome-note", _ctx.welcomeDetail.block])
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h4", {
          class: _normalizeClass([_ctx.welcomeDetail.titleStyling])
        }, _toDisplayString(_ctx.welcomeDetail.title), 3),
        (_ctx.welcomeDetail.subtitle)
          ? (_openBlock(), _createElementBlock("h6", {
              key: 0,
              class: _normalizeClass([_ctx.welcomeDetail.subtitleStyling])
            }, _toDisplayString(_ctx.welcomeDetail.subtitle), 3))
          : _createCommentVNode("", true)
      ]),
      (_ctx.welcomeDetail.body)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["body px-3 py-2 mb-3 mb-md-0 d-md-block d-none", [_ctx.welcomeDetail.bodyStyling]])
          }, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.welcomeDetail.body), 1)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.welcomeDetail.buttonText !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_ctx.link === true)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit'))),
                  customStyle: _ctx.welcomeDetail.buttonStyle
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.welcomeDetail.buttonText), 1)
                  ]),
                  _: 1
                }, 8, ["customStyle"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: _ctx.welcomeDetail.buttonLink
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseButton, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit'))),
                      customStyle: _ctx.welcomeDetail.buttonStyle
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.welcomeDetail.buttonText), 1)
                      ]),
                      _: 1
                    }, 8, ["customStyle"])
                  ]),
                  _: 1
                }, 8, ["to"]))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}