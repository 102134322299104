import { ActionTree } from "vuex";
import request from "@/utils/Request";
import { state } from "./types";

const actions: ActionTree<state, unknown> = {
  // GET ALL HEALTH DIRECTORATES
  async GET_HEALTH_DIRECTORATES(context): Promise<void> {
    try {
      const response = await request("GET", "health_directorates/");
      context.commit("healthDirectorates", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * GET SPECIFIC HEALTH DIRECTORATE
   * @param context
   * @param payload {id: string}
   * @returns {Promise<void>}
   * todo - to be used when the data is much { compare which is faster fetch request or filter }
   */
  async GET_HEALTH_DIRECTORATE(context, payload: string): Promise<void> {
    try {
      const response = await request("GET", `health_directorates/${payload}`);
      context.commit("healthDirectorate", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // GET ALL TECHNICAL DIRECTOR
  async GET_TECHNICAL_DIRECTORS(context): Promise<void> {
    try {
      const response = await request("GET", "technical_directors/");
      context.commit("technicalDirectors", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * GET SPECIFIC TECHNICAL DIRECTOR
   * @param context
   * @param payload {id: string}
   * @returns {Promise<void>}
   * todo - to be used when the data is much { compare which is faster fetch request or filter }
   */
  async GET_TECHNICAL_DIRECTOR(context, payload: string): Promise<void> {
    try {
      const response = await request("GET", `technical_directors/${payload}`);
      context.commit("technicalDirector", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // GET ALL TECHNICAL DIRECTORS BY HEALTH DIRECTORATE
  async GET_TECHNICAL_DIRECTOR_BY_HEALTH_DIRECTORATE(
    context,
    payload
  ): Promise<void> {
    try {
      const response = await request(
        "GET",
        `health_directorates/${payload}/technical_directors`
      );
      context.commit("technicalDirectors", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default actions;
