
import { defineComponent } from "vue";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import { mapActions, mapGetters } from "vuex";
import Toast from "@/utils/Toast";
import MultiLoginModal from "../components/form/MultiLoginModal.vue";

export default defineComponent({
  name: "ApproveInnovation",
  components: {
    Header,
    Footer,
    FormModal: MultiLoginModal,
  },
  props: {
    ApproveId: {
      type: String,
      required: false,
      default: "",
    },
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: "",
      userId: "",
      comments: "",
      authStatus: null,
      data: {},
      need: {},
      needId: "",
      loading: false,
      loading1: false,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
      userIdValue: "getUserId",
    }),
    ...mapGetters("INNOVATOR_STORE", {
      datasValue: "getInnovation",
    }),
    ...mapGetters("INNOVATION_STORE", {
      needValue: "getHealthNeed",
    }),
  },
  watch: {
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
        this.userId = this.userIdValue;
      },
    },
    datasValue: {
      handler() {
        this.data = this.datasValue;
        this.need = this.needValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "GET_INNOVATION",
      "APPROVE_INNOVATION",
      "REJECT_INNOVATION",
    ]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    getFileExt(data: string) {
      const name = data?.split(".");
      const fileExt = name[name?.length - 1];
      return fileExt;
    },
    getFileName(data: string) {
      const name = data?.split("/");
      return name[name?.length - 1];
    },
    async getData() {
      await this.GET_INNOVATION(this.id);
      this.authStatus = this.authStatusValue;
      this.data = this.datasValue;
      this.userId = this.userIdValue;
      this.needId = await this.datasValue?.need;
      await this.GET_HEALTH_NEED(this.needId);
      this.need = this.needValue;
    },
    async handleLogin() {
      this.authStatus = this.authStatusValue;
    },
    async onSubmit() {
      if (this.comments === "") {
        Toast.warning("Please enter your comments");
        return;
      }
      try {
        this.loading = true;
        const data = {
          informatic_official: this.userId,
          comment: this.comments,
        };
        await this.APPROVE_INNOVATION({ id: this.id, data });
        if (this.modal === true) {
          this.$emit("close");
          return;
        }
        setTimeout(() => {
          this.$router.push("/informatics");
        }, 3000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async reject() {
      try {
        this.loading1 = true;
        const data = {
          informatic_official: this.userId,
          comment: this.comments,
        };
        await this.REJECT_INNOVATION({ id: this.id, data });
        if (this.modal === true) {
          this.$emit("close");
          return;
        }
        setTimeout(() => {
          this.$router.push("/informatics");
        }, 3000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading1 = false;
      }
    },
    async gotoNeed() {
      this.needId = await this.datasValue?.need;
      await this.GET_HEALTH_NEED(this.needId);
      if (this.needValue?.title) {
        window.open(
          `/submit-proposal/call-for-proposal/${this.needValue?.title}?id=${this.needValue?.id}`,
          "_blank"
        );
      }
    },
    handleFile(file: string) {
      const url = "https://innovation-scaling-api-service.herokuapp.com" + file;
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.id = (this.$route.query.id as string) || this.ApproveId;
    this.getData();
  },
});
