
import { defineComponent } from "vue";
import Header from "@/components/Dashboard/Header.vue";
import SideNav from "@/components/Dashboard/SideNav.vue";

export default defineComponent({
  name: "Who-Dashboard",
  components: {
    Header,
    SideNav,
  },
  data() {
    return {
      sideBar: false,
      SideNavigation: [
        {
          name: "Dashboard",
          path: "/admin/dashboard",
          icon: "icon bi-columns-gap",
        },
        {
          name: "Countries",
          path: "/admin/countries",
          icon: "icon bi-globe2",
        },
        {
          name: "Tracking",
          path: "/admin/tracking",
          icon: "icon bi-geo-alt",
        },
        {
          name: "Partners",
          path: "/admin/partners",
          icon: "icon bi-people-fill",
        },
      ],
    };
  },
});
