/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import { state } from "./types";
const localUrl = "partners/";

const actions: ActionTree<state, unknown> = {
  // GET ALL PARTNERS
  async GET_PARTNERS(context): Promise<void> {
    try {
      const response = await Request("GET", localUrl);
      context.commit("partners", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // GET SPECIFIC PARTNER
  async GET_PARTNER({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl}${payload}/`);
      commit("partner", data);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // GET UPDATE PARTNER IMAGE
  async UPDATE_PARTNER(context, payload): Promise<void> {
    const { id, formData } = payload;
    try {
      const { data } = await Request("PATCH", `${localUrl}${id}/`, formData);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // GET SPECIFIC PARTNER FILE LIST
  async GET_PARTNER_FILE_LIST(context, id: string): Promise<void> {
    try {
      const response = await Request(
        "GET",
        `${localUrl}${id}/partnered_innovations`
      );
      context.commit("partnerFileList", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // get partnership declaration
  async GET_PARTNERSHIP_(context, id: string): Promise<void> {
    try {
      const response = await Request(
        "GET",
        `${localUrl}${id}/partnered_innovations`
      );
      context.commit("partnerFileList", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  /**
   * GET_MY_INNOVATIONS
   */
  async GET_MY_INNOVATIONS(context, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `partners/${payload}/partnered_innovations/`
      );
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
  /**
   * GET_MY_INNOVATIONS
   */
  async GET_MY_INNOVATIONS_INTEREST(context, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `partners/${payload}/interested_innovations/`
      );
      return data;
    } catch (error: any) {
      Toast.error(error);
      throw error;
    }
  },
};

export default actions;
