
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { emailProp } from "@/props-config";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "ForgotPassword",
  components: {},
  data() {
    return {
      loading: false,
      emailProp,
      email: "",
    };
  },
  methods: {
    ...mapActions("AUTH_STORE", ["FORGOT_PASSWORD"]),
    /**
     * @function: handleForgotPassword
     * @param string email
     * @return
     */
    async handleForgotPassword() {
      // check if email is valid
      if (this.email === "") {
        Toast.error("Please enter your email");
        return;
      }
      try {
        this.loading = true;
        await this.FORGOT_PASSWORD({ email: this.email });
        Toast.success(`A message was sent to ${this.email}`);
        this.email = "";
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
  },
});
