
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Header from "@/components/Dashboard/Header.vue";
import SideNav from "@/components/Dashboard/SideNav.vue";
import sideRoute from "../routes/SideRoute";
import { sideRouteType } from "@/types";

export default defineComponent({
  name: "Partner-Dashboard",
  components: {
    Header,
    SideNav,
  },
  data() {
    return {
      sideBar: false,
      miniSideBar: false,
      SideNavigation: [] as sideRouteType[],
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getDetails"]),
  },
  methods: {
    getUserNames() {
      return `Welcome ${this.getDetails?.username || this.getDetails?.email}`;
    },
  },
  mounted() {
    console.log(
      `%c 👋🏽, Welcome to BHMIS-Partners Dashboard! \n\n \r\rBHIMS is a platform for the sharing of ideas and innovations in the field of education and research.!`,
      "color: #ccc; font-family:sans-serif; font-size: 1rem; padding-left: 1rem"
    );
  },
  created() {
    this.SideNavigation = sideRoute;
  },
});
