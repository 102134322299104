
import { defineComponent } from "vue";
import Header from "@/components/Dashboard/Header.vue";
import SideNav from "@/components/Dashboard/SideNav.vue";
import sideRoute from "../routes/SideRoute";
import { sideRouteType } from "@/types";

export default defineComponent({
  name: "MdaDashboard",
  components: {
    Header,
    SideNav,
  },
  data() {
    return {
      sideBar: false,
      miniSideBar: false,
      SideNavigation: [] as sideRouteType[],
    };
  },
  created() {
    this.SideNavigation = sideRoute;
  },
});
