
import { defineComponent } from "vue";
import ManageCard from "../../components/MangeCard.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import Pagination from "@/components/Pagination.vue";
import SideFilter from "@/components/SideFilter.vue";
import { cardStyle, Datas } from "../home/Data";

export default defineComponent({
  name: "Pending",
  components: {
    ManageCard,
    ScrollArea,
    Pagination,
    SideFilter,
  },
  data() {
    return { cardStyle, Datas };
  },
  methods: {
    async programArea(value: { [key: string]: string }) {
      console.log(value);
    },
  },
});
