/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import Swal from "sweetalert2";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import route from "@/router";
import { setDetails, clearDetails } from "@/utils/LocalStorage";
import {
  state,
  details,
  loginData,
  inviteData,
  healthDirectorate,
  generic,
} from "./types";
const localUrl = "login/";

const actions: ActionTree<state, unknown> = {
  SET_USER_TOKEN(context, payload: string): void {
    context.commit("token", payload);
  },
  SET_USER_DETAIL(context, payload: details): void {
    context.commit("details", payload);
  },
  // ============ LOGIN ==============
  /**
   * @function LOGIN_INNOVATOR - function to login an innovator
   * @param commit
   * @param {object} payload as loginData
   */
  async LOGIN_INNOVATOR({ commit }, payload: loginData): Promise<unknown> {
    try {
      const response = await Request("POST", `${localUrl}innovators/`, payload);
      clearDetails();
      const { id, username, email, token, type, last_viewed_need } =
        response?.data;
      if (token) {
        const details = {
          id,
          username,
          email,
          account_type: "innovators",
          type,
          last: last_viewed_need,
        };
        setDetails(details, token);
        commit("set_token", token);
        commit("set_details", details);
        Toast.success("Login Successful");
        return true;
      }
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function LOGIN_PARTNER - function to login a partner
   * @param commit
   * @param {object} payload as loginData
   */
  async LOGIN_PARTNER({ commit }, payload: loginData): Promise<unknown> {
    try {
      clearDetails();
      const response = await Request("POST", `${localUrl}partners/`, payload);
      const { id, username, email, token, type, last_viewed_need } =
        response.data;
      if (token) {
        const details = {
          id,
          username,
          email,
          account_type: "partners",
          type,
          last: last_viewed_need,
        };
        setDetails(details, token);
        commit("set_token", token);
        commit("set_details", details);
        Toast.success("Login Successful");
        return true;
      }
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function LOGIN_TECHNICAL_DIRECTOR - function to login a technical director
   * @param commit
   * @param {object} payload as loginData
   */
  async LOGIN_TECHNICAL_DIRECTOR(
    { commit },
    payload: loginData
  ): Promise<unknown> {
    try {
      clearDetails();
      const response = await Request(
        "POST",
        `${localUrl}technical_directors/`,
        payload
      );
      const { id, username, email, token, type } = response.data;
      if (token) {
        const details = {
          id,
          username,
          email,
          account_type: "technical_directors",
          type,
        };
        setDetails(details, token);
        commit("set_token", token);
        commit("set_details", details);
        Toast.success("Login Successful");
        return true;
      }
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function LOGIN_INFORMATICS - function to login an informatics
   * @param commit
   * @param {object} payload as loginData
   */
  async LOGIN_INFORMATICS({ commit }, payload: loginData): Promise<unknown> {
    try {
      clearDetails();
      const response = await Request(
        "POST",
        `${localUrl}informatics_officials/`,
        payload
      );
      const { id, username, email, token, type } = response.data;
      if (token) {
        const details = {
          id,
          username,
          email,
          account_type: "informatics_officials",
          type: type || "Informatics",
        };
        setDetails(details, token);
        commit("set_token", token);
        commit("set_details", details);
        Toast.success("Login Successful");
        return true;
      }
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function LOGIN_PROG_CORD - function to login a program coordinator
   * @param commit
   * @param {object} payload as loginData
   */
  async LOGIN_PROG_CORD({ commit }, payload: loginData): Promise<unknown> {
    try {
      clearDetails();
      const response = await Request(
        "POST",
        `${localUrl}program_coordinators/`,
        payload
      );
      const { id, username, email, token, type, first_name, last_name } =
        response.data;
      if (token) {
        const details = {
          id,
          username,
          name: `${first_name} ${last_name}`,
          email,
          account_type: "program_coordinators",
          type: type || "program_coordinators",
        };
        setDetails(details, token);
        commit("set_token", token);
        commit("set_details", details);
        Toast.success("Login Successful");
        return true;
      }
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function LOGIN_M_AND_E - function to login a monitoring and evaluation
   * @param commit
   * @param {object} payload as loginData
   */
  async LOGIN_M_AND_E({ commit }, payload: loginData): Promise<unknown> {
    try {
      clearDetails();
      const response = await Request(
        "POST",
        `${localUrl}m_and_e_officials`,
        payload
      );
      const { id, username, email, token, type } = response.data;
      if (token) {
        const details = {
          id,
          username,
          email,
          account_type: "m_and_e_officials",
          type,
        };
        setDetails(details, token);
        commit("set_token", token);
        commit("set_details", details);
        Toast.success("Login Successful");
        return true;
      }
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_USER - function to get a user
   * @param commit
   * @param {object} localStorage
   */
  async GET_USER({ commit }): Promise<unknown> {
    try {
      const data = localStorage.getItem("human");
      const remember = localStorage.getItem("remember");
      if ((data && remember === "YES") || data) {
        const datar = JSON.parse(data);
        const response = await Request("GET", `${datar.type}/${datar.id}`);
        const { id, username, email } = response.data;
        if (id) {
          const details = {
            id,
            username,
            email,
            account_type: datar.type,
          };
          setDetails(details, datar.token);
          commit("set_token", datar.token);
          commit("set_details", details);
          Toast.info(`Welcome Back ${username}`);
        }
        return;
      }
      route.push("/auth/login");
      return;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function LOGOUT - function to logout a user
   */
  async LOGOUT({ commit }): Promise<void> {
    clearDetails();
    commit("logout");
    route.push("/auth/login");
    Toast.info("Logout Successful");
  },
  /**
   * @function LOGOUT - function to logout a user but don't redirect
   */
  async MODAL_LOGOUT({ commit }): Promise<void> {
    clearDetails();
    commit("logout");
  },
  // =========== REGISTER ============
  /**
   * @function INVITE_INFORMATICS_OFFICIAL - function to register a user {informatics official}
   * @param context
   * @param {object} payload as registerData
   */
  async INVITE_INFORMATICS_OFFICIAL(
    context,
    payload: inviteData
  ): Promise<void> {
    try {
      const response = await Request(
        "POST",
        `informatics_officials/invite/`,
        payload
      );
      Toast.success("Invite Successful");
      return response.data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function SETUP_HEALTH_DIRECTORATE - function to register a user {health directorate}
   * @param context
   * @param {object} payload as registerData
   */
  async SETUP_HEALTH_DIRECTORATE(
    context,
    payload: healthDirectorate
  ): Promise<void> {
    try {
      const { data } = await Request("POST", `health_directorates/`, payload);
      Toast.info("Account Setup Successful");
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function INVITE_PARTNER_IND - function to register a user {partner-individual}
   * @param context
   * @param {object} payload as inviteData
   */
  async INVITE_PARTNER_IND(context, payload: inviteData): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `partners/individual/invite/`,
        payload
      );
      Toast.success(`Invite Successful sent to ${payload.email}`);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function INVITE_PARTNER_ORG - function to register a user {partner-organization}
   * @param context
   * @param {object} payload as generic
   */
  async INVITE_PARTNER_ORG(context, payload: generic): Promise<void> {
    try {
      await Request("POST", `partners/organization/invite/`, payload.data);
      Toast.info(`Invite Successful sent to ${payload.email}`);
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function INVITE_INNOVATOR_IND - function to register a user {innovator-individual}
   * @param context
   * @param {object} payload as inviteData
   */
  async INVITE_INNOVATOR_IND(context, payload: inviteData): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `innovators/individual/invite/`,
        payload
      );
      Toast.success(`Invite Successful sent to ${payload?.email}`);
      Swal.fire("", `Invite Successful sent to ${payload?.email}`, "info");
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function INVITE_INNOVATOR_ORG - function to register a user {innovator-organization}
   * @param context
   * @param {object} payload as inviteData
   */
  async INVITE_INNOVATOR_ORG(context, payload: FormData): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `innovators/organization/invite/`,
        payload
      );
      Toast.success(`Invite Successful sent`);
      Swal.fire("", `Invite Successful sent to the business email`, "info");
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function INVITE_PROGRAM_CORD - function to register a user {program coordinator}
   * @param context
   * @param {object} payload as inviteData
   */
  async INVITE_PROGRAM_CORD(context, payload: inviteData): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `program_coordinators/invite/`,
        payload
      );
      return data;
    } catch (error: any) {
      console.log(error);
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function INVITE_M_AND_E - function to register a user {monitoring and evaluation}
   * @param context
   * @param {object} payload as inviteData
   */
  async INVITE_M_AND_E(context, payload: inviteData): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `m_and_e_officials/invite/`,
        payload
      );
      Toast.success(`Invite Successful sent to ${payload.email}`);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function ACTIVATE_ACCOUNT - function to activate an account
   * @param context
   * @param {object} payload as generic
   */
  async ACTIVATE_ACCOUNT(context, payload: generic): Promise<void> {
    try {
      const { type, token } = payload;
      const { data } = await Request("GET", `${type}/activate?token=${token}`);
      Toast.info("Account Activated Successful, Please login");
      setTimeout(() => {
        route.push("/auth/login");
      }, 3000);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function FORGOT_PASSWORD: function to hit the forgot password endpoint
   * @param {context}
   * @param {payload} email
   * @returns {Promise<void>}
   *
   */
  async FORGOT_PASSWORD(context, email: string): Promise<void> {
    try {
      const { data } = await Request("POST", "password_reset/", email);
      console.log({ data });
      return data;
    } catch (error: any) {
      console.log(error, error.response.data);
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function CHANGE_PASSWORD: function to hit the change password endpoint
   * @param {context}
   * @param {string} payload
   * @returns {Promise<void>}
   */
  async CHANGE_PASSWORD(context, payload: string): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `password_reset/confirm/`,
        payload
      );
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function VALIDATE_PASSWORD_TOKEN: function to hit the validate token endpoint
   * @param {context}
   * @param {payload} payload
   * @returns {Promise<void>}
   */
  async VALIDATE_PASSWORD_TOKEN(context, payload: string): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `password_reset/validate_token/`,
        payload
      );
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      Toast.error(error);
      throw error;
    }
  },
};

export default actions;
