import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b59908dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 1,
  class: "m_w_input"
}
const _hoisted_4 = ["placeholder", "id", "name"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = ["placeholder", "id", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.inputType.id
        }, _toDisplayString(_ctx.inputType.name), 9, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.toolTip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("textarea", {
            placeholder: _ctx.inputType.placeholder,
            class: "form-control w_input",
            id: _ctx.inputType.id,
            name: _ctx.inputType.id,
            rows: "6",
            cols: "50",
            value: "",
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(`update:${_ctx.inputType.id}`, _ctx.handleTextArea($event))))
          }, null, 40, _hoisted_4),
          _withDirectives(_createElementVNode("i", {
            class: _normalizeClass(`bi bi-${_ctx.toolTip.icon} input_icon`)
          }, null, 2), [
            [
              _directive_tooltip,
              _ctx.toolTip.message,
              void 0,
              { left: true }
            ]
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("textarea", {
            placeholder: _ctx.inputType.placeholder,
            class: "form-control",
            id: _ctx.inputType.id,
            name: _ctx.inputType.id,
            rows: "6",
            cols: "50",
            value: "",
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit(`update:${_ctx.inputType.id}`, _ctx.handleTextArea($event))))
          }, null, 40, _hoisted_6)
        ]))
  ]))
}