
import { defineComponent } from "vue";
import Timeline from "primevue/timeline";
import Card from "primevue/card";

export default defineComponent({
  name: "TrackingTimeline",
  components: {
    Timeline,
    Card,
  },
  data() {
    return {
      events: [
        {
          title: "Innovation Initiation Step",
          content:
            "(Submission of WBS, Proof of concept note and other relevant document)",
        },
        {
          title: "Step Two",
          content: "hello",
        },
        {
          title: "Step Three",
          content: "hello",
        },
        {
          title: "Step Four",
          content: "hello",
        },
      ],
    };
  },
  methods: {},
});
