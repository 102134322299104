import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "errorMsg" }
const _hoisted_2 = { class: "errorMsg" }
const _hoisted_3 = { class: "container text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    action: "",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
    class: "pt-2"
  }, [
    _createVNode(_component_BaseInput, {
      inputType: _ctx.usernameProp,
      username: _ctx.username,
      "onUpdate:username": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
    }, null, 8, ["inputType", "username"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.usernameError), 1),
    _createVNode(_component_BaseInput, {
      inputType: _ctx.passwordProp,
      password: _ctx.password,
      "onUpdate:password": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
    }, null, 8, ["inputType", "password"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.passwordError), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseButton, {
        disabled: _ctx.loading,
        class: _normalizeClass({ submitting: _ctx.loading }),
        customStyle: _ctx.buttonStyle
      }, {
        default: _withCtx(() => [
          _createTextVNode("Log in")
        ]),
        _: 1
      }, 8, ["disabled", "class", "customStyle"])
    ])
  ], 32))
}