
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import TimeLine from "@/components/Timeline.vue";
import { innovationTimeline, convertedInnovationTimeline } from "@/types";
import SubBar from "../../components/SubBar.vue";

export default defineComponent({
  name: "MyInnovationTimeline",
  components: {
    TimeLine,
    Calendar,
    SubBar,
  },
  data() {
    return {
      data: [] as convertedInnovationTimeline[],
      datas: [] as innovationTimeline[],
      loading: false,
      loadingForm: false,
      form: {
        date: "",
        name: "",
        deliverables: "",
        partner: "",
      },
      innovationId: "",
      btnStyle: "btn-primary rounded-0 px-lg-5 px-3 py-2 mx-2",
      minDate: new Date(2022, 5),
      maxDate: new Date(),
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", [
      "getInnovationTimeline",
      "getPartnerByInnovationId",
      "getMilestones",
    ]),
  },
  watch: {
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
        this.data = newValue.map((x: innovationTimeline) => ({
          name: x.name,
          list: x.targets,
          date: this.formatDate(x.expected_completion_date),
          expectedTime: x.expected_time,
        }));
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "GET_MILESTONE_BY_INNOVATION_ID",
      "GET_PARTNERS_BY_INNOVATION",
      "CREATE_MILESTONE",
    ]),
    async getData() {
      this.loading = true;
      try {
        await this.GET_MILESTONE_BY_INNOVATION_ID(this.innovationId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    formatDate(date: string) {
      return moment(date).format("DD/MM/YYYY");
    },
    /**
     * @function getPartnerListByInnovationId
     * @param {string} innovationId
     */
    async getPartnersList() {
      try {
        this.loading = true;
        await this.GET_PARTNERS_BY_INNOVATION(this.innovationId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @function handleSubmit - function to create a milestone
     */
    async handleSubmit() {
      const data = {
        ...this.form,
        innovation: this.innovationId,
      };
      try {
        this.loadingForm = true;
        await this.CREATE_MILESTONE(data);
        await this.getData();
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingForm = false;
      }
    },
  },
  async mounted() {
    this.innovationId =
      (this.$route.query.id as string) ||
      (localStorage.getItem("innovationId") as string);
    await this.getData();
    await this.getPartnersList();
  },
});
