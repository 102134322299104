
import { defineComponent, PropType } from "vue";
import { radioData } from "./propConfig";

export default defineComponent({
  name: "Base-Radio",
  emits: ["^update:"],
  props: {
    option: {
      type: Object as PropType<radioData>,
      required: true,
    },
  },
  setup() {
    /**
     * Typescript Definition for checkbox
     * @param {e} event
     * @returns {boolean}
     */
    const handleRadio = (e: Event) => (e.target as HTMLInputElement).value;
    return { handleRadio };
  },
});
