import { state, innovationType } from "./types";

const state: state = {
  innovators: [],
  innovations: [],
  innovationsByProgramArea: [],
  fourInnovations: [],
  myInnovations: [],
  cloneInnovationsByProgramArea: [],
  innovator: {} as { [key: string]: string | boolean },
  innovation: {} as innovationType,
  myInnovation: {} as innovationType,
  innovatorPartnerMou: {} as string | string[] | null,
  page: 1 as number,
  pageProgress: 50 as number,
  innovationProposal1: {} as FormData,
  innovationProposal2: {} as FormData,
  innovationProposal: {} as FormData,
  innovationMilestones: [],
  innovationTimeline: [],
  innovationPartnerList: [],
  signedDeclarationForm: {},
  partnerDeclarationForm: {},
  relevantDocument: [],
  partnersByInnovator: [],
  partnersByInnovationId: [],
  milestones: [],
};

export default state;
