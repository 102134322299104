
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { switchProfilePage } from "@/utils/Switcher";

export default defineComponent({
  name: "Header",
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: "",
      userBg: "",
      initials: "",
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getNamingRight", "getDetails"]),
  },
  watch: {
    getDetails() {
      if (this.getDetails?.id) {
        this.name =
          this.getDetails?.name?.split(" ")[0] ||
          this.getNamingRight?.charAt(0).toUpperCase() +
            this.getNamingRight?.slice(1);
        this.getUserColor();
      }
    },
  },
  methods: {
    shorterSides() {
      this.$emit("miniSideToggle");
    },
    async getUserColor() {
      if (this.getDetails?.name || this.getNamingRight) {
        const splitName = this.getDetails?.name?.split(" ");
        const firstName = this.getDetails?.name && splitName[0];
        const lastName = this.getDetails?.name && splitName[1];
        const initial =
          (firstName ? firstName.charAt(0) : this.getNamingRight.charAt(0)) +
          (lastName ? lastName.charAt(0) : this.getNamingRight.charAt(1));
        this.initials = initial.toUpperCase();
        // this.initials = this.getNamingRight.charAt(0).toUpperCase();
        const hash = initial.charCodeAt(0) + initial.charCodeAt(1);
        // generate a different color for each user rgb (255, 255, 255) based on the user's initials (e.g. "EA")
        const r = (hash * 5) % 255;
        const g = (hash * 7) % 255;
        const b = (hash * 11) % 255;
        this.userBg = `rgb(${r}, ${g}, ${b})`;
        // this.userBg = `hsl(${r}, 100%, 50%)`;
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("AUTH_STORE/LOGOUT");
      } catch (error) {
        console.log(error);
      }
    },
    gotoProfile() {
      switchProfilePage(this.getDetails?.account_type);
    },
  },
  mounted() {
    this.name =
      this.getDetails?.name?.split(" ")[0] ||
      this.getNamingRight?.charAt(0).toUpperCase() +
        this.getNamingRight?.slice(1);
    this.getUserColor();
  },
});
