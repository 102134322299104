
import { defineComponent } from "vue";
import MultiLoginForm from "../components/MultiLoginForm.vue";

export default defineComponent({
  name: "MultiLogin",
  components: {
    MultiLoginForm,
  },
  data() {
    return {
      category: "",
      isLoaded: false,
    };
  },
  methods: {
    updateCategory(category: string) {
      this.category = category;
    },
    onImgLoad() {
      this.isLoaded = true;
    },
  },
});
