
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import store from "@/store";
import ApprovalDetails from "./ApprovalDetails.vue";

export default defineComponent({
  name: "ApprovalPriorityNeedsForm",
  components: {
    ApprovalDetails,
  },
  setup() {
    const loading = ref(false);
    const loading1 = ref(false);
    const loadingReject = ref(false);
    const id = ref();
    const router = useRouter();
    const params = useRoute();
    // ================================================================
    const schema = yup.object({
      firstname: yup.string().required("First name is required"),
      lastname: yup.string().required("Last name is required"),
      middlename: yup.string(),
      employeeNumber: yup.string().required("Employee ID is required"),
      email: yup
        .string()
        .email("Email is invalid")
        .required("Email is required"),
      reason: yup.string().required("Reason is required"),
    });
    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: firstname, errorMessage: firstnameError } =
      useField("firstname");
    const { value: lastname, errorMessage: lastnameError } =
      useField("lastname");
    const { value: middlename, errorMessage: middlenameError } =
      useField("middlename");
    const { value: employeeNumber, errorMessage: employeeNumberError } =
      useField("employeeNumber");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: reason, errorMessage: reasonError } = useField("reason");

    //   ====================== Function to submit form =====================
    const onSubmit = handleSubmit(async (values, { resetForm }) => {
      const { firstname, lastname, middlename, employeeNumber, email, reason } =
        values;
      const name = `${lastname} ${firstname} ${middlename ? middlename : ""}`;
      const data = {
        supervisor_name: name,
        supervisor_email: email,
        supervisor_employer_id: employeeNumber,
        supervisor_comments: reason,
      };

      try {
        loading.value = true;
        await store.dispatch("INNOVATION_STORE/APPROVE_HEALTH_NEED", {
          id: id.value,
          data,
        });
        resetForm();
        setTimeout(() => {
          loading.value = false;
          router.push({ name: "InnovationHome", path: "/home" });
        }, 2000);
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    });
    //  ====================== Function to reject form ======================
    const onReject = handleSubmit(async (values, { resetForm }) => {
      const { firstname, lastname, middlename, employeeNumber, email, reason } =
        values;
      const middlenameCheck = middlename ? middlename : "";
      const name = `${lastname} ${middlenameCheck} ${firstname}`;
      const formData = {
        supervisor_name: name,
        supervisor_email: email,
        supervisor_employer_id: employeeNumber,
        supervisor_comments: reason,
      };

      try {
        loadingReject.value = true;
        await store.dispatch("INNOVATION_STORE/REJECT_HEALTH_NEED", {
          id: id.value,
          data: formData,
        });
        resetForm();
        setTimeout(() => {
          loading.value = false;
          router.push({ name: "InnovationHome", path: "/home" });
        }, 2000);
      } catch (error) {
        console.log(error);
      } finally {
        loadingReject.value = false;
      }
    });
    // ========================== Function to edit form =====================
    const onEdit = () => {
      router.push(`/priority-needs/edit-need?id=${id.value}`);
    };
    // mounted hook
    onMounted(async () => {
      id.value = params.query.id;
    });
    return {
      onSubmit,
      onReject,
      onEdit,
      loading,
      loading1,
      loadingReject,

      firstname,
      lastname,
      middlename,
      employeeNumber,
      email,
      reason,

      firstnameError,
      lastnameError,
      middlenameError,
      employeeNumberError,
      emailError,
      reasonError,
      buttonStyle: "btn-primary rounded-0 px-lg-5 px-3 py-2 mx-2",
      buttonStyleEdit: "btn-secondary rounded-0 px-lg-5 px-3 py-2 mx-2",
      buttonStyleReject: "btn-danger rounded-0 px-lg-5 px-3 py-2 mx-2",
    };
  },
});
