import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b42bfa3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard_header" }
const _hoisted_2 = {
  key: 0,
  class: "card shadow-sm dashboard_header_spread"
}
const _hoisted_3 = { class: "text-informatics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.data)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (el, i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, [
              _createElementVNode("div", {
                class: _normalizeClass(["box", [el.bgColor ? 'bg-informatics' : '']])
              }, [
                _createElementVNode("h5", null, _toDisplayString(el.title), 1),
                _createElementVNode("h6", _hoisted_3, _toDisplayString(el.value), 1)
              ], 2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}