
import { defineComponent } from "vue";
import LoginForm from "./Form/LoginModalForm.vue";

export default defineComponent({
  name: "innovatorSignMouLoginModal",
  components: {
    Form: LoginForm,
  },
  data() {
    return {
      show: true,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show === false) {
          this.$emit("closeModal");
        }
      },
    },
  },
});
