
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Card from "@/components/Card/InnovationCard.vue";
import WelcomeScreen from "../../components/Welcome.vue";
import WelcomeImageLoader from "../../components/Loader/WelcomeImageLoader.vue";
import CallForProposal from "./CallForProposal.vue";
import {
  whyShouldWeInnovate,
  Welcome,
  cardStyle,
  buttonStyle,
  Processes,
  Progress,
} from "./Data";
import { needType } from "@/types";

export default defineComponent({
  name: "SubmitProposal",
  components: {
    WelcomeScreen,
    Card,
    WelcomeImageLoader,
    CallForProposal,
  },
  data() {
    return {
      whyShouldWeInnovate,
      Welcome,
      cardStyle,
      buttonStyle,
      Processes,
      Progress,
      isLoaded: false,
      showModal: false,
      authStatus: null,
      Filtered: [] as needType[],
      datas: [] as needType[],
      data: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      AuthStatusValue: "isAuthenticated",
    }),
    ...mapGetters("INNOVATOR_STORE", {
      datasValue: "getFourInnovations",
    }),
  },
  watch: {
    AuthStatusValue(val) {
      this.authStatus = val;
    },
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATIONS_BY_STATUS"]),
    onImgLoad() {
      this.isLoaded = true;
    },
    SearchParams(value: string) {
      console.log(value);
    },
    async getData() {
      this.loading = true;
      await this.GET_INNOVATIONS_BY_STATUS({ status: "APPROVED" });
      this.loading = false;
    },
  },
  mounted() {
    this.authStatus = this.AuthStatusValue;
    // this.getData();
  },
});
