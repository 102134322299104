import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  getInnovations: (state: state) => state.Innovations,
  getInnovation: (state: state) => state.Innovation,
  getHealthNeeds: (state: state) => state.healthNeeds,
  getRelatedHealthNeeds: (state: state) => state.relatedHealthNeeds,
  getFourHealthNeeds: (state: state) => state.fourHealthNeeds,
  getHealthNeed: (state: state) => state.healthNeed,
  getHealthNeedsInEvaluation: (state: state) => state.healthNeedsInEvaluation,
  getPublishedHealthNeeds: (state: state) => state.publishedHealthNeeds,
  getHealthNeedsByProgramArea: (state: state) => state.healthNeedsByProgramArea,
  getCloneHealthNeedsByProgramArea: (state: state) =>
    state.cloneHealthNeedsByProgramArea,
  getPublishedHealthNeedsByProgramArea: (state: state) =>
    state.publishedHealthNeedsByProgramArea,
  getNeedDrafts: (state: state) => state.needDrafts,
  getNeedDraft: (state: state) => state.needDraft,
  getInnovationInTheField: (state: state) => state.innovationInTheField,
};

export default getters;
