
import { defineComponent, PropType } from "vue";
import { mapActions, mapGetters } from "vuex";

interface tabType {
  name: string;
  id: string;
  description: string;
}
export default defineComponent({
  name: "ScrollAreas",
  props: {
    Areas: {
      type: Array as PropType<Array<tabType>>,
      default: () => [{ name: "All", active: true }],
    },
  },
  data() {
    return {
      activeTab: "All",
      tags: [],
    };
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", ["getTags"]),
  },
  methods: {
    ...mapActions("INFORMATICS_STORE", ["GET_TAGS"]),
    ...mapActions("INNOVATION_STORE", [
      "GET_HEALTH_NEEDS",
      "GET_HEALTH_NEED_BY_TAG",
    ]),
    scroll_left() {
      const content = document.querySelector(".wrapper-box") as HTMLElement;
      content.scrollLeft -= 100;
    },
    scroll_right() {
      const content = document.querySelector(".wrapper-box") as HTMLElement;
      content.scrollLeft += 100;
    },
    filterByTag(item: { [key: string]: string }) {
      this.activeTab = item.name;
      this.$emit("programId", item);
    },
    getAll() {
      this.activeTab = "All";
    },
  },
  async mounted() {
    if (this.getTags?.length <= 0) {
      await this.GET_TAGS();
    }
  },
});
