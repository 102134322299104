import { MutationTree } from "vuex";
import { state, generic } from "./types";

const mutations: MutationTree<state> = {
  // Informatics Official
  officials: (state, data): void => {
    state.Officials = [];
    state.Officials = data;
  },
  official: (state, id: string): void => {
    state.Official = {} as generic;
    // filter the health directorates state by id
    state.Official = state.Officials.filter(
      (item: generic) => item.id === id
    )[0];
  },
};

export default mutations;
