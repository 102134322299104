
import { defineComponent, PropType } from "vue";
import { fileData } from "./propConfig";

export default defineComponent({
  name: "Base-File",
  props: {
    value: {
      type: Object as PropType<fileData>,
      default: () => ({
        name: "file if available",
        id: "filename",
        label: "Select File .pdf .doc .xls",
      }),
    },
  },
});
