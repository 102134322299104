
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import MultiSelect from "primevue/multiselect";
import Toast from "@/utils/Toast";
import { forLater, getForLater, removeForLater } from "@/utils/SaveForLater";
import { selectType } from "@/types/Select";
import { generic } from "@/types";
import TermsAndConditions from "@/components/TermsAndConditions.vue";
import {
  termsProp,
  disclaimerProp,
  fileProp,
  targetUserOption,
} from "./needsProp";

export default defineComponent({
  name: "SubmitNeedsForm",
  components: { MultiSelect, TermsAndConditions },
  data() {
    return {
      id: "createNeed",
      termsModal: false,
      supervisorEmail: "",
      form: {
        firstname: "",
        lastname: "",
        middlename: "",
        employeeNumber: "",
        email: "",
        address: "",
        healthFacility: "",
        supervisorName: "",
        supervisorEmail: "",
        title: "",
        healthChallenge: "",
        solutionDescription: "",
        // selectedProgramArea: [] as selectType[],
        selectedProgramArea: "",
        specificProgramArea: ([] as string[]) || "",
        targetUser: "",
        benefits: "",
        file: "",
        terms: false,
        disclaimer: false,
      },
      prevTitle: "",
      // targetValue: {} as generic,
      programAreas: [] as selectType[],
      specificPrograms: [] as string[],
      termsProp,
      disclaimerProp,
      fileProp,
      targetUserOption,

      loading: false,
      confirm: false,
      sent: false,

      btnStyle:
        "btn-primary rounded-0 border-white border-3 py-2 px-lg-5 px-3 mx-2",
      toolTip1: {
        icon: "question-circle-fill",
        message: "Every health need is to be approved by a health facility",
      },
      toolTip2: {
        icon: "question-circle-fill",
        message:
          "A supervisor not lower than a level 13 officer must approve the health need",
      },
      toolTip3: {
        icon: "question-circle-fill",
        message: `Every health need must have the following; 
          1. Listed goals to be met 
          2. Detailed scope of the need
          3. Detailed description on extent and origination of the need`,
      },
    };
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", {
      tagDataValue: "getTags",
    }),
  },
  watch: {
    "form.selectedProgramArea"(newVal) {
      if (this.form.selectedProgramArea !== "") {
        this.getSpecificTags(newVal);
      }
    },
  },
  methods: {
    ...mapActions("INFORMATICS_STORE", ["GET_TAGS"]),
    ...mapActions("INNOVATION_STORE", ["CREATE_HEALTH_NEEDS"]),
    async getData() {
      await this.GET_TAGS();
      this.programAreas = this.tagDataValue as selectType[];
    },
    getSpecificTags(value: string) {
      this.specificPrograms = [];
      this.form.specificProgramArea = [];
      const specificProgramArea = this.tagDataValue.find(
        (item: selectType) => item.id === value
      );
      this.specificPrograms = specificProgramArea.sub_tags?.map(
        (el: string) => el
      );
    },
    openToggle() {
      this.confirm = true;
    },
    closeToggle() {
      this.confirm = false;
    },
    closeToggle2() {
      this.sent = false;
      this.$router.push("/priority-needs");
    },
    // FORM VALIDATION
    formValidation() {
      const form = this.$refs.createNeed as HTMLFormElement;
      const Error = [];
      // ---------------------
      for (const [k, v] of Object.entries(this.form)) {
        if (
          v === "" ||
          v === false ||
          JSON.stringify(v) === "[]" ||
          v === undefined
        ) {
          if (
            k !== "file" &&
            k !== "middlename" &&
            k !== "targetUser" &&
            k !== "specificProgramArea"
          ) {
            const input = form.querySelector(`[name=${k}]`);
            Error.push(k);
            input?.classList.add("is-invalid");
            input?.classList.add("p-invalid");
          }
        } else {
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.remove("p-invalid");
          input?.classList.add("is-valid");
        }
      }
      // focus on the first invalid field
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const firstInvalid = form.querySelector(".is-invalid") as any;
      // if (firstInvalid?.name !== "" && firstInvalid?.name !== undefined) {
      //   firstInvalid.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //   });
      //   // Toast.error(`Please fill ${firstInvalid?.name}`);
      //   this.closeToggle();
      //   return false;
      // }
      if (Error.length > 0) {
        Toast.error(
          `${Error.join(", \n")}, Please fill out the following fields`
        );
        this.closeToggle();
        return false;
      }
      this.openToggle();
      return true;
    },
    // HANDLE SUBMIT FUNCTION
    async onSubmit() {
      if (this.formValidation() === false) {
        return;
      }
      this.loading = true;
      //   ======================
      const formData = new FormData();
      formData.append("author_first_name", this.form.firstname);
      formData.append("author_last_name", this.form.lastname);
      if (this.form.middlename !== "") {
        formData.append("author_middle_name", this.form.middlename);
      }
      formData.append("author_employee_number", this.form.employeeNumber);
      formData.append("author_email", this.form.email);
      formData.append("author_address", this.form.address);
      formData.append("supervisor_facility", this.form.healthFacility);
      formData.append("supervisor_name", this.form.supervisorName);
      formData.append("supervisor_email", this.form.supervisorEmail);
      formData.append("title", this.form.title);
      formData.append("description", this.form.healthChallenge);
      formData.append("requirements", this.form.solutionDescription);
      formData.append("program_areas", this.form.selectedProgramArea);
      if (Array.isArray(this.form.specificProgramArea)) {
        this.form.specificProgramArea?.map((el: string) => {
          formData.append("specific_program_areas", el);
        });
      } else {
        formData.append(
          "specific_program_areas",
          this.form.specificProgramArea
        );
      }
      formData.append("target_users", this.form.targetUser);
      formData.append("benefits", this.form.benefits);
      if (this.form.file && this.form.file.length > 0) {
        Array.from(this.form.file).forEach((file) => {
          formData.append("files", file);
        });
      } else if (this.form.file && Object.keys(this.form.file).length === 0) {
        formData.append("files", this.form.file);
      }
      try {
        this.closeToggle();
        this.prevTitle = this.form.title;
        await this.CREATE_HEALTH_NEEDS(formData);
        Toast.success(`Needs-${this.form.title}, submitted successfully`);
        this.supervisorEmail = this.form.supervisorEmail;
        removeForLater(this.id);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.createNeed as any).reset();
        // reset the form and this.form
        this.form = {
          firstname: "",
          lastname: "",
          middlename: "",
          employeeNumber: "",
          email: "",
          address: "",
          healthFacility: "",
          supervisorName: "",
          supervisorEmail: "",
          title: "",
          healthChallenge: "",
          solutionDescription: "",
          selectedProgramArea: "",
          specificProgramArea: [] || "",
          targetUser: "",
          benefits: "",
          file: "",
          terms: false,
          disclaimer: false,
        };
        this.form.file = "";
        this.loading = false;
        this.sent = true;
      } catch (error) {
        console.log(error, "submit needs");
      } finally {
        this.loading = false;
      }
    },
    // SAVE FOR LATER
    async saveForLater() {
      const form = this.$refs.createNeed as HTMLFormElement;
      const formData = new FormData(form);
      const data = {} as HTMLFormElement;
      for (let entry of formData.entries()) {
        data[entry[0]] = entry[1];
      }
      // if (this.form.selectedProgramArea.length > 0) {
      //   data["program_areas"] = JSON.stringify(this.form.selectedProgramArea);
      // }
      // if (this.form.specificProgramArea.length > 0) {
      //   data["specific_program_areas"] = JSON.stringify(
      //     this.form.specificProgramArea
      //   );
      // }
      forLater(this.id, data);
      Toast.success("Saved for later");
    },
    /**
     * RETRIEVE FROM LOCAL STORAGE
     * Function to retrieve formData to localStorage
     * @param id: string
     */
    async retrieveForm() {
      const data = getForLater(this.id);
      if (data) {
        // pop up a confirm dialog to confirm the retrieval
        if (
          confirm("You have a saved form. Do you want to retrieve it?") === true
        ) {
          await this.setFormData(data);
          Toast.success("Retrieved form data");
        } else {
          Toast.warning("Form data not retrieved");
          return;
        }
      }
    },
    /**
     * Function to pre-fill formData on component mount
     */
    async setFormData(data: generic) {
      const {
        firstname,
        lastname,
        middlename,
        employeeNumber,
        email,
        address,
        healthFacility,
        supervisorName,
        supervisorEmail,
        title,
        healthChallenge,
        solutionDescription,
        targetUser,
        benefits,
        // program_areas,
        // specific_program_areas,
      } = data;
      // const programArea = JSON.parse(program_areas);
      // const specificProgramArea = JSON.parse(specific_program_areas);
      this.form.firstname = firstname;
      this.form.lastname = lastname;
      this.form.middlename = middlename;
      this.form.employeeNumber = employeeNumber;
      this.form.email = email;
      this.form.address = address;
      this.form.healthFacility = healthFacility;
      this.form.supervisorName = supervisorName;
      this.form.supervisorEmail = supervisorEmail;
      this.form.title = title;
      this.form.healthChallenge = healthChallenge;
      this.form.solutionDescription = solutionDescription;
      this.form.targetUser = targetUser;
      this.form.benefits = benefits;
      // this.form.selectedProgramArea = programArea;
      // this.form.specificProgramArea = specificProgramArea;
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    if (this.retrieveForm) {
      this.retrieveForm();
    }
  },
});
