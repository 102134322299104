import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "d-flex w-100 justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSearch = _resolveComponent("BaseSearch")!
  const _component_CardList = _resolveComponent("CardList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_component_BaseSearch)
      ])
    ]),
    _createElementVNode("section", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datas, (data) => {
        return (_openBlock(), _createBlock(_component_CardList, {
          key: data.title,
          body: data,
          card: _ctx.card
        }, null, 8, ["body", "card"]))
      }), 128))
    ])
  ]))
}