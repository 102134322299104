
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import { useRouter } from "vue-router";
import * as yup from "yup";
import Toast from "@/utils/Toast";
import {
  usernameProp,
  passwordProp,
  organization_nameProp,
  addressProp,
  emailProp,
} from "@/props-config";
import {
  termsProp,
  countryProp,
  RCProp,
  needsProp,
  GoalsProp,
  innovationProp,
  programAreaProp,
  phoneNumberProp,
  innovationOverviewProp,
  experienceProp,
} from "@/props-config/application";

import AuthServices from "@/services/auth.service";

export default defineComponent({
  name: "LoginData",
  setup() {
    const loading = ref(false);
    const router = useRouter();
    const schema = yup.object({
      username: yup.string().required(),
      password: yup.string().required().min(8),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: remember } = useField("remember");

    const onSubmit = handleSubmit(async (values) => {
      let data = values;
      loading.value = true;
      let formdata = {
        username: data.username,
        password: data.password,
      };

      try {
        const resp = await AuthServices.login(formdata);
        if (resp) {
          setTimeout(() => {
            Toast.success("Redirecting...");
          }, 1500);

          setTimeout(() => {
            router.push({ name: "DashboardHome", path: "/innovator/home" });
          }, 3000);
        }
        loading.value = false;
      } catch (error) {
        Toast.warning("Error");
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,

      username,
      password,
      remember,

      usernameError,
      passwordError,

      usernameProp,
      passwordProp,
      organization_nameProp,
      addressProp,
      emailProp,
      programAreaProp,
      countryProp,
      RCProp,
      needsProp,
      GoalsProp,
      innovationProp,
      phoneNumberProp,
      innovationOverviewProp,
      experienceProp,
      termsProp,

      buttonStyle: "btn-primary rounded-0 border-light shadow border-2 ",
      buttonStyle1:
        "btn-success rounded-0 border-white shadow-sm border-2 button2",
    };
  },
});
