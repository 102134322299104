import { MutationTree } from "vuex";
import { state, details } from "./types";

const mutations: MutationTree<state> = {
  set_token: (state, data: string): void => {
    state.token = data;
  },
  set_details: (state, data: details): void => {
    state.details = data;
  },
  logout: (state): void => {
    state.token = "";
    state.details = {} as details;
  },
};

export default mutations;
