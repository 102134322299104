
import { defineComponent } from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import ProgressBar from "primevue/progressbar";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import Form from "@/modules/innovator/components/Form/InnovationSubmission.vue";
import Form2 from "@/modules/innovator/components/Form/InnovationSubmission2.vue";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "SubmitInnovation",
  components: {
    Form,
    Form2,
    ProgressBar,
    Header,
    Footer,
  },
  data() {
    return {
      page: 1 as 1 | 2,
      pageProgress: 50 as number,
      proposal: {} as FormData,
      loading: false,
      id: "",
      needDetails: {},
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getUserId"]),
    ...mapGetters("INNOVATOR_STORE", {
      pageValue: "getPage",
      pageProgressValue: "getPageProgress",
      proposalData: "getProposalForm",
    }),
  },
  methods: {
    ...mapMutations("INNOVATOR_STORE", [
      "CREATE_PROPOSAL",
      "UPDATE_PAGE",
      "UPDATE_PROGRESS",
    ]),
    ...mapActions("INNOVATOR_STORE", ["SUBMIT_PROPOSAL"]),
    ...mapActions("AUTH_STORE", ["LOGOUT"]),
    async resetPage() {
      this.UPDATE_PAGE(1);
      this.UPDATE_PROGRESS(50);
    },
    async handleSubmit() {
      try {
        this.loading = true;
        this.CREATE_PROPOSAL();
        this.proposal = this.proposalData;
        if (this.getUserId === "" || this.getUserId === undefined) {
          await this.LOGOUT();
          Toast.error("You are not logged in");
          this.loading = false;
          return;
        }
        await this.SUBMIT_PROPOSAL({ id: this.id, data: this.proposal });
        this.resetPage();
        Toast.info("Innovation Submitted successfully");
        setTimeout(() => {
          this.$router.push("/innovator");
        }, 2000);
        // this.$router.push("/innovation/submit-proposal");
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    getNeedData() {
      const data = sessionStorage.getItem("BHMIS_HealthNeed");
      if (data !== null) {
        this.needDetails = JSON.parse(data);
      }
    },
  },
  mounted() {
    this.getNeedData();
    this.page = this.pageValue;
    this.pageProgress = this.pageProgressValue;
    this.id = this.$route.query.id as string;
  },
  watch: {
    pageValue: {
      handler() {
        this.page = this.pageValue;
        this.pageProgress = this.pageProgressValue;
      },
      immediate: true,
    },
  },
});
