import { MutationTree } from "vuex";
import { state, generic } from "./types";

const mutations: MutationTree<state> = {
  // prog-cords Official
  set_prog_cords: (state, data): void => {
    state.prog_cords = [];
    state.prog_cords = data;
  },
  set_prog_cord: (state, id: string): void => {
    state.prog_cord = {} as generic;
    // filter the prog_cords state by id
    state.prog_cord = state.prog_cords.filter(
      (item: generic) => item.id === id
    )[0];
  },
};

export default mutations;
