import { state } from "./types";

const state: state = {
  tags: [],
  analytics: {},
  pagination: {
    page: 0,
    size: 0,
    total_records: 0,
    total_pages: 0,
    data: [],
  },
  withPartners: [],
  viewPartnerShipDist: { financial: 0, technology: 0, implementation: 0 },
};

export default state;
