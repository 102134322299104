
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { usernameProp, passwordProp } from "@/props-config";
import store from "@/store";

export default defineComponent({
  name: "innovatorSignMouLoginModalForm",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      username: yup.string().required("Username is required"),
      password: yup.string().required().min(8),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });
    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    const onSubmit = handleSubmit(async (values) => {
      let data = values;
      loading.value = true;
      const { username, password } = data;

      const formData = {
        username,
        password,
      };

      try {
        await store.dispatch("AUTH_STORE/LOGIN_INNOVATOR", formData);
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,

      username,
      password,

      usernameError,
      passwordError,

      usernameProp,
      passwordProp,

      buttonStyle:
        "btn-primary btn-lg py-2 w-100 rounded-0 border-primary border-3 mb-3",
    };
  },
});
