
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import LoginModal from "./LoginModal.vue";

export default defineComponent({
  name: "InnovationDescription",
  components: { LoginModal },
  data() {
    return {
      loading: false,
      id: "",
      userId: "",
      comments: "",
      authStatus: null,
      needId: "",
      buttonStyle: "btn-primary rounded-0 py-lg-3 py-2 px-lg-5 px-3",
      showLogin: false,
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getInnovation"]),
    ...mapGetters("INNOVATION_STORE", ["getHealthNeed"]),
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
    }),
    // get the last part of the url from the parameter
    // eslint-disable-next-line vue/return-in-computed-property
    getLastPartOfUrl(param) {
      console.log(param);
      // const lastPart = param.split("/").pop();
      // console.log({ lastPart });
      // return param;
    },
  },
  watch: {
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATION"]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    getFileExt(data: string) {
      const name = data?.split(".");
      const fileExt = name[name.length - 1];
      return fileExt;
    },
    getFileName(data: string) {
      const name = data?.split("/");
      return name[name?.length - 1];
    },
    async getData() {
      try {
        this.loading = true;
        await this.GET_INNOVATION(this.id);
        this.needId = await this.getInnovation?.need;
        // await this.GET_HEALTH_NEED(this.needId);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async gotoNeed() {
      this.needId = await this.getInnovation?.need;
      await this.GET_HEALTH_NEED(this.needId);
      if (this.getHealthNeed?.id) {
        window.open(
          `/submit-proposal/call-for-proposal/${this.getHealthNeed?.id}?id=${this.getHealthNeed?.id}`,
          "_blank"
        );
      }
    },
    partner() {
      if (this.authStatusValue === false) {
        this.authStatus = this.authStatusValue;
        this.showLogin = true;
        return;
      }
      this.$router.push(`/partner/partnership-interest?id=${this.id}`);
    },
    handleFile(file: string) {
      // const url = "https://innovation-scaling-api-service.herokuapp.com" + file;
      window.open(file, "_blank");
    },
  },
  mounted() {
    this.id = this.$route.query.id as string;
    this.getData();
  },
});
