import { getDataByCookie } from "@/utils/LocalStorage";
import { state, details } from "./types";

const tokenValue = getDataByCookie("bhims_token");
const Details = getDataByCookie("bhims_details");
const userDetails = Details ? JSON.parse(Details) : null;

const state: state = {
  token: tokenValue || "",
  details: userDetails || ({} as details),
};

export default state;
