
import { defineComponent } from "vue";
import { datas, sideLink } from "./Data";

export default defineComponent({
  name: "Home",
  data() {
    return {
      activeTitle: "",
      datas,
      sideLink,
      filtered: [] as { [key: string]: string | number }[],
    };
  },
  methods: {
    filterFaq(newValue: string) {
      return this.datas.filter(
        (f: { [key: string]: string | number }) => f.sideTitle === newValue
      );
    },
    onChange(title: string) {
      this.activeTitle = title;
      this.filtered = this.filterFaq(title);
    },
  },
  mounted() {
    this.activeTitle = this.datas[0]?.sideTitle;
    this.onChange(this.activeTitle);
  },
});
