
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import ApprovalDetails from "@/modules/health-directorate/components/form/ApprovalDetails.vue";
import EvaluateForm from "../components/form/EvaluateForm.vue";
import MultiLoginModal from "../components/form/MultiLoginModal.vue";

export default defineComponent({
  name: "EvaluateNeed",
  props: {
    EvaluateId: {
      type: String,
      required: false,
      default: "",
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Header,
    Footer,
    Display: ApprovalDetails,
    Form: EvaluateForm,
    FormModal: MultiLoginModal,
  },
  data() {
    return {
      id: "",
      authStatus: null,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      authStatusValue: "isAuthenticated",
    }),
  },
  watch: {
    authStatusValue: {
      handler() {
        this.authStatus = this.authStatusValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    async getData() {
      await this.GET_HEALTH_NEED(this.id);
    },
    async handleLogin() {
      this.authStatus = this.authStatusValue;
    },
    onEvaluate() {
      this.$emit("close");
    },
  },
  async mounted() {
    this.id = (this.$route.query.id as string) || this.EvaluateId;
    this.getData();
    this.handleLogin();
  },
});
