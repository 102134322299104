const sideRoute = [
  // {
  //   name: "Home",
  //   path: "/innovator",
  //   icon: "icon bi-house-fill",
  // },
  // {
  //   name: "Application Progress",
  //   path: "/innovator/application",
  //   icon: "icon bi-pen-fill",
  // },
  // {
  //   name: "My Innovations",
  //   path: "/innovator/my-innovation",
  //   icon: "icon bi-clock-history",
  // },
  // {
  //   name: "Emerging Innovations",
  //   path: "/innovator/emerging-innovation",
  //   icon: "icon bi-lightbulb-fill",
  // },
  {
    name: "Health Priority Needs",
    path: "/innovator",
    icon: "icon bi-house-fill",
  },
  {
    name: "My Innovations",
    path: "/innovator/my-innovation",
    icon: "icon bi-lightbulb-fill",
    children: [
      {
        name: "List Of Partners",
        path: "/innovator/my-innovation/list",
      },
      {
        name: "Innovations Timeline",
        path: "/innovator/my-innovation/timeline",
      },
      {
        name: "Message",
        path: "/innovator/my-innovation/messages",
      },
      {
        name: "Files",
        path: "/innovator/my-innovation/files",
      },
    ],
  },
  {
    name: "My Partners",
    path: "/innovator/my-partner",
    icon: "icon bi-hand-thumbs-up-fill",
  },
  // {
  //   name: "Other Innovations",
  //   path: "/innovator/other-innovation",
  //   icon: "icon bi-clock-history",
  // },
];

export default sideRoute;
