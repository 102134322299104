// import Role from "@/utils/Role";
import Index from "../views/Index.vue";
import Home from "../views/home/Home.vue";

const routes = [
  {
    path: "/monitoring",
    name: "M&E",
    component: Index,
    // meta: { requiresAuth: true, guest: false, authorize: [Role.mE] },
    children: [
      {
        path: "",
        alias: "home",
        name: "M&E-Home",
        component: Home,
      },
    ],
  },
];

export default routes;
