
import { defineComponent } from "vue";
import ProgressSpinner from "primevue/progressspinner";

export default defineComponent({
  name: "PaginationLoading",
  components: {
    ProgressSpinner,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        message: "Fetching Data...",
      }),
    },
  },
});
