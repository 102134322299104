
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import ProgressSpinner from "primevue/progressspinner";

export default defineComponent({
  name: "Validate",
  components: {
    ProgressSpinner,
  },
  data() {
    return {
      token: "",
      type: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions("AUTH_STORE", ["ACTIVATE_ACCOUNT"]),
    async activateToken() {
      try {
        const validateData = {
          token: this.token,
          type: this.type,
        };
        this.loading = true;
        await this.ACTIVATE_ACCOUNT(validateData);
        this.token = "";
        this.type = "";
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const data = new URL(window.location.href).href;
    if (data) {
      this.token = data.split("?")[1].split("=")[1];
      this.type = data.split("?")[2].split("=")[1];
    }
    await this.activateToken();
  },
});
