
import { defineComponent } from "vue";
import Header from "@/components/Dashboard/Header.vue";
import SideNav from "@/components/Dashboard/SideNav.vue";
import LoadingOverlay from "@/components/LoadingScreen.vue";
import Routes from "../Routes";
import { generic } from "@/types";

export default defineComponent({
  name: "M&E",
  components: {
    Header,
    SideNav,
    LoadingOverlay,
  },
  data() {
    return {
      sideBar: false,
      SideNavigation: [] as Array<generic>,
      loading: false,
    };
  },
  methods: {
    setRoute() {
      this.SideNavigation = Routes;
    },
    //   async setLoader() {
    //     this.loading = this.$store.state.loading;
    //   },
    // },
    // watch: {
    //   "$store.state.loading": {
    //     immediate: true,
    //     handler() {
    //       this.setLoader();
    //     },
    //   },
  },
  created() {
    this.setRoute();
  },
});
