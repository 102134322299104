
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueSignature from "vue3-signature-pad";
import Toast from "@/utils/Toast";
import ViewPartnerShipDeclaration from "../../components/ViewPartnerShipDeclaration.vue";

export default defineComponent({
  name: "Innovator-Step1",
  components: {
    VueSignature,
    ViewPartnerShipDeclaration,
  },
  data() {
    return {
      user: {},
      id: "",
      partner_id: "",
      form: {
        // partnershipType: "",
        aim: "",
        collaboration: "",
        modalities: "",
        agreement: "",
        publications: "",
        result: "",
        innovator_responsibility: "",
        partner_responsibility: "",
        parties: "",
        communications: "",
        adjustment_terms: "",
        settlement_terms: "",
        fullname: "",
        signature: "",
      },
      declarationModal: false,
      declarationModalHeader: "",
      partner: "",
      affirm: false,
      file: "",
      type: [
        {
          id: "Financial (donors, grants, VC funded, angel investors)",
          name: "FINANCIAL",
        },
        {
          id: "Technology (technology transfer and licensing)",
          name: "TECHNOLOGY",
        },
        {
          id: "Implementing (manufacturing, regulatory, supply chain, marketing, communication)",
          name: "IMPLEMENTATION",
        },
      ],
      fileProp: {
        id: "file",
        label: "Upload- Select Files: .pdf .ppt .doc .xls .mp4",
        accept: ".pdf,.ppt,.doc,.xls,.mp4",
      },
      btnStyle: "btn-primary rounded-0 px-lg-5 px-md-3 px-1 py-3 mx-2",
      loading: false,
      loading1: false,
      showSignature: false,
      options: {
        penColor: "#265785",
      },
      width: 100,
      height: 200,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getUserId", "getAccountType"]),
    ...mapGetters("INNOVATOR_STORE", ["getPartnerDeclarationForm"]),
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "INITIATE_PARTNER_WITH_INNOVATION",
      "GET_INNOVATOR",
    ]),
    ...mapActions("PARTNER_STORE", ["GET_PARTNER"]),
    ...mapActions("AUTH_STORE", ["MODAL_LOGOUT"]),
    ...mapActions("INNOVATOR_STORE", ["GET_PARTNER_DECLARATION"]),
    nextPage() {
      this.$router.push("/innovator/my-innovation/step2");
    },
    undo() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.signaturePad as any).undoSignature();
    },
    handleSignature() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data } = (this.$refs.signaturePad as any).saveSignature();
      this.form.signature = data;
      this.showSignature = false;
    },
    // Function to convert base64 to image
    convertBase64ToImage(base64: string) {
      const img = new Image();
      img.src = base64;
      return img;
    },

    DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
    // Form Validation
    formValidation() {
      const form = this.$refs.Mou1 as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        const input = form.querySelector(`[name=${k}]`);
        if (v === "") {
          Error.push(k);
          input?.classList.add("is-invalid");
          input?.classList.add("p-invalid");
        } else {
          input?.classList.remove("is-invalid");
          input?.classList.remove("p-invalid");
          input?.classList.add("is-valid");
          input?.classList.add("p-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          `Please fill out all the required fields, ${Error.join("\r\n,")}`
        );
        return false;
      }
      return true;
    },
    async onSubmit() {
      if (this.formValidation() === false) {
        return;
      }
      if (this.affirm === false) {
        Toast.warning("Please accept the agreement");
        return;
      }

      const data = new FormData();
      data.append("id", this.id);
      data.append("partner_id", this.partner_id);
      data.append("aim", this.form.aim);
      data.append("priorities", this.form.collaboration);
      data.append("terms", this.form.modalities);
      data.append("confidentiality", this.form.agreement);
      data.append("publications", this.form.publications);
      data.append("results", this.form.result);
      data.append(
        "innovator_responsibility",
        this.form.innovator_responsibility
      );
      data.append("partner_responsibility", this.form.partner_responsibility);
      data.append("conditions", this.form.parties);
      data.append("communications", this.form.communications);
      data.append("settlements", this.form.settlement_terms);
      data.append("innovator_full_name", this.form.fullname);
      // ===========================================
      const file = this.DataURIToBlob(this.form.signature);
      // data.append("innovator_signature", this.form.signature);
      data.append("innovator_signature", file, "innovator-signature");
      // ===========================================
      // data.append("type", this.form.partnershipType);
      data.append("status", "PENDING");

      // Display the key/value pairs
      for (var pair of data.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      try {
        this.loading = true;
        await this.INITIATE_PARTNER_WITH_INNOVATION({
          id: this.id,
          data,
          partner_id: this.partner_id,
        });
        //  clear this.form
        this.form = {
          // partnershipType: "",
          aim: "",
          collaboration: "",
          modalities: "",
          agreement: "",
          publications: "",
          result: "",
          innovator_responsibility: "",
          partner_responsibility: "",
          parties: "",
          communications: "",
          adjustment_terms: "",
          settlement_terms: "",
          fullname: "",
          signature: "",
        };
        this.id = "";
        this.partner_id = "";
        setTimeout(() => {
          this.$router.push("/innovator/my-innovation");
        }, 2000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    /**
     * @function getDeclarationByPartnerId
     * @param {string} partnerId
     */

    // async getDeclarationByPartnerId(arg: { [key: string]: string }) {
    //   try {
    //     this.loading = true;
    //     // await this.GET_PARTNER_DECLARATION(arg);
    //     this.declarationModal = true;
    //   } catch (error) {
    //     console.log({ error });
    //   } finally {
    //     this.loading = false;
    //   }
    // },
  },
  async mounted() {
    this.id = this.$route.query.innovation as string;
    this.partner_id = this.$route.query.partner as string;
    if (this.getAccountType !== "innovators") {
      await this.MODAL_LOGOUT();
    } else {
      try {
        this.loading = true;
        const response = await this.GET_INNOVATOR(this.getUserId);
        this.partner = await this.GET_PARTNER(this.partner_id);
        this.user = response;
        this.form.fullname = `${response.first_name} ${response.last_name}`;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  },
});
