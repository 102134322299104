
import { defineComponent } from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { programAreaProp } from "@/props-config";

import { filterResult, generic } from "@/types/";

export default defineComponent({
  name: "HealthInformaticsOfficials",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      dataDialog: false,
      title: "Health Informatics Officials",
      programAreaProp,
      datas: [] as Array<generic>,
      data: {} as generic,
    };
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", {
      datasValue: "getOfficials",
      dataValue: "getOfficial",
    }),
  },
  watch: {
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
      },
      immediate: true,
    },
    dataValue: {
      handler(newValue) {
        this.data = newValue;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations("INFORMATICS_STORE", ["official"]),
    ...mapActions("INFORMATICS_STORE", ["GET_OFFICIALS"]),
    async getOfficials() {
      this.loading = true;
      await this.GET_OFFICIALS();
      this.datas = this.datasValue;
      this.loading = false;
    },
    async getOfficial(id: string) {
      this.loading = true;
      this.official(id);
      this.data = await this.dataValue;
      this.dataDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    addOfficial() {
      this.$router.push("invites");
    },
  },
  async mounted() {
    this.getOfficials();
  },
  created() {
    this.initFilters();
  },
});
