import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce868e46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sub-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/innovator/my-innovation/list",
      class: _normalizeClass(["sub-bar-item", 
        _ctx.url === '/innovator/my-innovation/list' ||
        _ctx.url === '/innovator/my-innovation'
          ? 'sub-bar-active'
          : ''
      ])
    }, {
      default: _withCtx(() => [
        _createTextVNode("List of Partners")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/innovator/my-innovation/timeline",
      class: _normalizeClass(["sub-bar-item", 
        _ctx.url === '/innovator/my-innovation/timeline' ? 'sub-bar-active' : ''
      ])
    }, {
      default: _withCtx(() => [
        _createTextVNode("Innovation timeline ")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/innovator/my-innovation/messages",
      class: _normalizeClass(["sub-bar-item", 
        _ctx.url === '/innovator/my-innovation/messages' ? 'sub-bar-active' : ''
      ])
    }, {
      default: _withCtx(() => [
        _createTextVNode("Messages")
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_router_link, {
      to: "/innovator/my-innovation/files",
      class: _normalizeClass(["sub-bar-item", _ctx.url === '/innovator/my-innovation/files' ? 'sub-bar-active' : ''])
    }, {
      default: _withCtx(() => [
        _createTextVNode("Files")
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}