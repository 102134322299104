
import { defineComponent } from "vue";
import Overview from "../../../informatics/views/overview/prev-index.vue";
import Countries from "../../components/dashboard/Countries.vue";
import Gis from "../../components/dashboard/Gis.vue";

export default defineComponent({
  name: "Who-Dashboard",
  components: {
    Overview,
    Countries,
    Gis,
  },
  data() {
    return {};
  },
  methods: {
    SearchParams(value: string) {
      console.log(value);
    },
  },
});
