import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-208a06bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step" }
const _hoisted_2 = { class: "card border-0 my-3 bg-transparent border-bottom shadow-sm px-2" }
const _hoisted_3 = { class: "container-lg container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Steps = _resolveComponent("Steps")!
  const _component_MouForm = _resolveComponent("MouForm")!
  const _component_LoginModal = _resolveComponent("LoginModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Steps, {
        model: _ctx.items,
        readonly: false
      }, {
        item: _withCtx(({ item }) => [
          _createVNode(_component_router_link, {
            to: item.to,
            onClick: ($event: any) => (_ctx.toggleActive(item.title))
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(["title", [_ctx.activeTitle === item.title && 'active']])
              }, _toDisplayString(item.title), 3),
              _createElementVNode("p", null, _toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["to", "onClick"])
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MouForm),
      (_ctx.authStatus === false && _ctx.showLogin === true)
        ? (_openBlock(), _createBlock(_component_LoginModal, {
            key: 0,
            onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLogin = false))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}