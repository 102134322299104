
import { defineComponent } from "vue";
import { mapMutations, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult } from "@/types/";
import SubBar from "../../components/SubBar.vue";
import ViewMouDeclaration from "../../components/ViewMouDeclaration.vue";

export default defineComponent({
  name: "MyInnovationPartnerList",
  components: {
    DataTable,
    Column,
    SubBar,
    ViewMouDeclaration,
  },
  data() {
    return {
      innovationId: "",
      loading: false,
      filters: {} as filterResult,
      title: "List of Partners",
      datas: [],
      approvedDatas: [],
      partnershipsModal: false,
      partnershipModalHeader: "",
    };
  },
  methods: {
    ...mapMutations("INNOVATOR_STORE", ["myInnovation"]),
    ...mapActions("INNOVATOR_STORE", [
      "GET_MY_INNOVATIONS_PARTNER_LIST",
      "GET_MOU_DECLARATION",
    ]),

    /**
     * @function getPartnerListByInnovationId
     * @param {string} innovationId
     */
    async getPartnerListByInnovationId() {
      try {
        this.loading = true;
        const response = await this.GET_MY_INNOVATIONS_PARTNER_LIST(
          this.innovationId
        );
        this.datas = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * @function toMouForm
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async toMouForm(entity: any) {
      if (entity.data.status === "COMPLETED") {
        this.partnershipModalHeader =
          entity.data.innovation_name ||
          (localStorage.getItem("InnovationName") as string);
        const query = {
          partnerId: entity.data?.partner?.id,
          innovationId: this.innovationId,
        };
        await this.getMouDeclarationByPartnerId(query);
        this.partnershipsModal = true;
      } else {
        this.$router.push(
          `/innovator/my-innovation/mou-details?innovation=${this.innovationId}&partner=${entity.data.partner.id}`
        );
      }
    },
    /**
     * @function getMouDeclarationByPartnerId
     * @param {string} partnerId
     */
    async getMouDeclarationByPartnerId(arg: { [key: string]: string }) {
      try {
        this.loading = true;
        await this.GET_MOU_DECLARATION(arg);
        this.partnershipsModal = true;
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
    // Initiate table filter
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        innovation: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    this.innovationId =
      (this.$route.query.id as string) ||
      (localStorage.getItem("innovationId") as string);
    await this.getPartnerListByInnovationId();
  },
  created() {
    this.initFilters();
  },
});
// innovations/id/partnerships
