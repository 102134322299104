import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-100 pt-4" }
const _hoisted_2 = { class: "priority-needs pb-5 container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-9 order-lg-first order-md-last" }
const _hoisted_5 = { class: "container pb-5" }
const _hoisted_6 = { class: "container-fluid" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-3 order-lg-last order-md-first" }
const _hoisted_9 = { class: "card shadow-sm border-0 h-75 mb-5 mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollArea = _resolveComponent("ScrollArea")!
  const _component_CardList = _resolveComponent("CardList")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_BaseSearch = _resolveComponent("BaseSearch")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SideFilter = _resolveComponent("SideFilter")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("section", _hoisted_5, [
            _createVNode(_component_ScrollArea, { onProgramId: _ctx.programArea }, null, 8, ["onProgramId"])
          ]),
          _createElementVNode("section", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Datas, (data) => {
                return (_openBlock(), _createBlock(_component_CardList, {
                  key: data.title,
                  body: data,
                  card: _ctx.cardStyle,
                  route: `/mda/${data.title}`
                }, null, 8, ["body", "card", "route"]))
              }), 128))
            ]),
            _createVNode(_component_Pagination)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_BaseSearch),
          _createVNode(_component_router_link, {
            class: "fw-bold text-decoration-underline",
            to: "#"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Create a Need")
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_SideFilter)
          ])
        ])
      ])
    ])
  ]))
}