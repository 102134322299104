
import { defineComponent } from "vue";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";

export default defineComponent({
  name: "PartnershipNegotiationForm",
  components: {
    Header,
    Footer,
  },
});
