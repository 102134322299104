import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "innovatorMouLogin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Dialog, {
      visible: _ctx.show,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
      style: { width: '25rem' },
      breakpoints: { '600px': '85vw' },
      modal: true,
      header: "Login to Continue",
      class: "hide_close",
      dismissableMask: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Form)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}