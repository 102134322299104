
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ProgramCoordinatorProfile",
  components: {},
  data() {
    return {
      loading: false,
      profileData: {},
    };
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", [
      "getUserProfileParams",
      "getUserProfileData",
    ]),
  },
  mounted() {
    this.profileData = this.getUserProfileData;
  },
});
