
import { defineComponent } from "vue";
import Form from "../../components/form/EditNeedForm.vue";

export default defineComponent({
  name: "EditPriorityNeeds",
  components: {
    EditHealthPriorityNeed: Form,
  },
});
