const Route = [
  {
    name: "Overview",
    path: "/program-coordinators/home",
    icon: "icon bi-columns-gap",
  },
  {
    name: "Health Needs",
    path: "/program-coordinators/health_needs",
    icon: "icon bi-files",
  },
  {
    name: "Innovations",
    path: "/program-coordinators/innovations",
    icon: "icon bi-lightbulb",
  },
];

export default Route;
