
import { defineComponent } from "vue";
import ProgCoordHealthNeeds from "@/modules/program-cord/views/health-needs/index.vue";
import { mapMutations } from "vuex";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import PublishNeed from "../PublishHealthNeed.vue";

export default defineComponent({
  name: "InformaticsHealthNeeds",
  components: {
    HealthNeed: ProgCoordHealthNeeds,
    PublishNeed,
  },
  data() {
    return {
      healthNeedDialog: false,
      needLoading: false,
      id: "",
      getHealthNeeds: [],
    };
  },
  methods: {
    ...mapMutations("INNOVATION_STORE", [""]),
    async getPendingHealthNeeds() {
      try {
        this.needLoading = true;
        const { data } = await Request("GET", "health_needs/?status=APPROVED");
        this.getHealthNeeds = data.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Toast.error(error);
      } finally {
        this.needLoading = false;
      }
    },
    async closeHealthNeedDialog() {
      this.healthNeedDialog = false;
      await this.getPendingHealthNeeds();
    },
    gotoApproveNeed(id: string) {
      this.id = "";
      this.id = id;
      this.healthNeedDialog = true;
    },
  },
  mounted() {
    this.getPendingHealthNeeds();
  },
});
