const groups = [
  { name: "Individual", id: "INDIVIDUAL" },
  { name: "Business/Organization/Institute/Council", id: "ORGANIZATION" },
];
const genders = [
  { name: "Female", id: "Female" },
  { name: "Male", id: "Male" },
  // { name: "Binary", id: "Binary" },
  // { name: "Non-Binary", id: "Non-Binary" },
  // { name: "Prefer not to say", id: "Prefer not to say" },
];

// ============================================ INPUT FORM
// TEXT
const otherProp = {
  name: "Others",
  id: "other",
  placeholder: "If Other, then describe",
};

// SELECT
const orgTypes = [
  { id: "Academic Institution" },
  { id: "Business (for profit)" },
  { id: "NGO" },
  { id: "Others" },
];

const businessType = [
  { id: "Health" },
  { id: "ICT" },
  { id: "Education" },
  { id: "Agriculture" },
  { id: "Tourism" },
  { id: "Others" },
];

// TEXTAREA
const descProp = {
  name: "Description",
  placeholder:
    "Short description about yourself, your qualification(s), study and work background(s)",
  id: "description",
};

const descOrgProp = {
  name: "Organization Description",
  placeholder:
    "Short description about the organization, qualification(s), and experience",
  id: "descriptionOrg",
};

const termsProp = {
  text: "I have read and accepted the",
  id: "terms",
  // link: "#",
  // linkText: "Terms and Conditions",
};

// FILE PROP
const fileProp = {
  id: "docFile",
  name: "Certificate of Incorporation - Select File .pdf .doc .xls (5mb)",
  label: "Certificate of Incorporation - Select File .pdf .doc .xls",
  accept: ".pdf, .doc, .xls",
  required: true,
};

export {
  groups,
  genders,
  orgTypes,
  businessType,
  otherProp,
  descProp,
  descOrgProp,
  termsProp,
  fileProp,
};
