
import { defineComponent } from "vue";
import Nav from "@/components/Dashboard/Navs.vue";
import { programAreaProp } from "@/props-config";

export default defineComponent({
  name: "Proposal",
  components: {
    Nav,
  },
  data() {
    return {
      programAreaProp,
      navLink: [
        {
          name: "Pending Health Needs",
          path: "/mda/manage-needs/pending",
          notification: "",
        },
        {
          name: "Approved Health Needs",
          path: "/mda/manage-needs/approved",
          notification: "",
        },
      ],
    };
  },
});
