
import { defineComponent } from "vue";

export default defineComponent({
  name: "NoData",
  props: {
    data: {
      type: Object,
      default: () => ({
        message: "No Data Available...",
        size: "md",
      }),
    },
  },
});
