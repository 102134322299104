const titleProp = {
  type: "text",
  name: "Name/Title of solution",
  id: "title",
};

const otherProp = {
  type: "text",
  name: "Others",
  id: "other",
};

// SELECT
const stageSolutionProp = [
  { id: "Stage 1: Ideation" },
  { id: "Stage 2: Research & Development" },
  { id: "Stage 3: Proof of Concept" },
  { id: "Stage 4: Transition to scale" },
  { id: "Stage 5: Scaling" },
  { id: "Stage 6: Sustainable Scale" },
];

const stageClinicalProp = [
  { id: "Discovery and development" },
  { id: "Preclinical research" },
  { id: "Clinical trial" },
];

const stageSubjectProp = [{ id: "Yes" }, { id: "No" }];

const requirementProp = [
  { id: "Pharmaceutical" },
  { id: "Nutriceutical" },
  { id: "Medical device" },
  { id: "Vaccine" },
  { id: "Bureau of Standards or general product stand" },
  { id: "Other (Please state)" },
];

const fundProp = [
  { id: "< $10,000" },
  { id: "$10,000 - $25,000" },
  { id: "$25,000 - $50,000" },
  { id: "$50,000 - $100,000" },
  { id: "$100,000 - $250,000" },
  { id: "> $250,000" },
];

const statusProp = [
  { id: "Not Applied" },
  { id: "Applied" },
  { id: "Under consideration" },
  { id: "Completed and valid" },
  { id: "Needs renewal" },
];
// ======== page 2 select --------------
const contextTypeProp = [
  { id: "Rural" },
  { id: "Urban" },
  { id: "Peri Urban" },
];
const testedProp = [{ id: "Yes" }, { id: "No" }];

const findOutProp = [
  { id: "Facebook" },
  { id: "Google Search" },
  { id: "Individual" },
  { id: "Linkedin" },
  { id: "Organization" },
  { id: "Twitter" },
  { id: "Other" },
];

// ======== Text area
const descriptionProp = {
  name: "",
  id: "description",
  placeholder:
    "Describe the solution and how it addresses/solves the problem described above. In your description please provide solution/product details or specifications",
};

const innovativeProp = {
  name: "",
  id: "innovative",
  placeholder: "How is the solution innovative",
};

const similarProp = {
  name: "",
  id: "similar",
  placeholder:
    "If there are similar solutions available, what differentiates the solution from the others",
};
const othersProp = {
  name: "",
  id: "others",
  placeholder: "If Other selected, please describe.",
};

const employeeProp = {
  name: "",
  id: "employee",
  placeholder:
    "How many paid employees work to support the product/solution. Please describe roles as well.",
};

const regulatoryReqOthersProp = {
  name: "",
  id: "regulatoryReqOthers",
  placeholder: "If Other selected, please describe",
};
// ======== page 2 TextArea ---------------
const solutionImpactProp = {
  name: "",
  id: "impact",
  placeholder:
    "Describe the solution's impact to date, or potential impact to be achieved",
};

const contextProp = {
  name: "",
  id: "context",
  placeholder: "Describe the context",
};

const testProcessProp = {
  name: "",
  id: "testProcess",
  placeholder:
    "If yes, which country(s) has it been tested in? Explain the process of testing the solution and the results thereof",
};
const criticalProcessProp = {
  name: "",
  id: "criticalProcess",
  placeholder:
    "What are the next critical steps you need to take to get to the next level, and what support is required. (Please give detail - this can include, staff, skill, manufacturing, funding, etc requirements)",
};
const potentialProp = {
  name: "",
  id: "potential",
  placeholder: "What is the potential for scale?",
};
const challengesProp = {
  name: "",
  id: "challenges",
  placeholder: "What are the challenges for going to scale?",
};

const selfDescriptionProp = {
  name: "",
  id: "selfDescription",
  placeholder:
    "Please write a short description about yourself, your qualification and study and work background, as well as your relationship to the solution and your role with regards to its continued development",
};

// ======== file prop ------------
const fileProp = {
  name: `documentary evidence (PDF only)`,
  id: "file",
  label: "Upload documentary evidence- Select File: .pdf",
  accept: ".pdf",
  required: true,
};
// ======== file prop page 2 ------------
const fileProp1 = {
  name: `a 1-page reference letter (including the contact details of the person) as an endorsement of your solution i.e. a letter from either a client/customer/investor/mentor or service provider (PDF Only) (maximum size is 5mb)`,
  id: "fileOne",
  label: "Upload- Select File: .pdf",
  accept: ".pdf",
  required: true,
};
const fileProp2 = {
  name: "any relevant technical specifications (if applicable, PDF Only) (maximum size is 5mb)",
  id: "fileTwo",
  label: "Upload- Select File: .pdf",
  accept: ".pdf",
};
const fileProp3 = {
  name: "a short video that covers the following points: Your name; your country of origin; the problem and the solution. If your solution is a product or device then it is recommended you include it in the video (maximum size is 10mb)",
  id: "fileThree",
  label: "Upload- Select File: .mp4",
  accept: ".mp4, .mkv",
};

export {
  titleProp,
  otherProp,
  fundProp,
  descriptionProp,
  similarProp,
  innovativeProp,
  stageSolutionProp,
  stageClinicalProp,
  stageSubjectProp,
  requirementProp,
  statusProp,
  othersProp,
  employeeProp,
  solutionImpactProp,
  contextProp,
  contextTypeProp,
  testedProp,
  testProcessProp,
  criticalProcessProp,
  potentialProp,
  challengesProp,
  selfDescriptionProp,
  findOutProp,
  regulatoryReqOthersProp,
  fileProp,
  fileProp1,
  fileProp2,
  fileProp3,
};
