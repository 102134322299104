
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import Invite from "./HDSetup.vue";

interface filterResult {
  [key: string]: valueData;
}

interface generic {
  [key: string]: string;
}

interface constraintData {
  value: string;
  matchMode: string;
}

interface valueData {
  value?: string;
  operator?: string;
  matchMode?: string;
  constraints?: Array<constraintData>;
}

export default defineComponent({
  name: "ViewHealthDirectorates",
  components: {
    DataTable,
    Column,
    Invite,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      // archiveDataDialog: false,
      dataDialog: false,
      inviteDialog: false,
      title: "Health Directorate",
      programAreaProp,
      datas: [] as Array<generic>,
      data: {} as generic,
    };
  },
  methods: {
    ...mapMutations("HEALTH_DIRECTORATE_STORE", ["SET_HD"]),
    async getData() {
      this.loading = true;
      await this.$store.dispatch(
        "HEALTH_DIRECTORATE_STORE/GET_HEALTH_DIRECTORATES"
      );
      this.datas = await this.$store.getters["HEALTH_DIRECTORATE_STORE/getHDs"];
      this.loading = false;
    },
    // confirmArchiveHealthDirectorate(healthDirectorate: generic) {
    //   this.data = healthDirectorate;
    //   this.archiveHealthDirectorateDialog = true;
    // },
    // archiveHealthDirectorate() {
    //   this.datas = this.datas.filter((val) => val.id !== this.data.id);
    //   this.archiveHealthDirectorateDialog = false;
    //   this.data = {} as generic;
    //   //   this.$toast.add({
    //   //     severity: "success",
    //   //     summary: "Successful",
    //   //     detail: "HealthDirectorate Archived",
    //   //     life: 3000,
    //   //   });
    // },
    async viewData(id: string) {
      this.loading = true;
      this.SET_HD(id);
      this.data = await this.$store.getters["HEALTH_DIRECTORATE_STORE/getHD"];
      this.dataDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    addHealthDirectorate() {
      // this.$router.push("/mda/heath_directorate_setup");
      this.inviteDialog = true;
    },
  },
  async mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
