
import { defineComponent } from "vue";

export default defineComponent({
  name: "Relevant-Documents",
  data() {
    return {
      data: [
        {
          image: "pdf.svg",
          name: "Botswana's National eHealth Policy and Strategy",
        },
        {
          image: "pdf.svg",
          name: "Global strategy for Digital Health",
        },
        {
          image: "pdf.svg",
          name: "Regulatory & Ethical requirements for Health Innovations",
        },
      ],
      documentUrl: [
        {
          image: "pdf.svg",
          label: "The eHealth Strategy of Botswana (2020 - 2024)",
          link: "https://ehealth.ub.bw/bhdc/Docs/MOH%20ehealth%20Strategy%20Book%20A4.pdf",
        },
        {
          image: "pdf.svg",
          label: "Global strategy on digital health (2020 - 2025)",
          link: "https://www.who.int/docs/default-source/documents/gs4dhdaa2a9f352b0445bafbc79ca799dce4d.pdf",
        },
        {
          image: "pdf.svg",
          label:
            "Strategy for scaling up health innovations in the WHO African Region (2020)",
          link: "https://innov.afro.who.int/uploads/media-corner/afr_rc70_11_eng_20201202110758.pdf",
        },
        {
          image: "",
          label: "https://innov.afro.who.int/",
          link: "https://innov.afro.who.int/",
        },
      ],
    };
  },
  methods: {},
});
