
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import Toast from "@/utils/Toast";
import * as yup from "yup";
import {
  emailProp,
  usernameProp,
  passwordProp,
  departmentProp,
  confirm_passwordProp,
} from "@/props-config";
import store from "@/store";

export default defineComponent({
  name: "Informatics-Invite",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      department: yup.string(),
      username: yup.string().required("Username is required"),
      email: yup.string().required().email(),
      password: yup.string().required().min(8),
      confirm_password: yup
        .string()
        .required()
        .oneOf([yup.ref("password")], "Passwords do not match"),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: department, errorMessage: departmentError } =
      useField("department");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: confirm_password, errorMessage: confirm_passwordError } =
      useField("confirm_password");

    const onSubmit = handleSubmit(async (values, { resetForm }) => {
      let data = values;

      const { username, email, department, password } = data;

      let formData = {
        username,
        email,
        department,
        password,
      };
      try {
        loading.value = true;
        await store.dispatch(
          "AUTH_STORE/INVITE_INFORMATICS_OFFICIAL",
          formData
        );
        Toast.info(
          `Invite Sent! ,
          please check ${email} for activation link`
        );
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = true;
      }
      resetForm();
    });

    return {
      onSubmit,
      loading,

      department,
      email,
      username,
      password,
      confirm_password,

      departmentProp,
      emailProp,
      usernameProp,
      passwordProp,
      confirm_passwordProp,

      departmentError,
      emailError,
      usernameError,
      passwordError,
      confirm_passwordError,

      buttonStyle:
        "btn-primary btn-lg px-5 py-2 rounded-0 border-primary border-3 mt-4",
    };
  },
  methods: {},
});
