import Role from "@/utils/Role";
import Index from "../views/index.vue";
import Home from "../views/home/Home.vue";
import Declaration from "../views/home/Declaration.vue";
import HomeProgress from "../views/home/HomeProgress.vue";
// ==============================TRACKING================================
import Tracking from "../views/tracking/Tracking.vue";
import TrackingTimeline from "../views/tracking/TimeLine.vue";

import ViewPartner from "../views/view-partner/index.vue";
import Registration from "../views/Registration.vue";
import PartnershipNegotiationForm from "../views/PartnershipNegotiationForm.vue";
import Interest from "../views/Interest.vue";
// ==============================MY-PARTNERSHIP================================
import MyPartnershipsList from "../views/my-partnerships/index.vue";
import MyPartnershipTimeline from "../views/my-partnerships/MyTimeline.vue";
import MyPartnershipMessages from "../views/my-partnerships/MyMessages.vue";
// ==========================OTHER-INNOVATION==============================
import OtherInnovation from "../views/other-innovation/index.vue";
// ==========================PROFILE==============================
import PartnerProfile from "../views/profile/index.vue";

const routes = [
  {
    path: "/partner",
    name: "Partner-Dashboard",
    component: Index,
    meta: {
      requiresAuth: true,
      guest: false,
      authorize: [Role.partner],
      title: "Partner's Dashboard",
      description:
        "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
      keywords: "innovation, innovation in healthcare', partner",
      transition: "slide-left",
    },
    children: [
      {
        path: "",
        alias: "home",
        name: "PartnerHome",
        component: Home,
      },
      {
        path: "home/:title",
        name: "HomeProgress",
        component: HomeProgress,
      },
      {
        path: "home/declaration-form",
        name: "PartnershipDeclarationForm",
        component: Declaration,
      },
      {
        path: "track",
        name: "Tracking",
        component: Tracking,
      },
      {
        path: "tracking/timeline",
        name: "Tracking-Timeline",
        component: TrackingTimeline,
      },
      {
        path: "view-partners",
        name: "ViewPartner",
        component: ViewPartner,
      },
      // new pages
      {
        path: "my-partnerships",
        name: "MyPartnershipList",
        component: MyPartnershipsList,
      },
      {
        path: "my-partnerships/timeline",
        name: "MyPartnershipTimeline",
        component: MyPartnershipTimeline,
      },
      {
        path: "my-partnerships/messages",
        name: "MyPartnershipMessages",
        component: MyPartnershipMessages,
      },
      {
        path: "profile",
        name: "PartnerProfile",
        component: PartnerProfile,
      },
      {
        path: "other-innovation",
        name: "PartnerOtherInnovation",
        component: OtherInnovation,
        meta: { requiresAuth: false, guest: true },
      },
    ],
  },
  {
    path: "/partner/partnership-interest",
    name: "PartnerInterest",
    component: Interest,
    meta: { requiresAuth: false, guest: true },
  },
  {
    path: "/partner/partner-registration",
    name: "PartnerRegistration",
    component: Registration,
    meta: { requiresAuth: false, guest: true },
  },
  {
    path: "/partner/partnership-negotiation",
    name: "PartnershipNegotiationForm",
    component: PartnershipNegotiationForm,
    meta: { requiresAuth: false, guest: true },
  },
];

export default routes;
