
import { defineComponent } from "vue";
// import Service from "@/modules/innovation/services/Innovation.service";
import { mapGetters, mapActions } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { needDraftsData, filterResult } from "./Types";

export default defineComponent({
  name: "ViewHDNeedHDDraft",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      archiveNeedHDDraftDialog: false,
      needDraftDialog: false,
      title: "Need Drafts (Health Directorate)",
      programAreaProp,
      datas: [] as Array<needDraftsData>,
      data: {} as needDraftsData,
      user: {} as needDraftsData,
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", {
      datasValue: "getHealthNeeds",
    }),
  },
  methods: {
    // ...mapMutations("HEALTH_DIRECTORATE_STORE", ["SET_NEED_DRAFT"]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEEDS"]),
    async getData() {
      this.loading = true;
      await this.GET_HEALTH_NEEDS("PUBLISHED");
      this.datas = this.datasValue;
      this.loading = false;
    },
    confirmArchiveNeedHDDraft(data: needDraftsData) {
      this.data = data;
      this.archiveNeedHDDraftDialog = true;
    },
    archiveNeedHDDraft() {
      this.datas = this.datas.filter((val) => val.id !== this.data.id);
      this.archiveNeedHDDraftDialog = false;
      this.data = {} as needDraftsData;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        title: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        writer: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
