
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import CardList from "@/components/Card/InnovationList.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import { cardListStyle } from "./Data";

export default defineComponent({
  name: "DashboardHome",
  components: {
    CardList,
    ScrollArea,
  },
  data() {
    return {
      cardListStyle,
      Message: "",
      isLoaded: false,
      data: {},
      loading: false,
      tagTitle: "",
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getInnovations"]),
    ...mapGetters(["getPagination"]),
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "GET_INNOVATIONS_BY_STATUS",
      "GET_INNOVATIONS_BY_STATUS_PAGINATION",
      "GET_INNOVATION_BY_TAG",
    ]),
    gotoForm() {
      this.$router.push("/priority-needs/submit");
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    /**
     * Function to filter the data by the tab selected
     * @param {string} programArea
     * Filtered if programArea is not empty
     * Message if Filtered is empty
     */
    async programArea(value: { [key: string]: string }) {
      this.tagTitle = value.name;
      if (value.name === "All") {
        await this.getData();
        return;
      }
      try {
        this.loading = true;
        const data = await this.GET_INNOVATION_BY_TAG({
          id: value.id,
          status: "APPROVED",
        });
        this.publishedData = data.data;
        this.publishedDataPagination.size = data?.size;
        this.publishedDataPagination.total_records = data?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getData() {
      this.loading = true;
      await this.GET_INNOVATIONS_BY_STATUS({ status: "APPROVED" });
      this.loading = false;
    },
    // Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.loading = true;
        await this.GET_INNOVATIONS_BY_STATUS_PAGINATION({
          status: "APPROVED",
          page: data.page + 1,
        });
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.getData();
  },
});
