
import { defineComponent } from "vue";

export default defineComponent({
  name: "Stages",
  data() {
    return {
      data: [
        {
          title: "Ideation",
          message:
            "You are defining and analyzing the development problem and generating ideas for potential innovations to address the problem. Your innovation is at this stage if, you do not have one specific innovation you are working on, but have defined the problem that you are seeking to address and have potential solutions.",
        },
        {
          title: "Research & Development",
          message:
            "You are developing a specific innovation that has the potential to address a development problem you have identified. Your innovation is at this stage if, you have one specific innovation you are working on to address a specific problem. You clearly know the problem and whom/where you’re developing the innovation for as well as have some ideas for the exact innovation and how the innovation work. You have also defined some key questions that you need to answer in order to understand the viability of the innovation as a solution to the problem.",
        },
        {
          title: "Proof of Concept",
          message:
            "The intellectual concept behind this innovation has been field-tested to gain an early 'real-world' assessment of its potential. Your innovation is at this stage if, your innovation is beyond early development, its main components have already been tested in the real world in a low- or middle-income country and you can articulate its potential technical, organizational and financial viability based on the test(s).",
        },
        {
          title: "Transition to scale",
          message:
            "This innovation has demonstrated small-scale success and you're developing the business model and attracting partners to help fill gaps in your capacity to scale. Your innovation is at this stage if your innovation has demonstrated at least small-scale success in the real world and you are working on the scaling plan including refining the business model and developing necessary partnerships to support you by filling gaps (technical, financial) in your capacity to scale.",
        },
        {
          title: "Scaling",
          message:
            "You are replicating and/or adapting this innovation across large geographies and populations for transformational impact. Your innovation is at this stage if, your innovation is already on it’s scaling journey. You will have chosen among various possible scaling pathways - selecting not only a public (government) and/or commercial (private sector) route to scale and identified relevant partners for support but also specified the pathway within this domain - e.g. deciding between horizontal scaling (expanding impact through replication), vertical scaling (changing the policy/institutional environment through higher level influencing), functional scaling (expanding the functional scope of an innovation, e.g. adding TB and Malaria interventions to an innovation focusing on HIV/AIDS) or a hybrid of these.",
        },
        {
          title: "Sustainable Scale",
          message:
            "This innovation has reached wide-scale adoption or operation at the desired level of scale/exponential growth, sustained by an ecosystem of actors. Your innovation is at this stage if...your innovation has achieved scale. This means that your innovation has reached a desired level of scale or growth, and that you are tackling, “longer-term questions that arise regarding how to manage the process while it operates at scale, whether it is sustainable (financially, politically, etc.), and how to navigate as the environment changes (partners, competitors, policy/regulatory frameworks or infrastructure, etc).",
        },
      ],
    };
  },
});
