
import { defineComponent } from "vue";
import Nav from "@/components/Dashboard/Navs.vue";
import Breadcrumb from "primevue/breadcrumb";
import { programAreaProp } from "@/props-config";

export default defineComponent({
  name: "Proposal",
  components: {
    Nav,
    Breadcrumb,
  },
  data() {
    return {
      programAreaProp,
      navLink: [
        {
          name: "Shortlisted Innovation Proposal",
          path: "/mda/manage-proposal/shortlisted",
          notification: "",
        },
        {
          name: "Approved Innovation Proposal",
          path: "/mda/manage-proposal/approved",
          notification: "",
        },
      ],
      home: { icon: "pi pi-home", to: "#" },
      items: [
        { label: "Pending Innovation Proposals" },
        { label: "Postpartum haemorrhage" },
      ],
      items1: [
        { label: "Pending Innovation Proposals" },
        { label: "Postpartum haemorrhage" },
      ],
      items2: [
        { label: "Approved Innovation Proposals" },
        { label: "Postpartum haemorrhage" },
      ],
    };
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        const path = this.$route.path;
        if (path === "/mda/manage-proposal/shortlisted") {
          this.items = this.items1;
        } else {
          this.items = this.items2;
        }
      });
    },
  },
});
