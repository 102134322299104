/**
 * Welcome Image attribute
 */
const Welcome = {
  image: "hero_banner.png",
  title: `Botswana health innovation management system`,
  subtitle: "",
  body: "Managing end-to-end process of demand-driven innovations for sustainable impact in Botswana.",
  imageStyling: "",
  titleStyling: "text-uppercase",
  subtitleStyling: "",
  bodyStyling: "bg-body1 fs-2",
  buttonStyle:
    "btn-transparent btn-lg rounded-0 border-light text-white py-3 px-5 btn-elevate",
  buttonLink: "",
  buttonText: "",
};

/**
 * Card Styling
 */
const cardStyle = {
  customCard: "shadow text-center",
  titleStyle: "card-title zoom-effect-title",
  customRow: "col-lg-3 col-md-6 col-12",
  image: true,
};

/**
 * Card data
 */
const cardData = [
  {
    title: "HEALTH PRIORITY NEEDS",
    titleLink: "/priority-needs",
    image: "banner-10.png",
    body: "Highlight of the Botswana Health priority needs that can be addressed using innovative approaches, tools and technologies",
  },
  {
    title: "SUBMIT INNOVATION PROPOSAL",
    titleLink: "/submit-proposal",
    image: "banner-2.png",
    body: "Do you have an already tested and validated innovative solution that can meet one or more of the highlighted health priority needs? Submit your proposal",
  },
  {
    title: "PARTNER WITH US",
    titleLink: "/partners",
    image: "banner-6.png",
    body: "Do you wish to partner with us in scaling up innovations to address the identified health needs?",
  },
  {
    title: "INNOVATIONS IN THE FIELD",
    titleLink: "/innovation-field",
    image: "banner-1.png",
    body: "The innovations being scaled up are showing great impact. Check out these innovations",
  },
];

/**
 * Partner Section
 */
const partnerData = ["partner-1", "partner-1", "partner-1", "partner-1"];

/**
 * Registration Process
 */
const Process = [
  "Account-creation",
  "Health-needs-priorities",
  "Apply-for-needs",
  "Approval",
  "Innovation-begins",
];

/**
 * What we do section
 */
const whatWeDo = {
  title: `What We Do`,
  content: `The Ministry of Health (MoH) aims to maximize the
  impact of innovation through deliberate collaboration and
  co-creation opportunities to ensure efficiency, availability and
  sustainability in meeting specific health innovation needs.`,
  content2: `In line with our aim to improve the physical, mental, and social
  well-being of every citizen, the Ministry welcomes you to contribute
  to building a healthier nation through impactful innovations.`,
};
/**
 * How we do it section
 */
const howWeDoIt = {
  title: `How we do it`,
  content: `We populate the platforms with various health needs across Botswana,
  review submitted Innovation proposals and upscale them. Below is an
  infographics that best represents these.`,
};
/**
 * Who does it section
 */
const whoDoesIt = {
  title: `Who does it`,
  content: `The Ministry of Health has a dedicated team that
  reviews the innovations/proposals submitted, the partnerships
  initiated and the progress of innovations being implemented.`,
};

export {
  Welcome,
  cardStyle,
  cardData,
  partnerData,
  Process,
  whatWeDo,
  howWeDoIt,
  whoDoesIt,
};
