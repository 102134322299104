
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Skeleton from "primevue/skeleton";
import CardList from "@/components/Card/List.vue";
import DashboardHeader from "../../components/Dashboard-Header.vue";
import { cardListStyle } from "@/modules/innovation/views/priority-needs/Data";
import { NoData, LoadingPagination } from "@/components";

type headerData = {
  [key: string]: string | number | boolean;
};
export default defineComponent({
  name: "HealthInformatics",
  components: {
    CardList,
    Skeleton,
    DashboardHeader,
    NoData,
    LoadingPagination,
  },
  data() {
    return {
      loading: true,
      cardListStyle,
      datas: [],
      headerData: [] as headerData[],
      tagTitle: "",
      paginationLoader: false,
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", {
      datasValue: "getHealthNeeds",
    }),
    ...mapGetters({
      getAnalytics: "getAnalytics",
      paginateData: "getPagination",
    }),
  },
  watch: {
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", [
      "GET_HEALTH_NEEDS",
      "GET_HEALTH_NEEDS_BY_STATUS_PAGINATION",
    ]),
    ...mapActions(["GET_PLATFORMS_ANALYTICS"]),
    /**
     * Function to filter the data by the tab selected
     * @param {string} programArea
     * Filtered if programArea is not empty
     * Message if Filtered is empty
     */
    async programArea(value: string) {
      this.tagTitle = value;
    },
    /**
     * @function getData
     * @description Function to get the data from the API onMounted
     */
    async getData() {
      this.loading = true;
      await this.GET_HEALTH_NEEDS("PUBLISHED");
      this.loading = false;
    },
    // Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.paginationLoader = true;
        await this.GET_HEALTH_NEEDS_BY_STATUS_PAGINATION({
          status: "PUBLISHED",
          page: data.page + 1,
        });
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      } finally {
        this.paginationLoader = false;
      }
    },
    async getAnalyticsData() {
      await this.GET_PLATFORMS_ANALYTICS();
      if (Object.keys(this.getAnalytics).length !== 0) {
        this.headerData = [
          {
            title: "Published Needs",
            value: this.getAnalytics.needs_published,
            bgColor: true,
          },
          {
            title: "Needs",
            value: this.getAnalytics.total_needs,
            bgColor: false,
          },
          {
            title: "Innovations",
            value: this.getAnalytics.total_innovations,
            bgColor: false,
          },
          {
            title: "Innovators",
            value: this.getAnalytics.innovators,
            bgColor: false,
          },
          {
            title: "Partners",
            value: this.getAnalytics.partners,
            bgColor: false,
          },
        ];
      }
    },
  },
  async mounted() {
    this.getData();
    this.datas = this.datasValue;
    this.getAnalyticsData();
    this.loading = false;
  },
});
