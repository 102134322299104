import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  getTags: (state: state) => state.Tags,
  getOfficials: (state: state) => state.Officials,
  getOfficial: (state: state) => state.Official,
  getUsers: (state: state) =>
    state.Users.filter((el) => el.type !== "NOT SPECIFIED"),
  getUserProfileParams: (state: state) => state.userProfileParams,
  getUserProfileData: (state: state) => state.userProfileData,
  getPartnershipDeclarations: (state: state) => state.partnershipDeclarations,
};

export default getters;
