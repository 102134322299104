
import { defineComponent } from "vue";
import MultiLoginFormModal from "./LoginModalForm.vue";

export default defineComponent({
  name: "SubmitInnovationLoginModal",
  components: {
    Form: MultiLoginFormModal,
  },
  props: {
    closeBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
});
