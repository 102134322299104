import index from "./index.vue";
import PriorityNeeds from "./PriorityNeeds.vue";
import PriorityNeedsDescription from "./PriorityDescription.vue";
import PriorityNeedsList from "./PriorityNeedsList.vue";
import SubmitPriorityNeeds from "./SubmitNeed.vue";
import EditPriorityNeeds from "./EditNeed.vue";

export default [
  {
    path: "/priority-needs",
    name: "PriorityIndex",
    component: index,
    meta: {
      title: "Health Priority Needs",
      description:
        "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
      keywords: "innovation, innovation in healthcare",
      ogTitle: "Innovation | Health Needs",
      transition: "slide-left",
    },
    children: [
      {
        path: "",
        alias: "home",
        name: "PriorityNeeds",
        component: PriorityNeeds,
      },
      {
        path: "submit",
        name: "SubmitPriorityNeeds",
        component: SubmitPriorityNeeds,
      },
      {
        path: "edit-need",
        name: "EditPriorityNeeds",
        component: EditPriorityNeeds,
      },
      {
        path: ":slug",
        name: "PriorityNeedsDescription",
        component: PriorityNeedsDescription,
      },
    ],
  },
  {
    path: "priority-needs/list",
    name: "PriorityNeedList",
    component: PriorityNeedsList,
  },
];
