
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

interface distributionType {
  financial: number;
  technology: number;
  implementation: number;
}

export default defineComponent({
  name: "DistributionAndPartnershipView",
  props: {},
  data() {
    return {
      distributionDetail: {} as distributionType,
    };
  },
  computed: {
    ...mapGetters({
      getViewPartnerShipDist: "getViewPartnerShipDist",
    }),
  },
  methods: {
    ...mapActions(["GET_VIEW_PARTNERSHIP_DISTRIBUTIONS"]),
    async getViewPartnershipDistribution() {
      await this.GET_VIEW_PARTNERSHIP_DISTRIBUTIONS();
      if (this.getViewPartnerShipDist) {
        this.distributionDetail = this.getViewPartnerShipDist;
      }
    },
  },
  async mounted() {
    await this.getViewPartnershipDistribution();
  },
});
