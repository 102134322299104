import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d90afb12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rounded-3 zoom-effect-img card-img-container d-flex justify-content-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-body m-2 flex-fill" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 1,
  class: "card-text"
}
const _hoisted_6 = { class: "see_more text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-component my-3 d-flex", [_ctx.card.customRow]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["card rounded-1 pb-2", [_ctx.card.customCard]])
    }, [
      (_ctx.card.image || _ctx.body.image)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("img", {
              src: require(`@/assets/img/banner/${_ctx.body.image}`),
              class: _normalizeClass(["card-img-top img-fluid", [_ctx.card.imageStyle]]),
              alt: ""
            }, null, 10, _hoisted_2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.body.title)
          ? (_openBlock(), _createElementBlock("h5", {
              key: 0,
              class: _normalizeClass(["fw-bold", [_ctx.card.titleStyle]])
            }, [
              (_ctx.body.titleLink !== '')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: _ctx.body.titleLink
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.body.title), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.body.title), 1))
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.body.body)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _createTextVNode(_toDisplayString(_ctx.body.body.split(" ").splice(0, 25).join(" ")) + " ", 1),
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: _ctx.body.titleLink
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("See More")
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 2))
}