
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ViewPartnerShipDeclaration",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getPartnerDeclarationForm"]),
  },
});
