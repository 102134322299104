/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Swal from "sweetalert2";

/**
 * TOAST FUNCTION USING SWEETALERT
 * @param {*} title?: Pass the title {OPTIONAL} || Default = ""
 * @param {*} message?: Pass the message {OPTIONAL} || Default = "Something went wrong"
 * @param {*} toastIcon? Pass the Icon {OPTIONAL} || Default = INFO
 * @param {*} time? Pass the Time {OPTIONAL} || Default = 3500
 * ?? ICON-TYPES : error, info, success, warning, question
 * @returns ToastModal at the top right corner of the screen.
 */
const toastMods = (
  title?: string,
  message?: any,
  toastIcon?: any,
  time?: number
): void => {
  Swal.fire({
    toast: true,
    position: "bottom-start",
    showConfirmButton: false,
    timer: time || 3500,
    icon: toastIcon || "info",
    title: title || "",
    text: message || "Something went wrong!",
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
};

// check if it is an object

// create a toast class to use it in the components i.e toast.error
const Toast = {
  error: (message: string, duration = 5_000): void =>
    toastMods("Error", message, "error", duration),
  info: (message: string | { [key: string]: any }, duration = 5_000): void => {
    if (typeof message === "object") {
      const messages = [];
      for (const key in message) {
        messages.push(
          message[key].length > 40
            ? `${key}: ${message[key][0].substring(0, 40)} ...`
            : `${key}: ${message[key][0].substring(0, 40)}`
        );
      }
      toastMods("info", messages.join("\r\n"), "question", duration);
    } else if (typeof message === "string") {
      return toastMods("Info", message, "info", duration);
    }
  },
  success: (message: string, duration = 5_000): void =>
    toastMods("Success", message, "success", duration),
  warning: (message: string, duration = 5_000): void =>
    toastMods("Warning", message, "warning", duration),
  question: (message: string, duration = 5_000): void =>
    toastMods("Question", message, "question", duration),
};

export default Toast;
