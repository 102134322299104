
import { defineComponent, PropType } from "vue";
import { mapActions } from "vuex";
import { cardListData } from "./propConfig";
import { needType } from "@/types";

export default defineComponent({
  name: "ListView",
  props: {
    body: {
      type: Object as PropType<needType>,
      required: true,
    },
    card: {
      type: Object as PropType<cardListData>,
      required: true,
    },
    route: {
      type: String,
      required: false,
    },
  },
  methods: {
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    trimText(text: string, limit: number) {
      if (text.length > limit) {
        return `${text.substring(0, limit)}...`;
      }
      return text;
    },
    async handleDisplay() {
      await this.GET_HEALTH_NEED(this.body.id);
    },
  },
});
