
import { defineComponent, PropType } from "vue";
import { inputData } from "./propConfig";

export default defineComponent({
  name: "BaseInput",
  emits: ["^update:"],
  props: {
    inputType: {
      type: Object as PropType<inputData>,
      required: true,
      default: () => ({
        id: "",
        name: "",
        type: "",
        label: "",
        placeholder: "",
        autoComplete: "",
      }),
    },
    label: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    toolTip: {
      type: Object as PropType<{
        icon: string;
        message: string;
      }>,
      default: () => ({
        icon: "",
        message: "",
      }),
    },
  },

  data() {
    return {
      showPassword: false,
      countryCode: "",
    };
  },
  methods: {
    handleInput: function (e: Event) {
      return (e.target as HTMLInputElement).value;
    },
  },
});
