import Terms from "@/components/TermsAndConditions.vue";
import Disclaimer from "@/components/Disclaimer.vue";
import index from "../views/index.vue";
import PriorityNeeds from "../views/priority-needs/routes";
import Partner from "../views/partner-with-us/routes";
import resourceRoute from "../views/resource/router";
import Home from "../views/home/index.vue";
import Faq from "../views/faq/Faq.vue";
import SubmitProposal from "../views/submit-proposal/SubmitProposal.vue";
import CallForProposal from "../views/submit-proposal/CallForProposal.vue";
import Description from "../views/submit-proposal/Description.vue";
import InTheField from "../views/in-the-field/InTheField.vue";

// function importComponent(arg: string) {
//   return () => import(`../views/${arg}.vue`);
// }

export default [
  {
    path: "/",
    alias: "/innovation",
    component: index,
    children: [
      {
        path: "home",
        alias: "",
        name: "InnovationHome",
        // component: importComponent("home/index"),
        component: Home,
        meta: {
          title: "Home",
          description:
            "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
          keywords: "innovation, innovation in healthcare",
          transition: "slide-left",
        },
      },
      {
        path: "faq",
        name: "Faq",
        // component: importComponent("faq/Faq"),
        component: Faq,
      },
      ...PriorityNeeds,
      ...Partner,
      ...resourceRoute,
      {
        path: "submit-proposal",
        name: "SubmitProposal",
        // component: importComponent("submit-proposal/SubmitProposal"),
        component: SubmitProposal,
        meta: {
          title: "Submit Proposal",
          description: "Submit your innovation idea",
          keywords: "innovation, innovation in healthcare",
        },
      },
      {
        path: "submit-proposal/call-for-proposal",
        name: "CallForProposal",
        // component: importComponent("submit-proposal/CallForProposal"),
        component: CallForProposal,
      },
      {
        path: "submit-proposal/call-for-proposal/:slug",
        name: "ProposalDescription",
        // component: importComponent("submit-proposal/Description.vue"),
        component: Description,
      },
      {
        path: "innovation-field",
        name: "InTheField",
        // component: importComponent("in-the-field/InTheField"),
        component: InTheField,
        meta: {
          title: "Innovation In The Field",
          description: "List of innovations in the field that are scaling",
          keywords: "innovation, innovation in healthcare",
        },
      },
      {
        path: "terms",
        name: "TermsAndConditions",
        component: Terms,
        meta: {
          title: "Terms and Conditions",
          description: "Terms and Conditions",
          keywords: "innovation, innovation in healthcare",
        },
      },
      {
        path: "disclaimer",
        name: "Disclaimer",
        component: Disclaimer,
        meta: {
          title: "Disclaimer",
          description: "Disclaimer",
          keywords: "innovation, innovation in healthcare",
        },
      },
    ],
  },
];
