
import { defineComponent, PropType } from "vue";

// Typescript declaration for props DATA
interface dataType {
  title: string;
  value: string;
  bgColor: boolean;
}

export default defineComponent({
  name: "Informatics_Header",
  components: {},
  props: {
    data: {
      type: Array as PropType<dataType[]>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
});
