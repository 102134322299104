import { MutationTree } from "vuex";
import { state, generic } from "./types";

const mutations: MutationTree<state> = {
  // healthDirectorate
  healthDirectorates: (state, data): void => {
    state.healthDirectorates = [];
    state.healthDirectorates = data;
  },
  SET_HD: (state, id: string): void => {
    state.healthDirectorate = {} as generic;
    state.healthDirectorate = state.healthDirectorates.filter(
      (item: generic) => item.id === id
    )[0];
  },
  // technicalDirector
  technicalDirectors: (state, data): void => {
    state.technicalDirectors = [];
    state.technicalDirectors = [...data];
  },
  SET_TD: (state, id: string): void => {
    state.technicalDirector = {} as generic;
    state.technicalDirector = state.technicalDirectors.filter(
      (item: generic) => item.id === id
    )[0];
  },
};

export default mutations;
