
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Chart from "primevue/chart";
import BoxOverview from "@/components/Dashboard/Overview.vue";

export default defineComponent({
  name: "PrevOverview",
  components: { Chart, BoxOverview },
  data() {
    return {
      loading: false,
      tileData: {
        healthNeeds: 0,
        healthInnovations: 0,
        innovators: 0,
        partners: 0,
      },
      basicData: {},
      option: {
        plugins: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getAnalytics: "getAnalytics",
    }),
  },
  methods: {
    ...mapActions(["GET_PLATFORMS_ANALYTICS"]),
    async getChartDetails() {
      await this.GET_PLATFORMS_ANALYTICS();
      if (Object.keys(this.getAnalytics).length !== 0) {
        this.loading = true;
        this.tileData.healthNeeds = this.getAnalytics.total_needs;
        this.tileData.healthInnovations = this.getAnalytics.total_innovations;
        this.tileData.innovators = this.getAnalytics.innovators;
        this.tileData.partners = this.getAnalytics.partners;

        this.basicData = {
          labels: [
            "Health Needs",
            "Health Innovation Proposals",
            "Partnerships",
          ],
          datasets: [
            {
              type: "bar",
              label: "Published",
              backgroundColor: "#5DD3FF",
              data: [
                this.getAnalytics.needs_published,
                this.getAnalytics.innovations_published,
                this.getAnalytics.total_partnerships,
              ],
            },
            {
              type: "bar",
              label: "Evaluation",
              backgroundColor: "#ACE8FF",
              data: [
                this.getAnalytics.needs_in_evaluation,
                this.getAnalytics.innovations_in_evaluation,
                0,
              ],
            },
            {
              type: "bar",
              label: "Awaiting Approval",
              backgroundColor: "#CAECF9",
              data: [
                this.getAnalytics.needs_awaiting_approval,
                this.getAnalytics.innovations_awaiting_approval,
                this.getAnalytics.partnerships_approved,
              ],
            },
            {
              type: "bar",
              label: "Rejected",
              backgroundColor: "#DC485F",
              data: [
                this.getAnalytics.needs_rejected,
                this.getAnalytics.innovations_rejected,
                this.getAnalytics.partnerships_pending,
              ],
            },
          ],
        };
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getChartDetails();
    this.loading = false;
  },
});
