
import { defineComponent } from "vue";
import Nav from "@/components/Dashboard/Navs.vue";
import { programAreaProp } from "@/props-config";

export default defineComponent({
  name: "NeedDraft",
  components: {
    Nav,
  },
  data() {
    return {
      programAreaProp,
      navLink: [
        {
          name: "All Need Draft",
          path: "/mda/need-drafts",
          notification: "",
        },
        {
          name: "Health Directorate Need Draft",
          path: "/mda/need-drafts/view-HD-needs",
          notification: "",
        },
      ],
    };
  },
});
