
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "ApproveDetail",
  props: {
    id: {
      type: String,
      required: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      needId: "",
      loading: false,
      loading1: false,
      comment: "",
      btnStyle1: "btn-light rounded-0 px-5 py-3 btn_style",
      btnStyle2: "btn-danger rounded-0 px-5 py-3 btn_style",
      btnStyle3: "btn-primary rounded-0 px-5 py-3 btn_style",
      url: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", ["getHealthNeed"]),
    ...mapGetters("AUTH_STORE", ["getUserId"]),
  },
  methods: {
    ...mapActions("INNOVATION_STORE", [
      "PUBLISH_HEALTH_NEED",
      "ARCHIVE_HEALTH_NEED",
    ]),
    getFileExt(data: string) {
      const name = data?.split(".");
      const fileExt = name[name?.length - 1];
      return fileExt;
    },
    getFileName(data: string) {
      const name = data.split("/");
      return name[name.length - 1];
    },
    percentage(data: number) {
      const percentage = ((data / 5) * 100).toFixed(2);
      return percentage;
    },
    async onSubmit() {
      if (this.getUserId === "") {
        Toast.error("You are not logged in");
        this.$emit("NotLoggedIn");
        return;
      }
      if (this.comment === "") {
        Toast.error("Please enter a comment");
        return;
      }

      const data = {
        informatic_official: this.getUserId,
        comment: this.comment,
      };

      this.loading = true;
      try {
        // incase the id wasn't passed as a props (from email) == this.needId
        // console.log(this.id, this.getHealthNeed.id, 'legba', this.needId)
        await this.PUBLISH_HEALTH_NEED({
          id: this.id || this.getHealthNeed.id,
          data,
        });
        // if it is a dialog, close it
        if (this.modal === true) {
          this.$emit("Evaluated");
          return;
        }
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/priority-needs");
        }, 2000);
        this.comment = "";
        this.loading = false;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async onReject() {
      try {
        this.loading1 = true;
        await this.ARCHIVE_HEALTH_NEED(this.id);
        // if it is a dialog, close it
        if (this.modal === true) {
          this.$emit("Evaluated");
          return;
        }
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/priority-needs");
        }, 2000);
        Toast.info("Need REJECTED");
        this.comment = "";
      } catch (error) {
        Toast.error("An error occurred please try again");
      } finally {
        this.loading1 = false;
      }
    },
    handleFile(file: string) {
      const url = "https://innovation-scaling-api-service.herokuapp.com" + file;
      window.open(url, "_blank");
    },
  },
  mounted() {
    // incase the id wasn't passed as a props (from email)
    this.needId =
      (this.$route.query.id as string) || this.id || this.getHealthNeed?.id;
  },
});
