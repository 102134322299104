
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ApproveDetail",
  data() {
    return {
      Need: {},
      url: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", {
      needValue: "getHealthNeed",
    }),
  },
  watch: {
    needValue: {
      handler() {
        this.Need = this.needValue;
      },
    },
  },
  methods: {
    getFileExt(data: string) {
      const name = data.split(".");
      const fileExt = name[name.length - 1];
      return fileExt;
    },
    getFileName(data: string) {
      const name = data.split("/");
      return name[name.length - 1];
      // remove the first '/' from the string
      // return data.substring(1);
    },
    handleFile(file: string) {
      const url = "https://innovation-scaling-api-service.herokuapp.com" + file;
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.Need = this.needValue;
  },
});
