
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import Card from "@/components/Card/InnovationCard.vue";
import { cardStyle2, Welcome, buttonStyle } from "./Data";
import WelcomeScreen from "../../components/Welcome.vue";
import WelcomeImageLoader from "../../components/Loader/WelcomeImageLoader.vue";
import InnovationAndPartnerList from "./InnovationAndPartnerList.vue";
import DistributionAndPartnershipView from "./DistributionAndPartnershipView.vue";

export default defineComponent({
  name: "PartnerWithUs",
  components: {
    WelcomeScreen,
    WelcomeImageLoader,
    Card,
    InnovationAndPartnerList,
    DistributionAndPartnershipView,
  },
  data() {
    return {
      cardStyle2,
      Welcome,
      buttonStyle,
      datas: [],
      isLoaded: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", {
      datasValue: "getFourInnovations",
    }),
  },
  watch: {
    datasValue: {
      handler(newValue) {
        this.datas = newValue;
      },
    },
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATIONS_BY_STATUS"]),
    onImgLoad() {
      this.isLoaded = true;
    },
  },
  async mounted() {
    this.loading = true;
    await this.GET_INNOVATIONS_BY_STATUS({ status: "PARTNERED" });
    this.datas = this.datasValue;
    this.loading = false;
  },
});
