import Index from "./index.vue";
import Pending from "./Pending.vue";
import Approved from "./Approved.vue";

export default [
  {
    path: "manage-needs",
    name: "ManageNeeds",
    component: Index,
    children: [
      {
        path: "",
        alias: "pending",
        name: "Pending",
        component: Pending,
      },
      {
        path: "approved",
        name: "Approved",
        component: Approved,
      },
    ],
  },
];
