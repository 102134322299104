import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e33b766"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-list" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "col-md-3"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "p-3 w-100" }
const _hoisted_6 = { class: "see_more text-primary" }
const _hoisted_7 = {
  key: 1,
  class: "col-md-3"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "row w-100" }
const _hoisted_10 = {
  key: 0,
  class: "col-md-3"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "p-1" }
const _hoisted_13 = {
  key: 1,
  class: "col-md-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.body.link)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.route
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["card shadow-sm border-0 rounded-2", [_ctx.card.customCard]])
            }, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.body.image)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("img", {
                        src: require(`@/assets/img/banner/${_ctx.body.image}`),
                        class: _normalizeClass(["img-fluid rounded-2 h-100", [_ctx.card.customImage]]),
                        alt: "welcome-img"
                      }, null, 10, _hoisted_4)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(
              (_ctx.body.programArea !== '' && _ctx.body.location === '') || !_ctx.body.image
                ? 'col-12'
                : 'col-md-9'
            )
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.body.title)
                      ? (_openBlock(), _createElementBlock("h4", {
                          key: 0,
                          class: _normalizeClass(["mb-1", [_ctx.card.customTitle]])
                        }, _toDisplayString(_ctx.body.title), 3))
                      : _createCommentVNode("", true),
                    (_ctx.body.programArea !== '' && _ctx.body.location === '')
                      ? (_openBlock(), _createElementBlock("h6", {
                          key: 1,
                          class: _normalizeClass(["mb-2 fw-normal", [_ctx.card.customProgramArea]])
                        }, [
                          _createElementVNode("i", null, _toDisplayString(_ctx.body.programArea), 1),
                          _createTextVNode("9 ")
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.body.body)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 2,
                          class: _normalizeClass(["mb-2", [_ctx.card.customBody]])
                        }, [
                          _createTextVNode(_toDisplayString(_ctx.body.body.split(" ").splice(0, 25).join(" ")) + " ", 1),
                          _createElementVNode("span", _hoisted_6, [
                            _createVNode(_component_router_link, { to: _ctx.route }, {
                              default: _withCtx(() => [
                                _createTextVNode("See More")
                              ]),
                              _: 1
                            }, 8, ["to"])
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    (_ctx.body.innovator)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 3,
                          class: _normalizeClass([[_ctx.card.customInnovator], "card-text"])
                        }, [
                          _createElementVNode("strong", null, "Innovator: " + _toDisplayString(_ctx.body.firstname) + " " + _toDisplayString(_ctx.body.lastname), 1)
                        ], 2))
                      : _createCommentVNode("", true)
                  ])
                ], 2),
                (_ctx.body.programArea && _ctx.body.location)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["footer text-primary py-4 px-3 d-flex flex-column h-100", [_ctx.card.customFooter]])
                      }, [
                        _createElementVNode("small", {
                          class: _normalizeClass(["d-flex justify-content-end w-100", [_ctx.card.customProgramArea]])
                        }, [
                          _createElementVNode("i", null, _toDisplayString(_ctx.body.programArea), 1)
                        ], 2),
                        _createElementVNode("small", {
                          class: _normalizeClass(["h-100 d-flex align-items-end justify-content-end w-100", [_ctx.card.customLocation]])
                        }, [
                          _createElementVNode("strong", null, "Location: " + _toDisplayString(_ctx.body.location), 1)
                        ], 2)
                      ], 2)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", {
            class: _normalizeClass(["card shadow-sm border-0 rounded-2", [_ctx.card.customCard]])
          }, [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.body.image)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("img", {
                      src: require(`@/assets/img/banner/${_ctx.body.image}`),
                      class: _normalizeClass(["img-fluid rounded-2", [_ctx.card.customImage]]),
                      alt: "welcome-img"
                    }, null, 10, _hoisted_11)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass([!_ctx.body.image ? 'col-12' : 'col-md-9', "d-flex justify-content-start"])
              }, [
                _createElementVNode("div", _hoisted_12, [
                  (_ctx.body.title)
                    ? (_openBlock(), _createElementBlock("h5", {
                        key: 0,
                        class: _normalizeClass(["mb-3", [_ctx.card.customTitle]])
                      }, _toDisplayString(_ctx.body.title), 3))
                    : _createCommentVNode("", true),
                  (_ctx.body.programArea !== '' && _ctx.body.location === '')
                    ? (_openBlock(), _createElementBlock("h6", {
                        key: 1,
                        class: _normalizeClass(["mb-2 fw-normal", [_ctx.card.customProgramArea]])
                      }, [
                        _createElementVNode("i", null, _toDisplayString(_ctx.body.programArea), 1)
                      ], 2))
                    : _createCommentVNode("", true),
                  (_ctx.body.body)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 2,
                        class: _normalizeClass(["mb-2", [_ctx.card.customBody]])
                      }, _toDisplayString(_ctx.body.body.split(" ").splice(0, 25).join(" ")) + " ", 3))
                    : _createCommentVNode("", true),
                  (_ctx.body.innovator)
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 3,
                        class: _normalizeClass([[_ctx.card.customInnovator], "card-text"])
                      }, [
                        _createElementVNode("strong", null, "Innovator: " + _toDisplayString(_ctx.body.firstname) + " " + _toDisplayString(_ctx.body.lastname), 1)
                      ], 2))
                    : _createCommentVNode("", true)
                ])
              ], 2),
              (_ctx.body.programArea && _ctx.body.location)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["footer text-primary py-4 px-3 d-flex flex-column h-100", [_ctx.card.customFooter]])
                    }, [
                      _createElementVNode("small", {
                        class: _normalizeClass(["d-flex justify-content-end w-100", [_ctx.card.customProgramArea]])
                      }, [
                        _createElementVNode("i", null, _toDisplayString(_ctx.body.programArea), 1)
                      ], 2),
                      _createElementVNode("small", {
                        class: _normalizeClass(["h-100 d-flex align-items-end justify-content-end w-100", [_ctx.card.customLocation]])
                      }, [
                        _createElementVNode("strong", null, "Location: " + _toDisplayString(_ctx.body.location), 1)
                      ], 2)
                    ], 2)
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ]))
  ]))
}