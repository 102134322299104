const infoData = [
  "Product or Technological innovations",
  "Process or service innovations",
  "Social innovations",
];

const diseasesData = [
  { name: "Viral hepatitis", id: "viral hepatitis" },
  { name: "Tuberculosis", id: "tuberculosis" },
  { name: "Malaria", id: "malaria" },
  { name: "HIV", id: "hiv" },
];

const outbreakData = [
  { name: "Other haemorrhagic fevers", id: "Other haemorrhagic fevers" },
  { name: "Chikungunya virus", id: "Chikungunya virus" },
  { name: "Antimicrobial resistance", id: "Antimicrobial resistance" },
  { name: "Dengue virus", id: "Dengue virus" },
  { name: "Pathogenic vibrio (cholera)", id: "Pathogenic vibrio (cholera)" },
  { name: "Zika virus", id: "Zika virus" },
  { name: "Ebola virus", id: "Ebola virus" },
  { name: "Covid-19", id: "Covid-19" },
];

const non_communicableData = [
  { name: "Chronic kidney disease", id: "Chronic kidney disease" },
  { name: "Diabetes", id: "Diabetes" },
  { name: "Chronic respiratory disease", id: "Chronic respiratory disease" },
  { name: "Cancer", id: "Cancer" },
  { name: "Cardiovascular disease", id: "Cardiovascular disease" },
];

const tropical_diseases = [
  { name: "Trichuriasis", id: "Trichuriasis" },
  { name: "Ascariasis", id: "Ascariasis" },
  { name: "Hookworm", id: "Hookworm" },
  { name: "Schistosomiasis", id: "Schistosomiasis" },
  {
    name: "Human African Trypanosomiasis",
    id: "Human African Trypanosomiasis",
  },
  { name: "Active trachoma", id: "Active trachoma" },
  { name: "Onchocerciasis", id: "Onchocerciasis" },
  { name: "Lymphatic filariasis", id: "Lymphatic filariasis" },
  { name: "Leishmaniasis", id: "Leishmaniasis" },
];

const mental_health = [
  { name: "Severe depression", id: "Severe depression" },
  { name: "Bipolar mood disorder", id: "Bipolar mood disorder" },
  { name: "Schizophrenia", id: "Schizophrenia" },
];

const reproductiveData = [
  {
    name: "Reproductive, maternal, newborn and child health",
    id: "Reproductive, maternal, newborn and child health",
  },
];

const strengtheningData = [
  { name: "Health Promotion", id: "Health Promotion" },
  { name: "Leadership and governance", id: "Leadership and governance" },
  { name: "Health financing", id: "Health financing" },
  {
    name: "Essential medicines, vaccines and technologies",
    id: "Essential medicines, vaccines and technologies",
  },
  { name: "Health information", id: "Health information" },
  { name: "Health workforce", id: "Health workforce" },
  { name: "Health service provision", id: "Health service provision" },
];

const othersData = [{ name: "Others", id: "others" }];

export {
  infoData,
  diseasesData,
  outbreakData,
  non_communicableData,
  reproductiveData,
  strengtheningData,
  othersData,
  tropical_diseases,
  mental_health,
};
