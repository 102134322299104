
import { defineComponent, PropType } from "vue";
import { selectData } from "./propConfig";

export default defineComponent({
  name: "BaseSelect",
  emits: ["^update:"],
  props: {
    selectType: {
      type: Object as PropType<selectData>,
      required: true,
    },
  },
  setup() {
    /**
     * Typescript Definition for checkbox
     * @param {e} event
     * @returns {boolean}
     */
    const handleSelect = (e: Event) => (e.target as HTMLInputElement).value;
    return { handleSelect };
  },
});
