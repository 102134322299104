
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SideFilter",
  props: {
    filterAreas: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => ["All", "Bleeding", "Maternal"],
    },
    check: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      activeTab: "All",
      programAreaCheckBox: [
        { id: "nutrition", text: "Nutrition" },
        { id: "maternal&childHealth", text: "Maternal & Child Health (5)" },
        { id: "reproductiveHealth", text: "Reproductive health (3)" },
      ],
      districtsCheckBox: [
        { id: "southernDistrict", text: "Southern District" },
        { id: "kwenengDistrict", text: "Kweneng District" },
        { id: "kgatlengDistrict", text: "Kgatleng District" },
      ],
      healthSystemAreaCheckBox: [
        { id: "serviceDelivery", text: "Service delivery" },
        { id: "healthWorkforce", text: "Health workforce" },
      ],
    };
  },
  methods: {
    passTag(tag: string) {
      this.activeTab = tag;
      this.$emit("filterTag", tag);
    },
  },
});
