import { createApp } from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";
import Checkbox from "primevue/checkbox";
import Skeleton from "primevue/skeleton";
import Textarea from "primevue/textarea";
import Paginator from "primevue/paginator";
import TabMenu from "primevue/tabmenu";
import Chart from "primevue/chart";
import Tag from "primevue/tag";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import "./registerServiceWorker";

import App from "./App.vue";

import router from "./router";
import store from "./store";
import FormInputComponents from "./components/form-inputs";

import "./plugins";

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(ToastService)
  .use(PrimeVue, { ripple: true });

app.config.globalProperties.$store = store;
app.provide("$store", store);
//REGISTER FROM COMPONENT GLOBALLY
FormInputComponents.register(app);
app.component("Dialog", Dialog);
app.directive("tooltip", Tooltip);
app.component("InputText", InputText);
app.component("Dropdown", Dropdown);
app.component("Button", Button);
app.component("RadioButton", RadioButton);
app.component("Checkbox", Checkbox);
app.component("Skeleton", Skeleton);
app.component("Textarea", Textarea);
app.component("Paginator", Paginator);
app.component("TabMenu", TabMenu);
app.component("Chart", Chart);
app.component("Tag", Tag);
app.component("Toast", Toast);

app.mount("#app");
