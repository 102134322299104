import { EncryptData } from "./EncryptData";
import Cookies from "js-cookie";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/**
 * Function to set or clear local storage values
 * @param {Object} {id, token, type}
 * @returns {function} set or clear local storage values
 */
interface localStorage {
  id: string;
  username: string;
  email: string;
  account_type: string;
}
const setDetails = (data: localStorage, token: string) => {
  Cookies.set("bhims_token", token, { expires: 0.25 });
  Cookies.set("bhims_details", JSON.stringify({ ...data }), { expires: 0.25 });
};

const clearDetails = () => {
  localStorage.removeItem("human");
  localStorage.removeItem("bhims_token");
  removeDataByCookie("bhims_token");
  removeDataByCookie("bhims_details");
};

const getDetails = (): localStorage => {
  const data = localStorage.getItem("human");
  return data ? JSON.parse(data) : null;
};

const getEncryptedToken = () => {
  const token = localStorage.getItem("token");
  return token ? token : "";
};

const getToken = (): string => {
  const data = localStorage.getItem("token");
  return data ? EncryptData.decryptToken(data) : "";
};

const getDataByCookie = (value: string): string => {
  const data = Cookies.get(value);
  return data ? data : "";
};

const removeDataByCookie = (value: string) => {
  Cookies.remove(value);
};

export {
  setDetails,
  clearDetails,
  getDetails,
  getToken,
  getEncryptedToken,
  getDataByCookie,
  removeDataByCookie,
};
