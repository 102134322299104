import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  getPartners: (state: state) => state.partners,
  getPartner: (state: state) => state.partner,
  getPartnerFileList: (state: state) => state.partnerFileList,
};

export default getters;
