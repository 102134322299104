
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import InformaticsProfile from "./InformaticsProfile.vue";
import InnovatorProfile from "./InnovatorProfile.vue";
import PartnerProfile from "./PartnerProfile.vue";
import ProgramCoordProfile from "./ProgramCoordProfile.vue";

export default defineComponent({
  name: "UserProfile",
  components: {
    InformaticsProfile,
    PartnerProfile,
    InnovatorProfile,
    ProgramCoordProfile,
  },
  data() {
    return {
      loading: false,
      partnershipsModal: false,
      profileData: {},
    };
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", ["getUserProfileParams"]),
  },
  methods: {
    getUserType(value: { [key: string]: string }) {
      return value?.route?.split("/")[0];
    },
    getUserId(value: { [key: string]: string }) {
      return value?.id;
    },
  },
});
