
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "PartnerProfile",
  components: {},
  data() {
    return {
      form: {
        businessSector: "",
        address: "",
        organizationRcNo: "",
        password: "",
        confirmPassword: "",
        file: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getUserId"]),
    ...mapGetters("PARTNER_STORE", ["getPartner"]),
  },
  methods: {
    ...mapActions("PARTNER_STORE", ["GET_PARTNER", "UPDATE_PARTNER"]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async appendImage(e: any) {
      console.log(e.target.files[0]);
      if (e.target.files[0].size > 1024000) {
        Toast.error("File size should not be more than 1mb");
        return;
      }
      this.form.file = e.target.files[0];
      await this.uploadImage();
    },
    async updateProfile() {
      console.log("hello");
    },
    async getUserProfile() {
      try {
        this.loading = true;
        await this.GET_PARTNER(this.getUserId);
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
    async uploadImage() {
      const data = new FormData();
      data.append("profile_picture", this.form.file);
      try {
        await this.UPDATE_PARTNER({ id: this.getUserId, formData: data });
        await this.getUserProfile();
        Toast.info("Operation Successful");
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    await this.getUserProfile();
    this.form.businessSector = this.getPartner?.details?.business_type;
  },
});
