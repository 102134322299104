import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row py-3" }
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "errorMsg" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "errorMsg" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "errorMsg" }
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "errorMsg" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "errorMsg" }
const _hoisted_12 = { class: "w-100 d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", {
    action: "",
    onSubmit: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseInput, {
          inputType: _ctx.firstnameProp,
          firstName: _ctx.firstname,
          "onUpdate:firstName": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstname) = $event))
        }, null, 8, ["inputType", "firstName"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.firstnameError), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BaseInput, {
          inputType: _ctx.lastnameProp,
          lastName: _ctx.lastname,
          "onUpdate:lastName": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastname) = $event))
        }, null, 8, ["inputType", "lastName"]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.lastnameError), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseInput, {
          inputType: _ctx.usernameProp,
          username: _ctx.username,
          "onUpdate:username": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.username) = $event))
        }, null, 8, ["inputType", "username"]),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.usernameError), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BaseInput, {
          inputType: _ctx.emailProp,
          email: _ctx.email,
          "onUpdate:email": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.email) = $event))
        }, null, 8, ["inputType", "email"]),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.emailError), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_BaseInput, {
          inputType: _ctx.employeeNumberProp,
          employee_number: _ctx.employee_number,
          "onUpdate:employee_number": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.employee_number) = $event))
        }, null, 8, ["inputType", "employee_number"]),
        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.employeeNumberError), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_Button, {
        label: "Invite",
        disabled: _ctx.loading,
        class: _normalizeClass([{ submitting: _ctx.loading }, "px-5 p-button-primary"]),
        icon: "pi pi-folder-open",
        iconPos: "right",
        onClick: _withModifiers(_ctx.onSubmit, ["prevent"]),
        "aria-label": "Invite Program Coordinator"
      }, null, 8, ["disabled", "class", "onClick"])
    ])
  ], 32))
}