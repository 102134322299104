
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Toast from "@/utils/Toast";
import { selectType } from "@/types/Select";

export default defineComponent({
  name: "createProgramArea",
  data() {
    return {
      loading: false,
      form: {
        name: "",
        tag: "",
      },
      programAreas: [] as selectType[],
    };
  },
  methods: {
    ...mapActions("INFORMATICS_STORE", ["CREATE_SUB_PROGRAM_AREA", "GET_TAGS"]),
    async createSubTag() {
      try {
        this.loading = true;
        const { name, tag } = this.form;
        if (name === "" || tag === "") {
          Toast.error("Please fill all the fields");
          return;
        }
        await this.CREATE_SUB_PROGRAM_AREA(this.form);
        this.$emit("close");
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const response = await this.GET_TAGS();
    const result = [] as selectType[];
    response.map((el: selectType) => {
      result.push({
        id: el.id,
        name: el.name,
      });
    });
    this.programAreas = result;
  },
});
