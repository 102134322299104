
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Form from "./CreateTags.vue";
import SubTagsForm from "./CreateSubTags.vue";
import EditForm from "./EditForm.vue";

export default defineComponent({
  name: "programAreas",
  components: {
    TabView,
    TabPanel,
    DataTable,
    Column,
    Form,
    EditForm,
    SubTagsForm,
  },
  data() {
    return {
      loading: false,
      loading1: false,
      title: "program areas",
      editDialog: false,
      createDialog: false,
      editDetail: {},
      confirmDialog: false,
      confirmDialog2: false,
      createSubTagDialog: false,
      deleteId: "",
      deleteId2: "",
      subProgramArea: [],
    };
  },
  watch: {
    statusFilter: {
      handler() {
        this.getData();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("INFORMATICS_STORE", ["getTags"]),
  },
  methods: {
    ...mapActions("INFORMATICS_STORE", [
      "GET_TAGS",
      "SUB_GET_TAGS",
      "DELETE_PROGRAM_AREA",
      "DELETE_SUB_PROGRAM_AREA",
    ]),
    async getData() {
      this.loading = true;
      await this.GET_TAGS();
      this.loading = false;
    },
    async getData2() {
      try {
        this.loading1 = true;
        const response = await this.SUB_GET_TAGS();
        this.subProgramArea = response;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading1 = false;
      }
    },
    async editTag(data: { [key: string]: string }) {
      this.editDialog = true;
      this.editDetail = data;
    },
    async confirmDelete(id: string) {
      this.deleteId = id;
      this.confirmDialog = true;
    },
    async confirmDelete2(id: string) {
      this.deleteId2 = id;
      this.confirmDialog2 = true;
    },
    async deleteTag() {
      if (this.deleteId === "") return;
      try {
        this.loading = true;
        await this.DELETE_PROGRAM_AREA(this.deleteId);
        this.getData();
        this.confirmDialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteSubTag() {
      if (this.deleteId2 === "") return;
      try {
        this.loading = true;
        await this.DELETE_SUB_PROGRAM_AREA(this.deleteId);
        this.getData2();
        this.confirmDialog2 = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async closeEditDialog() {
      this.editDialog = false;
      this.getData();
    },
    async closeCreateDialog() {
      this.createDialog = false;
      this.getData();
    },
    async closeCreateSubTagDialog() {
      this.createSubTagDialog = false;
      this.getData2();
    },
  },
  async mounted() {
    await this.getData();
    await this.getData2();
  },
});
