const Route = [
  // {
  //   name: "Home",
  //   path: "/informatics/home",
  //   icon: "icon bi-columns-gap",
  // },
  {
    name: "Overview",
    path: "/informatics/overview",
    icon: "icon bi-columns",
  },
  {
    name: "User Management",
    path: "/informatics/user-management",
    icon: "icon bi-person-circle",
  },
  // {
  //   name: "Informatics",
  //   path: "/informatics/view_officials",
  //   icon: "icon bi-person-workspace",
  // },
  // {
  //   name: "Health Directorate",
  //   path: "/informatics/directorates",
  //   icon: "icon bi-person-workspace",
  // },
  // {
  //   name: "Technical Directors",
  //   path: "/informatics/technical_directors",
  //   icon: "icon bi-back",
  // },
  // {
  //   name: "Program Coordinators",
  //   path: "/informatics/program_cord",
  //   icon: "icon bi-person-workspace",
  // },
  // {
  //   name: "Partners",
  //   path: "/informatics/partners",
  //   icon: "icon bi-back",
  // },
  // {
  //   name: "Innovators",
  //   path: "/informatics/innovators",
  //   icon: "icon bi-back",
  // },
  {
    name: "Health Need",
    path: "/informatics/health_needs",
    icon: "icon bi-files",
  },
  {
    name: "Innovations",
    path: "/informatics/innovations",
    icon: "icon bi-lightbulb",
  },
  {
    name: "Program Areas",
    path: "/informatics/program_areas",
    icon: "icon bi-file-text",
  },
];

export default Route;
