
import { defineComponent, ref, onMounted } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import Toast from "@/utils/Toast";
import {
  categoryProp,
  emailProp,
  usernameProp,
  passwordProp,
  rememberProp,
  directorateSelectProp,
} from "@/props-config";
import store from "@/store";
import { switchDashboard } from "@/utils/Switcher";

export default defineComponent({
  name: "MultiLoginFormModal",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      category: yup.string().required("Category is required"),
      directorate: yup.string().when("category", {
        is: (category: string) => category === "Health Directorate",
        then: yup.string().required("Directorate is required"),
      }),
      // email: yup.string().when("category", {
      //   is: "Health Innovator",
      //   then: yup.string().required("Email is required"),
      // }),
      // username: yup.string().when("category", {
      //   is: (category: string) => category !== "Health Innovator",
      //   then: yup.string().required("Username is required"),
      // }),
      username: yup.string().required("Username is required"),
      password: yup.string().required().min(8),
      remember: yup.boolean(),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: category, errorMessage: categoryError } =
      useField("category");
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: username, errorMessage: usernameError } =
      useField("username");
    const { value: directorate, errorMessage: directorateError } =
      useField("directorate");
    const { value: password, errorMessage: passwordError } =
      useField("password");
    const { value: remember } = useField("remember");

    const onSubmit = handleSubmit(async (values) => {
      let data = values;
      loading.value = true;
      const { username, password, remember, category, directorate } = data;

      // Function to getUser by token in localStorage
      remember === true
        ? localStorage.setItem("remember", "YES")
        : localStorage.removeItem("remember");

      const formData = {
        username,
        password,
      };

      const formData2 = {
        username,
        password,
        directorate,
      };

      const formData3 = {
        username: email,
        password,
      };

      try {
        let resp;
        switch (category) {
          case "Health Informatics Team":
            resp = await store.dispatch(
              "AUTH_STORE/LOGIN_INFORMATICS",
              formData
            );
            break;
          case "Health Directorate":
            resp = await store.dispatch(
              "AUTH_STORE/LOGIN_TECHNICAL_DIRECTOR",
              formData2
            );
            break;
          case "Health Innovator":
            resp = await store.dispatch("AUTH_STORE/LOGIN_INNOVATOR", formData);
            break;
          case "Health Partner":
            resp = await store.dispatch("AUTH_STORE/LOGIN_PARTNER", formData);
            break;
          case "Program Coordinator":
            resp = await store.dispatch(
              "AUTH_STORE/LOGIN_PROG_CORD",
              formData3
            );
            break;
          default:
            break;
        }
        if (resp) {
          setTimeout(() => {
            localStorage.removeItem("category");
            Toast.info("Redirecting...");
          }, 1500);
          // Redirect user to a specific dashboard based on the category
          setTimeout(() => {
            localStorage.setItem("category", category || "");
            switchDashboard(category);
          }, 3000);
        }
        loading.value = false;
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    });
    const getHD = async () => {
      interface type {
        [key: string]: string;
      }
      await store.dispatch("HEALTH_DIRECTORATE_STORE/GET_HEALTH_DIRECTORATES");
      const directorate = store.getters["HEALTH_DIRECTORATE_STORE/getHDs"];
      if (directorate.length > 0) {
        const data = [] as Array<type>;
        directorateSelectProp.options = [];
        directorate.forEach((el: type) =>
          data.push({ key: el.id, name: el.name })
        );
        directorateSelectProp.options = data;
      }
    };
    onMounted(async () => {
      getHD();
    });

    return {
      onSubmit,
      loading,

      category,
      email,
      username,
      password,
      remember,

      categoryError,
      emailError,
      usernameError,
      passwordError,

      categoryProp,
      emailProp,
      usernameProp,
      passwordProp,
      rememberProp,
      directorate,
      directorateError,
      directorateSelectProp,

      buttonStyle:
        "btn-primary btn-lg py-2 w-100 rounded-0 border-primary border-3",
    };
  },
});
