import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7076841c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "application_progress" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-6" }
const _hoisted_4 = { class: "errorMsg" }
const _hoisted_5 = { class: "errorMsg" }
const _hoisted_6 = { class: "errorMsg" }
const _hoisted_7 = { class: "errorMsg" }
const _hoisted_8 = { class: "errorMsg" }
const _hoisted_9 = { class: "col-md-6" }
const _hoisted_10 = { class: "errorMsg" }
const _hoisted_11 = { class: "errorMsg" }
const _hoisted_12 = { class: "errorMsg" }
const _hoisted_13 = { class: "errorMsg" }
const _hoisted_14 = { class: "errorMsg" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "errorMsg" }
const _hoisted_17 = { class: "errorMsg" }
const _hoisted_18 = { class: "mt-4 mb-5" }
const _hoisted_19 = { class: "mb-3 mt-4" }
const _hoisted_20 = { class: "mb-5" }
const _hoisted_21 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseSelect2 = _resolveComponent("BaseSelect2")!
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!
  const _component_BaseFileLong = _resolveComponent("BaseFileLong")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseCheckBox = _resolveComponent("BaseCheckBox")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      action: "",
      onSubmit: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseInput, {
            inputType: _ctx.organization_nameProp,
            username: _ctx.username,
            "onUpdate:username": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseSelect2, {
            selectType: _ctx.countryProp,
            username: _ctx.username,
            "onUpdate:username": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["selectType", "username"]),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseInput, {
            inputType: _ctx.RCProp,
            username: _ctx.username,
            "onUpdate:username": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseSelect2, {
            selectType: _ctx.programAreaProp,
            username: _ctx.username,
            "onUpdate:username": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["selectType", "username"]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseSelect2, {
            selectType: _ctx.innovationProp,
            username: _ctx.username,
            "onUpdate:username": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["selectType", "username"]),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.usernameError), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_BaseInput, {
            inputType: _ctx.addressProp,
            username: _ctx.username,
            "onUpdate:username": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseInput, {
            inputType: _ctx.emailProp,
            username: _ctx.username,
            "onUpdate:username": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseInput, {
            inputType: _ctx.phoneNumberProp,
            username: _ctx.username,
            "onUpdate:username": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseInput, {
            inputType: _ctx.needsProp,
            username: _ctx.username,
            "onUpdate:username": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseInput, {
            inputType: _ctx.GoalsProp,
            username: _ctx.username,
            "onUpdate:username": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.username) = $event))
          }, null, 8, ["inputType", "username"]),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.usernameError), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_BaseTextArea, { inputType: _ctx.innovationOverviewProp }, null, 8, ["inputType"]),
          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.usernameError), 1),
          _createVNode(_component_BaseTextArea, { inputType: _ctx.experienceProp }, null, 8, ["inputType"]),
          _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.usernameError), 1)
        ])
      ]),
      _createElementVNode("section", _hoisted_18, [
        _createVNode(_component_BaseFileLong),
        _createVNode(_component_BaseFileLong)
      ]),
      _createElementVNode("section", _hoisted_19, [
        _createElementVNode("small", null, [
          _createElementVNode("strong", null, [
            _createTextVNode("Please read "),
            _createVNode(_component_router_link, { to: "#" }, {
              default: _withCtx(() => [
                _createTextVNode("terms and conditions")
              ]),
              _: 1
            }),
            _createTextVNode(" and accept before submitting a proposal")
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_20, [
        _createVNode(_component_BaseCheckBox, { inputType: _ctx.termsProp }, null, 8, ["inputType"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_router_link, { to: "complete" }, {
          default: _withCtx(() => [
            _createVNode(_component_BaseButton, {
              disabled: _ctx.loading,
              class: _normalizeClass({ submitting: _ctx.loading }),
              customStyle: _ctx.buttonStyle
            }, {
              default: _withCtx(() => [
                _createTextVNode("Save and Continue")
              ]),
              _: 1
            }, 8, ["disabled", "class", "customStyle"])
          ]),
          _: 1
        }),
        _createVNode(_component_BaseButton, {
          disabled: _ctx.loading,
          class: _normalizeClass({ submitting: _ctx.loading }),
          customStyle: _ctx.buttonStyle1
        }, {
          default: _withCtx(() => [
            _createTextVNode("Submit")
          ]),
          _: 1
        }, 8, ["disabled", "class", "customStyle"])
      ])
    ], 32)
  ]))
}