
import { defineComponent } from "vue";
import { mapMutations, mapActions } from "vuex";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { needDraftsData, filterResult } from "./Types";
import ApproveNeeds from "../manage-needs/ApproveNeeds.vue";

export default defineComponent({
  name: "ViewNeedDraft",
  components: {
    DataTable,
    Column,
    ApproveNeeds,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      approveNeedDraftDialog: false,
      dataDialog: false,
      title: "Need Draft",
      programAreaProp,
      datas: [] as Array<string>,
      data: {} as needDraftsData,
      needData: {} as needDraftsData,
    };
  },
  methods: {
    ...mapMutations("INNOVATION_STORE", ["SET_HEALTH_NEED"]),
    ...mapActions("INNOVATION_STORE", ["GET_HEALTH_NEED"]),
    async getData() {
      this.loading = true;
      // try {
      //   await this.GET_HEALTH_NEEDS();
      //   this.datas = await this.$store.getters[
      //     "INNOVATION_STORE/getHealthNeeds"
      //   ];
      // } catch (error) {
      //   console.log(error);
      // }
      this.loading = false;
    },
    async approveNeed(id: string) {
      this.loading = true;
      try {
        this.SET_HEALTH_NEED(id);
        this.needData = await this.$store.getters[
          "INNOVATION_STORE/getHealthNeed"
        ];
        this.approveNeedDraftDialog = true;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async viewData(id: string) {
      this.loading = true;
      this.SET_HEALTH_NEED(id);
      this.data = await this.$store.getters["INNOVATION_STORE/getHealthNeed"];
      this.dataDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        title: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        writer: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    this.getData();
  },
  created() {
    this.initFilters();
  },
});
