
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import { passwordProp, confirm_passwordProp } from "@/props-config";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "ForgotPassword",
  components: {},
  data() {
    return {
      loading: false,
      passwordProp,
      confirm_passwordProp,
      password: "",
      confirm_password: "",
      token: "",
    };
  },
  methods: {
    ...mapActions("AUTH_STORE", ["CHANGE_PASSWORD", "VALIDATE_PASSWORD_TOKEN"]),
    /**
     * @function: handleChangePassword
     * @param string email
     * @return
     */
    async handleChangePassword() {
      // check if password and confirm password are same
      if (this.password !== this.confirm_password) {
        Toast.error("Password and Confirm Password are not same");
        return;
      }
      // check if password is less than 8 characters
      if (this.password.length < 8) {
        Toast.error("Password must be at least 8 characters");
        return;
      }
      try {
        this.loading = true;
        await this.CHANGE_PASSWORD({
          token: this.token,
          password: this.password,
        });
        Toast.info(
          `password successfully updated!, Please login with your new password`
        );
        setTimeout(() => {
          this.$router.push("/auth/login");
        }, 2000);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Toast.warning(error || "an error occurred");
      } finally {
        this.loading = false;
      }
    },
    async handleValidatePasswordToken() {
      // check if token is valid
      if (this.token === "") {
        Toast.error("Token not provided, check your mail");
        return;
      }
      try {
        this.loading = true;
        await this.VALIDATE_PASSWORD_TOKEN({ token: this.token });
        Toast.success(`Token is valid`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Toast.warning(error || "an error occurred");
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token as string;
    this.handleValidatePasswordToken();
  },
});
