import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  isAuthenticated: (state: state) => !!state.token && state.token.length > 0,
  getToken: (state: state) => state.token,
  getUserId: (state: state) => state.details?.id,
  getNamingRight: (state: state) =>
    state.details?.username || state.details?.email,
  getLastViewedNeed: (state: state) => state.details?.last,
  getDetails: (state: state) => state.details,
  getAccountType: (state: state) => state.details?.account_type,
};

export default getters;
