import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { router as INNOVATION_ROUTE } from "@/modules/innovation";
import { router as AUTH_ROUTE } from "@/modules/auth";
import { router as INNOVATOR_ROUTE } from "@/modules/innovator";
import { router as INFORMATICS_ROUTE } from "@/modules/informatics";
import { router as HEALTH_DIRECTORATE_DASHBOARD_ROUTE } from "@/modules/health-directorate";
import { router as PARTNER_ROUTE } from "@/modules/partner";
import { router as ADMIN_ROUTE } from "@/modules/admin";
import { router as PROGRAM_CORD_ROUTE } from "@/modules/program-cord";
import { router as M_E_ROUTE } from "@/modules/m&e";
import Toast from "@/utils/Toast";
import store from "@/store";
import { switchDashboardByAccountType } from "@/utils/Switcher";

const routes: Array<RouteRecordRaw> = [
  ...INNOVATION_ROUTE,
  ...AUTH_ROUTE,
  ...INNOVATOR_ROUTE,
  ...INFORMATICS_ROUTE,
  ...HEALTH_DIRECTORATE_DASHBOARD_ROUTE,
  ...PARTNER_ROUTE,
  ...ADMIN_ROUTE,
  ...PROGRAM_CORD_ROUTE,
  ...M_E_ROUTE,
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("@/components/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to, from, next) => {
  // ?? dynamically update page title by route or assign BHMIS as default
  document.title = to?.meta?.title ? `BHMIS | ${to?.meta?.title}` : "BHMIS";
  // ?? dynamically update page description by route
  if (to?.meta?.description) {
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute("content", to?.meta?.description as string);
  }
  // ?? dynamically update page keyword by route
  if (to?.meta?.keyword) {
    document
      .querySelector('meta[name="keyword"]')
      ?.setAttribute("content", to?.meta?.keyword as string);
  }
  // =========================================================================
  const isAuthenticated = store.getters["AUTH_STORE/isAuthenticated"];
  const requireAuth = to.matched.some((record) => record.meta.requiresAuth);
  const { authorize } = to.meta;
  const authorized = authorize as string[];
  const accountType = store.getters["AUTH_STORE/getAccountType"];
  const guest = to.matched.some((record) => record.meta.guest);

  if (isAuthenticated && accountType && to.path === "/auth/login") {
    switchDashboardByAccountType(accountType);
  }

  if (authorized?.length && !authorized.includes(accountType) && !guest) {
    Toast.error("You are not authorized to access this page");
    // return router.go(-1);
    return router.push("/auth/login");
  }

  if (!isAuthenticated && requireAuth) {
    if (guest) {
      next();
    } else {
      Toast.warning("Access Denied!, Please Login");
      next({
        path: "/auth/login",
      });
    }
  } else {
    // Happens only when you are logged in and route is authenticated
    next(); // make sure to always call next()!
  }
});

export default router;
