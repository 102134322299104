import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-763877fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-check" }
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "form-check-input",
      type: "checkbox",
      id: _ctx.inputType.id,
      name: _ctx.inputType.id,
      checked: false,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checkbox', _ctx.handleCheck($event))))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.inputType.id
    }, [
      _createTextVNode(_toDisplayString(_ctx.inputType.text) + " ", 1),
      (_ctx.inputType.link)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            class: "label_link",
            to: _ctx.inputType.link
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.inputType.linkText), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_3)
  ]))
}