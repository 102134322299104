import index from "../views/index.vue";
import MultiLogin from "../views/MultiLogin.vue";
import Validate from "../components/invites/Validate.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ChangePassword from "../views/ChangePassword.vue";

const router = [
  {
    path: "/auth",
    component: index,
    meta: {
      description:
        "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
      keywords: "innovation, innovation in healthcare",
      transition: "slide-left",
    },
    children: [
      {
        path: "",
        alias: "login",
        name: "MultiLogin",
        component: MultiLogin,
        meta: {
          title: "Login",
          description:
            "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
          keywords: "innovation, innovation in healthcare",
          transition: "slide-left",
        },
      },
      {
        path: "validate_token",
        name: "Validate",
        component: Validate,
        meta: {
          title: "Validate Account",
          description:
            "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
          keywords: "innovation, innovation in healthcare",
          transition: "slide-left",
        },
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
          title: "Forgot Password",
          description:
            "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
          keywords: "innovation, innovation in healthcare",
          transition: "slide-left",
        },
      },
      {
        path: "change-password",
        name: "change-password",
        component: ChangePassword,
        meta: {
          title: "Change Password",
          description:
            "BHIMS is a platform for the sharing of ideas and innovations in the field of education and research.",
          keywords: "innovation, innovation in healthcare",
          transition: "slide-left",
        },
      },
    ],
  },
];

export default router;
