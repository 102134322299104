
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import InnovationCardList from "@/components/Card/InnovationList.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import { cardListStyle } from "@/modules/innovation/views/priority-needs/Data";
import { needType } from "@/types";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";

type headerData = {
  [key: string]: string | number | boolean;
};

export default defineComponent({
  name: "ProgCoordInnovations",
  props: {
    user: {
      type: String,
      required: false,
      default: "Program_Coordinator",
    },
  },
  components: {
    Accordion,
    AccordionTab,
    ScrollArea,
    InnovationCardList,
  },
  data() {
    return {
      id: "",
      title: "Overview Data Analytics of Health Innovations",
      headerData: [
        {
          title: "Health Innovation",
          value: 0,
          bgColor: false,
        },
        {
          title: "Awaiting Approval",
          value: 0,
          bgColor: false,
        },
        {
          title: "Pending Evaluations",
          value: 0,
          bgColor: false,
        },
        {
          title: "Published Innovation",
          value: 0,
          bgColor: false,
        },
        {
          title: "Archived Innovation",
          value: 0,
          bgColor: false,
        },
      ] as headerData[],
      loading: false,
      cardListStyle,
      paginationLoader: false,
      innovationLoading: false,
      datas: [] as needType[],
      publishedData: [] as needType[],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
      tagTitle: "",
    };
  },
  computed: {
    ...mapGetters(["getAnalytics"]),
  },
  methods: {
    ...mapActions(["GET_PLATFORMS_ANALYTICS"]),
    ...mapActions("INNOVATOR_STORE", [
      "GET_INNOVATIONS",
      "GET_INNOVATIONS_BY_STATUS_PAGINATION",
      "GET_INNOVATION_BY_TAG",
    ]),
    /**
     * Function to filter the data by the tab selected
     * @param {string} programArea
     * Filtered if programArea is not empty
     * Message if Filtered is empty
     */
    async programArea(value: { [key: string]: string }) {
      this.tagTitle = value.name;
      if (value.name === "All") {
        await this.getPublishedInnovations();
        return;
      }
      try {
        this.innovationLoading = true;
        const data = await this.GET_INNOVATION_BY_TAG({
          id: value.id,
          status: "APPROVED",
        });
        this.publishedData = data.data;
        this.publishedDataPagination.size = data?.size;
        this.publishedDataPagination.total_records = data?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.innovationLoading = false;
      }
    },
    // Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.innovationLoading = true;
        const response = await this.GET_INNOVATIONS_BY_STATUS_PAGINATION({
          status: "APPROVED&size=5",
          page: data.page + 1,
        });
        window.scrollTo(0, 0);
        this.publishedData = response.data;
        this.publishedDataPagination.size = response?.size;
        this.publishedDataPagination.total_records = response?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.innovationLoading = false;
      }
    },
    // GET HEALTH NEEDS PENDING EVALUATIONS
    async getPendingInnovations() {
      try {
        this.innovationLoading = true;
        const { data } = await Request(
          "GET",
          "innovations/?status=IN_EVALUATION"
        );
        this.datas = data.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        Toast.error(error);
      } finally {
        this.innovationLoading = false;
      }
    },
    // GET HEALTH NEEDS PUBLISHED EVALUATIONS
    async getPublishedInnovations() {
      try {
        this.innovationLoading = true;
        const { data } = await Request(
          "GET",
          "innovations/?status=APPROVED&size=5"
        );
        this.publishedData = data.data;
        this.publishedDataPagination.size = data?.size;
        this.publishedDataPagination.total_records = data?.total_records;
      } catch (error) {
        console.log(error);
      } finally {
        this.innovationLoading = false;
      }
    },
    // GET ANALYTICS DATA
    async getAnalyticsData() {
      try {
        this.loading = true;
        await this.GET_PLATFORMS_ANALYTICS();
        if (Object.keys(this.getAnalytics).length !== 0) {
          this.headerData = [
            {
              title: "Health Innovation",
              value: this.getAnalytics.total_innovations,
              bgColor: false,
            },
            {
              title: "Awaiting Approval",
              value: this.getAnalytics.innovations_awaiting_approval,
              bgColor: false,
            },
            {
              title: "Pending Evaluations",
              value: this.getAnalytics.innovations_in_evaluation,
              bgColor: false,
            },
            {
              title: "Published Innovation",
              value: this.getAnalytics.innovations_published,
              bgColor: false,
            },
            {
              title: "Archived Innovation",
              value: this.getAnalytics.innovations_rejected,
              bgColor: false,
            },
          ];
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.getAnalyticsData();
    await this.getPendingInnovations();
    await this.getPublishedInnovations();
  },
});
