
import { defineComponent } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PartnerList from "./MyPartnerLists.vue";
import Timeline from "./MyTimelines.vue";
import Message from "./MyMessages.vue";
import Files from "./MyFiles.vue";
// import { mapMutations, mapGetters, mapActions } from "vuex";

export default defineComponent({
  name: "MyInnovationDetails",
  components: {
    TabView,
    TabPanel,
    List: PartnerList,
    Timeline,
    Message,
    Files,
  },
  data() {
    return {
      loading: false,
    };
  },
  //   computed: {
  //     ...mapGetters("INNOVATOR_STORE", {
  //       value: "getInnovations",
  //       filteredValue: "getInnovation",
  //     }),
  //   },
  methods: {
    // ...mapMutations("INNOVATOR_STORE", ["myInnovation"]),
    // ...mapActions("INNOVATOR_STORE", ["GET_MY_INNOVATIONS"]),
  },
});
