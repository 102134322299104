import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1576810f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-danger mx-1"
}
const _hoisted_4 = ["id", "name", "accept", "multiple"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.value.name)
      ? (_openBlock(), _createElementBlock("h6", _hoisted_2, [
          _createTextVNode(" Upload " + _toDisplayString(_ctx.value.name) + " ", 1),
          (_ctx.value.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "form-control d-none",
      type: "file",
      id: _ctx.value.id,
      name: _ctx.value.id,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(`update:${_ctx.value.id}`, _ctx.handleInput($event)))),
      accept: _ctx.value.accept,
      multiple: _ctx.value.multiple
    }, null, 40, _hoisted_4),
    _createElementVNode("label", {
      for: _ctx.value.id,
      class: "box"
    }, [
      (_ctx.newLabel !== '')
        ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.newLabel), 1))
        : (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.value.label), 1))
    ], 8, _hoisted_5)
  ]))
}