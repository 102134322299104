import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  getHDs: (state: state) => state.healthDirectorates,
  getHD: (state: state) => state.healthDirectorate,
  getTDs: (state: state) => state.technicalDirectors,
  getTD: (state: state) => state.technicalDirector,
};

export default getters;
