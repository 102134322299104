
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    // totalPages: {
    //   type: Number,
    //   required: true,
    // },
    // total: {
    //   type: Number,
    //   required: true,
    // },
    // perPage: {
    //   type: Number,
    //   required: true,
    // },
    // currentPage: {
    //   type: Number,
    //   required: true,
    // },
  },
  computed: {
    ...mapGetters({
      data: "getPagination",
    }),
    startPage() {
      if (this.data.page === 1) {
        return 1;
      }
      if (this.data.page === this.data.total_pages) {
        return this.data.totalPages - this.maxVisibleButtons + 1;
      }
      // When on the first page
      return this.data.page - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + (this.maxVisibleButtons - 1),
        this.data.total_pages
      );
    },
    pages() {
      const pages = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        pages.push({
          name: i,
          isDisabled: i === this.data.page,
        });
      }
      return pages;
    },
    isInFirstPage() {
      return this.data.page === 1;
    },
    isInLastPage() {
      return this.data.page === this.data.total_pages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pageChanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pageChanged", this.data.size - 1);
    },
    onClickPage(page: number) {
      this.$emit("pageChanged", page);
    },
    onClickNextPage() {
      this.$emit("pageChanged", this.data.size + 1);
    },
    onClickLastPage() {
      this.$emit("pageChanged", this.data.total_pages);
    },
    isPageActive(page: number) {
      return this.data.size === page;
    },
  },
});
