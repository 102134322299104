
import { defineComponent, PropType } from "vue";
import { cardListDateData, bodyListDateData } from "./propConfig";

export default defineComponent({
  name: "Card-List-Date",
  props: {
    body: {
      type: Object as PropType<bodyListDateData>,
      required: true,
    },
    card: {
      type: Object as PropType<cardListDateData>,
      required: true,
    },
    route: {
      type: String,
      required: false,
      default: () => "/",
    },
  },
});
