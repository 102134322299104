import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "w-100 py-3" }
const _hoisted_2 = { class: "container-lg container-fluid pb-3" }
const _hoisted_3 = { class: "container-lg container-fluid" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "data_container"
}
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = {
  key: 1,
  class: "message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScrollArea = _resolveComponent("ScrollArea")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_CardList = _resolveComponent("CardList")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_ScrollArea, { onProgramId: _ctx.programArea }, null, 8, ["onProgramId"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      (_ctx.loading === true)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
              return _createVNode(_component_Skeleton, {
                width: "100%",
                height: "6rem",
                class: "mb-3",
                key: i
              })
            }), 64))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.getInnovations.length > 0)
              ? (_openBlock(), _createElementBlock("main", _hoisted_6, [
                  (_ctx.Message === '')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getInnovations, (item, i) => {
                          return (_openBlock(), _createBlock(_component_CardList, {
                            key: i,
                            body: item,
                            card: _ctx.cardListStyle,
                            route: `/partners/partner-on-proposal/${item.id}?id=${item.id}`
                          }, null, 8, ["body", "card", "route"]))
                        }), 128))
                      ]))
                    : (_ctx.Message !== '')
                      ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString(_ctx.Message), 1))
                      : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])),
      (_ctx.getInnovations.length > 0 && _ctx.Message === '')
        ? (_openBlock(), _createBlock(_component_Paginator, {
            key: 2,
            rows: _ctx.getPagination.size,
            totalRecords: _ctx.getPagination.total_records,
            onPage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPage($event))),
            class: "p-pagination-sm"
          }, null, 8, ["rows", "totalRecords"]))
        : _createCommentVNode("", true)
    ])
  ]))
}