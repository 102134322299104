const firstnameProp = {
  type: "text",
  name: "First Name",
  id: "firstname",
  autoComplete: "given-name",
};
const lastnameProp = {
  type: "text",
  name: "Last Name",
  id: "lastname",
  autoComplete: "family-name",
};
const middlenameProp = {
  type: "text",
  name: "Middle Name",
  id: "middlename",
  autoComplete: "other-name",
};

const employeeIdProp = {
  type: "text",
  name: "Employee Number",
  id: "employeeNumber",
  placeholder: "Employee Number (Ministry of Health)",
  autoComplete: "employee-number",
};

const emailProp = {
  type: "email",
  name: "Email",
  id: "email",
  placeholder: "Email Address",
  autoComplete: "email",
};

const addressProp = {
  type: "text",
  name: "Address",
  id: "address",
  autoComplete: "address",
};

const healthFacilityProp = {
  type: "text",
  name: "Health Facility",
  placeholder: "Health Facility/Department",
  id: "healthFacility",
};

const supervisorNameProp = {
  type: "text",
  name: "Supervisor Name",
  placeholder: "Health Facility/Department head email",
  id: "supervisorName",
};

const supervisorEmailProp = {
  type: "email",
  name: "Supervisor Email",
  placeholder: "Supervisor email",
  id: "supervisorEmail",
};

const titleProp = {
  name: "Title",
  placeholder: "Title of health need",
  id: "title",
};

const healthChallengeProp = {
  name: "Health Challenge",
  placeholder: "Description of health need/challenge",
  id: "healthChallenge",
};

const solutionDescriptionProp = {
  name: "Solution Description",
  placeholder: "Description of the required solution",
  id: "solutionDescription",
};

const targetUserProp = {
  type: "text",
  name: "Target User",
  placeholder: "Who are your target end users?",
  id: "targetUser",
};

const benefitsProp = {
  name: "Benefits",
  placeholder: "Benefits to end-users and other beneficiaries",
  id: "benefits",
};

const documentProp = {
  type: "file",
  name: "Document",
  id: "documents",
};

const termsProp = {
  text: "I have read and accepted the",
  id: "terms",
  // link: "#",
  // linkText: "Terms and Conditions",
};

const disclaimerProp = {
  text: "Disclaimer: I have no personal interest and financial implications connected to this health need/challenge",
  id: "disclaimer",
};

// ======== file prop ------------
const fileProp = {
  id: "file",
  name: "For additional information, upload relevant documents and pictures",
  label: "Select File: .pdf, .doc, .xls",
  accept: ".pdf , .doc , .xls, image/*",
  multiple: true,
};

// ===========
const targetUserOption = [
  { name: "Infants" },
  { name: "Children under five" },
  { name: "Adolescents" },
  { name: "Mothers" },
  { name: "Women of reproductive age" },
  { name: "Adults" },
  { name: "Men" },
  { name: "Elderly" },
  { name: "Youth" },
  { name: "School children" },
  { name: "Out-of-school children" },
  { name: "Health workers" },
  { name: "In-patients" },
  { name: "Outpatients" },
  { name: "Civil servants" },
  { name: "Artisans" },
  { id: "others", name: "Others please specify" },
];

export {
  firstnameProp,
  lastnameProp,
  middlenameProp,
  employeeIdProp,
  emailProp,
  addressProp,
  healthFacilityProp,
  supervisorNameProp,
  supervisorEmailProp,
  titleProp,
  healthChallengeProp,
  solutionDescriptionProp,
  targetUserProp,
  benefitsProp,
  documentProp,
  termsProp,
  disclaimerProp,
  fileProp,
  targetUserOption,
};
