
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Badge from "primevue/badge";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult } from "@/types/";
import SubBar from "../../components/SubBar.vue";

export default defineComponent({
  name: "MyInnovationFiles",
  components: {
    DataTable,
    Column,
    Badge,
    SubBar,
  },
  data() {
    return {
      innovationId: "",
      loading: false,
      filters: {} as filterResult,
      title: "Documents",
      dataSelected: [],
      filterType: [
        { id: "PDF" },
        { id: "Documents" },
        { id: "Spreadsheets" },
        { id: "Presentations" },
        { id: "Photos & Images" },
        { id: "Videos" },
      ],
      type: "",
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getRelevantDocument"]),
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_INNOVATION_FILES"]),
    async getData() {
      try {
        this.loading = true;
        await this.GET_INNOVATION_FILES(this.innovationId);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        innovation: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    getFileName(data: string) {
      const name = data.split("/");
      return name[name.length - 1];
    },
    getDateTime(arg: string) {
      const date = arg.split("T")[0];
      const time = arg.split("T")[1].split(".")[0];
      return `${date} - ${time}`;
    },
  },
  async mounted() {
    this.innovationId =
      (this.$route.query.id as string) ||
      (localStorage.getItem("innovationId") as string);
    await this.getData();
  },
  created() {
    this.initFilters();
  },
});
