const whyShouldWeInnovate = {
  title: `Why Should We Innovate?`,
  content: `
    Improvements in research, patient satisfaction, education, and access to
    care are additional factors to keep in mind. Simply put, the ultimate
    goal of health innovation is to improve our ability to meet public and
    personal healthcare needs and demands by optimizing the performance of
    the health system.`,
};
const Welcome = {
  image: "banner-2.jpg",
  title: "Submit Innovation Proposal",
  subtitle: "",
  body: `Do you have an already tested and validated innovative solution that can meet one or more of the highlighted health priority needs? Submit your proposal`,
  imageStyling: "",
  titleStyling: "",
  subtitleStyling: "",
  bodyStyling: "",
  buttonLink: "/priority-needs/list",
  buttonStyle:
    "btn-transparent btn-lg rounded-0 border-light text-white py-3 px-5 btn-elevate",
  buttonText: "Submit Innovation Proposal",
};

const cardStyle = {
  customCard: "border-hover rounded-0 text-left bg-white border-0",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12",
  image: false,
};

const buttonStyle = `btn-primary btn-lg rounded-0 border-primary border-3 text-white py-2 px-5`;

const Datas = [
  {
    id: 1,
    title: "Wearable technology to prevent hand-to-face contact",
    titleLink: "",
    body: "The system utilizes an inertial measurement unit (IMU) to obtain features that characterize hand movement involving face touching. Time-series data can be efficiently classified using 1D-Convolutional Neural Network (CNN.",
  },
  {
    id: 2,
    title: "Video treatment support (VOT) for TB patients via mobiles",
    titleLink: "",
    body: "The end product will exploit video communication between patient and health care professional, transmitted live or self-recorded over a secure internet connection, and to help TB patients improve their treatment outcomes.",
  },
  {
    id: 3,
    title: "Affordable purification systems for low-middle income areas",
    titleLink: "",
    body: "The system is also expected to treat enough water sufficiently quickly for point-of-use in households or small settings such as schools, etc. It is also be durable, and maintenance, and with little or no plumbing.",
  },
  {
    id: 4,
    title: "Turbine-Powered Oxygen supply",
    titleLink: "",
    body: "Air is compressed to elevated temperature and pressure, at least a portion of the air is combusted and a portion of the oxygen is removed from the air or combustion effluent through a membrane.",
  },
];

const Processes = [
  {
    id: 1,
    image: "logo-1.svg",
    title: "Create Account",
  },
  {
    id: 2,
    image: "logo-2.svg",
    title: "Select a Need",
  },
  {
    id: 3,
    image: "logo-3.svg",
    title: "Fill Needs Application Form",
  },
  {
    id: 4,
    image: "logo-4.svg",
    title: "Approval",
  },
];
const Progress = [
  "Account-creation",
  "Health-needs-priorities",
  "Apply-for-needs",
  "Approval",
  "Innovation-begins",
];
export {
  whyShouldWeInnovate,
  Welcome,
  cardStyle,
  buttonStyle,
  Datas,
  Processes,
  Progress,
};
