const cardStyle = {
  customCard: "rounded-0 text-left border-0 pb-5 pt-5",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12 justify-content-center",
  image: false,
};

const cardStyle2 = {
  customCard: "border-hover rounded-0 text-left border-0",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12 justify-content-center",
  image: false,
};

const Welcome = {
  image: "banner-6.jpg",
  title: `Partner with Us`,
  subtitle: "",
  body: `Do you wish to partner with us in scaling up innovations to address the identified health needs?`,
  imageStyling: "",
  titleStyling: "",
  subtitleStyling: "",
  bodyStyling: "",
  buttonStyle:
    "btn-transparent btn-lg rounded-0 border-light text-white py-3 px-5 btn-elevate",
  // buttonLink: "/partner/partner-registration",
  buttonLink: "/submit-proposal/call-for-proposal",
  buttonText: "Become a Partner",
};
const buttonStyle = `btn-transparent btn-lg rounded-0 border-primary border-3 text-primary py-3 px-5`;

const Datas = [
  {
    id: 1,
    title: "Wearable technology to prevent hand-to-face contact",
    titleLink: "",
    body: "The system utilizes an inertial measurement unit (IMU) to obtain features that characterize hand movement involving face touching. Time-series data can be efficiently classified using 1D-Convolutional Neural Network (CNN.",
  },
  {
    id: 2,
    title: "Video treatment support (VOT) for TB patients via mobiles",
    titleLink: "",
    body: "The end product will exploit video communication between patient and health care professional, transmitted live or self-recorded over a secure internet connection, and to help TB patients improve their treatment outcomes.",
  },
  {
    id: 3,
    title: "Affordable purification systems for low-middle income areas",
    titleLink: "",
    body: "The system is also expected to treat enough water sufficiently quickly for point-of-use in households or small settings such as schools, etc. It is also be durable, and maintenance, and with little or no plumbing.",
  },
  {
    id: 4,
    title: "Turbine-Powered Oxygen supply",
    titleLink: "",
    body: "Air is compressed to elevated temperature and pressure, at least a portion of the air is combusted and a portion of the oxygen is removed from the air or combustion effluent through a membrane.",
  },
];

const Benefits = [
  { title: "Value Co-creation", titleLink: "" },
  { title: "Contributing to Impact", titleLink: "" },
  { title: "Business opportunities", titleLink: "" },
  { title: "Efficient use of resources", titleLink: "" },
];

// =======================Login
const loginButtonStyle = `btn-primary btn-lg py-2 w-75 rounded-0 border-primary border-3 mt-4 mb-3 mx-auto`;
const loginCardStyle = {
  customCard: "border-hover rounded-0 text-left bg-white border-0",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12",
  image: false,
};

// ====================== Registration
/**
 * Login button styling
 */
const regButtonStyle = `btn-primary btn-lg py-2 w-75 rounded-0 border-primary border-3 mb-3 mx-auto`;
const regCardStyle = {
  customCard: "border-hover rounded-0 text-left bg-white border-0",
  titleStyle: "",
  customRow: "col-lg-3 col-md-6 col-12",
  image: false,
};

export {
  cardStyle,
  cardStyle2,
  Welcome,
  buttonStyle,
  Datas,
  Benefits,
  loginButtonStyle,
  loginCardStyle,
  regButtonStyle,
  regCardStyle,
};
