
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { innovationMilestone, generic, milestoneComment } from "@/types";
import Toast from "@/utils/Toast";
import SubBar from "../../components/SubBar.vue";

export default defineComponent({
  name: "MyPartnershipMessages",
  components: {
    SubBar,
  },
  data() {
    return {
      innovator: {} as { [key: string]: string },
      innovationId: "",
      data: {} as innovationMilestone,
      loading: false,
      loadingPartner: false,
      loadingComments: false,
      url: process.env.VUE_APP_API_BASE_URL,
      comment: "",
      messages: [] as milestoneComment[],
      userData: {} as generic,
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", ["getUserId"]),
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "GET_INNOVATION_MESSAGE",
      "POST_INNOVATION_MESSAGE",
    ]),
    ...mapActions("PARTNER_STORE", ["GET_PARTNER"]),
    formatDate(date: string) {
      return moment(date).format("DD, MMM YYYY");
    },
    /**
     * @description POST A MILE STONE COMMENT
     */
    async getMileStoneComment() {
      const data = {
        innovationId: this.innovationId,
        partnerId: this.userData?.id,
      };
      this.loadingPartner = true;
      try {
        this.messages = await this.GET_INNOVATION_MESSAGE(data);
        const element = document.getElementById("scrollTo");
        setTimeout(() => {
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 500);
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingPartner = false;
      }
    },
    /**
     * @description POST A MILE STONE COMMENT
     */
    async createMileStoneComment() {
      if (this.comment?.length <= 0) {
        Toast.info("Please enter your message");
        return;
      }
      const data = {
        innovation: this.innovationId,
        message: this.comment,
        sent_by: "PARTNER",
        partner: this.userData?.id,
      };
      this.loadingComments = true;
      try {
        await this.POST_INNOVATION_MESSAGE(data);
        await this.getMileStoneComment();
        this.comment = "";
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComments = false;
      }
    },
    /**
     * @description get partner details
     */
    async getPartner() {
      try {
        this.loading = true;
        this.userData = await this.GET_PARTNER(this.getUserId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.innovationId =
      (this.$route.query.id as string) ||
      (localStorage.getItem("partner_innovationId") as string);
    this.innovator = JSON.parse(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      localStorage.getItem("innovator_details") as any
    );

    if (this.innovationId === "") {
      this.$router.push(`/partner/my-partnerships`);
      return;
    }
    await this.getPartner();
    await this.getMileStoneComment();
  },
});
