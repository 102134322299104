
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import store from "@/store";
import { emailProp, passwordProp } from "@/props-config";

export default defineComponent({
  name: "ProgramCordLoginFormModal",
  setup() {
    const loading = ref(false);
    const schema = yup.object({
      email: yup.string().required("email is required"),
      password: yup.string().required().min(8),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } =
      useField("password");

    const onSubmit = handleSubmit(async (values) => {
      let data = values;
      loading.value = true;
      const { email, password } = data;

      const formData = {
        username: email,
        password,
      };

      try {
        await store.dispatch("AUTH_STORE/LOGIN_PROG_CORD", formData);
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    });

    return {
      onSubmit,
      loading,

      email,
      password,

      emailError,
      passwordError,

      emailProp,
      passwordProp,

      buttonStyle:
        "btn-primary btn-lg py-2 w-100 rounded-0 border-primary border-3",
    };
  },
});
