const chartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
  },
  title: {
    text: "60%<br/> Approved",
    verticalAlign: "middle",
    y: 10,
    floating: true,
  },
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        distance: -50,
        y: -5,
        format: "{y}%",
        style: {
          fontWeight: "bold",
          color: "black",
          fontSize: "14px",
        },
        filter: {
          property: "name",
          operator: "===",
          // value: "Firefox",
        },
      },
      borderWidth: 3,
      showInLegend: true,
    },
    legend: {
      align: "left",
      layout: "vertical",
      verticalAlign: "top",
      x: 40,
      y: 0,
    },
    series: {
      animation: true,
    },
  },
  series: [
    {
      type: "pie",
      name: "Statistics",
      innerSize: "70%",
      data: [
        { name: "Approved", y: 69, color: "#1F78B4" },
        { name: "Closed", y: 31, color: "#A6CEE3" },
        { name: "Submitted", y: 19, color: "#B2DF8A" },
      ],
    },
  ],
};

export default chartOptions;
