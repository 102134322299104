import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12f3f807"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-list card border-0" }
const _hoisted_2 = { class: "card-header bg-transparent border-bottom-0" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "card-footer bg-transparent border-top-0" }
const _hoisted_5 = { class: "d-flex w-50 justify-content-between" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "card-header bg-transparent border-bottom-0" }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "card-footer bg-transparent border-top-0" }
const _hoisted_10 = { class: "d-flex w-50 justify-content-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.route)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.route
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["shadow-sm border-0 rounded-2", [_ctx.card.customCard]])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h5", {
                  class: _normalizeClass([_ctx.card.customTitle])
                }, _toDisplayString(_ctx.body.title), 3)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", {
                  class: _normalizeClass(["mb-2", [_ctx.card.customBody]])
                }, _toDisplayString(_ctx.body.body), 3)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("small", {
                    class: _normalizeClass([_ctx.card.customId])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.body.id), 1)
                  ], 2),
                  _createElementVNode("small", {
                    class: _normalizeClass([_ctx.card.customDate])
                  }, [
                    _createElementVNode("i", null, _toDisplayString(_ctx.body.date), 1)
                  ], 2)
                ])
              ])
            ], 2)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(["shadow-sm border-0 rounded-2", [_ctx.card.customCard]])
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h5", {
                class: _normalizeClass([_ctx.card.customTitle])
              }, _toDisplayString(_ctx.body.title), 3)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", {
                class: _normalizeClass(["mb-2", [_ctx.card.customBody]])
              }, _toDisplayString(_ctx.body.body), 3)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("small", {
                  class: _normalizeClass([_ctx.card.customId])
                }, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.body.id), 1)
                ], 2),
                _createElementVNode("small", {
                  class: _normalizeClass([_ctx.card.customDate])
                }, [
                  _createElementVNode("i", null, _toDisplayString(_ctx.body.date), 1)
                ], 2)
              ])
            ])
          ], 2)
        ]))
  ]))
}