const partnershipProp = {
  type: "text",
  name: "Organization/Partner Name",
  id: "name",
  placeholder: "Organization/Partner Name",
};

const webProp = {
  type: "text",
  name: "Your website/web address",
  id: "web",
  placeholder: "https://yourwebsite.com",
};

const descriptionProp = {
  name: "Brief description of your organization",
  id: "description",
  placeholder: "Describe your organization in details",
};

const resourceProp = {
  name: "Internal and External resources available to meet the innovation needs",
  id: "resource",
  placeholder:
    "Please state all resources available to make a successful partnership for the above innovation",
};

const sizeProp = {
  label: true,
  name: "Organization size",
  id: "size",
  options: ["size"],
};

const operatingProp = {
  label: true,
  name: "Operating Countries",
  id: "operating",
  options: ["operating"],
};

const supportProp = {
  label: true,
  name: "Type of support/partnership",
  id: "support",
  options: ["support"],
};
//=======================================

const partnerType = [
  {
    id: "Financial (donors, grants, VC funded, angel investors)",
    name: "FINANCIAL",
  },
  { id: "Technology (technology transfer and licensing)", name: "TECHNOLOGY" },
  {
    id: "Implementing (manufacturing, regulatory, supply chain, marketing, communication)",
    name: "IMPLEMENTATION",
  },
];
// Radios
const yesProps = {
  label: "Yes",
  id: "yes",
};

const noProps = {
  label: "No",
  id: "no",
};

export {
  partnershipProp,
  descriptionProp,
  sizeProp,
  operatingProp,
  supportProp,
  webProp,
  resourceProp,
  yesProps,
  noProps,
  partnerType,
};
