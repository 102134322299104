const termsProp = {
  text: "I have read and accepted the",
  id: "terms",
  // link: "#",
  // linkText: "Terms and Conditions",
};

const RCProp = {
  type: "text",
  name: "Organization RC No.",
  id: "rc_number",
};

const needsProp = {
  type: "text",
  name: "Needs",
  id: "needs",
};

const GoalsProp = {
  type: "text",
  name: "Goals",
  id: "goals",
};

const phoneNumberProp = {
  type: "tel",
  name: "Contact Person",
  id: "contact_person",
  autoComplete: "tel-national",
};

// Select
const countryProp = {
  label: true,
  name: "Country",
  id: "country",
  options: ["Nigeria"],
};

const innovationProp = {
  label: true,
  name: "Innovation types",
  id: "programArea",
  options: ["Postpartum Hermorrhage"],
};

const programAreaProp = {
  label: true,
  name: "Program Area",
  id: "programArea",
  options: ["Postpartum Hermorrhage"],
};

const programAreaPropAlt = {
  label: false,
  name: "Program Area",
  id: "programArea",
  options: ["Postpartum Hermorrhage"],
};

// TextArea
const innovationOverviewProp = {
  name: "Innovation Overview",
  id: "innovation_overview",
  placeholder: "What is your proposed solution to this health need?",
};

const experienceProp = {
  name: "Experience",
  id: "experience",
  placeholder:
    "What previous experience do you have that is related to this health need?",
};

export {
  termsProp,
  RCProp,
  needsProp,
  GoalsProp,
  phoneNumberProp,
  countryProp,
  innovationProp,
  programAreaProp,
  innovationOverviewProp,
  experienceProp,
  programAreaPropAlt,
};
