
import { defineComponent } from "vue";
import { mapMutations } from "vuex";
import MultiSelect from "primevue/multiselect";
import {
  infoData,
  diseasesData,
  outbreakData,
  non_communicableData,
  reproductiveData,
  strengtheningData,
} from "./InnovationSubmissionData";
import {
  otherProp,
  fundProp,
  similarProp,
  innovativeProp,
  stageSolutionProp,
  stageClinicalProp,
  stageSubjectProp,
  requirementProp,
  statusProp,
  fileProp,
  employeeProp,
  regulatoryReqOthersProp,
} from "./InnovationSubmissionProp";
import Toast from "@/utils/Toast";

interface type {
  id: string;
  name: string;
}

export default defineComponent({
  name: "InnovationSubmissionForm",
  components: {
    MultiSelect,
  },
  data() {
    return {
      information: [] as string[],
      diseases: [] as type[],
      outbreak: [] as type[],
      non_communicable: [] as type[],
      reproductive: [] as type[],
      strengthening: [] as type[],
      otherProp,
      fundProp,
      similarProp,
      innovativeProp,
      stageSolutionProp,
      stageClinicalProp,
      stageSubjectProp,
      requirementProp,
      statusProp,
      fileProp,
      employeeProp,
      regulatoryReqOthersProp,
      buttonStyle: "btn-primary rounded-0 px-lg-5 px-md-3 px-1 py-2 mx-2",
      btnStyle:
        "btn-transparent fw-bold rounded-0 px-lg-5 px-md-3 px-1 py-2 mx-2 d-none",
      form: {
        selectedInformation: [] as string[],
        selectedDiseases: [] as type[],
        selectedOutbreak: [] as type[],
        selectedNon_communicable: [] as type[],
        selectedStrengthening: [] as type[],
        reproductive: "",
        other: "",
        title: "",
        description: "",
        innovative: "",
        similar: "",
        solution: "",
        clinical: "",
        subject: "",
        fund: "",
        employee: "",
      },
      form2: {
        requirement: "",
        regulatoryReqOthers: "",
        status: "",
      },
      file: "",
      needDetails: {},
      loading: false,
    };
  },
  methods: {
    ...mapMutations("INNOVATOR_STORE", [
      "UPDATE_PAGE",
      "UPDATE_PROGRESS",
      "SAVE_INNOVATION1",
    ]),
    async updatePage() {
      this.UPDATE_PAGE(2);
      this.UPDATE_PROGRESS(100);
    },
    formValidation() {
      // check if every item in this.form is not empty else toast the appropriate error message
      const form = this.$refs.innovation1 as HTMLFormElement;
      const Error = [];
      if (this.form.subject === "Yes") {
        for (const [k, v] of Object.entries(this.form2)) {
          if (
            v === "" ||
            JSON.stringify(v) === "[]" ||
            JSON.stringify(v) === "{}" ||
            v === undefined
          ) {
            const input = form.querySelector(`[name=${k}]`);
            if (k !== "regulatoryReqOthers" && k !== "requirement") {
              Error.push(`${k} is required`);
              input?.classList.add("p-invalid");
            }
          } else {
            // find the input that has been filled in form and remove the error class
            const input = form.querySelector(`[name=${k}]`);
            input?.classList.remove("p-invalid");
            input?.classList.add("is-valid");
          }
        }
      }

      for (const [k, v] of Object.entries(this.form)) {
        if (
          v === "" ||
          JSON.stringify(v) === "[]" ||
          JSON.stringify(v) === "{}" ||
          v === undefined
        ) {
          const input = form.querySelector(`[name=${k}]`);
          if (
            k !== "similar" &&
            k !== "clinical" &&
            k !== "employee" &&
            k !== "selectedDiseases" &&
            k !== "selectedOutbreak" &&
            k !== "selectedNon_communicable" &&
            k !== "selectedStrengthening" &&
            k !== "reproductive" &&
            k !== "other"
          ) {
            Error.push(`${k} is required`);
            input?.classList.add("is-invalid");
            input?.classList.add("p-invalid");
          }
        } else {
          // find the input that has been filled in form and remove the error class
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.remove("p-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          `Please fill out all the required fields, ${Error.join(",\r\n ")}`
        );
        return false;
      }
      return true;
    },
    nextPage() {
      if (this.formValidation() === false) {
        return;
      }
      if (
        (this.file.length === 0 && this.form2.status === "Applied") ||
        (this.file.length === 0 &&
          this.form2.status === "Under consideration") ||
        (this.file.length === 0 && this.form2.status === "Completed and valid")
      ) {
        Toast.warning("Please upload a document");
        return;
      }
      this.loading = true;
      const formData = new FormData();
      // GET THE SELECT IDS
      const diseaseId = this.form.selectedDiseases.map((item: type) => item.id);
      const outbreakId = this.form.selectedOutbreak.map(
        (item: type) => item.id
      );
      const non_communicableId = this.form.selectedNon_communicable.map(
        (item: type) => item.id
      );
      const strengtheningId = this.form.selectedStrengthening.map(
        (item: type) => item.id
      );
      formData.append(
        "solution_alignment",
        JSON.stringify(this.form.selectedInformation) || ""
      );
      formData.append("infectious_diseases", JSON.stringify(diseaseId) || "");
      formData.append("disease_outbreaks", JSON.stringify(outbreakId) || "");
      formData.append(
        "non_communicable_diseases",
        JSON.stringify(non_communicableId) || ""
      );
      formData.append(
        "health_strengthning",
        JSON.stringify(strengtheningId) || ""
      );
      formData.append("RMNCH", this.form.reproductive[0] || "");
      formData.append("others", this.form.other || "");
      formData.append("solution_title", this.form.title);
      formData.append("solution_description", this.form.description);
      formData.append("innovative_description", this.form.innovative);
      formData.append("solution_uniqueness", this.form.similar || ""); //optional
      formData.append("development_stage", this.form.solution);
      formData.append("clinical_development_stage", this.form.clinical || ""); //optional
      formData.append("is_regulatory_subject", this.form.subject);
      formData.append("regulatory_requirements", this.form2.requirement || ""); //optional
      formData.append(
        "other_reg_requirements",
        this.form2.regulatoryReqOthers || ""
      ); //optional
      formData.append("reg_requirement_status", this.form2.status);
      formData.append("reg_req_doc", this.file);
      formData.append("funds_raised", this.form.fund);
      formData.append("paid_employees", this.form.employee || ""); //optional

      // ============================
      this.SAVE_INNOVATION1(formData);
      this.updatePage();
      this.loading = false;
      return;
    },
    setData() {
      this.information = infoData;
      this.diseases = diseasesData;
      this.outbreak = outbreakData;
      this.non_communicable = non_communicableData;
      this.reproductive = reproductiveData;
      this.strengthening = strengtheningData;
    },
    saveForLater() {
      console.log("save for later");
    },
    getNeedData() {
      const data = sessionStorage.getItem("BHMIS_HealthNeed");
      if (data !== null) {
        this.needDetails = JSON.parse(data);
      }
    },
  },
  created() {
    this.setData();
    this.getNeedData();
  },
});
