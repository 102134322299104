
import { defineComponent } from "vue";
import TDInvite from "@/modules/auth/components/invites/TechnicalDirectorate.vue";

export default defineComponent({
  name: "TDInvites",
  components: {
    TDInvite,
  },
});
