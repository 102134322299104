
import { defineComponent, PropType } from "vue";

interface dataType {
  title: string;
  location: string;
  body: string;
  programArea: string;
}

export default defineComponent({
  name: "ManageNeedsCard",
  props: {
    body: {
      type: Object as PropType<dataType>,
      required: true,
      default: () => ({
        title: "Postpartum Hermorrhage",
        location: "Orapa Town",
        body: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis ea deserunt at explicabo, sunt, sint quam impedit odit obcaecati eaque, reprehenderit magni aperiam consequuntur iusto esse ipsa quo alias voluptas?",
        programArea: "Maternal & Child Health",
      }),
    },
  },
});
