import { MutationTree } from "vuex";
import { state, generic } from "./types";

const mutations: MutationTree<state> = {
  setUserProfileParams: (state, payload) => {
    state.userProfileParams = {};
    state.userProfileParams = payload;
  },
  setUserProfileData: (state, payload) => {
    state.userProfileData = {};
    state.userProfileData = payload;
  },
  setPartnershipDeclarations: (state, payload) => {
    state.partnershipDeclarations = {};
    state.partnershipDeclarations = payload;
  },
  setTags: (state, payload) => {
    state.Tags = [];
    state.Tags = payload;
  },
  // Informatics Official
  officials: (state, data): void => {
    state.Officials = [];
    state.Officials = data;
  },
  official: (state, id: string): void => {
    state.Official = {} as generic;
    // filter the health directorates state by id
    state.Official = state.Officials.filter(
      (item: generic) => item.id === id
    )[0];
  },
  // Users
  setUsers: (state, payload) => {
    state.Users = [];
    state.Users = payload;
  },
};

export default mutations;
