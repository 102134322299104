
import { defineComponent } from "vue";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { innovationMilestone, generic, milestoneComment } from "@/types";
import Toast from "@/utils/Toast";
import SubBar from "../../components/SubBar.vue";

export default defineComponent({
  name: "MyInnovationMessages",
  components: {
    SubBar,
  },
  data() {
    return {
      innovator: {} as { [key: string]: string },
      innovationId: "",
      data: {} as innovationMilestone,
      loading: false,
      loadingComments: false,
      url: process.env.VUE_APP_API_BASE_URL,
      comment: "",
      currentPartnerTitle: "Innovator Partners Comment",
      currentPartner: {} as generic,
      messages: [] as milestoneComment[],
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getPartnerByInnovationId"]),
    ...mapGetters("AUTH_STORE", ["getUserId"]),
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", [
      "GET_PARTNERS_BY_INNOVATION",
      "POST_INNOVATION_MESSAGE",
      "GET_INNOVATION_MESSAGE",
      "GET_INNOVATOR",
    ]),
    async getData() {
      this.loading = true;
      try {
        await this.GET_PARTNERS_BY_INNOVATION(this.innovationId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    formatDate(date: string) {
      return moment(date).format("DD, MMM YYYY");
    },
    async onPartnerClick(partner: { [key: string]: string }) {
      this.currentPartner = partner;
      this.currentPartnerTitle =
        `${partner?.first_name} ${partner?.last_name}` || partner?.username;
      await this.getMileStoneComment();

      const element = document.getElementById("scrollTo");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    getUserImage(imageUrl: string) {
      return this.url.slice(0, -1) + imageUrl;
    },
    /**
     * @description POST A MILE STONE COMMENT
     */
    async createMileStoneComment() {
      if (this.comment?.length <= 0) {
        Toast.info("Please enter your message");
        return;
      }
      const data = {
        innovation: this.innovationId,
        message: this.comment,
        sent_by: "INNOVATOR",
        partner: this.currentPartner?.id,
      };
      this.loadingComments = true;
      try {
        await this.POST_INNOVATION_MESSAGE(data);
        await this.getMileStoneComment();
        this.comment = "";
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingComments = false;
      }
    },
    /**
     * @description POST A MILE STONE COMMENT
     */
    async getMileStoneComment() {
      this.loading = true;
      const data = {
        innovationId: this.innovationId,
        partnerId: this.currentPartner?.id,
      };
      try {
        this.messages = await this.GET_INNOVATION_MESSAGE(data);
        const element = document.getElementById("scrollTo");
        setTimeout(() => {
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 500);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async getUserDetails() {
      this.loading = true;
      try {
        this.innovator = await this.GET_INNOVATOR(this.getUserId);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.currentPartner = {};
    this.innovationId =
      (this.$route.query.id as string) ||
      (localStorage.getItem("innovationId") as string);
    await this.getData();
    await this.getUserDetails();
  },
});
