const sideRoute = [
  // {
  //   name: "Home",
  //   path: "/partner/home",
  //   icon: "icon bi-house-fill",
  // },
  // {
  //   name: "Track Partnership",
  //   path: "/partner/track",
  //   icon: "icon bi-search",
  // },
  // {
  //   name: "View Partners",
  //   path: "/partner/view-partners",
  //   icon: "icon bi-person-lines-fill",
  // },
  {
    name: "Innovative Proposals",
    path: "/partner/home",
    icon: "icon bi-house-fill",
  },
  {
    name: "My Partnership",
    path: "/partner/my-partnerships",
    icon: "icon bi-lightbulb-fill",
  },
  {
    name: "Innovations Interest",
    path: "/partner/other-innovation",
    icon: "icon bi-clock-history",
  },
];

export default sideRoute;
