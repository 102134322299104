
import { defineComponent } from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import Skeleton from "primevue/skeleton";
import CardList from "@/components/Card/List.vue";
import ScrollArea from "@/components/ScrollAreas.vue";
import { cardListStyle } from "./Data";

export default defineComponent({
  name: "DashboardHome",
  components: {
    CardList,
    ScrollArea,
    Skeleton,
  },
  data() {
    return {
      cardListStyle,
      Message: "",
      isLoaded: false,
      data: {},
      loading: false,
      programAreaValue: "",
      tagTitle: "",
      publishedData: [],
      publishedDataPagination: { size: 0, total_records: 0 } as {
        [key: string]: string | number;
      },
    };
  },
  computed: {
    ...mapGetters("INNOVATION_STORE", ["getHealthNeeds"]),

    ...mapGetters(["getPagination"]),
  },
  methods: {
    ...mapActions("INNOVATION_STORE", [
      "GET_HEALTH_NEEDS",
      "GET_HEALTH_NEEDS_BY_STATUS_PAGINATION",
    ]),
    ...mapMutations("INNOVATION_STORE", [
      "SET_HEALTH_NEED",
      "healthNeedsByProgramArea",
    ]),
    gotoForm() {
      this.$router.push("/priority-needs/submit");
    },
    onImgLoad() {
      this.isLoaded = true;
    },
    /**
     * Function to filter the data by the tab selected
     * @param {string} programArea
     * Filtered if programArea is not empty
     * Message if Filtered is empty
     */
    async programArea(value: { [key: string]: string }) {
      console.log(value);
    },
    /**
     * @function getData
     * @description Function to get the data from the API onMounted
     */
    async getData() {
      this.loading = true;
      await this.GET_HEALTH_NEEDS("PUBLISHED");
      this.loading = false;
    },
    // Handle pagination
    async onPage(data: { [key: string]: number }) {
      try {
        this.loading = true;
        await this.GET_HEALTH_NEEDS_BY_STATUS_PAGINATION({
          status: "PUBLISHED",
          page: data.page + 1,
        });
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (this.getHealthNeeds?.length <= 0) {
      this.getData();
    }
  },
});
