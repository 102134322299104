import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4184c6c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-3" }
const _hoisted_2 = { class: "text-primary" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, [
      _createTextVNode(" Upload "),
      (_ctx.value.name)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.value.name), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("input", {
      class: "form-control d-none",
      type: "file",
      id: _ctx.value.id
    }, null, 8, _hoisted_4),
    _createElementVNode("label", {
      for: _ctx.value.id,
      class: "box"
    }, [
      (_ctx.value.label)
        ? (_openBlock(), _createElementBlock("small", _hoisted_6, "+ " + _toDisplayString(_ctx.value.label), 1))
        : (_openBlock(), _createElementBlock("small", _hoisted_7, "+ upload image"))
    ], 8, _hoisted_5)
  ]))
}