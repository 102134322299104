import Index from "./index.vue";
import Documents from "./RelevantDocuments.vue";
import Stages from "./Stages.vue";

export default [
  {
    path: "resource-library",
    name: "ResourceLibrary",
    component: Index,
    meta: {
      title: "Resource Library",
      description:
        "BHIMS is a platform for the sharing of ideas and innovations in the field of health.",
      keywords:
        "innovation, innovation in healthcare, partner with an innovation",
      ogTitle: "Innovation | Resource Library",
      transition: "slide-left",
    },
    children: [
      {
        path: "",
        alias: "documents",
        name: "Documents",
        component: Documents,
      },
      {
        path: "stages",
        name: "Stages",
        component: Stages,
      },
    ],
  },
];
