
import { defineComponent } from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { filterResult } from "@/types/";

export default defineComponent({
  name: "MyInnovations",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      loading: false,
      filters: {} as filterResult,
      title: "Innovations(s)",
    };
  },
  computed: {
    ...mapGetters("INNOVATOR_STORE", ["getMyInnovations", "getInnovation"]),
  },
  methods: {
    ...mapMutations("INNOVATOR_STORE", ["myInnovation"]),
    ...mapActions("INNOVATOR_STORE", ["GET_MY_INNOVATIONS"]),
    async getData() {
      try {
        this.loading = true;
        await this.GET_MY_INNOVATIONS();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toPartners(e: any) {
      // Pass the innovation id through the four tabs
      localStorage.removeItem("innovationId");
      localStorage.removeItem("InnovationName");
      localStorage.setItem("innovationId", e.data.id);
      localStorage.setItem("InnovationName", e.data.solution_title);
      this.$router.push(`/innovator/my-innovation/list?id=${e.data.id}`);
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        innovation: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
  },
  async mounted() {
    if (this.getMyInnovations?.length <= 0) {
      this.getData();
    }
  },
  created() {
    this.initFilters();
  },
});
