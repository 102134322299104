import { GetterTree } from "vuex";
import { state } from "./types";

const getters: GetterTree<state, unknown> = {
  getTags: (state: state) => state.tags,
  getAnalytics: (state: state) => state.analytics,
  getPagination: (state: state) => state.pagination,
  getWithPartners: (state: state) => state.withPartners,
  getViewPartnerShipDist: (state: state) => state.viewPartnerShipDist,
};

export default getters;
