import { state, generic } from "./types";

const state: state = {
  userProfileParams: {},
  userProfileData: {},
  partnershipDeclarations: {},
  Tags: [],
  Officials: [],
  Official: {} as generic,
  Users: [],
};

export default state;
