/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import Swal from "sweetalert2";
import { state } from "./types";

const localUrl = "innovators/";
const localUrl2 = "innovations/";

const actions: ActionTree<state, unknown> = {
  // ====================== INNOVATION ======================
  async GET_INNOVATIONS(context, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `${localUrl2}?status=${payload || "PUBLISHED"}`
      );
      context.commit("innovations", data.data);
      // set pagination data
      context.commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data.data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  async GET_INNOVATION_BY_ID(context, payload): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl2}/${payload}/`);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // GET INNOVATION BY STATUS
  async GET_INNOVATIONS_BY_STATUS(context, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `${localUrl2}?status=${payload.status}&size=${payload.size || 5}`
      );
      context.commit("innovations", data.data);
      // set pagination data
      context.commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // GET INNOVATION BY STATUS AND PAGINATION
  async GET_INNOVATIONS_BY_STATUS_PAGINATION(context, payload): Promise<void> {
    try {
      const { status, size, page } = payload;
      const { data } = await Request(
        "GET",
        `${localUrl2}?status=${status}&page=${page}&size=${size || 5}`
      );
      context.commit("innovations", data.data);
      // set pagination data
      context.commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // GET SPECIFIC INNOVATION
  async GET_INNOVATION({ commit }, id: string): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl2}${id}/`);
      commit("innovation", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function SEARCH_INNOVATIONS - function to search health Innovations
   * @param commit
   * @param payload {string}
   */
  async SEARCH_INNOVATIONS(
    { commit },
    payload: { [key: string]: string }
  ): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `${localUrl2}/?status=${payload.type || "APPROVED"}&search=${
          payload.search
        }&size=5`
      );
      commit("innovations", data.data);
      commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(details);
      // throw details;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_INNOVATION_BY_TAG
   * @param context
   * @param payload {string}
   */
  async GET_INNOVATION_BY_TAG(
    { commit },
    payload: { [key: string]: string }
  ): Promise<void> {
    try {
      const { id, status } = payload;
      const { data } = await Request(
        "GET",
        `innovations/?status=${status || "APPROVED"}&program_area=${id}`
      );
      commit(
        "setPagination",
        {
          page: data.page,
          size: data.size,
          total_pages: data.total_pages,
          total_records: data.total_records,
        },
        { root: true }
      );
      return data;
    } catch (error: any) {
      // const { detail } = error.response?.data;
      // Toast.error(detail);
      // throw detail;
      Toast.error(error);
      throw error;
    }
  },
  // APPROVE INNOVATION
  async APPROVE_INNOVATION(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("POST", `${localUrl2}${id}/approve/`, data);
      Swal.fire("Success", "Innovation sent for evaluation", "success");
      Toast.success("Innovation sent for evaluation");
      return;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // REJECT INNOVATION
  async REJECT_INNOVATION(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("POST", `${localUrl2}${id}/reject/`, data);
      Swal.fire("Success", "Innovation was successfully rejected", "info");
      Toast.info("Innovation was successfully rejected");
      return;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // EVALUATE INNOVATION
  async EVALUATE_INNOVATION(context, payload): Promise<void> {
    try {
      const { id, data } = payload;
      await Request("POST", `${localUrl2}${id}/evaluate/`, data);
      Swal.fire("Success", "Innovation was successfully evaluated", "info");
      Toast.info("Innovation was successfully evaluated");
      return;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  async GET_MY_INNOVATIONS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl2}my/`);
      commit("myInnovations", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  async GET_MY_INNOVATIONS_PARTNER_LIST(context, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `${localUrl2}${payload}/partnerships/`
      );
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // GET ALL INNOVATORS
  async GET_INNOVATORS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", localUrl);
      commit("innovators", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // GET SPECIFIC INNOVATOR
  async GET_INNOVATOR({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl}${payload}/`);
      commit("innovator", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // SUBMIT A PROPOSAL
  async SUBMIT_PROPOSAL(context, payload): Promise<void> {
    const { id, data } = payload;
    try {
      await Request("POST", `innovations/needs/${id}/proposal/`, data);
      return;
    } catch (error: any) {
      console.log({ error });
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // PARTNER WITH AN INNOVATION
  async PARTNER_WITH_INNOVATION(context, payload): Promise<void> {
    const { id, data } = payload;
    try {
      const response = await Request(
        "POST",
        `${localUrl2}${id}/partner/`,
        data
      );
      return response.data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // INITIATE PARTNER WITH AN INNOVATION
  async INITIATE_PARTNER_WITH_INNOVATION(context, payload): Promise<void> {
    const { id, data, partner_id } = payload;
    try {
      const response = await Request(
        "POST",
        `${localUrl2}${id}/initiate_partnership_mou/${partner_id}/`,
        data
      );
      Toast.info("Operation Successful");
      return response.data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // PARTNER SIGNS MOU
  async PARTNER_SIGNS_MOU(context, payload): Promise<void> {
    const { token, data } = payload;
    try {
      const response = await Request(
        "POST",
        `${localUrl2}sign_mou/?token=${token}`,
        data
      );
      return response.data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * FUNCTION TO DECODE TOKEN AND GET THE DATA FOR INNOVATOR-PARTNER-MOU PAGE
   * @param {string} payload as token
   * @returns {Promise<void>} success or error
   */
  async GET_INNOVATOR_PARTNER_MOU({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request(
        "POST",
        `${localUrl2}partnership/decode_token/?token=${payload}`
      );
      commit("setInnovatorPartnerMou", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },

  // GET INNOVATION MILESTONES
  async GET_INNOVATION_MILESTONES({ commit }, id: string): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl2}${id}/milestones/`);
      commit("innovationMilestones", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },

  // GET INNOVATION TIMELINE
  async GET_INNOVATION_TIMELINE({ commit }, id: string): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl2}${id}/timeline/`);
      commit("innovationTimeline", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  // GET INNOVATION FILES
  async GET_INNOVATION_FILES({ commit }, id: string): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `${localUrl2}${id}/relevant_documents/`
      );
      commit("setRelevantDocument", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },

  /**
   * @function: Get a list of partnered innovations
   */
  async GET_INNOVATION_PARTNER_LIST(context): Promise<void> {
    try {
      const user_id = context.rootGetters["AUTH_STORE/getUserId"];
      const { data } = await Request(
        "GET",
        `${localUrl}${user_id}/get_partnered_innovations/`
      );
      context.commit("innovationPartnerList", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_PARTNER_DECLARATION
   * @description - get the user partnership declarations by partner_id
   */
  async GET_PARTNER_DECLARATION({ commit }, payload): Promise<void> {
    const { partnerId, innovationId } = payload;
    try {
      const { data } = await Request(
        "GET",
        `partnership_declarations/?partner=${partnerId}&innovation=${innovationId}`
      );
      commit("setPartnerDeclarationForm", data.data[0]);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(error || details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_MOU_DECLARATION
   * @description - get the user mou partnership declarations by partner_id and the innovation_id
   */
  async GET_MOU_DECLARATION({ commit }, payload): Promise<void> {
    const { partnerId, innovationId } = payload;
    try {
      const { data } = await Request(
        "GET",
        `partnership_declarations/?partner=${partnerId}&innovation=${innovationId}`
      );
      commit("setSignedDeclarationForm", data.data[0]);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(error || details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_PARTNERS_BY_INNOVATOR
   * @description - get a list of partner by innovator_id
   */
  async GET_PARTNERS_BY_INNOVATOR({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl}${payload}/partners/`);
      commit("setPartnerByInnovator", data);
      return data;
    } catch (error: any) {
      // const { details } = error.response.data;
      // Toast.error(error || details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_PARTNERS_BY_INNOVATION
   * @description - get a list of partners by innovation id
   */
  async GET_PARTNERS_BY_INNOVATION({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request("GET", `${localUrl2}${payload}/partners/`);
      commit("setPartnerByInnovationId", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function CREATE_MILESTONE
   * @description - create a milestone
   */
  async CREATE_MILESTONE(context, payload): Promise<void> {
    try {
      await Request("POST", "milestones/", payload);
      Toast.success("Milestone successfully created");
      return;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_MILESTONE_BY_INNOVATION_ID
   * @description - Get a list of milestone
   */
  async GET_MILESTONE_BY_INNOVATION_ID({ commit }, payload): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `${localUrl2}${payload}/milestones/`
      );
      commit("setMileStones", data);
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function POST_INNOVATION_MESSAGE
   * @description - Get a list of milestone
   */
  async POST_INNOVATION_MESSAGE(context, payload): Promise<void> {
    try {
      await Request("POST", "innovation_messages/", payload);
      // return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
  /**
   * @function GET_INNOVATION_MESSAGE
   * @description - Get a list of milestone
   */
  async GET_INNOVATION_MESSAGE(context, payload): Promise<void> {
    const { innovationId, partnerId } = payload;
    try {
      const { data } = await Request(
        "GET",
        `innovation_messages/?innovation=${innovationId}&partner=${partnerId}`,
        payload
      );
      return data;
    } catch (error: any) {
      // Toast.error(error.response.data.details);
      // throw error;
      Toast.error(error);
      throw error;
    }
  },
};

export default actions;
