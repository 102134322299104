
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import MultiSelect from "primevue/multiselect";
import Toast from "@/utils/Toast";
import { selectType } from "@/types/Select";

export default defineComponent({
  name: "EditNeedsForm",
  components: { MultiSelect },
  data() {
    return {
      id: "createNeed",
      needId: "",
      dataForm: {},
      form: {
        firstname: "",
        lastname: "",
        middlename: "",
        employeeNumber: "",
        email: "",
        address: "",
        healthFacility: "",
        supervisorName: "",
        supervisorEmail: "",
        title: "",
        healthChallenge: "",
        solutionDescription: "",
        selectedProgramArea: "",
        specificProgramArea: [] as selectType[],
        targetUser: "",
        benefits: "",
        file: "",
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      option: [] as any,
      programAreas: [] as selectType[],

      loading: false,
      loadingForm: false,
      confirm: false,
      sent: false,

      btnStyle:
        "btn-primary rounded-0 border-white border-3 py-2 px-lg-5 px-3 mx-2",
      toolTip1: {
        icon: "question-circle-fill",
        message: "Every health need is to be approved by a health facility",
      },
      toolTip2: {
        icon: "question-circle-fill",
        message:
          "A supervisor not lower than a level 13 officer must approve the health need",
      },
      toolTip3: {
        icon: "question-circle-fill",
        message: `Every health need must have the following; 
          1. Listed goals to be met 
          2. Detailed scope of the need
          3. Detailed description on extent and origination of the need`,
      },
    };
  },
  computed: {
    ...mapGetters({
      tagDataValue: "getTags",
    }),
    ...mapGetters("INNOVATION_STORE", {
      healthNeed: "getHealthNeed",
    }),
  },
  methods: {
    ...mapActions(["GET_TAGS"]),
    ...mapActions("INNOVATION_STORE", ["EDIT_HEALTH_NEED", "GET_HEALTH_NEED"]),
    async getData() {
      await this.GET_TAGS();
      this.programAreas = this.tagDataValue.map((item: selectType) => {
        return {
          name: item.name,
          id: item.id,
        };
      }) as selectType[];
      this.dataForm = this.healthNeed;
    },
    openToggle() {
      this.confirm = true;
    },
    closeToggle() {
      this.confirm = false;
    },
    closeToggle2() {
      this.sent = false;
      this.$router.push(`/mda/approve-needs?id=${this.needId}`);
    },
    // Handle cancel
    handleCancel() {
      // router.push(`/priority-needs/edit-need?id=${id.value}`);
      this.$router.push(`/mda/approve-needs?id=${this.needId}`);
    },
    // FORM VALIDATION
    formValidation() {
      const form = this.$refs.createNeed as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        if (v === "" || JSON.stringify(v) === "[]" || v === undefined) {
          if (k !== "file" && k !== "middlename") {
            const input = form.querySelector(`[name=${k}]`);
            Error.push(k);
            input?.classList.add("is-invalid");
            input?.classList.add("p-invalid");
          }
        } else {
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.remove("p-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          `Please fill out all the required fields, ${Error.join(
            ", "
          )} are missing`
        );
        this.closeToggle();
        return false;
      }
      // this.openToggle();
      return true;
    },
    // HANDLE SUBMIT FUNCTION
    async onSubmit() {
      if (this.formValidation() === false) {
        return;
      }
      this.loading = true;
      //   ======================
      const formData = new FormData();
      formData.append("author_first_name", this.form.firstname);
      formData.append("author_last_name", this.form.lastname);
      if (this.form.middlename !== "") {
        formData.append("author_middle_name", this.form.middlename);
      }
      formData.append("author_employee_number", this.form.employeeNumber);
      formData.append("author_email", this.form.email);
      formData.append("author_address", this.form.address);
      formData.append("supervisor_facility", this.form.healthFacility);
      formData.append("supervisor_name", this.form.supervisorName);
      formData.append("supervisor_email", this.form.supervisorEmail);
      formData.append("title", this.form.title);
      formData.append("description", this.form.healthChallenge);
      formData.append("requirements", this.form.solutionDescription);
      formData.append("program_areas", this.form.selectedProgramArea);
      // if (Array.isArray(this.form.specificProgramArea)) {
      //   // this.form.specificProgramArea?.map((el: selectType) => {
      //   //   formData.append("specific_program_areas", JSON.stringify(el.id));
      //   // });
      //   // formData.append(
      //   //   "specific_program_areas",
      //   //   this.form.specificProgramArea
      //   // );
      // }
      if (this.form.specificProgramArea?.length > 0) {
        formData.append(
          "specific_program_areas",
          JSON.stringify(this.form.specificProgramArea)
        );
      }

      formData.append("target_users", this.form.targetUser);
      formData.append("benefits", this.form.benefits);
      if (this.form.file && this.form.file.length > 0) {
        Array.from(this.form.file).forEach((file) => {
          formData.append("files", file);
        });
      } else if (this.form.file && Object.keys(this.form.file).length > 0) {
        formData.append("files", this.form.file);
      }
      //   ======================

      try {
        // this.closeToggle();
        await this.EDIT_HEALTH_NEED({ id: this.needId, data: formData });
        this.sent = true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.createNeed as any).reset();
        this.form.specificProgramArea = [] as selectType[];
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    // function to fill data from the api
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async setEditFormData(data: any) {
      this.form.firstname = data.author_first_name;
      this.form.lastname = data.author_last_name;
      this.form.middlename = data.author_middle_name;
      this.form.employeeNumber = data.author_employee_number;
      this.form.email = data.author_email;
      this.form.address = data.author_address;
      this.form.healthFacility = data.supervisor_facility;
      this.form.supervisorName = data.supervisor_name;
      this.form.supervisorEmail = data.supervisor_email;
      this.form.title = data.title;
      this.form.healthChallenge = data.description;
      this.form.solutionDescription = data.requirements;
      this.form.targetUser = data.target_users;
      this.form.benefits = data.benefits;
      this.form.selectedProgramArea = data.program_areas[0]?.id;
      this.form.specificProgramArea = data.specific_program_areas.map(
        (item: { [key: string]: number | string }) => {
          return item.id;
        }
      );
      this.option = data.program_areas[0].sub_tags;
      this.form.file = data.document_upload;
    },
    async getEditFormData() {
      this.needId = this.$route.query.id as string;
      if (Object.keys(this.dataForm).length > 0) {
        await this.setEditFormData(this.healthNeed);
      } else {
        await this.GET_HEALTH_NEED(this.needId);
        await this.setEditFormData(this.healthNeed);
      }
    },
  },
  async mounted() {
    this.loadingForm = true;
    await this.getData();
    await this.getEditFormData();
    this.loadingForm = false;
  },
});
