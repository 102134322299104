import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 informatics_dashboard" }
const _hoisted_2 = { class: "container-lg container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_SideNav, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sideBar = false)),
      showSideBar: _ctx.sideBar,
      showMiniSideBar: _ctx.miniSideBar,
      Navigation: _ctx.SideNavigation,
      onMiniSideToggle: _cache[1] || (_cache[1] = ($event: any) => (_ctx.miniSideBar = !_ctx.miniSideBar))
    }, null, 8, ["showSideBar", "showMiniSideBar", "Navigation"]),
    _createVNode(_component_Header, {
      onToggle: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sideBar = !_ctx.sideBar))
    }),
    _createElementVNode("main", {
      class: _normalizeClass(["content", {
        mini_sidebar: _ctx.miniSideBar,
        overlay: _ctx.sideBar,
      }]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sideBar = false))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              name: route.meta?.transition || 'slide-fade',
              duration: { enter: 300, leave: 500 },
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                }))
              ]),
              _: 2
            }, 1032, ["name"])
          ]),
          _: 1
        })
      ])
    ], 2)
  ]))
}