import Role from "@/utils/Role";
import Index from "../views/index.vue";
import Dashboard from "../views/dashboard/index.vue";
import Countries from "../views/countries/index.vue";
import Tracking from "../views/tracking/index.vue";
import Partners from "../views/partners/index.vue";

const routes = [
  {
    path: "/admin",
    name: "Who-ADMIN",
    component: Index,
    meta: { requiresAuth: true, guest: false, authorize: [Role.admin] },
    children: [
      {
        path: "",
        alias: "dashboard",
        name: "Who-Dashboard",
        component: Dashboard,
      },
      {
        path: "countries",
        name: "Who-Countries",
        component: Countries,
      },
      {
        path: "tracking",
        name: "Who-Tracking",
        component: Tracking,
      },
      {
        path: "partners",
        name: "Who-Partners",
        component: Partners,
      },
    ],
  },
];

export default routes;
