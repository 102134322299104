/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import Request from "@/utils/Request";
import Toast from "@/utils/Toast";
import { state } from "./types";

const actions: ActionTree<state, unknown> = {
  /**
   * @function GET_TAGS - function to get all tags
   */
  async GET_TAGS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", "tags/");
      commit("setTags", data);
      return data;
    } catch (error: any) {
      const { details } = error?.response?.data;
      Toast.error(details || error);
      return;
    }
  },
  /**
   * @function PLATFORMS_ANALYTICS
   */
  async GET_PLATFORMS_ANALYTICS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", "analytics/");
      commit("setAnalytics", data);
      return data;
    } catch (error: any) {
      const { details } = error?.response?.data;
      Toast.error(details || error);
      return;
    }
  },
  /**
   * @function PLATFORMS_ANALYTICS
   */
  async GET_PLATFORMS_ANALYTICS_BY_PERIOD({ commit }, payload): Promise<void> {
    const { year, month } = payload;
    try {
      const { data } = await Request(
        "GET",
        `analytics/?year=${year}&month=${month}`
      );
      commit("setAnalytics", data);
      return data;
    } catch (error: any) {
      const { details } = error?.response?.data;
      Toast.error(details || error);
      return;
    }
  },
  /**
   * @function GET_ALL_PLATFORMS_ANALYTICS
   */
  async GET_ALL_PLATFORMS_ANALYTICS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", "analytics/all/");
      commit("setAnalytics", data);
      return data;
    } catch (error: any) {
      const { details } = error?.response?.data;
      Toast.error(details || error);
      return;
    }
  },
  /**
   * @function GET_INNOVATION_WITH_PARTNERS
   */
  async GET_INNOVATION_WITH_PARTNERS({ commit }): Promise<void> {
    try {
      const { data } = await Request("GET", `innovations/with_partners_names/`);
      commit("setWithPartners", data);
      return data;
    } catch (error: any) {
      const { details } = error?.response?.data;
      Toast.error(details || error);
      return;
    }
  },
  /**
   * GET_VIEW_PARTNERSHIP_DISTRIBUTIONS
   */
  async GET_VIEW_PARTNERSHIP_DISTRIBUTIONS({ commit }): Promise<void> {
    try {
      const { data } = await Request(
        "GET",
        `innovations/view_partnership_distributions/`
      );
      commit("setViewPartnerShipDist", data);
      return data;
    } catch (error: any) {
      const { details } = error?.response?.data;
      Toast.error(details || error);
      return;
    }
  },
};

export default actions;
