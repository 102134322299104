
import { defineComponent } from "vue";
import CreateNeeds from "@/modules/innovation/components/form/SubmitNeedForm.vue";
import List from "@/components/Dashboard/List.vue";
import chartOptions from "../../components/highChart";
import { programAreaProp } from "@/props-config";

export default defineComponent({
  name: "Create-Needs",
  components: {
    CreateNeeds,
    List,
  },
  data() {
    return {
      programAreaSelect: "",
      programAreaProp,
      data: [
        {
          id: 1,
          title: "HIV prevalence in Mothers",
          time: "32/02/9999",
        },
        {
          id: 2,
          title: "HIV prevalence in Mothers",
          time: "32/02/9999",
        },
      ],
      chartOptions,
    };
  },
});
