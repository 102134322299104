import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04acbb4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "w-100"
}
const _hoisted_2 = { class: "container-lg container-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_SideNav = _resolveComponent("SideNav")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_LoadingOverlay, { key: 0 }))
    : (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createVNode(_component_SideNav, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sideBar = false)),
          showSideBar: _ctx.sideBar,
          Navigation: _ctx.SideNavigation
        }, null, 8, ["showSideBar", "Navigation"]),
        _createVNode(_component_Header, {
          onToggle: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sideBar = !_ctx.sideBar))
        }),
        _createElementVNode("main", {
          class: _normalizeClass(["content", { overlay: _ctx.sideBar }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sideBar = false))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view)
          ])
        ], 2)
      ]))
}