import Index from "./index.vue";
import Home from "./Home.vue";
import Description from "@/modules/innovator/views/home/Description.vue";

export default [
  {
    path: "",
    name: "MdaIndex",
    component: Index,
    children: [
      {
        path: "",
        alias: "home",
        name: "Mda-Home",
        component: Home,
      },
      {
        path: ":slug",
        name: "Priority Needs Description",
        component: Description,
      },
    ],
  },
];
