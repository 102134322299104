
import { defineComponent, PropType } from "vue";
import { cardProgressData, bodyProgressData } from "./propConfig";

export default defineComponent({
  name: "Card-Progress",
  props: {
    body: {
      type: Object as PropType<bodyProgressData>,
      required: true,
    },
    card: {
      type: Object as PropType<cardProgressData>,
      required: true,
    },
    buttonRoute: {
      type: String,
      required: true,
    },
  },
});
