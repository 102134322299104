
import { defineComponent } from "vue";
import Steps from "primevue/steps";
import { mapGetters } from "vuex";
import MouForm from "../../components/Form/MouForm.vue";
import LoginModal from "../../components/LoginModal.vue";

export default defineComponent({
  name: "Innovator-Step",
  components: {
    Steps,
    MouForm,
    LoginModal,
  },
  data() {
    return {
      activeTitle: 1,
      showLogin: true,
      items: [
        {
          title: 1,
          label: "Innovator Partner MOU terms",
          to: "/innovator/my-innovation/mou-details",
        },
        {
          title: 2,
          label: "Scaling Milestones Road Map",
          to: "/innovator/my-innovation/innovator-partner-mou",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("AUTH_STORE", {
      authStatus: "isAuthenticated",
    }),
  },
  methods: {
    toggleActive(title: number) {
      console.log(title);
      this.activeTitle = title;
    },
  },
});
