import { MutationTree } from "vuex";
import { state, innovationType } from "./types";
import { program_areas } from "@/types";

const mutations: MutationTree<state> = {
  // innovator
  innovators: (state, data): void => {
    state.innovators = [];
    state.innovators = data;
  },
  innovator: (state, data): void => {
    state.innovator = {};
    state.innovator = data;
  },
  innovations: (state, data): void => {
    state.innovations = [];
    state.innovations = data;
    // get the first four innovation and store it in fourInnovations state
    const cloneData = data.slice(0);
    state.fourInnovations = [];
    state.fourInnovations = cloneData.splice(0, 4);
  },
  innovation: (state, data): void => {
    state.innovation = {} as innovationType;
    state.innovation = data;
  },
  innovationsByProgramArea: (state, name: string) => {
    state.innovationsByProgramArea = [];
    const data = [] as innovationType[];
    if (name === "All") {
      state.innovationsByProgramArea = state.innovations;
      return;
    }
    state.innovations.forEach((item: innovationType) =>
      item?.need?.program_areas.forEach((itemArea: program_areas) => {
        if (itemArea && itemArea.name.toLowerCase() === name.toLowerCase()) {
          data.push(item);
        }
      })
    );
    state.innovationsByProgramArea = data.length === 0 ? [] : data;
  },
  cloneInnovationsByProgramArea: (state, name: string) => {
    state.cloneInnovationsByProgramArea = [];
    const data = [] as innovationType[];
    if (name === "All") {
      state.cloneInnovationsByProgramArea = state.fourInnovations;
      return;
    }
    state.fourInnovations.forEach((item: innovationType) =>
      item?.need?.program_areas.forEach((itemArea: program_areas) => {
        if (itemArea && itemArea.name.toLowerCase() === name.toLowerCase()) {
          data.push(item);
        }
      })
    );
    state.cloneInnovationsByProgramArea = data.length === 0 ? [] : data;
  },

  myInnovations: (state, data): void => {
    state.myInnovations = [];
    state.myInnovations = data;
  },
  myInnovation: (state, id: string): void => {
    state.myInnovation = {} as innovationType;
    state.myInnovation = state.myInnovations.filter(
      (item: innovationType) => item.id === id
    )[0];
  },
  innovationMilestones: (state, data): void => {
    state.innovationMilestones = [];
    state.innovationMilestones = data;
  },
  innovationTimeline: (state, data): void => {
    state.innovationTimeline = [];
    state.innovationTimeline = data;
  },
  innovationPartnerList: (state, data): void => {
    state.innovationPartnerList = [];
    state.innovationPartnerList = data;
  },
  /**
   * @description: store the data for the innovation-partner-mou
   * @returns {object} state.innovatorPartnerMou
   */
  setInnovatorPartnerMou: (state, data): void => {
    state.innovatorPartnerMou = {} as string | string[] | null;
    state.innovatorPartnerMou = data;
  },
  // =============================== INNOVATION MULTI PAGE FORM
  // Update the page progress number
  UPDATE_PAGE: (state, data: number): void => {
    state.page = data;
  },
  // Update the page progress bar
  UPDATE_PROGRESS: (state, data: number): void => {
    state.pageProgress = data;
  },
  /**
   * Get the innovation proposal from the 1st page
   */
  SAVE_INNOVATION1: (state, data): void => {
    state.innovationProposal1 = data;
  },
  /**
   * Get the innovation proposal form the 2nd page
   */
  SAVE_INNOVATION2: (state, data): void => {
    state.innovationProposal2 = data;
  },
  /**
   * Create the merged innovation form from page 1 and page 2
   */
  CREATE_PROPOSAL: (state): void => {
    state.innovationProposal = {} as FormData;
    // combine innovationProposal1 and innovationProposal2 into proposal
    for (const item of state.innovationProposal2.entries()) {
      state.innovationProposal1.append(item[0], item[1]);
    }
    state.innovationProposal = state.innovationProposal1;
  },
  setSignedDeclarationForm: (state, data): void => {
    state.signedDeclarationForm = {};
    state.signedDeclarationForm = data;
  },
  setMouDeclarationForm: (state, data): void => {
    state.partnerDeclarationForm = {};
    state.partnerDeclarationForm = data;
  },
  setRelevantDocument: (state, data): void => {
    state.relevantDocument = [];
    state.relevantDocument = data;
  },
  setPartnerByInnovator: (state, data): void => {
    state.partnersByInnovator = [];
    state.partnersByInnovator = data;
  },
  setPartnerByInnovationId: (state, data): void => {
    state.partnersByInnovationId = [];
    state.partnersByInnovationId = data;
  },
  setMileStones: (state, data): void => {
    state.milestones = [];
    state.milestones = data;
  },
};

export default mutations;
