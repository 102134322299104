
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["buttonClick"],
  props: {
    customStyle: {
      required: true,
      type: String,
      default: "btn",
    },
  },
  setup() {
    return {};
  },
});
