
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import Toast from "@/utils/Toast";

export default defineComponent({
  name: "createProgramArea",
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({
        id: "",
        name: "",
        description: "",
      }),
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        description: "",
      },
    };
  },
  methods: {
    ...mapActions("INFORMATICS_STORE", [
      "CREATE_PROGRAM_AREA",
      "EDIT_PROGRAM_AREA",
    ]),
    async editTag() {
      try {
        this.loading = true;
        const { name, description } = this.form;
        if (name === "" || description === "") {
          Toast.error("Please fill all the fields");
          return;
        }
        await this.EDIT_PROGRAM_AREA({ id: this.data.id, form: this.form });
        this.$emit("closeEdit");
      } catch (error) {
        console.log({ error });
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.form.name = this.data.name;
    this.form.description = this.data.description;
  },
});
