
import { defineComponent } from "vue";
import Calendar from "primevue/calendar";
import AutoComplete from "primevue/autocomplete";
import countriesData from "@/data/Countries.json";
import { genders, termsProp } from "./InnovationRegistrationData";
import { mapActions } from "vuex";
import { findOutProp } from "./InnovationSubmissionProp";
import Toast from "@/utils/Toast";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

interface type {
  id: string;
  name: string;
}

export default defineComponent({
  name: "InnovatorRegForm",
  components: {
    Calendar,
    AutoComplete,
    TermsAndConditions,
  },
  props: {
    id: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      termsModal: false,
      loading: false,
      popUpLoading: false,
      countries: [] as Array<type>,
      selectedCountries: [] as Array<type>,
      genders: [] as type[],
      terms: false,
      maxDate: new Date(),
      form: {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        group: "INDIVIDUAL",
        findOut: "",
        firstname: "",
        middlename: "",
        lastname: "",
        passportNo: "",
        username: "",
        nationality: "" as any,
        countryOfResidence: "" as any,
        dob: "",
        gender: "" as any,
        email: "",
        password: "",
        confirm_password: "",
        landline: "",
        mobile: "",
        description: "",
        altFirstname: "",
        altMiddlename: "",
        altLastname: "",
        altEmail: "",
        altMobile: "",
      },
      termsProp,
      findOutProp,
      btnStyle: "btn-primary rounded-0 px-lg-5 px-3 py-2 mx-2",
    };
  },
  methods: {
    ...mapActions("AUTH_STORE", ["INVITE_INNOVATOR_IND"]),
    // function to filter country on keyup
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchCountry(event: any) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.selectedCountries = [...this.countries];
        } else {
          this.selectedCountries = this.countries.filter((country: type) => {
            return country.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    formValidation() {
      // check if every item in this.form is not empty else toast the appropriate error message
      const form = this.$refs.InviteInnovatorInd as HTMLFormElement;
      const Error = [];
      for (const [k, v] of Object.entries(this.form)) {
        if (
          v === "" ||
          v === false ||
          JSON.stringify(v) === "[]" ||
          JSON.stringify(v) === "{}" ||
          v === undefined
        ) {
          // do not validate if k === file && k === middlename
          if (
            k !== "middlename" &&
            k !== "landline" &&
            k !== "altFirstname" &&
            k !== "altMiddlename" &&
            k !== "altLastname" &&
            k !== "altEmail" &&
            k !== "altMobile" &&
            k !== "findOut"
          ) {
            Error.push(`${k} is required`);
          }
          // find the input that has not been filled in form and add the error class
          const input = form.querySelector(`[name=${k}]`);
          if (
            k !== "middlename" &&
            k !== "landline" &&
            k !== "altFirstname" &&
            k !== "altMiddlename" &&
            k !== "altLastname" &&
            k !== "altEmail" &&
            k !== "altMobile" &&
            k !== "findOut"
          ) {
            input?.classList.add("is-invalid");
          }
        } else {
          // find the input that has been filled in form and remove the error class
          const input = form.querySelector(`[name=${k}]`);
          input?.classList.remove("is-invalid");
          input?.classList.add("is-valid");
        }
      }
      if (Error.length > 0) {
        Toast.warning(
          // "Please fill out all the required fields",
          Error.join(",\r\n ")
        );
        return false;
      }
      return true;
    },

    async onSubmit() {
      const formDetail = this.$refs.InviteInnovatorInd as HTMLFormElement;
      if (this.formValidation() === false) {
        return;
      }
      if (this.terms === false) {
        Toast.warning("Please accept the terms and conditions");
        return;
      }
      if (this.form.password !== this.form.confirm_password) {
        Toast.warning("Password and Confirm Password do not match");
        return;
      }
      if (this.form.password.length < 8) {
        Toast.warning("Password must be at least 8 characters long");
        return;
      }
      const data = {
        username: this.form.username.toLowerCase() || null,
        first_name: this.form.firstname,
        middle_name: this.form.middlename || null,
        last_name: this.form.lastname,
        email: this.form.email.toLowerCase(),
        mobile: this.form.mobile,
        landline: this.form.landline || null,
        password: this.form.password,
        type: this.form.group,
        passport_number: this.form.passportNo,
        nationality: this.form.nationality?.name || this.form.nationality,
        country_of_residence:
          this.form.countryOfResidence?.name || this.form.countryOfResidence,
        date_of_birth: this.form.dob,
        last_viewed_need: this.id?.id || null,
        gender: this.form.gender?.id,
        short_description: this.form.description,
        alternate_contact_first_name: this.form.altFirstname || null,
        alternate_contact_middle_name: this.form.altMiddlename || null,
        alternate_contact_last_name: this.form.altLastname || null,
        alternate_contact_email: this.form.altEmail || null,
        alternate_contact_mobile: this.form.altMobile || null,
        discovery_means: this.form.findOut || null,
      };
      try {
        this.loading = true;
        await this.INVITE_INNOVATOR_IND(data);
        formDetail.reset();
        setTimeout(() => {
          this.$router.push("/submit-proposal/call-for-proposal");
        }, 3000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.countries = countriesData.data;
    this.genders = genders;
  },
  created() {
    let today = new Date();
    let year = today.getFullYear();
    const yearData = year - 16;
    this.maxDate = new Date();
    this.maxDate.setFullYear(yearData);
  },
});
