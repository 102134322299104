import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardListDate = _resolveComponent("CardListDate")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Datas, (data) => {
        return (_openBlock(), _createBlock(_component_CardListDate, {
          key: data.title,
          body: data,
          card: _ctx.cardStyle,
          route: `/mda-dashboard/home/${data.title}`
        }, null, 8, ["body", "card", "route"]))
      }), 128))
    ])
  ]))
}