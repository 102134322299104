
import { defineComponent } from "vue";
import Stepper from "./Stepper.vue";
import Step1 from "../../components/Form/ApplicationProcess/Step1.vue";

export default defineComponent({
  name: "ApplicationProcess",
  components: { Step1, Stepper },
  data() {
    return {};
  },
  methods: {},
});
