
import { defineComponent } from "vue";
import { programAreaProp } from "@/props-config";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import { filterResult } from "@/modules/health-directorate/views/need-draft/Types";
import Invite from "./Invite.vue";

interface partnersData {
  id: string;
  username: string;
  email: string;
  department: string;
}

export default defineComponent({
  name: "ViewPartners",
  components: {
    DataTable,
    Column,
    Invite,
  },
  data() {
    return {
      count: 0,
      loading: false,
      filters: {} as filterResult,
      archivePartnerDialog: false,
      partnerDialog: false,
      inviteDialog: false,
      title: "Partners",
      programAreaProp,
      partners: [] as Array<partnersData>,
      partner: {} as partnersData,
    };
  },
  methods: {
    async getPartners() {
      this.loading = true;
      await this.$store.dispatch("PARTNER_STORE/GET_PARTNERS");
      this.partners = this.$store.getters["PARTNER_STORE/getPartners"];
      this.loading = false;
    },
    confirmArchivePartner(partner: partnersData) {
      this.partner = partner;
      this.archivePartnerDialog = true;
    },
    archivePartner() {
      this.partners = this.partners.filter((val) => val.id !== this.partner.id);
      this.archivePartnerDialog = false;
      this.partner = {} as partnersData;
      //   this.$toast.add({
      //     severity: "success",
      //     summary: "Successful",
      //     detail: "partner Archived",
      //     life: 3000,
      //   });
    },
    async getPartner(id: string) {
      this.loading = true;
      await this.$store.dispatch("PARTNER_STORE/GET_PARTNER", id);
      this.partner = this.$store.getters["PARTNER_STORE/getPartner"];
      this.partnerDialog = true;
      this.loading = false;
    },
    clearFilter() {
      this.initFilters();
    },
    initFilters() {
      this.filters = {
        global: { value: "", matchMode: FilterMatchMode.CONTAINS },
        username: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
        email: {
          operator: FilterOperator.AND,
          constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
        },
      };
    },
    addPartner() {
      this.inviteDialog = true;
    },
  },
  async mounted() {
    this.getPartners();
  },
  created() {
    this.initFilters();
  },
});
