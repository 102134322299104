<template>
  <div class="card card-body border-0 table-responsive">
    <table class="table table-hover align-top text-primary">
      <thead>
        <tr class="text-uppercase text-start fs-6 d-none">
          <th scope="col" v-for="item in header" :key="item">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id" :id="item.id">
          <td style="width: 25%">
            {{ item.name }}
          </td>
          <td style="width: 25%">{{ item.application }}</td>
          <td style="width: 25%">{{ item.date }}</td>

          <td :id="`status-${item.id}`" class="w-25">
            <span v-for="(color, index) in item.progress" :key="index">
              <span class="badge dash" :class="[item.status]">&nbsp;</span>
            </span>
          </td>

          <td @click="display(item.id)" class="d-flex feat py-5">
            <span :id="`icon-${item.id}`" class="icon bi-gear"
              >&ensp;&ensp;</span
            >
            <div :id="`more-${item.id}`" class="features">
              <span class="icon bi-pen-fill text-primary"
                >&ensp;edit&ensp;&ensp;</span
              >
              <span class="icon bi-cloud-arrow-down-fill text-primary"
                >&ensp;download&ensp;&ensp;</span
              >
              <span class="icon bi-trash-fill text-danger"
                >&ensp;delete&ensp;&ensp;</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Table",
  props:{
      data: {
        type: Array,
        required: true,
      },
      header:{
        type: Array,
        required: true,
        default() { return ["S/N", "Data", "Options"]},
      }
  },
  data() {
    return {};
  },
  methods: {
    display(id) {
      if(id){
      let status = document.getElementById(`status-${id}`);
      let icon = document.getElementById(`icon-${id}`);
      let more = document.getElementById(`more-${id}`);
      icon?.classList.toggle("reveal");
      more?.classList.toggle("reveal-features");
      status?.classList.toggle("hide-status");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
table {
  font: normal normal 0.9rem Segoe UI;
  letter-spacing: 0px;
  cursor: pointer;
  tr {
    // padding: 1rem 0;
    vertical-align: middle;
    height: inherit;
    th {
      padding-left: 0;
      padding-right: 0;
    }
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: max-content;
      word-break: keep-all;
      word-wrap: inherit;
    }
  }
  .dash {
    width: 17px;
    height: 5px;
    margin: auto 0.2rem;
    cursor: pointer;
  }
  .submitted {
    background: #d6c90a;
  }
  .approved {
    background: #2c9e0b 0% 0% no-repeat padding-box;
  }
  .rejected {
    background: #9e0b15 0% 0% no-repeat padding-box;
  }
  // hide status data
  .hide-status {
    // transition: all 0.6s ease-in-out;
    position: absolute;
    z-index: -2;
  }
  // td for the settings section
  .feat {
    // transition: all 0.6s ease-in-out;
    z-index: 1;
  }
  //manipulate settings icon
  .icon {
    cursor: pointer;
    // transition: all 0.6s ease-in-out;
  }
  .reveal {
    // transition: all 0.6s ease-in-out;
    z-index: 1;
  }
  .features {
    // transition: all 0.4s ease-in-out;
    position: absolute;
    left: -99rem;
  }
  .reveal-features {
    // transition: all 0.4s ease-in-out;
    position: static;
    // left: 0;
    z-index: 1;
  }
}
</style>
