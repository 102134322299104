
import { defineComponent, PropType } from "vue";

interface welcomeDetailType {
  image: string;
  block?: string;
  title: string;
  titleStyling: string;
  subtitle?: string;
  subtitleStyling?: string;
  body?: string;
  bodyStyling?: string;
  buttonText?: string;
  buttonLink?: string;
  buttonStyle?: string;
}

export default defineComponent({
  name: "WelcomeScreen",
  props: {
    welcomeDetail: {
      type: Object as PropType<welcomeDetailType>,
      required: true,
    },
    link: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      socialIcon: false,
    };
  },
  mounted() {
    if (this.$route.path === "/") {
      this.socialIcon = true;
    }
  },
});
