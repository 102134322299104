import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-lg container-fluid py-3" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "p-text-center" }
const _hoisted_4 = { class: "p-text-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_DataTable, {
        responsiveLayout: "scroll",
        breakpoint: "700px",
        class: "",
        scrollable: true,
        value: _ctx.body,
        paginator: true,
        loading: _ctx.loading,
        rows: 10,
        stripedRows: "",
        selectionMode: "single",
        scrollHeight: "flex",
        columnResizeMode: "fit"
      }, {
        empty: _withCtx(() => [
          _createElementVNode("h6", _hoisted_3, "No " + _toDisplayString(_ctx.title) + " found.", 1)
        ]),
        loading: _withCtx(() => [
          _createElementVNode("h6", _hoisted_4, "Loading " + _toDisplayString(_ctx.title) + " data. Please wait.", 1)
        ]),
        footer: _withCtx(() => [
          (_ctx.body.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " In total there " + _toDisplayString(_ctx.body && _ctx.body.length === 1 ? "is" : "are") + " " + _toDisplayString(_ctx.body ? _ctx.body.length : 0) + " " + _toDisplayString(_ctx.title) + ". ", 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6))
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Header, (col, index) => {
            return (_openBlock(), _createBlock(_component_Column, {
              key: index,
              field: col.field,
              header: col.header,
              sortable: col.sortable,
              frozen: col.frozen
            }, null, 8, ["field", "header", "sortable", "frozen"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value", "loading"])
    ])
  ]))
}