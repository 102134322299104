
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseSearch",
  emits: ["search"],
  setup() {
    /**
     * Typescript Definition for checkbox
     * @param {e} event
     * @returns {boolean}
     */
    const handleCheck = (e: Event) => (e.target as HTMLInputElement).checked;
    return { handleCheck };
  },
});
