
import { defineComponent } from "vue";
import HealthDirectorateInviteForm from "@/modules/auth/components/invites/SetupHealth-Directorates.vue";

export default defineComponent({
  name: "HDSetup",
  components: {
    HealthDirectorateInviteForm,
  },
  data() {
    return {
      /**
       * Details and styling for the welcome section of the Registration page
       */
      registrationWelcome: {
        block: "block",
        image: "banner-2.png",
        title: "",
        subtitle: "",
        body: "",
        imageStyling: "",
        titleStyling: "text-capitalize fs-1 mb-4",
        subtitleStyling: "text-white text-capitalize",
        bodyStyling: "body-login py-4",
        buttonStyle: "",
        buttonLink: "",
        buttonText: "",
      },
      isLoaded: false,
    };
  },
  methods: {
    onImgLoad() {
      this.isLoaded = true;
    },
  },
});
