import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd24c7de"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "timeline_wrapper shadow p-3 my-3"
}
const _hoisted_2 = { class: "left_section" }
const _hoisted_3 = { class: "right_section" }
const _hoisted_4 = {
  key: 1,
  class: "timeline_wrapper shadow-sm p-3 my-3"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "left_section" }
const _hoisted_7 = { class: "right_section" }
const _hoisted_8 = {
  key: 1,
  class: "no_data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass({ full: _ctx.size })
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (el, i) => {
            return _createElementVNode("div", {
              key: i,
              class: "timeline_content"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Skeleton, {
                  width: "100%",
                  style: {"height":"2rem"},
                  class: "mb-2"
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Skeleton, {
                  width: "60%",
                  style: {"height":"2rem"},
                  class: "mb-2"
                }),
                _createVNode(_component_Skeleton, {
                  width: "90%",
                  style: {"height":"2rem"},
                  class: "mb-2"
                }),
                _createVNode(_component_Skeleton, {
                  width: "50%",
                  style: {"height":"2rem"},
                  class: "mb-2"
                })
              ])
            ])
          }), 64))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.data.length >= 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (el, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: i,
                    class: "timeline_content"
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h5", null, _toDisplayString(_ctx.getDateFormat(el?.created_at)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h5", null, _toDisplayString(el.name), 1),
                      _createElementVNode("p", null, _toDisplayString(el.deliverables), 1),
                      _createElementVNode("p", null, _toDisplayString(el.partner?.first_name) + " " + _toDisplayString(el.partner?.last_name), 1)
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.data.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, "No Milestone!"))
            : _createCommentVNode("", true)
        ]))
  ], 2))
}