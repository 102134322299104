
import { defineComponent } from "vue";
import { mapActions } from "vuex";
import TimeLine from "@/components/Timeline.vue";
import { milestoneDetail } from "@/types";
import SubBar from "../../components/SubBar.vue";

export default defineComponent({
  name: "MyPartnershipTimeline",
  components: {
    TimeLine,
    SubBar,
  },
  data() {
    return {
      innovationId: "",
      loading: false,
      data: [] as milestoneDetail[],
    };
  },
  methods: {
    ...mapActions("INNOVATOR_STORE", ["GET_MILESTONE_BY_INNOVATION_ID"]),
    async getData() {
      this.loading = true;
      try {
        this.data = await this.GET_MILESTONE_BY_INNOVATION_ID(
          this.innovationId
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.innovationId =
      (this.$route.query.id as string) ||
      (localStorage.getItem("partner_innovationId") as string);

    if (this.innovationId === "") {
      this.$router.push(`/partner/my-partnerships`);
      return;
    }
    this.getData();
  },
});
