import Role from "@/utils/Role";
// import View from "../views/home/View.vue";
// =======
import Index from "../views/index.vue";
import Home from "../views/home/index.vue";
import ProgCoordHealthNeeds from "../views/health-needs/index.vue";
import ProgCoordInnovations from "../views/innovations/index.vue";
import EvaluateNeed from "../views/EvaluateHealthNeed.vue";
import EvaluateInnovation from "../views/EvaluateInnovation.vue";

const routes = [
  {
    path: "/program-coordinators",
    name: "ProgramCord",
    component: Index,
    meta: { requiresAuth: true, guest: false, authorize: [Role.programCord] },
    children: [
      {
        path: "",
        alias: "home",
        name: "ProgramCoord",
        component: Home,
      },
      {
        path: "health_needs",
        name: "ProgCoordHealthNeeds",
        component: ProgCoordHealthNeeds,
      },
      {
        path: "innovations",
        name: "ProgCoordInnovations",
        component: ProgCoordInnovations,
      },
    ],
  },
  {
    path: "/program-coordinators/evaluate-need",
    name: "EvaluateNeed",
    component: EvaluateNeed,
    meta: { requiresAuth: false, guest: true },
  },
  {
    path: "/program-coordinators/evaluate-innovation",
    name: "EvaluateInnovation",
    component: EvaluateInnovation,
    meta: { requiresAuth: false, guest: true },
  },
];

export default routes;
