
import { defineComponent } from "vue";
import Header from "@/modules/innovation/components/layout/header.vue";
import Footer from "@/modules/innovation/components/layout/footer.vue";
import Form from "../components/Form/InnovatorReg.vue";
import FormOrg from "../components/Form/InnovatorRegOrg.vue";
import { groups } from "../components/Form/InnovationRegistrationData";

interface type {
  id: string;
  name: string;
}

export default defineComponent({
  name: "InnovatorRegistration",
  components: {
    Individual: Form,
    Organization: FormOrg,
    Header,
    Footer,
  },
  data() {
    return {
      id: {},
      groups: [] as type[],
      group: {} as type,
      groupId: "INDIVIDUAL",
    };
  },
  watch: {
    group: {
      handler() {
        this.groupId = this.group?.id;
      },
      immediate: true,
    },
  },
  mounted() {
    this.id = this.$route.query;
    this.groups = groups;
  },
});
