import { Path } from "./Paths";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * Function to switch Dashboard-Url
 * @param {string} dashboardName
 * @returns {function} redirects to the specified dashboard
 */

import router from "@/router";

const switchDashboard = (dashboardName: string | undefined) => {
  switch (dashboardName) {
    case "Health Informatics Team":
      return router.push(Path.informaticsDashboard);
    case "Health Directorate":
      return router.push(Path.healthDirectorateDashboard);
    case "Health Innovator":
      return router.push(Path.innovatorDashboard);
    case "Health Partner":
      return router.push(Path.partnerDashboard);
    case "Program Coordinator":
      return router.push(Path.programCoordinatorDashboard);
    default:
      break;
  }
};

const switchDashboardByAccountType = (dashboardName: string | undefined) => {
  switch (dashboardName) {
    case "informatics_officials":
      return router.push(Path.informaticsDashboard);
    case "innovators":
      return router.push(Path.innovatorDashboard);
    case "partners":
      return router.push(Path.partnerDashboard);
    case "program_coordinators":
      return router.push(Path.programCoordinatorDashboard);
    default:
      break;
  }
};

const switchProfilePage = (type: string | undefined) => {
  switch (type) {
    case "partners":
      return router.push(Path.partnerProfile);
    case "innovators":
      return router.push(Path.innovatorProfile);
  }
};

const switchApiByUserType = (userType: string | undefined) => {
  switch (userType) {
    case "INFORMATICS":
      return Path.informaticsUrl;
    case "INNOVATORS":
      return Path.innovatorUrl;
    case "PARTNERS":
      return Path.partnerUrl;
    case "PROGRAM_COORDINATOR":
      return Path.programCoordinatorUrl;
    default:
      break;
  }
};

export {
  switchDashboard,
  switchDashboardByAccountType,
  switchProfilePage,
  switchApiByUserType,
};
