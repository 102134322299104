
import { defineComponent } from "vue";
import Form from "../../components/form/SubmitNeedForm.vue";

export default defineComponent({
  name: "SubmitPriorityNeeds",
  components: {
    SubmitHealthPriorityNeed: Form,
  },
});
