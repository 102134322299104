const cardStyle = {
  customCard: "border-hover my-3 border-0 card-bg",
  customTitle: "h5 mb-1",
  customBody: "",
  customId: "small",
  customDate: "",
};

const Datas = [
  {
    title: "Lorem Ipsum 1",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    id: "XDGEHTT7YTK_123DK",
    date: "12/05/2021",
    link: false,
  },
  {
    title: "Lorem Ipsum 2",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    id: "XDGEHTT7YTK_123DK",
    date: "12/05/2021",
    link: false,
  },
  {
    title: "Lorem Ipsum 3",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    id: "XDGEHTT7YTK_123DK",
    date: "12/05/2021",
    link: false,
  },
  {
    title: "Lorem Ipsum 4",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    id: "XDGEHTT7YTK_123DK",
    date: "12/05/2021",
    link: false,
  },
  {
    title: "Lorem Ipsum 5",
    body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    id: "XDGEHTT7YTK_123DK",
    date: "12/05/2021",
    link: false,
  },
];

export { cardStyle, Datas };
