
import { defineComponent } from "vue";
import { programAreaProp } from "@/props-config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default defineComponent({
  name: "Shortlisted",
  components: {
    DataTable,
    Column,
  },
  data() {
    return {
      title: "Shortlisted Proposals",
      loading: false,
      programAreaProp,
      body: [
        {
          submitted: "Development of innovation-scaling dahboard versioon 1",
          innovators: "Ehealth4everyone",
          stage: "ideation",
          file: "10",
          partners: 24,
        },
      ],
    };
  },
});
