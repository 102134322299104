
import { defineComponent } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { mapActions, mapGetters } from "vuex";

interface partnerListType {
  id: number;
  heathInnovation: string;
  partners: string;
}
export default defineComponent({
  name: "InnovationAndPartnerList",
  components: {
    DataTable,
    Column,
  },
  props: {},
  data() {
    return {
      selectedPartners: null,
      partnerList: [] as partnerListType[],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getWithPartners: "getWithPartners",
    }),
  },
  methods: {
    ...mapActions(["GET_INNOVATION_WITH_PARTNERS"]),
    async getInnovationData() {
      try {
        await this.GET_INNOVATION_WITH_PARTNERS();
        if (this.getWithPartners.length !== 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.getWithPartners.forEach((item: any, i: number) => {
            this.partnerList.push({
              id: i,
              heathInnovation: Object.keys(item).toString(),
              partners: Object.values(item).join(",").toString(),
            });
          });
        }
      } catch (error) {
        console.log(error);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.getInnovationData();
    this.loading = false;
  },
});
