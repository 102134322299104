const Route = [
  {
    name: "Home",
    path: "/monitoring/home",
    icon: "icon bi-columns-gap",
  },
  {
    name: "List",
    path: "/monitoring/list",
    icon: "icon bi-columns-workspace",
  },
  {
    name: "Active-Innovations",
    path: "/monitoring/list2",
    icon: "icon bi-columns-workspace",
  },
  // {
  //   name: "Informatics",
  //   path: "/informatics/view_officials",
  //   icon: "icon bi-person-workspace",
  // },
  // {
  //   name: "Health Directorate",
  //   path: "/informatics/directorates",
  //   icon: "icon bi-person-workspace",
  // },
  // {
  //   name: "Technical Directors",
  //   path: "/informatics/technical_directors",
  //   icon: "icon bi-back",
  // },
  // {
  //   name: "Partners",
  //   path: "/informatics/partners",
  //   icon: "icon bi-back",
  // },
  // {
  //   name: "Innovators",
  //   path: "/informatics/innovators",
  //   icon: "icon bi-back",
  // },
  // {
  //   name: "Health Need",
  //   path: "/informatics/health_needs",
  //   icon: "icon bi-file-text",
  // },
];

export default Route;
