import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check form-check-inline" }
const _hoisted_2 = ["name", "id"]
const _hoisted_3 = {
  class: "form-check-label",
  for: "inlineRadio1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "form-check-input",
      type: "radio",
      name: _ctx.option.id,
      id: _ctx.option.id,
      value: "option1",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(`update:${_ctx.option.id}`, _ctx.handleRadio($event))))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.option.label), 1)
  ]))
}